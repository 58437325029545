import { config } from '../config/config'
import axios from 'axios';
import { formDataHeader, jsonHeaders, formDataHeaderToken, jsonHeadersToken } from '../helpers';
import { userLogout } from '../Components/common/userlogot';



var configFormDataHeaders = {
    headers: formDataHeader()
}

var formDataToken = {
    headers: formDataHeaderToken()
}

var configJsonHeaders = {
    headers: jsonHeaders()
}

var jsonHeadersTokenHeader = {
    headers: jsonHeadersToken()
}

var user_id = localStorage.getItem('user_id');

export const userService = {
    logIn,
    signUp,
    forgotPassword,
    getQuizes,
    getQuiz,
    calculateBMI,
    submitQuiz,
    getQuizResult,
    getAllSuggestionUsers,
    getPostComments,
    postComment,
    deletePostCommentByCommentId,
    addAndRemoveParentCommentFlag,
    getPostCommentByCommentId,
    getCurrentLocation,
    socialLogin,
    getBlogs,
    getBlog,
    getCategories,
    getProducts,
    updateFavourite,
    getFavourites,
    getProductDetail,
    addToCart,
    getCartProducts,
    updateQuantity,
    addCard,
    updatePassword,
    getCards,
    deleteCard,
    getProductCount,
    addAddress,
    createOrder,
    getActiveOrders,
    getOrderDetails,
    cancelOrder,
    addMoney,
    getPastOrders,
    getCancelledOrders,
    submitRating,
    submitReturnReason,
    getOffers,
    getRatings,
    getNotificationsCount,
    deleteAddress,
    cancelSubscription,
    uploadImage,
    editProfile,
    getAllNotifications,
    getProfile,
    createShipment,
    cartShipment,
    editAddress,
    userSubsciptions,
    sendProfileDetails,
    sendMessageContact,
    registerUser,
    ResendEmailVerificationApi,
    EmailVerifyApi,
    create_profile,
    med_list,
    get_category,
    getmed_detail,
    create_new_post,
    ResetpasswordApi,
    subscribe_user,
    un_subscribe_user,
    deleted_med_data,
    SocialLogin_user_type,
    subscribe_management,
    contactUs,
    certificateLogo,
    TrustedLogo,
    Graphdata,
    banner_data,
    testimonial,
    quizList,
    saveQuizData,
    create_webinar,
    webnair_list,
    webnair_detail,
    share_profile,
    getOtherUserDetails,
    e_learningList,
    e_learningDetails,
    e_learningcategory,
    exerciseVideo,
    exerciseDetails,
    delete_webinar_data,
    AddmyExerciseData,
    getExerise,
    delete_Exercise,
    getExeriseDetails,
    EDITmyExerciseData,
    Start_execution,
    ///////////chat api
    getPreviousChatHistory,
    getMessageChat,
    upload_mediafile,
    get_user,
    add_Availability,
    userAvailability_status,
    getUserAvailabilityDetail,
    getMDList,
    getMDDetail,
    getAppointment_Detail,
    book_appointment,
    get_Appointment_list,
    acceptAppointment,
    rejectAppointment,
    getScheduleList,
    updateSession,
    getCalllerInfo,
    postLike,
    logoutUser,
    getStripeTax,
    reportPost


};

function getStripeTax(params){
    let url=`${config.apiUrl}/admin/getTaxInfo`;
    return axios.post(url, params,configJsonHeaders)
}
function getCalllerInfo(qb_id){
    let url = `${config.apiUrl}/user-qb/${qb_id}`;
    return axios.get(url,jsonHeadersTokenHeader )

}

function updateSession(params){
    let url = `${config.apiUrl}/appointment/update-session/${params?.id}`;
    return axios.put(url,params,jsonHeadersTokenHeader )
}
function rejectAppointment(params){
    // console.log("-------------------------",params)
    let url = `${config.apiUrl}/appointment/change-status/${params?.id}`;
    return axios.put(url,params,jsonHeadersTokenHeader )
}

function acceptAppointment(params){
    // console.log("-------------------------",params)
    let url = `${config.apiUrl}/appointment/change-status/${params?.id}`;
    return axios.put(url,params,jsonHeadersTokenHeader )
}

function getScheduleList(page,status,limit){
    ///v1/my-schedule/requests/:status
    // let url = `${config.apiUrl}/appointment/requests/${status}`;
    let url = `${config.apiUrl}/my-schedule/requests/${status}?page=${page}&limit=${limit ? limit: ""}`;
    return axios.get(url,jsonHeadersTokenHeader )
}
function get_Appointment_list(page,status, limit){
    ///v1/my-schedule/requests/:status
    let url = `${config.apiUrl}/appointment/requests/${status}?page=${page}&limit=${limit ? limit: ""}`;
    // let url = `${config.apiUrl}/my-schedule/requests/${status}`;
    return axios.get(url,jsonHeadersTokenHeader )
}

function book_appointment(params){
    let url = `${config.apiUrl}/appointment/book`;
    return axios.post(url,params,jsonHeadersTokenHeader )
}

function getAppointment_Detail(date,id, user){
    let url = `${config.apiUrl}/user-days-slots/${id}?date=${date}&user=${user}`;
    return axios.get(url,jsonHeadersTokenHeader )
}

function getMDDetail(id){
    let url = `${config.apiUrl}/user-availability/${id}`;
    return axios.get(url,jsonHeadersTokenHeader )
}

function getMDList(page,category,professionalType,userId, login, search ){
    let url = `${config.apiUrl}/list-med?page=${page}&category=${category}&professionalType=${professionalType}&user=${userId}&login=${login}&search=${search}`;
    return axios.get(url,jsonHeadersTokenHeader )
}

function getUserAvailabilityDetail(){
    let url = `${config.apiUrl}/list-availability`;
    return axios.get(url,jsonHeadersTokenHeader )
}

function userAvailability_status(params) {
    let url = `${config.apiUrl}/change-availability-status`;
    return axios.put(url, params, jsonHeadersTokenHeader)
}
async function add_Availability (params)  {
    let url = `${config.apiUrl}/add-availability`;
    return await axios.post(url, params, jsonHeadersTokenHeader)
   
}

function get_user(id){
    let url = `${config.apiUrl}/get-user/${id}`;
    return axios.get(url,configJsonHeaders )
}

function upload_mediafile(data){
    let url = `${config.apiUrl}/upload-media`;
    return axios.post(url,data,formDataHeader )
}

function getMessageChat() {
    let url = `${config.apiUrl}/user/message-threads`;
    return axios.get(url, jsonHeadersTokenHeader)
}
function getPreviousChatHistory(otheruserid) {
    let url = `${config.apiUrl}/user/messages/${otheruserid}`;
    return axios.get(url, jsonHeadersTokenHeader)
}
function Start_execution(params) {
    let url = `${config.apiUrl}/executeExercise`;
    return axios.post(url, params, configJsonHeaders)
}

function EDITmyExerciseData(params) {
    let url = `${config.apiUrl}/editExercise`;
    return axios.post(url, params, configJsonHeaders)
}

function getExeriseDetails(id) {
    let url = `${config.apiUrl}/my-exercise-detail/${id}`;
    return axios.get(url, configJsonHeaders)
}

function delete_Exercise(params) {
    let url = `${config.apiUrl}/deleteExercise`;
    return axios.post(url, params, configJsonHeaders);
}


function getExerise(params) {
    let url = `${config.apiUrl}/listOfExercises?page=${params.page}`;
    return axios.post(url, params, configJsonHeaders)
}
function AddmyExerciseData(params) {
    let url = `${config.apiUrl}/addExercise`;
    return axios.post(url, params, configJsonHeaders)
}

function exerciseDetails(id) {
    let url = `${config.apiUrl}/exercise-detail/${id}`;
    return axios.get(url, configJsonHeaders)
}

function exerciseVideo(params) {
    let url = `${config.apiUrl}/getExerciseVideos`;
    return axios.post(url, params, configJsonHeaders)
}

function e_learningDetails(id) {
    let url = `${config.apiUrl}/e-learning/detail/${id}`;
    return axios.get(url, configJsonHeaders)
}

function e_learningcategory() {
    let url = `${config.apiUrl}/e-learning/all-categories`;
    return axios.get(url, configJsonHeaders)
}

function e_learningList(activeTab, page) {
    let url = `${config.apiUrl}/e-learning/list?category=${activeTab}&page=${page}`;
    return axios.get(url, configJsonHeaders);
}

function getOtherUserDetails(params) {
    let url = `${config.apiUrl}/user-detail/${params.slug}`;
    return axios.get(url, params, configJsonHeaders);
}

function share_profile(params) {
    let url = `${config.apiUrl}/sharing`;
    return axios.post(url, params, jsonHeadersTokenHeader)
}

function webnair_detail(slug) {
    let url = `${config.apiUrl}/webinar/${slug}`;
    return axios.get(url, configJsonHeaders)
}

function webnair_list(page, activeTab, user_id, profile) {
    // console.log("activeTab---", activeTab)
    let url = `${config.apiUrl}/webinars?page=${page}&category=${activeTab}&user=${user_id ? user_id : ""}&profile=${profile ? profile : " "}`;
    return axios.get(url, configJsonHeaders)
}

function create_webinar(params) {
    let url = `${config.apiUrl}/post-webinar`;
    return axios.post(url, params, jsonHeadersTokenHeader)
}
function saveQuizData(params) {
    let url = `${config.apiUrl}/quiz/submit`;
    return axios.post(url, params, jsonHeaders)
}

function quizList() {
    let url = `${config.apiUrl}/quiz`;
    return axios.get(url, jsonHeaders)
}
function testimonial(type) {
    let url = `${config.apiUrl}/get-testimonial?type=${type}`;
    return axios.get(url, jsonHeaders)
}

function banner_data() {
    let url = `${config.apiUrl}/home-data`;
    return axios.get(url, jsonHeaders)
}

function certificateLogo() {
    let url = `${config.apiUrl}/get-certifications`;
    return axios.get(url, jsonHeaders)
}

function Graphdata() {
    let url = `${config.apiUrl}/get-graph-data`;
    return axios.get(url, jsonHeaders)
}

function TrustedLogo() {
    let url = `${config.apiUrl}/get-trusted-logos`;
    return axios.get(url, jsonHeaders)
}

function contactUs(params) {
    let url = `${config.apiUrl}/save-contact-feed`;
    return axios.post(url, params, jsonHeaders);
}

function subscribe_management(page) {
    let url = `${config.apiUrl}/my-subscribers?page=${page}`;
    return axios.get(url, jsonHeadersTokenHeader)
}

function SocialLogin_user_type(params) {
    let url = `${config.apiUrl}/updateUserType`;
    return axios.post(url, params, jsonHeadersTokenHeader);
}
function un_subscribe_user(id) {
    let url = `${config.apiUrl}/unsubscribe-user/${id}`;
    // console.log("jsonHeadersToken", jsonHeadersToken)
    return axios.delete(url, jsonHeadersTokenHeader);
}

function subscribe_user(params) {
    let url = `${config.apiUrl}/subscribe-user`;
    // console.log("jsonHeadersToken---", jsonHeadersTokenHeader)
    return axios.post(url, params, jsonHeadersTokenHeader);
}

function ResetpasswordApi(params) {
    let url = `${config.apiUrl}/resetPassword`;
    return axios.post(url, params, configJsonHeaders);
}

function create_new_post(data) {
    let url = `${config.apiUrl}/med-posts`;
    return axios.post(url, data, formDataToken)
}
function getmed_detail(id, user_id) {
    let url = `${config.apiUrl}/postDetail/${id}?user=${user_id}&website=1`;
    return axios.get(url, configJsonHeaders)
}
function get_category() {
    let url = `${config.apiUrl}/big-categories`;
    return axios.get(url, configJsonHeaders)
}
function med_list(page, userType, activeTab, user_id) {
    // console.log("activeTab---", activeTab)
    let url = `${config.apiUrl}/get-posts?userType=${userType}&category=${activeTab}&user=${user_id ? user_id : ""}&page=${page}`;
    return axios.get(url, configJsonHeaders)
}
function create_profile(data) {
    let url = `${config.apiUrl}/createProfile`;
    return axios.post(url, data, formDataToken)
}
function EmailVerifyApi(params) {
    let url = `${config.apiUrl}/verify-mail/${params.token}`;
    return axios.post(url, params, configJsonHeaders)
}
function ResendEmailVerificationApi(params) {
    let url = `${config.apiUrl}/resend-mail?email=${params.email}`;
    return axios.post(url, params, configJsonHeaders);
}
function registerUser(params) {
    let url = `${config.apiUrl}/userSignUp`;
    return axios.post(url, params, configJsonHeaders)
}
function logIn(params) {
    let url = `${config.apiUrl}/userLogin`;
    return axios.post(url, params, configJsonHeaders)
}

function socialLogin(params) {
    let url = `${config.apiUrl}/userSignUp`;
    if (typeof params == FormData)
        return axios.post(url, params, configFormDataHeaders)
    else
        return axios.post(url, params, configJsonHeaders)
}

function signUp(params) {
    let url = `${config.apiUrl}/userSignUp`;
    return axios.post(url, params, configFormDataHeaders)
}

function forgotPassword(params) {
    let url = `${config.apiUrl}/forgotPassword`;
    return axios.post(url, params, configJsonHeaders)
}

function getQuizes(city) {
    let url = `${config.apiUrl}/quiz?userId=${user_id ? user_id : ''}&location=${city}`;
    return axios.get(url, configFormDataHeaders);
}

function getQuiz(quizeId) {
    let url = `${config.apiUrl}/quiz/${quizeId}?userId=${user_id}`;
    return axios.get(url, configFormDataHeaders);
}

function calculateBMI(params) {
    let url = `${config.apiUrl}/calculateBMI`;
    return axios.post(url, params, configFormDataHeaders);
}

function submitQuiz(params) {
    let url = `${config.apiUrl}/quiz/submit`;
    return axios.post(url, params, configJsonHeaders);
}

function getQuizResult(quizeId) {
    let url = `${config.apiUrl}/quiz/${quizeId}/user/${user_id}`;
    return axios.get(url, configFormDataHeaders);
}

function getAllSuggestionUsers() {
    let url = `${config.apiUrl}/get-tag-users`;
    return axios.get(url, configJsonHeaders);
}

function postComment(params) {
    let url = `${config.apiUrl}/post-comment`;
    return axios.post(url,params, jsonHeadersTokenHeader);
}

function deletePostCommentByCommentId(commentId) {
    let url = `${config.apiUrl}/delete-comment/${commentId}`;
    return axios.delete(url,jsonHeadersTokenHeader);
}

function addAndRemoveParentCommentFlag(params) {
    let url = `${config.apiUrl}/flag-comment`;
    return axios.post(url,params,jsonHeadersTokenHeader);
}

function getPostCommentByCommentId(commentId,startCount,endCount) {
    let url = `${config.apiUrl}/parent-comment/${commentId}?startCount=${startCount}&endCount=${endCount}`;
    return axios.get(url,jsonHeadersTokenHeader);
}

function getPostComments(postId,page) {
    let url = `${config.apiUrl}/get-comment/${postId}?page=${page}`;
    return axios.get(url, jsonHeadersTokenHeader);
}

function getCurrentLocation(lat, lng) {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=administrative_area_level_2&sensor=true&key=AIzaSyAyiC-K1_Bxfbh6jCaG3zs_Y_5PxrsXUGE`;
    return axios.get(url, jsonHeaders);
}

function getBlogs(page, category) {
    let url = `${config.apiUrl}/blog?page=${page}&category=${category}`;
    return axios.get(url, configJsonHeaders);
}

function getBlog(id) {
    let url = `${config.apiUrl}/blog/${id}`;
    return axios.get(url, configJsonHeaders);
}

function getCategories(searchTxt) {
    let url = `${config.apiUrl}/product/category?search=${searchTxt}`;
    return axios.get(url, configJsonHeaders);
}

function getProducts(categoryId, searchTxt, pageNo) {
    let url = `${config.apiUrl}/product/all/list?category=${categoryId}&search=${searchTxt}&page=${pageNo}&userId=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function updateFavourite(params) {
    let url = `${config.apiUrl}/product/favourites`;
    return axios.post(url, params, configJsonHeaders);
}

function getFavourites() {
    let url = `${config.apiUrl}/favourites/products?user=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function getProductDetail(productId) {
    let url = `${config.apiUrl}/product/${productId}/details/product?userId=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function addToCart(params) {
    let url = `${config.apiUrl}/cart`;
    return axios.post(url, params, configJsonHeaders);
}

function getCartProducts() {
    let url = `${config.apiUrl}/cart?user=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function updateQuantity(params) {
    let url = `${config.apiUrl}/cart`;
    return axios.put(url, params, configJsonHeaders);
}

function cartShipment(params, cart_id) {
    let url = `${config.apiUrl}/cart/${cart_id}`;
    return axios.put(url, params, configJsonHeaders);
}

function addCard(params) {
    let url = `${config.apiUrl}/card`;
    return axios.put(url, params, jsonHeadersTokenHeader);
}

// function addCard(params) {
//     let url = `${config.apiUrl}/card`;
//     return axios.post(url, params, configJsonHeaders);
// }

function updatePassword(params) {
    let url = `${config.apiUrl}/updatePassword`;
    return axios.post(url, params, configJsonHeaders);
}

function getCards() {
    let url = `${config.apiUrl}/card?userId=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function deleteCard(id) {
    let url = `${config.apiUrl}/card/${id}`;
    return axios.delete(url, configJsonHeaders);
}

function getProductCount() {
    let url = `${config.apiUrl}/count?userId=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function getNotificationsCount() {
    let url = `${config.apiUrl}/getUnreadCount`;
    return axios.post(url, JSON.stringify({ userId: user_id }), configJsonHeaders);
}

function addAddress(params) {
    let url = `${config.apiUrl}/cart/address`;
    return axios.post(url, params, configJsonHeaders);
}

function deleteAddress(id) {
    let url = `${config.apiUrl}/cart/address/${id}`;
    return axios.delete(url, configJsonHeaders);
}

function createOrder(params) {
    let url = `${config.apiUrl}/order`;
    return axios.post(url, params, configJsonHeaders);
}

function cancelOrder(id, params) {
    let url = `${config.apiUrl}/order/${id}`;
    return axios.put(url, params, configJsonHeaders);
}

function getActiveOrders() {
    let url = `${config.apiUrl}/active-order?user=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function getPastOrders() {
    let url = `${config.apiUrl}/past-order?user=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function getCancelledOrders() {
    let url = `${config.apiUrl}/cancel-order?user=${user_id}`;
    return axios.get(url, configJsonHeaders);
}

function getOrderDetails(id) {
    let url = `${config.apiUrl}/order/${id}`;
    return axios.get(url, configJsonHeaders);
}

function addMoney(params) {
    let url = `${config.apiUrl}/transactions/deposit`;
    return axios.put(url, params, configJsonHeaders);
}

function submitReturnReason(params, id) {
    let url = `${config.apiUrl}/order/${id}/return`;
    return axios.put(url, params, configJsonHeaders);
}

function submitRating(params) {
    let url = `${config.apiUrl}/rating`;
    return axios.post(url, params, configJsonHeaders);
}

function getOffers() {
    let url = `${config.apiUrl}/offer`;
    return axios.get(url, configJsonHeaders);
}

function getRatings(id) {
    let url = `${config.apiUrl}/rating/${id}`;
    return axios.get(url, configJsonHeaders);
}

function cancelSubscription(params) {
    let url = `${config.apiUrl}/transactions/subscribe/cancel`;
    return axios.put(url, params, configJsonHeaders);
}

function uploadImage(params) {
    let url = `${config.imageUrl}fileupload`;
    return axios.post(url, params, configFormDataHeaders);
}

function editProfile(params) {
    let url = `${config.apiUrl}/editProfile`;
    return axios.post(url, params, jsonHeadersTokenHeader);
}

function getAllNotifications(params) {
    let url = `${config.apiUrl}/getAllNotifications?page=${params?.page}&notification_type=${params?.notification_type}`;
    return axios.post(url, params, configJsonHeaders);
}

// function getProfile(params) {
//     // console.log("params---",params)
//     let url = `${config.apiUrl}/user-profile/${params?.id}`;
//     return axios.post(url, params, configJsonHeaders);
// }

function getProfile(params) {
    console.log("params-------",params)
    let url = `${config.apiUrl}/getProfile`;
    return axios.post(url, params, jsonHeadersTokenHeader);
}

// async function getProfile(params) {
//     let url = `${config.apiUrl}/getProfile`;
//     let response = await axios.post(url, params, jsonHeadersTokenHeader);
//     console.log("userdata----------",response.data)
//     // userdata.then((res) =>{
//     //     console.log("result--------",res);
//     // })
//     // .catch((error) =>{
//     //     console.log("error",error)
//     // })
//     // userdata.then(function (result) {
//     //     console.log("result", result)
//     // })
//     if (response.status === 401) {
//         userLogout()
//     } else {
//         return response
//     }
// }

function deleted_med_data(id) {
    let url = `${config.apiUrl}/delete-post/${id}`;
    return axios.delete(url, jsonHeadersTokenHeader);
}

function delete_webinar_data(id) {
    let url = `${config.apiUrl}/delete-webinar/${id}`;
    return axios.delete(url, jsonHeadersTokenHeader);
}

function createShipment(params) {
    let url = `${config.apiUrl}/cart/createShipment`;
    return axios.post(url, params, configJsonHeaders);
}

function editAddress(params) {
    let url = `${config.apiUrl}/cart/address`;
    return axios.put(url, params, configJsonHeaders)
}

function userSubsciptions(id) {
    let url = `${config.apiUrl}/subscribed-products/${id}`;
    return axios.get(url, configJsonHeaders);
}
function sendProfileDetails(params) {
    let url = `${config.apiUrl}/sharing`;
    return axios.post(url, params, configJsonHeaders);
}
function sendMessageContact(params) {
    let url = `${config.apiUrl}/sendEmail`;
    return axios.post(url, params, configJsonHeaders);
}

function postLike(params) {
    let url = `${config.apiUrl}/postLike`;
    return axios.post(url, params, jsonHeadersTokenHeader);
}



function reportPost(params) {
    let url = `${config.apiUrl}/post/report`;
    return axios.post(url, params, jsonHeadersTokenHeader);
}

function handleError(error) {
    if (error.response && error.response.status == 401) {
        alert(error.response.data.message);
        localStorage.clear();
        window.location.href = "/";
    } else {

        if (error.name == 'NetworkError') {
            alert('Please check you network');
        } else if (error.response) {
            alert(error.response.data.message);
        }
        else {
            alert("Something went wrong")
        }


    }
}

function logoutUser(params){
    let url = `${config.apiUrl}/logOut`;
    return axios.put(url, params, jsonHeadersTokenHeader)
}