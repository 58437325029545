import Header from "./common/Header";
import Footer from "./common/Footer";
import { useEffect, useState } from "react";
import { userService } from "../services";
import { config } from '../config/config';
import Pagination from "react-js-pagination";
import Download from "./common/Download";
import Loader from './common/Loader'

const ELearning = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setcategoryList] = useState()
  const [totalpage, settotalpage] = useState();
  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "");
  const [e_learningDetail, setE_learningDetail] = useState()
  const [page, setPage]=useState(1)
  const [data, setData]=useState("")

  useEffect(() => {
    getCotagery()
    if(activeTab){
      E_learning(activeTab, page)
    }
  }, [])

  useEffect(() => {
    if (categoryList?.length > 0) {
      var data = categoryList[0]?._id;
      setData(data)
      if (data) {
        E_learning(data, page);
      }
    }
  }, [data]);

  function getCotagery() {
    userService.e_learningcategory().then((res) => {
      setcategoryList([...res?.data?.data])
      if(activeTab){
        E_learning(activeTab, page)
      }
      else{
        setActiveTab(res?.data?.data[0]?._id)
        E_learning(res?.data?.data[0]?._id, page)
      }
    

    })
      .catch((Error) => {
      })
  }

  function handlePageChange(pageNumber) {
    if (activeTab) {
      setPage(pageNumber);
      E_learning(activeTab, pageNumber)
    }
  }

  const handleTabClick = (tab) => {
    localStorage.setItem('activeTab', tab._id);
    setActiveTab(tab._id);
    setPage(1)
    E_learning(tab?._id, 1)
  };

  function E_learning(activeTab, page) {
    setIsLoading(true)
    userService.e_learningList(activeTab, (page-1)).then((res) => {
      if (res?.data?.status === 200) {
        settotalpage(res?.data?.total_records)
        setE_learningDetail(res?.data?.data)
        setIsLoading(false)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const onVideoPlay = (item) => {
    setE_learningDetail(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
      )
    )
  }
  const onVideoPause = (item) => {
    setE_learningDetail(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
      )
    )
  }

  function handleContextMenu(event) {
    event.preventDefault();
}

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('activeTab');
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);


  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med">
        <div className="container">
          <div class="big-med-heading d-flex align-items-start justify-content-between w-100">
            <h1>Educational Videos</h1>
          </div>
        </div>
      </div>
      <div className="world-box elearning-box">
        <div className="container">
          <div>
            <div className="tab-container">
              {categoryList?.map(tab => (
                <button
                  key={tab._id}
                  className={`cat-list mb-3 ${activeTab === tab._id ? 'active' : ''}`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab?.name}
                </button>
              ))}
            </div>
          </div>

          <div class="tab-content">
            <div class="tab-pane container active" >
              <div className="webinar-outer-box">
                <div className="row">
                  {e_learningDetail?.length > 0 ?
                    e_learningDetail?.map((item, key) => {
                      return (
                        <>
                          <aside className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <span>
                              <div className="big-med-wrap">
                                <div className="big-med-icon-text">
                                  <div className="big-med-main-img big-med-main-vid"
                                   onClick={(e) =>window.location.href=`/e-learning-details/${item?._id}`} 
                                   >
                                    <div className="big-med-main-img big-med-main-vid"
                                      onMouseOver={event => onVideoPlay(item)}
                                      onMouseOut={event => onVideoPause(item)}>
                                      <span
                                      >
                                        {item?.videoPlay ?
                                          <video
                                          onContextMenu={handleContextMenu}
                                          controlsList="nodownload"
      
       
                                          
                                            autoPlay={item?.videoPlay}
                                            width="100%"
                                            height="100%"
                                            muted
                                            loop
                                            preload='auto'
                                            playsinline
                                            controls ={false}
                                            poster={ item?.url}

                                          >
                                            <source
                                              src={ item?.url + "#t=0.1"}
                                              type="video/mp4"
                                            />
                                            <source
                                              src={ item?.url + "#t=0.1"}
                                              type="video/ogg"
                                            />
                                            <source
                                              src={ item?.url + "#t=0.1"}
                                              type="video/webm"
                                            />
                                            {/* Add the subtitle track */}
                                            <track
                                              label="English"
                                              kind="subtitles"
                                              src={config.imageUrl+ item?.captions}
                                              srcLang="en"
                                              default
                                            />
                                          </video>
                                          :

                                          <div className="big-med-main-img">
                                             <video
                                          onContextMenu={handleContextMenu}
                                          controlsList="nodownload"
      
       
                                          
                                            autoPlay={item?.videoPlay}
                                            width="100%"
                                            height="100%"
                                            muted
                                            loop
                                            preload='auto'
                                            playsinline
                                            controls ={false}
                                            poster={ item?.url}

                                          >
                                            <source
                                              src={ item?.url + "#t=0.1"}
                                              type="video/mp4"
                                            />
                                            <source
                                              src={ item?.url + "#t=0.1"}
                                              type="video/ogg"
                                            />
                                            <source
                                              src={ item?.url + "#t=0.1"}
                                              type="video/webm"
                                            />
                                          
                                          </video>
                                                                        {/* <a href="#"><img src={config.imageUrl+ item?.thumbnail + "#t=0.1"} alt="img" /></a> */}
                                                                    </div>
                                        }
                                      </span>

                                      {!item.videoPlay &&
                                        <img className="video-btn" onClick={(e) => onVideoPlay(item)} src={require("../images/video-play.svg").default} alt="img" />
                                      }
                                    </div>
                                  </div>
                                  <div className="outer-content">
                                    <div className="big-med-text">
                                      <h4>{item?.title}</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </aside>
                        </>
                      )
                    }) :
                    <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                      <div className="no-data-box">
                        <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                        <h3>No data found</h3>
                      </div>
                    </div>
                  }
                   {totalpage > 15 && (
                    <div className='col-md-12 text-center'>
                    <div className="pagination1">
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={totalpage}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        hideDisabled={true}
                        prevPageText={"Previous"}
                        nextPageText={"Next"}
                      />
                    </div>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* contact us section start */}
      <Download />

      <Footer />

    </>
  );
};
export default ELearning;
