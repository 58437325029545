import Header from './Header'
import Footer from './Footer'

const CopyRight = () => {
    return (
        <>
            <Header />

            <div className="container">
                <h3><b>Copyright Notice</b></h3>
                <p> All files and information contained in this web app at https://www.big4health.com are copyright by BIG4 Health L.L.C, and may not be duplicated, copied, modified or adapted, in any way without our written permission. Our Web App or Blog may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words, graphics, and logos.
                </p>
                <p>Your use of our Web App, Blog or Services does not constitute any right or license for you to use our service marks or trademarks, without the prior written permission of BIG4 Health L.L.C.
                </p>
                <p>Our Content, as found within our Web App, Blog and Services, is protected under local and foreign copyrights. The copying, redistribution, use or publication by you of any such Content, is strictly prohibited. Your use of our App and Services does not grant you any ownership rights to our Content.
                </p>
                <p>Creators can only share videos they've made or have permission to use. That means they shouldn't upload videos made by others or include content owned by someone else without the right permissions.</p>
                <h5><b>Enforcement of copyright</b></h5>
                <p>BIG4 Health L.L.C takes the protection of its copyright very seriously.
                </p>
                <p>If BIG4 Health L.L.C discovers that you have used its copyright materials in contravention of the license above, BIG4 Health L.L.C may bring legal proceedings against you seeking monetary damages and an injunction to stop you using those materials. You could also be ordered to pay legal costs.
                </p>
                <p>If you become aware of any use of BIG4 Health L.L.C’s copyright materials that contravenes or may contravene the license above, please report this to us immediately.
                </p>
                <p>Copyright © BIG4 Health L.L.C {new Date().getFullYear()} All Rights Reserved
                </p>
            </div>






            <Footer />
        </>
    )
}

export default CopyRight;
