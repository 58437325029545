let token = localStorage.getItem("token")
console.log("token", token)

export function formDataHeader() {
    return Object.assign({}, { "Content-Type": "application/x-www-form-urlencoded" }, commonHeaders);
}

export function formDataHeaderToken() {
    return Object.assign({}, { "Content-Type": "application/x-www-form-urlencoded", 'Authorization': token }, commonHeaders);
}

export function jsonHeaders() {
    return Object.assign({}, { "Content-Type": "application/json" }, commonHeaders);
}

export function jsonHeadersToken() {
    return Object.assign({}, { "Content-Type": "application/json", 'Authorization': token }, commonHeaders());
}

function commonHeaders() {
    return {
        'deviceType': 'w',
        'Cache-Control': 'no-store',
        'appVersion': '1.0',
        'Access-Control-Allow-Origin': 'true',
        'timezone': 'Asia/Kolkata',
    }
}