import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { config } from '../config/config';
import { userService } from '../services';
import Footer from './common/Footer';
import Header from './common/Header';
import Loader from './common/Loader';
import { useHistory } from 'react-router-dom';

const Cart = () => {

    const history = useHistory()
    const [cartProducts, setCartProducts] = useState([]);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [prices, setPrices] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [loadingStates, setLoadingStates] = useState(0);
    const [cartCount, setCartCount] = useState('');
    const [shipping, setShipping] = useState('');
    const [tax, setTax] = useState('');
    const [taxVal, setTaxVal] = useState('');
    const [favCount, setFavCount] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [temp, setTemp] = useState('0')

    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) {
            setUserId(user_id);
            setIsLoading(true);
            getCartProducts();
        }
    }, []);

    function getCartProducts() {
        setIsLoading(true);
        userService.getCartProducts().then((response) => {
            setIsLoading(false);

            if (response.data.status == 200) {
                let tmpArry = [];
                setCartCount(response.data.cart.lineitems.length);
                response.data.cart.lineitems.map((product) => {
                    tmpArry.push({ price: product?.qty * product?.product?.price, qty: product?.qty, subscription: product.subscription, subscription_type: product.subscription_type, discount: product?.product?.discount });
                });
                setPrices(tmpArry);
                setCartProducts(response.data.cart.lineitems);
                setSimilarProducts(response.data.similarProducts);
                setTax(response.data.tax);
                setShipping(response.data.shipping);
                setCartItems(response.data.cart.products);

            } else {
                setCartCount('');
                setCartProducts([]);
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setCartProducts([]);
        });
    }

    function handleQuantity(i, type, qty, price, id) {
        var tmpArry = prices;
        let tmpQty
        if (type === 'INC' && qty < cartProducts[i].product.quantity) {
            tmpQty = qty + 1;
        } else if (type === 'DEC' && qty > 1) {
            tmpQty = qty - 1;
        } else {
            return
        }
        tmpArry[i] = { ...tmpArry[i], qty: tmpQty, price: tmpQty * price };

        setPrices(tmpArry);
        setLoadingStates(loadingStates + 1);
        updateQuantity(type, id)
    }

    function updateQuantity(type, id) {
        setIsLoading(true);
        let params = { type: type, lineitem: id }
        userService.updateQuantity(params).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                if (type === 'DEL') getCartProducts();
                setTemp('787')
            } else {
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
        });
    }

    function getTotalPrice() {
        let sum = 0;
        prices.map((item) => {
            let value = (item.price - (item.discount * item.price / 100))
            sum = sum + value
        });
        return sum;
    }

    function getTax() {
        return parseFloat(((getTotalPrice() - getSubDiscount()) * tax) / 100).toFixed(2);
    }

    function getSubDiscount() {
        let sum = 0;
        prices.forEach((item) => {
            let value = item.subscription ? (item.price - (item.discount * item.price / 100)) / 100 * parseFloat(item.subscription_type.save_percent) : 0
            sum = sum + value
        });
        return sum.toFixed(2);
    }

    function getGrandTotalPrice() {

        let gPrice = getTotalPrice();       //setting initial GrandTotal from getTotalPrice()

        let taxVal = parseFloat(getTax());
        let shippingVal = 0;
        gPrice += taxVal;
        gPrice += shippingVal;
        gPrice -= getSubDiscount()
        return gPrice;
    }


    function handleFavourite(id, status) {
        if (userId) {
            var answer = true;
            if (status) answer = window.confirm("Are you sure want to remove it from your favourite list.");
            if (answer) {
                setIsLoading(true);
                let params = { user: userId, product: id, is_favourite: !status }
                userService.updateFavourite(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status == 200) {
                        setFavCount(response.data.favCount);
                        getCartProducts()
                        setTemp(favCount)
                    } else {
                        toast.error("Some Error Occur");
                    }
                }).catch((error) => {
                    setIsLoading(false);
                });
            }
        } else {
            window.location.pathname = '/signin'
        }
    }

    function getDiscountedPrice(item) {
        return (item.price - (item.discount / 100) * item.price).toFixed(2)
    }

    function getDiscountedPriceWithSub(item) {
        let discountedPrice = (item.product.price - (item.product.discount / 100) * item.product.price).toFixed(2)
        let finalPrice = item.subscription ? discountedPrice * item.subscription_type.save_percent / 100 : 0
        return discountedPrice - finalPrice
    }

    function addToCart(id) {
        if (window.confirm("Are you sure you want to add this product?")) {
            if (userId) {
                setIsLoading(true);
                let params = { user: userId, product: id, qty: 1 }
                userService.addToCart(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status == 200) {
                        setCartCount(response.data.cartCount);
                        getCartProducts();
                        setTemp(cartCount)
                        toast.success("Product added to cart successfully.")
                    } else {
                        toast.error("Some Error Occur");
                    }
                }).catch((error) => {
                    setCartCount('');
                    setIsLoading(false);
                })

            } else {
                window.location.pathname = './signin';
            }
        }
    }

    function goToCart() {
        window.location.href = window.location.origin + '/cart';
    }

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} refresh={temp} />
            {isLoading && <Loader />}
            <div className="main-div">
                <section className="py-4">
                    <div className="container">
                        <div className="row">

                            <aside className={cartProducts.length > 0 ? "col-lg-9" : "col-lg-12"}>
                                <h5 className="mb-4">Your Shopping Cart</h5>
                                {cartProducts.length > 0 ? cartProducts.map((cartProduct, i) => {
                                    return (<div>
                                        <div className="row align-items-center">
                                            <div className="col-lg-5 col-md-5 col-sm-6 mb-2">
                                                <figure className="itemside align-items-center mb-0">
                                                    <div className="aside1">
                                                        <img src={cartProduct.product?.images.length > 0 ? config.imageUrl + cartProduct.product.images[0].image : ''} alt="img" />
                                                    </div>
                                                    <figcaption className="info"> <a href={"./product_details?id=" + cartProduct.product._id} className="title text-dark">{cartProduct.product?.name}</a>
                                                        <p className="text-muted small mb-0"> Price:
                                                            {cartProduct.product?.discount > 0 && <del>${cartProduct.product.price}</del>} ${getDiscountedPrice(cartProduct.product)}
                                                        </p>
                                                        <p className="text-muted small mb-0">{cartProduct.subscription == true && `Subscribed(-${cartProduct.subscription_type.save_percent}%)`}</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-3 mb-2">
                                                <button type="button" class="btn add-minus mr-2" onClick={() => handleQuantity(i, 'DEC', prices[i]?.qty, cartProduct.product?.price, cartProduct._id)}><i class="fa fa-minus" aria-hidden="true"></i></button> {prices[i]?.qty} <button type="button" class="btn add-minus ml-2" onClick={() => handleQuantity(i, 'INC', prices[i]?.qty, cartProduct.product?.price, cartProduct._id)}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-3 mb-2">
                                                {cartProduct.product?.discount > 0 ? <p className="text-muted small mb-0">
                                                    <del>${((cartProduct.product?.price) * prices[i]?.qty).toFixed(2)}</del>
                                                </p> : null}
                                                <div className="price-wrap"> ${(getDiscountedPriceWithSub(cartProduct) * prices[i]?.qty).toFixed(2)}</div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 mb-2 removebtn text-center">
                                                <button className="btn btn-light btn-round" onClick={() => updateQuantity('DEL', cartProduct._id)}><i className="fa fa-trash mr-1"></i> Remove</button>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>)
                                }) :
                                    !isLoading ? <p className="no-categgory text-center">No Products Added Yet. </p> : ''
                                }
                            </aside>
                            {cartProducts.length > 0 &&
                                <aside className="col-lg-3 summry">
                                    <div className="card">
                                        <div className="card-body">
                                            <dl className="dlist-align">
                                                <dt>Total price:</dt>
                                                <dd className="text-right ml-3">${getTotalPrice().toFixed(2)}</dd>
                                            </dl>

                                            {getSubDiscount() > 0 && <dl className="dlist-align">
                                                <dt>Discount:</dt>
                                                <dd className="text-right text-success ml-3">-${getSubDiscount()}</dd>
                                            </dl>}
                                            <dl className="dlist-align">
                                                <dt>Tax({tax}%):</dt>
                                                <dd className="text-right ml-3">${getTax()}</dd>
                                            </dl>
                                            <dl className="dlist-align">
                                                <dt>Shipping:</dt>
                                               
                                                <dd className="text-right ml-3" style={{ color: 'red' }}>{'pending'}</dd>
                                            </dl>
                                            <dl className="dlist-align">
                                                <dt>Grand Total:</dt>
                                                <dd className="text-right b ml-3"><strong>${getGrandTotalPrice().toFixed(2)}</strong></dd>
                                            </dl>
                                            <hr /> <a onClick={() => history.push('checkout')} className="btn chkoutbtn w-100 shadow">Checkout </a>
                                        </div>
                                    </div>

                                </aside>
                            }

                        </div>
                    </div>
                </section>

                {similarProducts.length > 0 ?
                    <section className="product-page-area similar_products py-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="mb-2">Similar Products</h5>
                                </div>
                                {similarProducts.map((similarProduct, i) => {
                                    let btnStatus = cartItems?.filter(cartItem => similarProduct?._id === cartItem).length > 0;

                                    return (
                                        <div className="col-lg-3 col-md-4 mb-3">
                                            <div className="product-list-box">
                                                <a href={"./product_details?id=" + similarProduct._id}>
                                                    <div className="product-list-image text-center">
                                                        <img src={similarProduct.images.length > 0 ? config.imageUrl + similarProduct.images[0].image : ''} alt="img" />
                                                    </div>
                                                    <div className="product-list-details">
                                                        <h4>{similarProduct.name}</h4>
                                                        {similarProduct?.rating && <h6>
                                                            {Array(parseInt(similarProduct?.rating)).map(t => {
                                                                return <i class="fa fa-star" aria-hidden="true" />
                                                            })}
                                                            <span className="total-review ml-1">({similarProduct?.reviewCount} reviews)</span>
                                                        </h6>}
                                                        {similarProduct?.discount > 0 ? <h5>Price:
                                                            <del className="orginal-amount">${parseFloat(similarProduct?.price).toFixed(2)}</del>
                                                            <span className="discount-amount"> ${getDiscountedPrice(similarProduct)}</span>
                                                        </h5> : <h5>Price: <span className="discount-amount"> ${parseFloat(similarProduct?.price).toFixed(2)}</span></h5>}
                                                    </div>
                                                </a>
                                                <div className="product-details">
                                                    <div className="buttons d-flex flex-row">
                                                        <a className="cart shadow pb-3" href={/* /my_favorites */ '#'} onClick={() => handleFavourite(similarProduct._id, similarProduct.is_favourite)}><i className={similarProduct.is_favourite ? "fa fa-heart" : "fa fa-heart-o"}></i></a>
                                                        <a className="btn btn-success cart-button btn-block shadow pointer" onClick={() => btnStatus ? '' : addToCart(similarProduct._id)}><i className="fa fa-shopping-cart mr-2" style={{ fontSize: "19px" }}></i> {btnStatus ? 'ADDED' : 'ADD'} TO CART </a>
                                                    </div>
                                                    <div class="weight"> </div>
                                                </div>
                                            </div>
                                            {/*  */}
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </section>
                    : null}

            </div>
            <Footer />
        </>
    )
}
export default Cart;