import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

export default function Counter(props) {
  function decimalLength(number) {
    const numberStr = number.toString();
    const parts = numberStr.split('.');
    if (parts.length === 1) {
      return 0;
    }
    return parts[1].length;
  }

  return (
    <div className="number">
      {props?.data?.value_prefix}<CountUp decimals={decimalLength(props?.data?.count)}
        duration={5} delay={0} className="counter" end={props?.data?.count} redraw={true} />{props?.data?.count_type}
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start} delayedCall>
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </div>
  );
}
