import Header from "./common/Header";
import Footer from "./common/Footer";
import Download from "./common/Download";
import { userService } from "../services";
import { useEffect, useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useSSR } from "react-i18next";
import { config } from "../config/config";
import Loader from './common/Loader'
const ExerciseListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState(localStorage.getItem("selectedTab") || "Beginner");
  const [user_id, setUserId] = useState(localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "")
  const [exerciseData, setExerciseData] = useState("")
  const [onplaying, setOnPlaying] = useState(false)
  const play = useRef("")

  useEffect(() => {
    getExerciseVideo(user_id, userType)
  }, [])

  useEffect(() => {
    const storedTab = localStorage.getItem("selectedTab");
    if (storedTab) {
      setUserType(storedTab);
    }
  }, []);

  function getExerciseVideo(user_id, userType) {
    const params = {
      userId: user_id,
      videoType: userType
    }
    setIsLoading(true)
    userService.exerciseVideo(params).then((res) => {
      if (res?.data?.status === 200) {
        setExerciseData(res?.data?.data)
        setIsLoading(false)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }


  const onVideoPlay = (item) => {
    setExerciseData(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
      )
    )
  }
  const onVideoPause = (item) => {
    setExerciseData(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
      )
    )
  }

  const handleTabSelect = (selectedTab) => {
    localStorage.setItem("selectedTab", selectedTab)
    setUserType(selectedTab);
    getExerciseVideo(user_id, selectedTab);
  };

  function handleContextMenu(event) {
    event.preventDefault();
  }

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="container">
        <div className="back-btn-sec mb-3">
          <Button className="btn back-btn" href="/exercise"><i className="fa fa-angle-left mr-2"></i>Back to Exercise</Button>
        </div>
      </div>
      <section className="exercise-banner">
        <div className="container">
          <div className="exercise-text">
            <h1>Exercise is <span>medicine,</span> prescribed by you,<br /> for you, to create <span>a healthier<br /> and happier</span> you</h1>
          </div>
        </div>
      </section>
      <section className="exercise-listing">
        <div className="world-box exercise-tab-area">
          <div className="container">
            <Tabs
              defaultActiveKey={userType}
              id="uncontrolled-tab-example"
              className="my-3 mb-4"
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="Beginner"
                title="Beginner"
              >
              </Tab>
              <Tab eventKey="Intermediate" title="Intermediate">
              </Tab>
              <Tab eventKey="Advanced" title="Advanced">
              </Tab>
            </Tabs>
            <div class="tab-content">
              <div class="tab-pane container active" id="exercise-1">
                <div className="webinar-outer-box">
                  <div className="row">
                    {exerciseData?.length > 0 ?
                      exerciseData?.map((item, key) => {
                        return (
                          <>
                            <aside className="col-md-4">
                              <span>
                                <div className="big-med-wrap">
                                  <div className="big-med-icon-text">
                                    <div className="big-med-main-img big-med-main-vid">
                                      <div className="big-med-main-img big-med-main-vid">
                                        <div className="big-med-main-img big-med-main-vid" onClick={(e) => window.location.href = `/exercise-details/${item?._id}`} >
                                          <div className="big-med-main-img big-med-main-vid"
                                            onMouseOver={event => onVideoPlay(item)}
                                            onMouseOut={event => onVideoPause(item)}
                                          >

                                            {item?.videoPlay ?
                                              <video
                                                onContextMenu={handleContextMenu}
                                                controlsList="nodownload"
                                                autoPlay={item?.videoPlay}
                                                width="100%"
                                                height="100%"
                                                muted
                                                loop
                                                preload='auto'
                                                playsinline
                                                poster={item?.videoLink}

                                              >
                                                <source
                                                  src={item?.videoLink + "#t=0.1"}
                                                  type="video/mp4"
                                                />
                                                <source
                                                  src={item?.videoLink + "#t=0.1"}
                                                  type="video/ogg"
                                                />
                                                <source
                                                  src={item?.videoLink + "#t=0.1"}
                                                  type="video/webm"
                                                />
                                                <track
                                                  label="English"
                                                  kind="subtitles"
                                                  src={config.imageUrl + item?.captions}
                                                  srcLang="en"
                                                  default
                                                />
                                              </video>
                                              :
                                              <video
                                              onContextMenu={handleContextMenu}
                                              controlsList="nodownload"
                                              autoPlay={item?.videoPlay}
                                              width="100%"
                                              height="100%"
                                              muted
                                              loop
                                              preload='auto'
                                              playsinline
                                              poster={item?.videoLink}

                                            >
                                              <source
                                                src={item?.videoLink + "#t=0.1"}
                                                type="video/mp4"
                                              />
                                              <source
                                                src={item?.videoLink + "#t=0.1"}
                                                type="video/ogg"
                                              />
                                              <source
                                                src={item?.videoLink + "#t=0.1"}
                                                type="video/webm"
                                              />
                                              <track
                                                label="English"
                                                kind="subtitles"
                                                src={config.imageUrl + item?.captions}
                                                srcLang="en"
                                                default
                                              />
                                            </video>

                                            }

                                            {!item.videoPlay &&
                                              <img className="video-btn" onClick={(e) => onVideoPlay(item)} src={require("../images/video-play.svg").default} alt="img" />
                                            }


                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="outer-content mt-3">
                                      <div className="big-med-text">
                                        <h4>{item?.type}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </aside>

                          </>
                        )
                      })
                      :
                      <>
                        <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                          <div className="no-data-box">
                            <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                            <h3>No data found</h3>
                          </div>
                        </div></>}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Download />
      <Footer />

    </>
  );
};
export default ExerciseListing;
