function LoaderMessage() {
    return (
        <div className="loader">
            <div className="loaderh">
                <h2 >Sending Message...</h2>
            </div>
        </div>

    )
}
export default LoaderMessage;