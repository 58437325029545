import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { userService } from "../../services";
import Loader from "./Loader";
import { useState } from "react";
import { toast } from "react-toastify";

const CardForm = ({ handleCloseAddCardModel }) => {
    const [isloader, setIsLoader] = useState(false)
    const stripe = useStripe();
    const elements = useElements();
    const getAllItem = localStorage.getItem("user");
    const { stripe_cus_id } = JSON.parse(getAllItem);
    const [cards, setCards] = useState()
    const [cartId, setCartId] = useState('');

    // get cards
    function getCards() {
        setIsLoader(true);
        userService.getCards().then((response) => {
            setIsLoader(false);
            if (response.data.status == 200) {
                setCards(response?.data?.data);
                if (response?.data?.data?.length > 0)
                    setCartId(response?.data?.data[0]?._id)
            }
            else {
                toast.error("Some Error Occur", {
                    toastId: 'success_draft_1',
                });
            }
        }).catch((error) => {
            setIsLoader(false);
        });
    }
    //   const customerId = "cus_PdJ8EWEO1V9OEL"

    const addPayment = (token, id) => {
        const body = {
            customerId: stripe_cus_id,
            cardId: id,
            token: token,
        };
        setIsLoader(true)
        userService.addCard(body).then((resp) => {
            setIsLoader(false)

            try {
                console.log(resp?.data);
                if (resp?.data?.status == 200) {
                    getCards()
                    handleCloseAddCardModel();
                    setIsLoader(false)

                    // window.location.reload();
                }
                else {
                    toast.error(resp.data.message);
                }
                setIsLoader(false)

            } catch (error) {
                setIsLoader(false)
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!elements || !stripe) {
            return;
        }
        const paymentMethod = await stripe.createToken(
            elements.getElement(CardElement)
        );
        const token = paymentMethod?.token?.id;
        const id = paymentMethod?.token?.card?.id;

        addPayment(token, id);
    };

    return (
        <div>
            {isloader && <Loader />}
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{ hidePostalCode: true }}
                    onReady={() => {
                        console.log("CardElement [ready]");
                    }}
                    onChange={(event) => {
                        console.log("CardElement [change]", event);
                    }}
                    onBlur={() => {
                        console.log("CardElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardElement [focus]");
                    }}
                />
                <div class="new-card-add-btn">
                    <button className="btn btn-bg mt-5" type="submit" disabled={!stripe}>
                        Confirm
                    </button></div>
            </form>
        </div>
    );
};

export default CardForm;
