import Header from "./common/Header";
import Footer from "./common/Footer";
import socket from "./../config/socket"
import { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom/cjs/react-router-dom";
import { userService } from "../services";
import moment from 'moment';
import { config } from "../config/config";
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
import { Button, Dropdown } from "react-bootstrap";
import ImageConfirmationModal from "./common/ImageConfirmationModal";
import Loader from "./common/Loader";

const Chat = () => {
    const newSocket = socket;
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false);
    const otherUserId = location?.pathname?.split("/")[2];
    const [chatDetails, setChatDetails] = useState({ messageThreadId: "", messageHistory: [], selectedUser: {} })
    const [selectedUser, setSelectedUser] = useState()
    const [textMessaqge, setTextMessaqge] = useState()
    const [chatList, setChatList] = useState([])
    const [masterChatList, setMasterChatList] = useState([])
    const [searchChatList, setSearchChatList] = useState()
    const chatContainerRef = useRef();
    const history = useHistory()
    const [MediaType, setMediaType] = useState("")
    const [imageSelectModal, setImageSelectModal] = useState(false)
    const [mediaDetail, setmediaDetail] = useState({
        fileInput: null, messageType: ""
    });

    const inputChangefile = (e) => {
        const { name, files } = e.target;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            const mediaType = selectedFile?.type?.split("/")[1]
            const allowedExtensions = ["jpg", "jpeg", "png"];
            const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
            const maxFileSizeMB = 5;
            if (allowedExtensions.includes(fileExtension)) {
                if (selectedFile.size <= maxFileSizeMB * 1024 * 1024) {
                    uploadMedia(selectedFile, mediaType);
                } else {
                    Swal.fire("Warning", `File size exceeds the limit (Max ${maxFileSizeMB} MB)`, "warning");
                    e.target.value = null;
                }
            } else {
                Swal.fire("Warning", "Invalid file type. Allowed file types: jpg, jpeg, png, pdf", "warning");
                e.target.value = null;
            }
        }
    };
    useEffect(() => {
        const userid = localStorage.getItem("user_id");
        newSocket.emit("joinUser", userid);
        socketConnect();
        setTimeout(() => {
            getChatList()
        }, 1000)


    }, []);

    function getChatList() {
        userService.getMessageChat().then((res) => {
            if (res?.data?.status === 200) {
                setChatList(res.data.data)
                setMasterChatList(res.data.data)
                const userid = localStorage.getItem("user_id");
                const userCheck = res?.data?.data[0]?.userDetail?._id === userid
                const item = res?.data?.data[0]
                item.otherUser = !userCheck ? res?.data?.data[0]?.userDetail : res?.data?.data[0]?.otherUserDetail
                setIsLoading(false)

                if (item?.otherUser?._id) {
                    if (!otherUserId) {
                        if (window?.innerWidth < 768) {
                            history.replace(`/chat`)
                            return
                        }
                        history.replace(`/chat/${item?.otherUser?._id}`)
                        getUser(item?.otherUser?._id)
                        GetPreviousChatHistory(item?.otherUser?._id)
                    } else {
                        getUser(otherUserId)
                        GetPreviousChatHistory(otherUserId)

                    }
                }
            }
            setIsLoading(false)
        })
    }

    function uploadMedia(fileInput, mediaType) {
        setIsLoading(true)
        let data = new FormData();
        data.append("media_file", fileInput);
        data.append("mediaType", mediaType)
        data.append("messageType", "MEDIA")
        userService.upload_mediafile(data).then((res) => {
            if (res?.data?.status === 200) {
                setmediaDetail({ fileInput: res?.data?.data, messageType: "MEDIA" })
                setMediaType(res?.data?.mediaType)
                setImageSelectModal(true)
                setIsLoading(false)
            }
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    function GetPreviousChatHistory(otherUserId) {
        userService.getPreviousChatHistory(otherUserId).then((res) => {
            if (res?.data?.status === 200) {
                const userid = localStorage.getItem("user_id");

                setChatDetails({ ...chatDetails, messageHistory: res?.data?.data, messageThreadId: res.data.messageThreadId });
                newSocket.emit("roomJoin", res.data.messageThreadId);
                if (res?.data?.data && res?.data?.data[0]?.senderId !== userid) {
                    newSocket.emit("readMessage", res?.data?.data[0]?._id);
                }
                chatContainerRef.current.scrollTop =
                    chatContainerRef.current.scrollHeight;


            }
        })
    }

    const socketConnect = async () => {
        const userid = localStorage.getItem("user_id");
        newSocket.emit("createThread", {
            userid: userid,
            otherUserId: otherUserId,
            chatType: "Normal",
        });
        if (otherUserId) {
            GetPreviousChatHistory(otherUserId)
            getUser(otherUserId)
        }

    }


    // Send message
    const onSendMessage = () => {
        if (textMessaqge || mediaDetail?.fileInput) {
            const userid = localStorage.getItem("user_id");
            newSocket.emit("sendMessage", {
                messageThreadId: chatDetails.messageThreadId,
                recipientId: otherUserId,
                messageText: mediaDetail?.messageType === "MEDIA" ? mediaDetail?.fileInput : textMessaqge,
                mediaType: mediaDetail?.messageType === "MEDIA" ? MediaType : "",
                messageType: mediaDetail?.messageType,
            });
        }
    }

    // getUser
    function getUser(id) {
        userService.get_user(id).then((res) => {
            if (res?.data?.status === 200) {
                // console.log("res.data.getDetails>>>>>>>>>",res.data.getDetails)
                GetPreviousChatHistory(id)
                setSelectedUser(res.data.getDetails)
            }
        })
    }

    // Delete message emit event
    const onDeleteMessage = (id) => {
        newSocket.emit("deleteMessage", id);
        const newArray = [];
        chatDetails.messageHistory.forEach(item => {
            if (item._id !== id) {
                newArray.push(item);
            }
        });
        setChatDetails({ ...chatDetails, messageHistory: newArray });
    }
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            onSendMessage(e);
        }
    };

    // Read message emit event
    const onReadMessage = (id) => {
        newSocket.emit("readMessage", id);
    }

    // Message read on event
    newSocket.off("messageRead").on("messageRead", function (event) {
        // // console.log("event messageRead", event)
        let array = [];
        let findId = false
        chatDetails.messageHistory.forEach((value) => {
            let obj = { ...value };
            if (!findId) {
                obj.readStatus = "READ";
            }
            if (value?._id === event._id) {
                findId = true
            }
            array.push(obj);
        })
        setChatDetails({ ...chatDetails, messageHistory: array });
    });

    // New message on event for chatList
    newSocket.on("newMessage", function (event) {
        let mainArray = [...masterChatList]
        let index = mainArray.findIndex((item) => item._id === event._id)
        if (index !== -1) {
            mainArray.splice(index, 1);
        }
        mainArray.unshift(event)
        setChatList(mainArray);
        setMasterChatList(mainArray)
    });


    newSocket.off("messageSent").on("messageSent", function (event) {
        const updatedChatDetails = { ...chatDetails };
        updatedChatDetails.messageHistory.unshift(event);
        setChatDetails(updatedChatDetails);
        setMediaType("")
        setTextMessaqge("")
        setmediaDetail({ fileInput: null, messageType: "" })
        let mainArray = [...masterChatList]
        let findObj = mainArray.find((item) => item.otherUserId === selectedUser._id || item.userId === selectedUser._id)
        findObj.lastMessageDetail = event
        let index = mainArray.findIndex((item) => item.otherUserId === selectedUser._id || item.userId === selectedUser._id)
        if (index !== -1) {
            mainArray.splice(index, 1);
        }
        mainArray.unshift(findObj)
        setChatList(mainArray);
        setMasterChatList(mainArray)
        setTimeout(() => {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }, 200)
    });

    const onSearchChatList = (text) => {
        let e = text.trimStart()
        const newData = masterChatList.filter(
            function (el) {
                const userid = localStorage.getItem("user_id");
                const userCheck = el?.userDetail?._id === userid
                const item = el
                item.otherUser = !userCheck ? el?.userDetail : el?.otherUserDetail
                const itemData = item ? item?.otherUser?.name.toUpperCase()
                    : ''.toUpperCase();
                const textData = e.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
        setChatList(newData);
        setSearchChatList(e);

    }


    // Delete message on event
    newSocket.off("messageDeleted").on("messageDeleted", function (event) {
        // // console.log("event messageDeleted", event)
        const newArray = [];
        chatDetails.messageHistory.forEach(item => {
            if (item._id !== event) {
                newArray.push(item);
            }
        });
        setChatDetails({ ...chatDetails, messageHistory: newArray });
    });


    // Received message on event for chat history
    newSocket.off("messageReceived").on("messageReceived", function (event) {
        // // console.log("event messageReceived", event)
        if (event?.senderId === selectedUser._id) {
            onReadMessage(event._id)
            const updatedChatDetails = { ...chatDetails };
            updatedChatDetails.messageHistory.unshift(event);
            setChatDetails(updatedChatDetails);
            setTimeout(() => {
                chatContainerRef.current.scrollTop =
                    chatContainerRef.current.scrollHeight;
            }, 200)
        }
    });

    return (
        <>
            <Header />
            {isLoading && <Loader />}


            <section className="mainsection chat_section notranslate chat-area">
                <div className="container chat_app">

                    <div className="back-btn-sec mb-3 d-flex justify-content-between">
                        <Button className="btn back-btn"
                            onClick={(e) => {
                                history.goBack();
                            }}
                        ><i className="fa fa-angle-left"></i>Back to Profile</Button>
                    </div>

                    <div className="row app-one">
                        <div className={`col-lg-4 col-md-12 side ${window?.innerWidth < 768 ? !selectedUser?._id ? "" : "hide-chat" : ""}`}>
                            <div className="side-one">
                                <div className="message-side">
                                    <h2>Message</h2>
                                    {chatList?.length == 0 && <p>Start New Conversation</p>}
                                    <input value={searchChatList} onKeyDown={(e) => {
                                        if (!e.target.value && e.code === "Space") {
                                            e.preventDefault();
                                        }
                                    }} onChange={(e) => onSearchChatList(e.target.value)} id="searchText" type="text" className="form-control" name="searchText" placeholder="Search..." />
                                </div>
                                <div className="sideBar">
                                    {chatList.length !== 0 ?
                                        <>

                                            {chatList.map((itemData) => {
                                                const userid = localStorage.getItem("user_id");
                                                const userCheck = itemData?.userDetail?._id === userid
                                                const item = itemData
                                                item.otherUser = !userCheck ? itemData?.userDetail : itemData?.otherUserDetail
                                                return (
                                                    <div onClick={() => {
                                                        history.replace(`/chat/${item?.otherUser?._id}`)
                                                        getUser(item?.otherUser?._id)
                                                    }} className={`sideBar-body ${item?.otherUser?._id === selectedUser?._id ? "chat-listing-active" : ""}  `}>
                                                        <div className="avatar-icon">
                                                            <img src={item?.otherUser?.profilePicture && item?.otherUser?.profilePicture !== "undefined" ? item?.otherUser?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" />
                                                        </div>
                                                        <div className="chat-person" >
                                                            <h4 className="name-meta">{item?.otherUser?.name}
                                                            </h4>
                                                            <p>{item?.lastMessageDetail?.messageType === "MEDIA" ? "Media" : item?.lastMessageDetail?.messageText}</p>
                                                        </div>
                                                        <span className="time-meta pull-right">{moment(item?.lastMessageDetail?.createdOn).format("hh:mm A")}
                                                        </span>
                                                    </div>
                                                )
                                            })}

                                        </>
                                        :
                                        <>
                                            {isLoading ?
                                                null :
                                                <span style={{ color: "white" }} className="row align-items-center justify-content-center">No data found</span>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={`col-lg-8 col-md-12 conversation  ${window?.innerWidth < 768 ? selectedUser?._id ? "" : "hide-chat" : ""} `}>
                            {!selectedUser && !isLoading ?

                                <>
                                    <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                        <div className="no-data-box">
                                            <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                            <h3>No Conversation Message</h3>
                                        </div>
                                    </div>

                                </>
                                :
                                <>

                                    <div className="message" id="conversation ">
                                        <div className="chat-person-name">
                                            <i onClick={() => {
                                                history.replace(`/chat`)
                                                setSelectedUser()
                                            }} class="fa fa-angle-left" aria-hidden="true"></i>
                                            <div className="sideBar-body border-0" onClick={(e) => {
                                                window.location.href = `/profile/${selectedUser?.userSlug}`;
                                            }}>
                                                <div className="avatar-icon" >
                                                    <img src={selectedUser && selectedUser?.profilePicture && selectedUser?.profilePicture != "undefined" ? selectedUser?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" />
                                                </div>
                                                <div className="chat-person">
                                                    <h4 className="name-meta text-dark">{selectedUser?.name}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chat_outer" ref={chatContainerRef}
                                            style={{
                                                overflowY: "scroll",
                                            }}>
                                            {chatDetails.length !== 0 && chatDetails?.messageHistory?.slice().reverse().map((item) => {
                                                const userid = localStorage.getItem("user_id");
                                                return (
                                                    <>
                                                        {userid !== item?.senderId ?
                                                            <div className="message_header1 px-4">
                                                                <div className="sideBar-name p-0">
                                                                    <div className="chatuser_details">
                                                                        <div className="chat-area-box">
                                                                            {item?.messageType === "MEDIA" ?
                                                                                <>

                                                                                    <img src={config.imageUrl + item.messageText} alt="img" />
                                                                                    <p className="message-time text-dark">
                                                                                        {moment(item?.createdOn).format("hh:mm A")}
                                                                                    </p>
                                                                                </>
                                                                                :

                                                                                <span className="mb-0 text-secondary">{item.messageText}
                                                                                    <p className="message-time text-dark">
                                                                                        {moment(item?.createdOn).format("hh:mm A")}
                                                                                    </p>
                                                                                </span>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :


                                                            <div className="message-main-sender">
                                                                <div className="sender">
                                                                    <div className="message-name">
                                                                    </div>

                                                                    <div className="right-blue-msg">
                                                                        <div className="mesage_text">
                                                                            <Dropdown className="drop-down">
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                    <img src={require("../images/update/three-dot.svg").default} alt="img" className="dot-image" />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu onClick={() => onDeleteMessage(item._id)} className="delete-wrap">
                                                                                    <Dropdown.Item className="delet-icon-txt"
                                                                                    ><img src={require("../images/update/delete.svg").default} classNam="mr-3 " alt="img" className="dot-image delete-red" />Delete</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                            {item?.messageType === "MEDIA" ?

                                                                                <img src={config.imageUrl + item.messageText} alt="img" />

                                                                                :

                                                                                item.messageText}


                                                                        </div>
                                                                        <p className="text-right"><span className="message-time">
                                                                            {moment(item?.createdOn).format("hh:mm A")}
                                                                        </span>
                                                                        </p>
                                                                    </div>

                                                                    <img src={item?.readStatus === "READ" ? require("../images/update/double-check.svg").default : require("../images/update/check.svg").default} />

                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })}

                                        </div>
                                    </div>

                                    <div className=" reply">
                                        <div className="reply-main px-0">
                                            <textarea onKeyPress={handleKeyPress} value={textMessaqge} onChange={(e) => {
                                                setTextMessaqge(e.target.value);
                                                setmediaDetail({ ...mediaDetail, messageType: "TEXT" })

                                            }} className="form-control" rows="1" id="comment" placeholder="Write your message..."></textarea>
                                            <input type="file" name="fileInput" onChange={inputChangefile} />
                                            <img className="plus-btn" src={require("../images/update/plus.svg").default} alt="img" />
                                            <img onClick={(e) => onSendMessage()} src={require("../images/update/send.svg").default} alt="img" />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                </div>

            </section>


            <Footer />

            <ImageConfirmationModal
                show={imageSelectModal}
                onHide={() => {
                    setmediaDetail({
                        fileInput: null, messageType: ""
                    })
                    setImageSelectModal(false)
                }}
                onConfirm={() => {
                    setImageSelectModal(false)
                    onSendMessage()
                }}
                imageSrc={mediaDetail.fileInput}
            />

        </>
    );
};
export default Chat;
