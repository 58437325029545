import React, { useState } from 'react';
import { userService } from '../../services';
import { toast } from 'react-toastify';
import Loader from './Loader';
import Header from './Header'
import Footer from './Footer'
import Swal from 'sweetalert2';

const Forgotpassword = () => {

    const [forgotEmail, setForgotEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function onForgotPassword(e) {
        e.preventDefault()
        var emailRegrex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (forgotEmail.trim() == '') {
            toast.error("Please Enter Email", {
                toastId: 'success_draft_1',
            });
        }
        else if (!emailRegrex.test(forgotEmail)) {
            toast.error("Please enter valid email address", {
                toastId: 'success_draft_1',
            });
        }
        else {
            setIsLoading(true);
            let params = {
                'email': forgotEmail,
            };
            userService.forgotPassword(params).then((response) => {
                if (response.status === 200) {
                    setForgotEmail("")
                    setIsLoading(false);
                    Swal.fire("Success", response?.data?.message, "success").then((res) => {
                        window.location.href = '/signin';
                    })
                } else {
                    setIsLoading(false);
                }
            })
                .catch((error) => {
                    setIsLoading(false);
                    Swal.fire("Error", error?.response?.data?.message, "error")
                })
        }
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="bg_section py-5">
                <div className="container">
                    <div className="row">
                        <aside className="col-md-12 col-sm-12 pt-4">
                            <div className="Signup_box text-center forgot-box">
                                <h5>FORGOT PASSWORD</h5>
                                <p className="text-secondary">Enter your registered email address in the field below. We will send you a link to reset your password.</p>
                                <form className="py-2">
                                    <div className="row">
                                        <aside className="col-md-12 mb-3">
                                            <div className="input_row">
                                                <span className='forget-email'><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
                                                <input type="email" name="email" placeholder="Email Address" className="input103 w-100"
                                                    value={forgotEmail}
                                                    onChange={(e) => setForgotEmail(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            onForgotPassword(e);
                                                        }
                                                    }}

                                                />
                                            </div>
                                        </aside>
                                        <aside className="col-md-12 mt-3 sign_btn">
                                            <a className="btn" onClick={(e) => onForgotPassword(e)}>SEND</a>
                                        </aside>
                                        <aside className="col-md-12">
                                            <h6 className="back mt-4">
                                                <i class="fa fa-arrow-left"></i> Back to <a href="/signin">Login</a>
                                            </h6>
                                        </aside>

                                    </div>
                                </form>
                            </div>
                        </aside>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Forgotpassword;