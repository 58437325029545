import Header from './Header'
import Footer from './Footer'
import { ContactUsEmail } from '../../config/config';

const PrivacyPolicyApp = () => {
	return (
		<>
			{/* <Header /> */}
			<div className="container">
				<h1>Privacy Policy</h1>
				<h3>Last Updated: March 21, 2020</h3>
				<h3>Introduction</h3>
				<p>CollConnect L.L.C. and its subsidiaries (collectively, “CollConnect L.L.C.”, “Company”, “we”, or “our”) are committed to protecting your privacy and ensuring you enjoy a positive experience on our websites, our App, and when you use our products and services (collectively, “Products”).
				</p>
				<p>This privacy policy (“Policy”) describes how CollConnect L.L.C in Minnesota processes, collects, uses and shares personal data when using this App https://www.big4healthapp.com (the “App”). The policy is applicable worldwide, and also describes rights you may have to control our use of Personal Data and how to exercise those rights.
				</p>
				<p>Please read the following information carefully to understand our practices regarding your personal data and how we will process data.  CollConnect L.L.C. reserves the right to change or modify this Privacy Policy at any time and in our sole discretion. If we make changes to this Privacy Policy, we will provide notice of such changes, such as by sending an email notification, providing notice through our website or updating the “Last Updated” date at the beginning of this Privacy Policy. We encourage you to review this Privacy Policy periodically and whenever you use or access our websites, and the App or otherwise interact with us to stay informed about our information practices and the ways you can help protect your privacy.
				</p>
				<p>By using the App, you consent to the content outlined in this privacy policy.</p>

				<h1>Purposes of Processing</h1>
				<h3>What is personal data?</h3>
				<p>We collect information about you in a range of forms, including personal data and your BIG4 data such as glucose levels, blood pressure levels, cholesterol levels, weights, height, number of medications used. As used in this Policy, “personal data” is as defined in the General Data Protection Regulation, this includes any information which, either alone or in combination with other information we process about you, identifies you as an individual, including, for example, your name, postal address, email address and telephone number.
				</p>
				<h3>Why do we need your personal data?</h3>
				<p>We will only process your personal data in accordance with applicable data protection and privacy laws. We need certain personal data in order to provide you with access to the App. If you registered with us, you will have been asked to tick to agree to provide this information in order to access our services, purchase our products, or view our content. This consent provides us with the legal basis we require under applicable law to process your data. You maintain the right to withdraw such consent at any time. If you do not agree to our use of your personal data in line with this Policy, please do not use our App.
				</p>
				<h3>Collecting Your Personal Data</h3>
				<p>We collect information about you in the following ways:
				</p>
				<h3>Information You Give Us.<span>This includes:</span></h3>
				<uL>
					<li>
						the personal data you provide when you register to use our App, including your name, postal address, email address, telephone number, username, password and demographic information (such as your gender);
					</li>
					<li>
						the personal data that may be contained in any video, comment or other submission you upload or post to the App;
					</li>
					<li>
						the personal data you provide in connection with our rewards program and other promotions we run on the App;
					</li>
					<li>
						the personal data you provide when you report a problem with our App or when we provide you with customer support;
					</li>
					<li>
						the personal data you provide when you make a purchase thorough our App; and
					</li>
					<li>
						the personal data you provide when you correspond with us by phone, email or otherwise.
					</li>
					<li>
						The BIG4 data and your number of used medications, if applicable.
					</li>
				</uL>
				<h3>Information from Social Networking Apps.</h3>
				<p>Our App may include interfaces that allow you to connect with social networking sites (each a “SNS”). If you connect to a SNS through our App, you authorize us to access, use and store the information that you agreed the SNS could provide to us based on your settings on that SNS. We will access, use and store that information in accordance with this Policy. You can revoke our access to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the applicable SNS.
				</p>
				<h3>Information Automatically Collected.</h3>
				<p>We automatically log information about you and your computer or mobile device when you access our App. For example, when visiting our App, we log your computer or mobile device operating system name and version, manufacturer and model, browser type, browser language, screen resolution, the App you visited before browsing to our App, pages you viewed, how long you spent on a page, access times and information about your use of and actions on our App. We collect this information about you using cookies.
				</p>
				<h3>Automated Decision Making and Profiling.</h3>
				<p>We do not use your personal data for the purposes of automated decision-making. However, we may do so in order to fulfill obligations imposed by law, in which case we will inform you of any such processing and provide you with an opportunity to object.
				</p>
				<h1>Cookies</h1>
				<h3>What are cookies?</h3>
				<p>We may collect information using “cookies.” Cookies are small data files stored on the hard drive of your computer or mobile device by a App. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our App.
				</p>
				<p>We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which are used only by us to recognize your computer or mobile device when it revisits our App; and (2) third party cookies, which are served by service providers on our App, and can be used by such service providers to recognize your computer or mobile device when it visits other Apps.
				</p>
				<h3>Cookies we use</h3>
				<p>Our App uses the following types of cookies for the purposes set out below:
				</p>
				<table width="100%">
					<tr>
						<th>Type of cookie</th>
						<th>Purpose</th>
					</tr>
					<tr>
						<td>Essential Cookies</td>
						<td>These cookies are essential to provide you with services available through our App and to enable you to use some of its features. For example, they allow you to log in to secure areas of our App and help the content of the pages you request load quickly. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.
						</td>
					</tr>
					<tr>
						<td>Functionality Cookies</td>
						<td>These cookies allow our App to remember choices you make when you use our App, such as remembering your language preferences, remembering your login details and remembering the changes you make to other parts of our App which you can customize. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you visit our App.
						</td>
					</tr>
					<tr>
						<td>Analytics and Performance Cookies</td>
						<td>These cookies are used to collect information about traffic to our App and how users use our App. The information gathered does not identify any individual visitor. It includes the number of visitors to our App, the Apps that referred them to our App, the pages they visited on our App, what time of day they visited our App, whether they have visited our App before, and other similar information. We use this information to help operate our App more efficiently, to gather broad demographic information and to monitor the level of activity on our App.
							We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our App works. You can find out more information about Google Analytics cookies
							here: https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
							You can find out more about how Google protects your data here: https://policies.google.com/privacy.
							You can prevent the use of Google Analytics relating to your use of our App by downloading and installing the browser plugin available via this link: http://tools.google.com/dlpage/gaoptout?hl=en-GB
						</td>
					</tr>
					<tr>
						<td>Targeted and advertising cookies</td>
						<td>These cookies track your browsing habits to enable us to show advertising which is more likely to be of interest to you. These cookies use information about your browsing history to group you with other users who have similar interests. Based on that information, and with our permission, third party advertisers can place cookies to enable them to show adverts which we think will be relevant to your interests while you are on third party Apps.
							You can disable cookies which remember your browsing habits and target advertising at you by visiting http://www.youronlinechoices.com/uk/your-ad-choices. If you choose to remove targeted or advertising cookies, you will still see adverts but they may not be relevant to you. Even if you do choose to remove cookies by the companies listed at the above link, not all companies that serve online behavioral advertising are included in this list, and so you may still receive some cookies and tailored adverts from companies that are not listed.
						</td>
					</tr>
					<tr>
						<td>Social Media Cookies</td>
						<td>These cookies are used when you share information using a social media sharing button or “like” button on our App or you link your account or engage with our content on or through a social networking App such as Facebook, Twitter or Google+. The social network will record that you have done this.
						</td>
					</tr>
				</table>
				<h3>Disabling cookies</h3>
				<p>You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility). Many browsers are set to accept cookies until you change your settings.
					If you do not accept our cookies, you may experience some inconvenience in your use of our App. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our App.
				</p>
				<h3>Advertising</h3>
				<p>We may use other companies to serve third-party advertisements when you visit and use the App. These companies may collect and use click stream information, browser type, time and date, subject of advertisements clicked or scrolled over during your visits to the App and other Apps in order to provide advertisements about goods and services likely to be of interest to you. These companies typically use tracking technologies to collect this information. Other companies’ use of their tracking technologies is subject to their own privacy policies.
				</p>
				<h3>Using Your Personal Data</h3>
				<p>We may use your personal data as follows:</p>
				<ul>
					<li>
						to operate, maintain, and improve our App, products, and services;
					</li>
					<li>
						to manage your account, including to communicate with you regarding your account, if you have an account on our App;
					</li>
					<li>
						to operate and administer our rewards program and other promotions you participate in on our App;
					</li>
					<li>
						to show you the trends of your BIG4 numbers;
					</li>
					<li>
						to respond to your comments and questions and to provide customer service;
					</li>
					<li>
						to send information including technical notices, updates, security alerts, and support and administrative messages;
					</li>
					<li>
						with your consent, to send you marketing e-mails about upcoming promotions, and other news, including information about products and services offered by us and our affiliates. You may opt-out of receiving such information at any time: such marketing emails tell you how to “opt-out.” Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails. Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you;
					</li>
					<li>
						to process payments you make via our App;
					</li>
					<li>
						as we believe necessary or appropriate (a) to comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities; (c) to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others;
					</li>
					<li>
						for analysis and study services; and
					</li>
					<li>
						as described in the “Sharing of your Personal Data” section below.
					</li>
				</ul>
				<h3>Sharing Your Personal Data</h3>
				<p>We may share your personal data as follows:</p>
				<ul>
					<li>
						<b>Third Parties Designated by You.</b> We may share your personal data with third parties where you have provided your consent to do so.
					</li>
					<li>
						<b>Our Third Party Service Providers.</b>We may share your personal data with our third party service providers who provide services such as data analysis, payment processing, information technology and related infrastructure provision, customer service, email delivery, auditing and other similar services.
					</li>
					<li>
						<b>Third Party Sites</b>
					</li>
				</ul>
				<p>Our App may contain links to third party Apps and features. This Policy does not cover the privacy practices of such third parties. These third parties have their own privacy policies and we do not accept any responsibility or liability for their Apps, features or policies. Please read their privacy policies before you submit any data to them.
				</p>
				<p>We do not sell your personal data to unrelated third parties. We only collect personal information that is reasonable or necessary to accomplish the purpose of your interaction with the BIG4 Health App and when visiting our websites.  However, in the course of business, we may hire third party individuals and organizations to help us make the BIG4 Health App and CollConnect services better. These third parties include our web host provider, other providers, such as an email hosting service, payment processors or outside contractors we hire to perform maintenance or assist us in securing our website. We may also hire third parties to operate, maintain, repair, or otherwise improve or preserve our website or its underlying files or systems.
				</p>
				<p>We may also share information about you as follows or as otherwise described in this Privacy Policy:</p>
				<ul>
					<li>
						When we need to work with vendors or consultants and other service providers but only to carry out work or perform services on our behalf;
					</li>
					<li>
						In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, rule, regulation or legal process;
					</li>
					<li>
						If we believe your actions are inconsistent with the Terms of Service of CollConnect and or other policies, or to protect the rights, property or safety of CollConnect or others;
					</li>
					<li>
						In connection with, or during negotiations of, any merger, acquisition, sale of assets or any business, other change of control transaction or financing;
					</li>
					<li>
						Between and among CollConnect and any current or future parent, subsidiary and/or affiliated company;
					</li>
					<li>
						For analytics and measurement to understand how our services are used.
					</li>
				</ul>
				<h3>User Generated Content</h3>
				<p>You may share personal data with us when you submit user generated content to our App, including via our rewards program, forums, message boards and blogs on our App. Please note that any information you post or disclose on our App will become public information, and will be available to other users of our App and to the general public. We urge you to be very careful when deciding to disclose your personal data, or any other information, on our App. Such personal data and other information will not be private or confidential once it is published on our App.
				</p>
				<p>If you provide feedback to us, we may use and disclose such feedback on our App, provided we do not associate such feedback with your personal data. If you have provided your consent to do so, we may post your first and last name along with your feedback on our App. We will collect any information contained in such feedback and will treat the personal data in it in accordance with this Policy.
				</p>
				<h3>International Data Transfer</h3>
				<p>Your information, including personal data that we collect from you, may be transferred to, stored at and processed by us outside the country in which you reside, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By accepting this Policy, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy.
				</p>
				<h3>Security</h3>
				<p>We seek to use reasonable organizational, technical and administrative measures to protect personal data within our organization. Unfortunately, no transmission or storage system can be guaranteed to be completely secure, and transmission of information via the Internet is not completely secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us at {ContactUsEmail}
				</p>
				<h3>Retention</h3>
				<p>We will only retain your personal data as long reasonably required for you to use the App until you close your account/cancel your subscription unless a longer retention period is required or permitted by law (for example for regulatory purposes).
				</p>
				<h3>Our Policy on Children</h3>
				<p>Our App is/are not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable. If you are under 16 (sixteen) years old, you may not use the App and may not enter into the Privacy Policy under any circumstances unless you are registered by parent or guardian. Users under the age of 16 MUST be registered by parent or guardian. If you are under 16 and you are having trouble understanding the Privacy Policy, please ask a responsible adult such as your parent or guardian.
				</p>
				<h1>Your Rights</h1>
				<ul>
					<li>
						<b>Opt-out.</b>You may contact us anytime to opt-out of: (i) direct marketing communications; (ii) automated decision-making and/or profiling; (iii) our collection of sensitive personal data; (iv) any new processing of your personal data that we may carry out beyond the original purpose; or (v) the transfer of your personal data outside the EEA. Please note that your use of some of the App may be ineffective upon opt-out.
					</li>
					<li>
						<b>Access.</b> You may access the information we hold about you at any time via your profile/account or by contacting us directly.
					</li>
					<li>
						Amend. You can also contact us to update or correct any inaccuracies in your personal data
					</li>
					<li>
						<b>Move.</b> Your personal data is portable – i.e. you to have the flexibility to move your data to other service providers as you wish.
					</li>
					<li>
						Erase and forget. In certain situations, for example when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.
					</li>
				</ul>
				<p>If you wish to exercise any of these rights, please contact us. In your request, please make clear: (i) what personal data is concerned; and (ii) which of the above rights you would like to enforce. For your protection, we may only implement requests with respect to the personal data associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable and in any event, within one month of your request. Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting such change or deletion.
				</p>
				<h3>Complaints</h3>
				<p>We are committed to resolve any complaints about our collection or use of your personal data. If you would like to make a complaint regarding this Policy or our practices in relation to your personal data, please contact us through the information listed on our App. We will reply to your complaint as soon as we can and in any event, within 30 days. We hope to resolve any complaint brought to our attention, however if you feel that your complaint has not been adequately resolved, you reserve the right to contact your local data protection supervisory authority
				</p>
				<h3>Contact Information</h3>
				<p>We welcome your comments or questions about this Policy. You may contact us in writing or through our App or at {ContactUsEmail}
				</p>
			</div>
			{/* <Footer /> */}
		</>

	)
}

export default PrivacyPolicyApp;
