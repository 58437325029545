import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button, Form, Card, Accordion, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Country, State, City, getAllCountries } from "country-state-city";
import { userService } from "../services";
import React, { useEffect, useState, useRef } from "react";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TweenMax, Power2 } from "gsap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { appUrl, config } from "../config/config";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./common/AnimatedProgressProvider";
import Counter from "./common/Counter";
import { Autoplay, Navigation, Pagination } from "swiper";
const borderColors = ['orange','red', 'yellow', 'green'];



const Home_new = () => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [onplaying, setOnPlaying] = useState(false)
  const asideRef = useRef(null);
  const play = useRef("")
  const [testimonial, setTestiMonial] = useState("")
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  let preview;
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false)
  const [address, setAddress] = useState("");
  const [Trusteddata, setTrustedData] = useState()
  const [certificationdata, setCertificationData] = useState()
  const [graphData, setGraphdata] = useState()
  const [homeData, setHomeData] = useState()
  const [startCounting, setStartCounting] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: [""], message: "" })

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleContextMenu(event) {
    event.preventDefault();
  }






  useEffect(() => {
    Cerifificate()
    Trusted()
    GraphdataLogo()
    GetBannerLogo()
    TestiMonialVideo()
    localStorage.removeItem("webnairType");
    localStorage.removeItem("profilewebnairType");
    localStorage.removeItem("activeTab");
    localStorage.removeItem("selectedTabAppointment");
    localStorage.removeItem("selectedTab");
    
  }, [])
  const [service, setservice]=useState(false)
  const startCountAnimation = () => {
    setStartCounting(true);
  };

  const [serviceOther, setServiceOther] = useState("")
  const handleInputChange = (event) => {
    const { value } = event.target;
    setServiceOther(value)
  };


  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;
    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }
    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));
  };


  const handleSubmit2 = () => {
    let temp =[...userDetail.service];
    temp[0]=serviceOther;

    setuserDetails((prevState) => ({
      ...prevState,
      service: temp,
    }));
    setservice(true)
    handleClose()
  };

  function TestiMonialVideo(type) {
    userService.testimonial("HOME").then((res) => {
      if (res?.data?.status === 200) {
        setTestiMonial(res?.data?.getTestimonial)
      }
    })
      .catch((error) => {
      })
  }

  const onVideoPlay = (item) => {
    setTestiMonial(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
      )
    )
  }

  const onVideoPause = (item) => {
    setTestiMonial(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
      )
    )
  }



  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Graph section is in view, start counting animation
            startCountAnimation();
            observer.unobserve(entry.target); // Stop observing once animated
          }
        });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px", // No margin
        threshold: 0.5, // 50% of the element must be in view
      }
    );

    // Attach the observer to the graph section
    const graphSection = document.querySelector(".burden-disease-counter");
    if (graphSection) {
      observer.observe(graphSection);
    }
  }, []);


  const fadeInAnimation = (element) => {
    TweenMax.from(element, 1, {
      opacity: 0,
      y: 50,
      ease: Power2.easeOut,
    });
  };

  function AnimatedSection({ children }) {
    const sectionRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Element is in view, trigger the animation
              fadeInAnimation(sectionRef.current);
              observer.unobserve(sectionRef.current); // Stop observing once animated
            }
          });
        },
        {
          root: null, // Use the viewport as the root
          rootMargin: "0px", // No margin
          threshold: 0.5, // 50% of the element must be in view
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
    }, []);

    return <div ref={sectionRef}>{children}</div>;
  }



  function handleSumit(e) {
    e.preventDefault();
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!EMAILREGEX.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country?.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    // else if (!userDetail?.service) {
    //   Swal.fire("Warning", 'service field can’t be empty.', "warning")
    //   return
    // }
    else if (!userDetail?.service?.filter(item => item)?.length) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message?.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service.filter(item => item),
      message: userDetail?.message,
      page_type: "home"
    }
    userService.contactUs(params).then((res) => {
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: [""],
            message: ""
          });
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: [""],
            message: ""
          });
        })
      }
    })
      .catch((error) => {
      })
  }

  function Cerifificate() {
    userService.certificateLogo().then((res) => {
      if (res?.data?.status === 200) {
        setCertificationData(res?.data?.data)
      }
    })
      .catch((error) => {
      })
  }

  function Trusted() {
    userService.TrustedLogo().then((res) => {
      if (res?.data?.status === 200) {
        setTrustedData(res?.data?.data)
      }
    })
      .catch((error) => {
      })
  }



  function GraphdataLogo() {
    userService.Graphdata().then((res) => {
      if (res?.data?.status === 200) {
        setGraphdata(res?.data?.data);
        setYear(res?.data.year);
      }
    })
      .catch((error) => {
      })
  }

  function GetBannerLogo() {
    userService.banner_data().then((res) => {
      if (res?.data?.status === 200) {
        setHomeData(res?.data?.homeData)
      }
    })
      .catch((error) => {
      })
  }

  const handleVideoClick = (key) => {
    if (currentVideoIndex === key) {
      setCurrentVideoIndex(-1); // Agar current video already play ho raha hai to use pause kardo
    } else {
      setCurrentVideoIndex(key); // Naya video play karo
    }
  };



  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};

    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }

  const handleClearText = () => {
    if(service == false){
      setuserDetails({ ...userDetail,
        service: [""],
      });
    }
  }

  {console.log("userDetail========",userDetail)}

  return (
    <>
      <Header />
      <section className="home-banner" style={{ backgroundImage: `url(${homeData && homeData?.DESKTOP[0] && homeData?.DESKTOP[0]?.image})`, backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="banner-text text-center pt-5">
            <h1>Transforming healthcare and unlocking business success, with <span>BIG4 Health</span></h1>
            <p>Healthcare providers save 25% more time, and businesses earn millions of dollars with your premier corporate wellness platform.</p>
            <p>BIG4 Health helps leading hospitals, top health insurance companies, and private and government businesses worldwide in saving
              both time and money while addressing the BIG4: diabetes, high blood pressure, high cholesterol, and obesity.</p>
          </div>
        </div>
      </section>
      <section className="home-banner home-banner-mob d-none" style={{ backgroundImage: `url(${homeData && homeData?.DESKTOP[0] && homeData?.MOBILE[0]?.image})`, backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="banner-text text-center pt-5">
            <h1>Transforming healthcare and unlocking business success, with <span>BIG4 Health</span></h1>
            <p>Healthcare providers save 25% more time, and businesses earn millions of dollars with your premier corporate wellness platform.</p>
            <p>BIG4 Health helps leading hospitals, top health insurance companies, and private and government businesses worldwide in saving
              both time and money while addressing the BIG4: diabetes, high blood pressure, high cholesterol, and obesity.</p>
          </div>
        </div>
      </section>
      <section className="burden-disease">
        <div className="container">
          <h2>{year}</h2>

          <div className="burden-disease-counter d-flex justify-content-between ">
            {graphData &&
              graphData.map((value, key) => (
                
                <div
                  key={key}
                  className={`burden-counter-box col-lg-3 col-sm-6 ${startCounting ? "count-animation" : ""
                    }`}
                >
                  <div className={`burden-counter-box-wrap border-${borderColors[key]} notranslate`}>
                    <p>
                      <Counter data={value} />+
                    </p>
                  </div>
                  <p className={`disease-name text-center burden-${borderColors[key]}`}>
                    {value?.title}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </section>
      <section className="global-solution-section" style={{ backgroundImage: `url(${homeData && homeData?.DESKTOP[1] && homeData?.DESKTOP[1]?.image})`, backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="global-solution-text">
            <h2>Global solutions and insights for healthier employees and more profitable business</h2>
            <p className="global-trusting">Trusted by 11,000 + healthcare providers</p>
            <p>We optimize the health of both patients and employees, leading to increased business productivity and profitability.
              We partner with world-leading medical experts in <b>Lifestyle Medicine</b> specializing in preventing and reversing chronic conditions.
              Our partnerships with diverse healthcare providers and business experts enable us to deliver cutting-edge
              medical apps and personalized whole health and wellness programs.</p>
          </div>
        </div>
      </section>
      {/* global-section-mobile */}
      <section className="global-solution-section global-solution-section-mob d-none" style={{ backgroundImage: `url(${homeData && homeData?.MOBILE[1] && homeData?.MOBILE[1]?.image})`, backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="global-solution-text">
            <h2>Global solutions and insights for healthier employees and more profitable business</h2>
            {/* <h5 >Trusted by 11,000 + healthcare providers</h5> */}
            <p className="global-trusting">Trusted by 11,000 + healthcare providers</p>
            <p>We optimize the health of both patients and employees, leading to increased business productivity and profitability.
              We partner with world-leading medical experts in <b>Lifestyle Medicine</b> specializing in preventing and reversing chronic conditions.
              Our partnerships with diverse healthcare providers and business experts enable us to deliver cutting-edge
              medical apps and personalized whole health and wellness programs.</p>
          </div>
        </div>
      </section>

      <section className="brand-logos-section">
        <div className="container">
        <h5 className="mb-2">Trusted By</h5>
          <div className="">
            <Swiper
            slidesPerView={6}
            spaceBetween={50}
            speed={1000}
            loop={true}
            autoplay={{
              delay:1000,
              disableOnInteraction:false
            }}
            navigation={true}
            modules={[ Pagination, Navigation]}
            
            className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 3,
                },
                639: {
                  slidesPerView: 3,
                },
                865: {
                  slidesPerView: 5,
                },
                1000: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 5,
                },
                1700: {
                  slidesPerView: 5,
                },
              }}
            >
             
              {Trusteddata && Trusteddata.map((item, index) => (
                <SwiperSlide key={index}>
                  <img src={item?.image} alt={`img-${index}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="optimize-profit-section pt-5" style={{ backgroundImage: `url(${homeData && homeData?.DESKTOP[2] && homeData?.DESKTOP[2]?.image})`, backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="optimize-profite-text">
            <h1>Optimize your corporate wellness and maximize profits</h1>
            <p>Get the only corporate wellness platform that combines the power of lifestyle medicine, the 12-in-one BIG4 Health App, and the BIG4
              Health Ingredients - the leading plant-based protein powder to prevent and reverse the BIG4 health concerns: high blood pressure, diabetes, high cholesterol, and obesity. The results are:</p>
            <ul>
              <li>Healthcare expenditures decrease by 20% to 40%</li>
              <li> Healthcare providers save 25% more time</li>
              <li> Employee engagement increases by 52%</li>
              <li>Absenteeism decreases by 70%.</li>
              <li><b>Ultimately more profitable businesses.</b></li>
            </ul>
          </div>
        </div>
      </section>
      <section className="optimize-profit-section optimize-profit-section-mob d-none pt-5 bg-background" style={{ backgroundImage: `url(${homeData && homeData?.MOBILE[2] && homeData?.MOBILE[2]?.image})`, backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="optimize-profite-text">
            <h1>Optimize your corporate wellness and maximize profits</h1>
            <p>Get the only corporate wellness platform that combines the power of lifestyle medicine, the 12-in-one BIG4 Health App, and the BIG4
              Health Ingredients - the leading plant-based protein powder to prevent and reverse the BIG4 health concerns: high blood pressure, diabetes, high cholesterol and obesity. The results are:</p>
            <ul>
              <li>Healthcare expenditures decrease by 20% to 40%</li>
              <li> Healthcare providers save 25% more time</li>
              <li> Employee engagement increases by 52%</li>
              <li>Absenteeism decreases by 70%.</li>
              <li><b>Ultimately more profitable businesses.</b></li>
            </ul>
          </div>
        </div>
      </section>

<section className="video-testimonials slider-btn testimonial-spacing">
      <div className="container">
        <h2>Real Clients- Genuines Stories</h2>
        <div className="video-test-wrap">
          <Swiper
            spaceBetween={20}
            slidesPerView={2}
            loop={false}
            navigation
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              639: {
                slidesPerView: 1,
              },
              865: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 2,
              },
              1500: {
                slidesPerView: 2,
              },
              1700: {
                slidesPerView: 2,
              }
            }}
          >
            {testimonial && testimonial.map((item, key) => {
              const isVideoPlaying = currentVideoIndex === key;
              return (
                <SwiperSlide key={key}>
                  <div className="big-med-main-img big-med-main-vid" onClick={() => handleVideoClick(key)}>
                    {item.videoPlay ? (
                      <video
                        width="100%"
                        height="100%"
                        muted
                        loop
                        preload='auto'
                        playsInline
                        poster={item.image}
                        autoPlay={isVideoPlaying}
                        onPause={() => setCurrentVideoIndex(-1)} // Jab video pause ho jaye to currentVideoIndex ko reset kardo
                      >
                        <source src={item.image + "#t=0.1"} type="video/mp4" />
                        <source src={item.image + "#t=0.1"} type="video/ogg" />
                        <source src={item.image + "#t=0.1"} type="video/webm" />
                      </video>
                    ) : (
                      <div className="big-med-main-img big-med-main-vid">
                        <video
                          onContextMenu={handleContextMenu}
                          controlsList="nodownload"
                          width="100%"
                          height="100%"
                          muted
                          loop
                          controls
                          ref={play}
                          onPlay={(e) => setOnPlaying(true)}
                          onEnded={(e) => setOnPlaying(false)}
                          preload='auto'
                          playsInline
                          autoPlay={isVideoPlaying}
                          onPause={() => setCurrentVideoIndex(-1)} // Jab video pause ho jaye to currentVideoIndex ko reset kardo
                        >
                          <source src={item.image + "#t=0.1"} type="video/mp4" />
                          <source src={item.image + "#t=0.1"} type="video/ogg" />
                          <source src={item.image + "#t=0.1"} type="video/webm" />
                        </video>
                      </div>
                    )}
                    <h5>{item.title && item.title}</h5>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>

      <section className="get-started-form bg_section signup_section redesign-profile">
        <div className="container">
          <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
          <div className="get-form-wrap">
            <h2>Get Started</h2>

            <aside className="col-lg-12 pt-4">
              <div className="Signup_box text-center w-100">
                <Form className="">
                  <div className="row frist_step contact-form-area  ">
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Full Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="text"
                          autoComplete="off"
                          name="name"
                          maxLength={50}
                          placeholder="Enter Full Name"
                          className="input103 w-100"
                          value={userDetail?.name}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Email<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="text"
                          name="email"
                          autoComplete="off"
                          placeholder="Enter Email"
                          className="input103 w-100"
                          value={userDetail?.email}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Phone Number<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="text"
                          autoComplete="off"
                          name="PhoneNumber"
                          placeholder="Enter Phone Number"
                          className="input103 w-100"
                          maxLength={13}
                          value={userDetail?.PhoneNumber}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Company Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Text"
                          name="companyName"
                          autoComplete="off"
                          maxLength={50}
                          placeholder="Enter Company Name"
                          className="input103 w-100"
                          value={userDetail?.companyName}
                          onChange={(e) => inputChange(e)}


                        />
                      </div>
                    </aside>

                    <aside className="col-md-4 mb-3 text-left">
                      <label>Country </label>
                      <div className="input_row input103">


                        <select
                          className="w-100 select-main"
                          name="country"
                          value={userDetail?.country}
                          onChange={(e) => inputChange(e)}
                        >
                          <option value={""}>Select Country</option>
                          {Country.getAllCountries().map((c) => {
                            return (
                              <option value={c.name}>{c.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </aside>
                 

                    <aside className="col-md-4 mb-3 text-left" ref={asideRef} id="primaryservice">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShow} className='d-block servies-text'>
                          {userDetail?.service?.filter(item => item)?.length > 0 ? "+ Update Services" : "+ Add Services"}
                        </a>
                        <div className='services-preview'>
                          {userDetail?.service && <span>{showFullContent ? userDetail?.service?.filter(item => item)?.join(" , ") : userDetail?.service?.filter(item => item)?.join(" , ")?.slice(0, 30)}</span>}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                    <aside className="col-md-12 mb-3 text-left">
                      <div className="input_row">
                        <label>Message</label>
                        <textarea
                          className=" w-100 select-main"
                          placeholder="Enter Message"
                          autoComplete="off"
                          name="message"
                          maxLength={500}
                          value={userDetail?.message}
                          onChange={(e) => inputChange(e)}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </Form>
              </div>
              <div className="next_btn text-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="btn w-25"
                  onClick={handleSumit}
                >
                  Submit
                </Button>
              </div>
            </aside>

          </div>
        </div>
      </section>


      <section className="certificate-section">
        <div className="container">
          <h2>Certifications</h2>
          <div className="certificate-wrap">
            {certificationdata && certificationdata?.map((item, index) => {
              return (
                <>
                  <img src={item?.certification} alt="img" />
                </>
              )
            })}
          </div>
        </div>
      </section>
      
      <Modal show={show} 
       onHide={(e) => { handleClose(); handleClearText(); }}

      className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Chronic Disease Prevention/Reversal Programs" checked={userDetail.service.includes('Chronic Disease Prevention/Reversal Programs')} value="Chronic Disease Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    {/* Health Advocacy and Information: */}
                    Healthcare Speaker & Health Information
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                    Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <label className='mt-3'><b>Other</b></label>
              <textarea
                className="form-control"
                value={serviceOther ? serviceOther :""}
                placeholder="Please Specify"
                name="service"
                maxLength={500}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 &&
            <a
              type="button"
              className="join-button"
              onClick={handleSubmit2}
            >
              Submit
            </a>
          }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Footer />
    </>
  );
};
export default Home_new