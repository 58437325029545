import React, { useState, useEffect } from "react";
import Paginate from "./common/Pagination";
import { userService } from "../services";
import Loader from "./common/Loader";
import { toast } from "react-toastify";
import { appUrl, config } from "../config/config";
import ShareOnSocialMedia from "./common/ShareOnSocialMedia";
import Header from "./common/Header";
import Footer from "./common/Footer";
import moment from "moment";
import Download from "./common/Download";
import { Button } from "react-bootstrap";

const Blog_details = () => {
  const [blog, setBlog] = useState("");
  const [popularBlogs, setPopularBlogs] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const id = window?.location?.pathname?.split("=")[1];
  const [ID, setBlogID] = useState('');


  useEffect(() => {
    let id = new URLSearchParams(window.location.search).get("id");
    setBlogID(id);
    if (id) getBlog(id);
  }, []);

  function getBlog(id) {
    setIsLoading(true);
    userService
      .getBlog(id)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status == 200) {
          setBlog(response.data.data);
          setPopularBlogs(response.data.popularBlogs);
          document.getElementById("article-description").innerHTML =
            response.data.data.description;
        } else {
          setBlog("");
          setIsLoading(false);
          toast.error("Some Error Occur");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setBlog("");
      });
  }

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="blog-single py-4">
        <div className="container">
          
          <div className="col-md-12 mb-4">
            <div className="back-btn-sec mb-3">
              <Button onClick={(e) => {
               window.location.href="/blog"
              }} className="btn back-btn"><i className="fa fa-angle-left mr-2"></i>
                Back to Listing
              </Button>


            </div>

            <div className="blog_head">
              <div className="blog-heading">
                <h5 className="latest">Latest Blogs</h5>
              </div>
 <ShareOnSocialMedia url={window.location.href}  text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
            </div>
          </div>
          <div className="row align-items-start">
            <div className="col-lg-4 blog-aside">
              <div className="popular">
                <h4>Popular Blogs</h4>
                <ul className="p-0 mt-4">
                  {popularBlogs.length > 0 ? (
                    popularBlogs.map((blog, i) => {
                      console.log(blog._id,' ',ID)
                      return (
                        <li className={blog._id == ID ?'active' :""}>
                          <a href={"/blog_details?id=" + blog._id}>
                            <span>{(i < 10 ? "0" : "") + (i + 1)}</span>
                            <div>
                              <h6>{blog.title}</h6>
                            </div>
                          </a>
                        </li>
                      );
                    })
                  ) : (
                    <li className="no-blog">No Popular-Blogs Found</li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <article className="article">

                <div className="article-img">
                  <img
                    src={blog?.image ?  blog.image : ""}
                    title=""
                    alt=""
                  />
                </div>
                <div className="blog-details-title">
                  <h2>{blog?.title}</h2>

                  <h6>
                    Posted On::{" "}
                    <b>
                      <span className="simple-font">
                        {moment(blog?.createdOn).format("MM/DD/YYYY")}
                      </span>
                    </b>
                  </h6>
                </div>
                <div className="article-title d-flex flex-wrap mt-1">
                  <div className="author-date">
                    <h6>
                      Author:{" "}
                      <span className="simple-font">{blog?.author}</span>
                    </h6>
                  </div>
                  <div className="pl-3 pr-3 desk-line">|</div>
                  <i className=""><h6>
                    Bio: <span className="simple-font">{blog?.bio}</span>
                  </h6></i>

                  <div className="media mt-4">
                    <div className="avatar">
                    </div>
                  </div>
                </div>
                <div className="article-content" id="article-description">
                 
                </div>
              </article>
            </div>

          </div>
        </div>
      </div>
      <Download />
      <Footer />
    </>
  );
};
export default Blog_details;
