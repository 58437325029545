import React from 'react'
import { useEffect, useState } from "react";
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { userService } from '../services';
import Pagination from "react-js-pagination";
import { config, healthCareProvidercategories } from '../config/config';
import Loader from './common/Loader'

const HealthMD = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [categoryList, setcategoryList] = useState()
  const [activeTab, setActiveTab] = useState("");
  const [page, setPage] = useState(1);
  const [professionalData, setprofessionalData] = useState([])
  const [showtime, setShowtime] = useState(false);
  const [totalRecords, setTotalrecords] = useState()
  const [userId, setUserId] = useState(localStorage?.getItem("user_id") || "")
  const [totalRecord, setTotalRecord] = useState("")
  const [userDetail, setUserDetail] = useState("")
  const [perPage, setPerPage] = useState("")
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getCotagery()
    getMdList(1, activeTab, selectedCategory, userId, login,searchQuery)
    getProfileList()
  }, [])

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        setUserDetail(userData)
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
      }
    }).catch((error) => {
    });
  }

  const handleFilterChange = (e) => {
    let professonalType = e.target.value === "All" ? "" : e.target.value;
    setSelectedCategory(professonalType);
    getMdList(1, activeTab, professonalType, userId, login,searchQuery)
  };


  function getCotagery() {
    userService.get_category().then((res) => {
      setcategoryList([{ name: "All", _id: "" }, ...res?.data?.data])

    })
      .catch((Error) => {
      })
  }

  const handleTabClick = (tab) => {
    if(activeTab == tab?._id){
      return;
    }
    setActiveTab(tab._id);
    setPage(1)
    getMdList(1, tab._id, selectedCategory, userId, "yes",searchQuery)
  };

  function handlePageChange(pageNumber) {
    setPage(pageNumber);
    getMdList(pageNumber, activeTab, selectedCategory, userId, "yes",searchQuery)
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }
  let login = localStorage.getItem("user_id") ? "yes" : ""
  function getMdList(page, activeTab, selectedCategory, userId, login,searchQuery) {
    // setIsLoading(true)
    userService.getMDList(page - 1, activeTab,selectedCategory, userId, login,searchQuery).then((res) => {
      if (res?.data?.status === 200) {
        if(res?.data?.data){
          setprofessionalData(res?.data?.data)
          setTotalrecords(res?.data?.total_records)
        }
        // setIsLoading(false)
      }
    })
      .catch((error) => {
        // setIsLoading(false)
      })
  }

  function handleSearchInputChange(event) {
    setSearchQuery(event.target.value);
    getMdList(1, activeTab, selectedCategory, userId, login,event.target.value)
  }

  return (
    <div>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med">
        <div className="container">
          <div class="big-med-heading d-flex align-items-start justify-content-between w-100">
            {/* <h1>BIG4 Health MD, a worldwide open healthcare platform - joining in interprofessional consultation. </h1> */}
            <h1>Find Healthcare Provider, a worldwide open healthcare platform - joining in professional consultation.</h1>
          </div>
        </div>
      </div>
      <div className="world-box">
        <div className="container">
          <div className="filter-area-top">
     

          <div className="tab-container">
            {categoryList?.map(tab => (
              <button
                key={tab._id}
                className={`cat-list mb-3 ${activeTab === tab._id ? 'active' : ''}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab?.name}
              </button>
            ))}
          </div>

          <div className="filter-area">
            <label><b>Filter By: </b></label>
            <select value={selectedCategory} onChange={handleFilterChange}>
              {healthCareProvidercategories && healthCareProvidercategories?.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>

          </div>
          </div>
          <div className='health-md-search-area'>
          {/* <form onSubmit={handleSearchSubmit}>   */}
          <div className="search-bar">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Find Provider..."
              onKeyDown={(e) => {
                if (!e.target.value && e.code === "Space") {
                  e.preventDefault();
                }
              }}
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
          {/* </form> */}
          </div>
          <div class="tab-content">
            <div class="tab-pane container active" id="world-1">
              <div className="webinar-outer-box schedule-box notranslate">
                <div className="row">
                  {professionalData?.length > 0 ?
                    professionalData?.map((item, key) => {
                      console.log("item------------",item)

                      return (
                        <>
                          <aside className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="webinar-inner-box">
                              <div className='webinar-left-image-icons'>

                              <img onClick={(e) => {
                                window.location.href = `/profile/${item?.userSlug}`;
                              }} src={item?.profilePicture && item?.profilePicture !== "undefined" && item?.profilePicture !== undefined ? item?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" className="mr-2 schedule-img" />
                              <div className="profile-top-cons-area">
                                        {item && item?.linkedInUrl && item?.linkedInUrl  !=="null" && 
                                        <div className="social-btn">
                                            <button className="btn btn-sm btn-facebook" data-toggle="tooltip" title={item?.linkedInUrl}
                                                onClick={(e) => window.open(item?.linkedInUrl , '_blank')}>
                                                <i class="fa fa-linkedin" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        } 
                                        {item && item?.websiteUrl && item?.websiteUrl !== "null" && (
                                            <div className="social-btn">
                                                <a
                                                    className="btn btn-sm twt-btn"
                                                    href={item.websiteUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title={item?.websiteUrl}
                                                >
                                                    <img src={require("../images/update/web-icon.png").default} alt="Website Icon" />
                                                </a>
                                            </div>
                    )}


                                    </div>
                              </div>

                              <div>
                                <h4>{item?.name}</h4>
                                <p>{item?.professionalType}</p>
                         

                                <p className="mb-0">
                                  <img src={require("../images/update/briefcase.svg").default} alt="img" className="mr-2" />
                                  {item?.experience ? item?.experience:""}+ years
                                  </p>
                                <p className='category-text'><b>{item?.category?.name}</b></p>
                                <button className='join-button mr-2' onClick={(e) => window.location.href = `/health-md-details/${item?._id}`}>Book Appointment</button>
                                <a type='button' onClick={(e) => window.location.href = `/chat/${item?._id}`} variant="unset" className='chat-message-icon'><img src={require("../images/update/chat-message.png").default} /></a>
                              </div>
                            </div>
                          </aside>
                        </>
                      )
                    })
                    :
                    <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                      <div className="no-data-box">
                        <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                        <h3>No data found</h3>
                      </div>
                    </div>}
                  {totalRecords > 12 && (
                    <div className='col-md-12 text-center'>
                      <div className="pagination1">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={12}
                          totalItemsCount={totalRecords}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                          hideDisabled={true}
                          prevPageText={"Previous"}
                          nextPageText={"Next"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered className="quiz-pop quiz-close"
      >
        <Modal.Header className="m-auto q-mark">
          <Modal.Title>?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='start-the-quiz text-center p-0'>

          <p>Are you sure you want to reject?</p>
        </Modal.Body>
        <Modal.Footer className="border-none m-auto">
          <Button className='btn notnowbtn' onClick={handleClose}>NO</Button>
          <Button className='btn quiznow-btn'>YES</Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  )
}

export default HealthMD;