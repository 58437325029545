import Header from "./common/Header";
import { useEffect, useRef, useState } from 'react';
import Footer from "./common/Footer";
import Download from "./common/Download";
import { Button, Modal } from "react-bootstrap";
import { userService } from "../services";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import moment from "moment";
import Pagination from "react-js-pagination";
export const DATE_OPTIONS = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

const MyExercise = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalpage, settotalpage] = useState();
  const handleClose = () => setShow(false);
  const KeyDowns = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "<", ">", ".", "\\", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
  const handleShow = () => setShow(true);
  const [myExercise, setmyExercise] = useState({ exerciseName: "", timeDuration: "", reminderFrequencyArray: "", reminderTime: "", reminderNotification: false })
  const [ExerciseDetail, setExerciseDetail] = useState()
  const [formattedDate, setFormattedDate] = useState("")
  const [reminderSelectedTime, setReminderSelectedTime] = useState("")
  const [userDetail, setUserDetail] = useState("")
  useEffect(() => {
    getMyExercise(localStorage.getItem("user_id"), 1)
    getProfileList()
  }, [])

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        setUserDetail(userData)
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
      }
    }).catch((error) => {
    });
  }
  // Add my exercise
  function AddmyExercise() {
    const params = {
      userId: localStorage.getItem("user_id"),
      exerciseName: myExercise?.exerciseName,
      timeDuration: myExercise.timeDuration,
      distance: '',
      reminderDays: myExercise?.reminderNotification == true ? myExercise?.reminderFrequencyArray : "",
      reminderTime: myExercise?.reminderNotification == true ? formattedDate : "",
      reminderNotification: myExercise?.reminderNotification,
      reminderSelectedTime: reminderSelectedTime,
      timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone

    }
    userService.AddmyExerciseData(params).then((res) => {
      if (res?.data?.status === 200) {
        getMyExercise(localStorage.getItem("user_id"), page)
        setShow(false)
        setmyExercise({ exerciseName: "", timeDuration: "", reminderFrequencyArray: "", reminderTime: "", reminderNotification: false })
        Swal.fire("Success", res?.data?.message, "success");
      }
    })
      .catch((error) => {
      })
  }

  function inputChange(e) {
    let { name, value } = e.target;
    setmyExercise({ ...myExercise, [name]: value });
  }

  const handleDurationChange = (event) => {
    const inputValue = event.target.value;
    const parsedValue = parseInt(inputValue);
    if (!isNaN(parsedValue)) {
      setmyExercise({ ...myExercise, timeDuration: parsedValue });
    } else {
      setmyExercise({ ...myExercise, timeDuration: 0 });
    }
  };

  const handleOptionChange = (event) => {
    setmyExercise({ ...myExercise, reminderNotification: !myExercise?.reminderNotification })

  };

  const handleCheckboxChange = (day) => {
    const updatedFrequencyArray = [...myExercise.reminderFrequencyArray];
    if (updatedFrequencyArray.includes(day)) {
      updatedFrequencyArray.splice(updatedFrequencyArray.indexOf(day), 1);
    } else {
      updatedFrequencyArray.push(day);
    }
    setmyExercise({
      ...myExercise,
      reminderFrequencyArray: updatedFrequencyArray,
    });
  };
let pageNumberRef =useRef()
  function handlePageChange(pageNumber) {
    console.log("pageNumber============",pageNumber)
    setPage(pageNumber);
    getMyExercise(localStorage.getItem("user_id"), pageNumber)
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    setmyExercise({
      ...myExercise,
      [name]: value,
    });
  };
  const currentDate = new Date();
  function handleAddExercise(e) {
    AddmyExercise()
  }
  // get exercise
  function getMyExercise(user_id, page) {
    const params = { userId: localStorage.getItem("user_id"), page: page - 1 }
    console.log("params=========",params)
    userService.getExerise(params).then((res) => {
      if (res?.data?.status == 200) {
        setExerciseDetail(res?.data?.data)
        settotalpage(res?.data?.total_records)
      }
    })
      .catch((error) => {
      })
  }

  // delete Exercise
  function deteteExercise(id) {
    const params = { userId: localStorage.getItem("user_id"), exerciseId: id }
    userService.delete_Exercise(params).then((res) => {
      if (res?.data?.status === 200) {
        getMyExercise(localStorage.getItem("user_id"), page)
        Swal.fire("Success", "Deleted Successfully.", "success")
      }
    })
      .catch((Error) => {
      })
  }

  const handleDateTimeChange = (e) => {
    const selectedDateTime = e.target.value;
    const utcDateTime = new Date(selectedDateTime).toISOString();
    setmyExercise({
      ...myExercise,
      reminderTime: utcDateTime,
    });
  };

  return (
    <>
      <Header />
      <div className="container">
        <Button className="btn back-btn mb-3" href="/exercise"><i className="fa fa-angle-left mr-2"></i>Back to Exercise</Button>
      </div>
      <section className="exercise-banner">
        <div className="back-btn-sec mb-3">
        </div>
        <div className="container">
          <div className="exercise-text">
            <h1>Exercise is <span>medicine,</span> prescribed by you,<br /> for you, to create <span>a healthier<br /> and happier</span> you</h1>
          </div>
        </div>
      </section>
      <section className="exercise-listing">
        <div className="world-box">
          <div className="container">
            <a
              onClick={(e) => {
                if (userDetail?.ProfileCreated == 0) {
                  Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                    window.location.href = "/create-profile";
                    let redirectUri = window.location.pathname + window.location.search;
                    localStorage.setItem("redirectUri", redirectUri);
                  })
                }
                else if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
                  Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                    window.location.href = "/signin";
                    let redirectUri = window.location.pathname + window.location.search;
                    localStorage.setItem("redirectUri", redirectUri);
                  })
                }
                else {
                  handleShow()
                }
              }}
              className="btn create w-fix"><img src={require("../images/update/create-vid-icon.svg").default} alt="img" />Create</a>
            <div class="tab-content">
              <div class="tab-pane container active" id="exercise-1">
                <div className="webinar-outer-box">
                  <div className="row">
                    {ExerciseDetail?.length > 0 ?
                      ExerciseDetail?.map((item, key) => {
                        return (
                          <><aside className="col-lg-4 col-md-6">
                            <span>
                              <div className="webinar-inner-box inner-exercise">
                                <i class="fa fa-trash-o" aria-hidden="true" onClick={(e) => {
                                  let confirmBox =
                                    Swal.fire({
                                      icon: "info",
                                      html: `<div class="logout-text">
                               Are you sure you want to delete this exercise?
                             </div>
                             `,
                                      showCloseButton: true,
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                    }).then((res) => {
                                      if (res.isConfirmed) {
                                        deteteExercise(item?._id)
                                      }
                                    });
                                }}></i>
                                <div className="my-exercise-inner d-flex align-items-center" onClick={(e) => window.location.href = `/exercise-count/${item?._id}`}>
                                  <img src={require("../images/update/knee.png").default} alt="img" />
                                  <div>
                                    <h4>{item?.exerciseName}</h4>
                                    <p>
                                      {moment(item?.reminderTime ? item?.reminderTime : item?.startTime ? item?.startTime : item?.createdOn).format("lll")}

                                    </p>
                                  </div>
                                </div>
                                <p className="mt-2"><span className="mr-2">Duration:</span>
                                  {item?.timeDuration}
                                  min</p>
                              </div>
                            </span>

                          </aside>
                          </>
                        )
                      }) :
                      <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                        <div className="no-data-box">
                          <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                          <h3>No data found</h3>
                        </div>
                      </div>
                    }
                    {totalpage > 10 && (
                      <div className='col-md-12 text-center'>
                        <div className="pagination1">
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={10}
                            totalItemsCount={totalpage}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e)}
                            hideDisabled={true}
                            prevPageText={"Previous"}
                            nextPageText={"Next"}
                          />
                        </div>
                      </div>
                    )}

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Download />
      <Footer />
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title><h2>Add Exercise</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="billing-box-address signin-bg">
            <div className="delivery-body biling-box Signup_box p-0 contact-box add-exercise">
              <form>
                <div className="row">
                  <aside class="col-md-12 mb-3">
                    <div class="input_row">
                      <label>Exercise Name</label>
                      <input
                        placeholder="Enter Exercise Name"
                        type="exerciseName"
                        name="exerciseName"
                        className="input103 w-100"
                        value={myExercise?.exerciseName}
                        onChange={(e) => inputChange(e)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                        maxLength={50}
                      />
                    </div>
                  </aside>
                  <aside className="col-md-12 mb-3">
                    <div className="input_row duration-box">
                      <label>Time Duration</label>
                      <input
                        type="text"
                        placeholder="Enter Time Duration"
                        className="input103 w-100"
                        onChange={handleDurationChange}
                      />
                      <p className="duration">{myExercise?.timeDuration} Min</p>
                    </div>
                  </aside>
                  <aside class="col-md-12 mb-3">
                    <label className="d-flex radio-text-mob">
                      <input
                        type="radio"
                        name="reminderNotification"
                        value={myExercise?.reminderNotification}
                        checked={myExercise?.reminderNotification}
                        onClick={handleOptionChange}
                        className="remem mr-2"
                      /><span className='text-left'>
                        Would you like reminder notifications?
                      </span>
                    </label>
                    {myExercise.reminderNotification == true &&
                      <div className="tags-days mt-3">
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Sunday")}
                            onChange={() => handleCheckboxChange("Sunday")}
                          />
                          Sunday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Monday")}
                            onChange={() => handleCheckboxChange("Monday")}
                          />
                          Monday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Tuesday")}
                            onChange={() => handleCheckboxChange("Tuesday")}
                          />
                          Tuesday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Wednesday")}
                            onChange={() => handleCheckboxChange("Wednesday")}
                          />
                          Wednesday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Thursday")}
                            onChange={() => handleCheckboxChange("Thursday")}
                          />
                          Thursday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Friday")}
                            onChange={() => handleCheckboxChange("Friday")}
                          />
                          Friday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise.reminderFrequencyArray.includes("Saturday")}
                            onChange={() => handleCheckboxChange("Saturday")}
                          />
                          Saturday
                        </label>
                      </div>}
                  </aside>

                  <aside className="col-md-12 mb-4 text-left">
                    <div className="input_row">
                      <label>Date & Time<span className="blue-asterisk"></span></label>
                      <div className="">
                        <div className="boder-box">
                          <input
                            type="datetime-local"
                            value={myExercise?.reminderTime}
                            id="birthdaytime"
                            name="birthdaytime"
                            onChange={(e) => {
                              const selectedDateTime = e.target.value;
                              const formattedDateTime = new Date(selectedDateTime);

                              const formattedDate = `${formattedDateTime.getFullYear()}-${(formattedDateTime.getMonth() + 1).toString().padStart(2, '0')}-${formattedDateTime.getDate().toString().padStart(2, '0')}`;

                              const formattedTime = `${formattedDateTime.getHours().toString().padStart(2, '0')}:${formattedDateTime.getMinutes().toString().padStart(2, '0')}`;

                              const formattedDateTimeString = `${formattedDate}T${formattedTime}`;

                              setFormattedDate(formattedDateTimeString);

                              setmyExercise({
                                ...myExercise,
                                reminderTime: formattedDateTimeString,
                              });

                              setReminderSelectedTime(formattedTime);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </aside>

                </div>

                <div className="next_btn mt-4 text-center">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleAddExercise}
                    disabled={!myExercise?.exerciseName || !myExercise?.timeDuration || !myExercise?.reminderTime || !myExercise?.reminderNotification}
                    className={`btn w-25 ${myExercise?.exerciseName && myExercise?.timeDuration && myExercise?.reminderTime ? 'enabled' : 'disabled'}`}
                  >
                    ADD
                  </Button>

                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default MyExercise;
