import React, { useEffect, useState } from 'react'
import { Modal, Form } from 'react-bootstrap';
import Header from './common/Header'
import Footer from './common/Footer'
import { toast } from 'react-toastify';
import { Country } from 'country-state-city';
import { userService } from '../services';
import Loader from './common/Loader';
import { config } from '../config/config';
import MaskedInput from 'react-text-mask';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId
} from "react-places-autocomplete";
import { useHistory } from 'react-router-dom';

// import { useLocalStorage } from "../useLocalStorage";


const Checkout = () => {

    const history = useHistory()

    const [show, setShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [street1, setStreet1] = useState("");
    const [street2, setStreet2] = useState("");
    const [phone, setNumber] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [address_id, setAddressID] = useState("");
    const [address, setAddress] = useState("")
    const [selCountry, setSelCountry] = useState("")
    // const mapsService = useRef(null);



    // const handleSelect = async valueOfLocation => {
    //     const results = await geocodeByAddress(valueOfLocation)
    //     const latlong = await getLatLng(results[0])
    //     setAddress(valueOfLocation)
    //     // console.log(results)

    const handleSelect = async (valueOfLocation, placeId) => {
        const results = await geocodeByAddress(valueOfLocation);
        const latLng = await getLatLng(results[0]);
        setAddress(valueOfLocation)
        const [place] = await geocodeByPlaceId(placeId);
        const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
        const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
        const { long_name: selState = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
        const { long_name: selStreet = '' } = place.address_components.find(c => c.types.includes("neighborhood")) || {};
        const { long_name: route = '' } = place.address_components.find(c => c.types.includes("route")) || {};
        const { long_name: street_number = '' } = place.address_components.find(c => c.types.includes("street_number")) || {};
        const { long_name: locality = '' } = place.address_components.find(c => c.types.includes("locality") || c.types.includes("political") || c.types.includes('administrative_area_level_2')) || {};

        // // console.log("postalCode", postalCode);
        // // console.log("place-details", place)
        // // console.log("country", countryselect)
        setZip(postalCode)
        setSelCountry(countryselect)
        setCountry(countryselect)
        setState(selState)
        setCity(locality)
        setStreet1(street_number + " " + route)
    }

    const handleClearText = () => {
        setName("")
        setNumber("")
        setAddress("")
        setZip("")
        setCountry("")
        setState("")
        setCity("")
        setStreet1("")

    }

    const handleAddNewAddress = () => {
        setLgShow(true)
        handleClearText()

    }

    //---------------------------------------------
    const [bname, setBName] = useState("");
    const [bphone, setBNumber] = useState("");
    const [bzip, setBZip] = useState("");
    const [bcountry, setBCountry] = useState("");
    const [cityName, setCityName] = useState("")
    const [streetName, setStreetName] = useState("")
    const [bstate, setBState] = useState("")
    // ----------------------------------------------
    const [baddress, setBAddress] = useState("");
    // ----------------------------------------------
    const [isLoading, setIsLoading] = useState(false);
    const [prices, setPrices] = useState([]);
    const [cartProducts, setCartProducts] = useState([]);
    const [cartCount, setCartCount] = useState('');
    const [shipping, setShipping] = useState('');
    const [tax, setTax] = useState('');
    const [similarProducts, setSimilarProducts] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selectedAddressID, setSelectedAddressID] = useState("");
    const [favCount, setFavCount] = useState('');
    const [cartId, setCartId] = useState('');
    const [editAddress, setEditAddress] = useState(false)



    const handleShowEdit = (id) => {
        let address = addresses.filter(i => i._id == id)[0]
        // // console.log('addresses[0]', addresses[0])
        setName(address.name)
        setCountry(address.country)
        setNumber(address.phone)
        setZip(address.zip_code)
        setStreet1(address.street1)
        setStreet2(address.street2)
        setCity(address.city)
        setState(address.state)
        setAddressID(address._id)
        let temArr = addresses.indexOf(address)
        // // console.log('temArr', temArr)

        setEditAddress(true)

    }

    const handleEditAddress = (id) => {
        setIsLoading(true)
        let address = addresses.filter(i => i._id == id)[0]

        // // console.log('aID', address_id)

        // // console.log('id to edit', id);
        // let address = addresses.filter(i => i._id == id)[0]
        // // console.log('addresses[0]', addresses[0])
        // // console.log('address index of id', addresses.indexOf(address))
        // // console.log('addresses', addresses)
        // // console.log('address', address)

        // setName(address.name)
        // setCountry(address.country)
        // setNumber(address.phone)
        // setZip(address.zip_code)
        // setStreet1(address.street1)
        // setStreet2(address.street2)
        // setCity(address.city)
        // setState(address.state)
        // setAddressID(address._id)
        // // console.log('address_id=>', address_id)


        let data = {
            name, phone, country, zip_code: zip, street1, street2,
            city, state, address: "", id: address_id, user: localStorage.getItem('user_id')
        }
        // // console.log('data', data)
        // let temArr = addresses.indexOf(address)
        // // console.log('temArr', temArr)
        // addresses[temArr] = data



        userService.editAddress(data).then(response => {
            setIsLoading(false)
            // // console.log('data response', response.data)
            if (response.data.status == 200) {
                toast.success(response.data.message)

                // let tempArr = addresses;
                // tempArr.push(data);

                getCartProducts()
                setLgShow(false)
            } else if (response.data.status == 400) {
                setIsLoading(false)
                alert(response.data.result.errors[0].message)
            }
        }).catch(err => {
            setIsLoading(false)
            // console.log(err)
        })

        handleCloseEdit()
    }

    // const handleEditSubmit = () => {

    // }
    const handleCloseEdit = () => setEditAddress(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getCartProducts();

    }, [])

    const onAddAddress = () => {
        if (!name) {
            toast.error("Please Enter Name")
        } else if (!phone) {
            toast.error("Please Enter Number")
        }
        else if (!zip) {
            toast.error("Please Enter Zip")
        }
        else if (!country) {
            toast.error("Please Enter Country")
        }
        else {
            setIsLoading(true)
            let data = {
                name, phone, country, zip_code: zip, street1, street2,
                city, state, user: localStorage.getItem('user_id')
            }
            // // console.log(data)
            // alert(country)
            userService.addAddress(data).then(response => {
                setIsLoading(false)
                // // console.log(response.data)
                if (response.data.status == 200) {
                    toast.success(response.data.message)
                    let tempArr = addresses;
                    tempArr.push(data);
                    getCartProducts()
                    setLgShow(false)
                } else if (response.data.status == 400) {
                    setIsLoading(false)
                    alert(response.data.result.errors[0].message)
                }
            }).catch(err => {
                setIsLoading(false)
                // console.log(err)
            })
        }
    }
    // ------------------------------------------------------------------------------------------
    // const onAddEditedAddress = () => {
    //     if (!editName) {
    //         toast.error("Please Enter Name")
    //     } else if (!editNumber) {
    //         toast.error("Please Enter Number")
    //     }
    //     // // ------------------------------------------ 
    //     // else if (!cityName) {
    //     //     toast.error("Please Enter City")
    //     // } else if (!streetName) {
    //     //     toast.error("Please Enter Street")
    //     // } 
    //     // // ------------------------------------------
    //     else if (!editZip) {
    //         toast.error("Please Enter Zip")
    //     } else if (!editCountry) {
    //         toast.error("Please Enter Country")
    //     } else {
    //         setIsLoading(true)
    //         let data = {
    //             editName, editNumber, editCountry, zip_code: editZip, editStreetName1, editStreetName2,
    //             editCityName, editState, user: localStorage.getItem('user_id')
    //         }
    //         // console.log(data)
    //         userService.addAddress(data).then(response => {
    //             setIsLoading(false)
    //             // console.log(response.data)
    //             if (response.data.status == 200) {
    //                 toast.success(response.data.message)
    //                 let tempArr = addresses;
    //                 tempArr.push(data);
    //                 getCartProducts()
    //                 setLgShow(false)
    //             } else if (response.data.status == 400) {
    //                 setIsLoading(false)
    //                 alert(response.data.result.errors[0].message)
    //             }
    //         }).catch(err => {
    //             setIsLoading(false)
    //             // console.log(err)
    //         })
    //     }
    // }
    // ------------------------------------------------------------------------------------------------------------------

    function getCartProducts() {
        setIsLoading(true);
        userService.getCartProducts().then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                let tmpArry = [];
                setCartId(response.data.cart._id)
                setCartCount(response.data.cart.lineitems.length)
                response.data.cart.lineitems.map((product) => {
                    tmpArry.push({ price: product?.qty * product?.product?.price, qty: product?.qty, subscription: product.subscription, subscription_type: product.subscription_type, discount: product?.product?.discount });
                });
                setPrices(tmpArry);
                setCartProducts(response.data.cart.lineitems);
                setSimilarProducts(response.data.similarProducts);
                setTax(response.data.tax);
                setShipping(response.data.shipping);
                if (response.data.addresses.length > 0) {
                    setAddresses(response.data.addresses);
                    setSelectedAddress(response.data.addresses[0]._id);
                    setSelectedAddressID(response.data.addresses[0].addressId);
                } else {
                    setAddresses([]);
                    setSelectedAddress("");
                    setSelectedAddressID("");
                }
            } else {
                setCartProducts([]);
                // toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setCartProducts([]);
            // console.log("error ", error);
        });
    }

    function addToCart(id) {
        if (window.confirm("Are you sure you want to add this product?")) {
            let userId = localStorage.getItem('user_id');
            if (userId) {
                setIsLoading(true);
                let params = { user: userId, product: id, qty: 1 };
                // // console.log(params);
                userService.addToCart(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status === 200) {
                        setCartCount(response.data.cartCount);
                        getCartProducts();
                        // updateQuantity('ADD', id);
                        toast.success("Product added to cart successfully.")
                    } else {
                        toast.error("Some Error Occur");
                    }
                }).catch((error) => {
                    setCartProducts([]);
                    setIsLoading(false);
                    // console.log('error : ', error);
                });
            } else {
                window.location.pathname = './signin';
            }
        }
    }

    function deleteAddress(id) {
        if (window.confirm("Are you sure you want to delete this address?")) {
            userService.deleteAddress(id).then(res => {
                if (res.status == 200) {
                    getCartProducts()
                }
            })
        }
    }



    function handleFavourite(id, status) {
        let userId = localStorage.getItem('user_id');
        if (userId) {
            var answer = true;
            if (status) answer = window.confirm("Are you sure want to remove it from your favourite list.");
            if (answer) {
                setIsLoading(true);
                let params = { user: userId, product: id, is_favourite: !status }
                userService.updateFavourite(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status == 200) {
                        setFavCount(response.data.favCount);
                        getCartProducts()
                    } else {
                        // setProducts([]);
                        toast.error("Some Error Occur");
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    // setProducts([]);
                    // console.log("error ", error);
                });
            }
        } else {
            window.location.pathname = '/signin'
        }
    }


    function BAddressValidation() {
        var nameRegExp = new RegExp(/^[a-zA-Z ]+$/i);
        if (addresses.length == 0) {
            toast.error("Please Add Valid Shipping Address");
        } else if (bname.length == 0) {
            toast.error("Please Enter Name");
        } else if (!(bphone.length >= 8 && bphone.length <= 12)) {
            toast.error("Please Enter Valid Phone Number");
        }

        // ------------------------------------------ 
        else if (!cityName) {
            toast.error("Please Enter City")
        } else if (!streetName) {
            toast.error("Please Enter Street")
        }
        else if (!bstate) {
            toast.error("Please Enter State")
        }
        // ------------------------------------------


        else if (!(bzip.length <= 6 && bzip !== '')) {
            toast.error("Please Enter Valid Zip Code");
        } else if (bcountry == -1 || bcountry === '') {
            toast.error("Please Enter Valid Country");
        }
        // else if (!baddress) {
        //     toast.error("Please Enter Valid Address");
        // } 
        else {
            // window.location.href = 'shipping?address=' + selectedAddress + '&addressID=' + selectedAddressID + '&cartId=' + cartId;
            history.replace('shipping?address=' + selectedAddress + '&addressID=' + selectedAddressID + '&cartId=' + cartId)

            // localStorage.setItem("bname", JSON.stringify(bname));
            // localStorage.setItem("bphone", JSON.stringify(bphone));
            // localStorage.setItem("bzip", JSON.stringify(bzip));
            // localStorage.setItem("bcountry", JSON.stringify(bcountry));
            // localStorage.setItem("cityName", JSON.stringify(cityName));
            // localStorage.setItem("streetName", JSON.stringify(streetName));
            // localStorage.setItem("bstate", JSON.stringify(bstate));
        }

    }

    function getDiscountedPrice(item) {
        return (item.price - (item.discount / 100) * item.price).toFixed(2)
    }

    function getTotalPrice() {
        let sum = 0;
        prices.map((item) => {
            let value = (item.price - (item.discount * item.price / 100))
            sum = sum + value
        });
        return sum;
    }

    function getTax() {
        return parseFloat(((getTotalPrice() - getSubDiscount()) * tax) / 100).toFixed(2);
    }

    function getSubDiscount() {
        let sum = 0;
        prices.forEach((item) => {
            let value = item.subscription ? (item.price - (item.discount * item.price / 100)) / 100 * parseFloat(item.subscription_type.save_percent) : 0
            sum = sum + value
        });
        return sum.toFixed(2);
    }

    function getGrandTotalPrice() {

        let gPrice = getTotalPrice();       //setting initial GrandTotal from getTotalPrice()

        let taxVal = parseFloat(getTax());
        let shippingVal = 0;
        //Calculation for Grand Total Price
        gPrice += taxVal;
        gPrice += shippingVal;
        gPrice -= getSubDiscount()
        return gPrice;
    }
    function selectedAddressValue(item) {
        setSelectedAddress(item._id)
        setSelectedAddressID(item.addressId)
    }

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} />
            {!isLoading && <section className="card_section pt-4 pb-5 checkout">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 summry">
                            <div className="card mt-3">
                                <div className="card-body">
                                    {cartProducts.map((item, index) => {
                                        return <dl className="dlist-align mb-1">
                                            <h6><span>{index + 1}.</span> {item.product.name} <span>({item.qty}x)</span></h6>
                                            <dd className="text-right ml-3 text-dark" style={{ fontSize: "14px" }}>${getDiscountedPrice(item.product)}</dd>
                                        </dl>
                                    })}
                                    <hr />
                                    <dl className="dlist-align">
                                        <dt>Total price:</dt>
                                        <dd className="text-right ml-3">${getTotalPrice().toFixed(2)}</dd>
                                    </dl>
                                    {getSubDiscount() > 0 && <dl className="dlist-align">
                                        <dt>Discount:</dt>
                                        <dd className="text-right text-success ml-3">-${getSubDiscount()}</dd>
                                    </dl>}
                                    <dl className="dlist-align">
                                        <dt>Tax({tax}%):</dt>
                                        <dd className="text-right ml-3">${getTax()}</dd>
                                    </dl>
                                    <dl className="dlist-align">
                                        <dt>Shipping:</dt>
                                        {/* {parseFloat(shipping).toFixed(2) != "0.00" ?
                                            <dd className="text-right ml-3">${parseFloat(shipping).toFixed(2)}</dd>
                                            : */}
                                        <dd className="text-right ml-3" style={{ color: 'red' }}>{'pending'}</dd>
                                        {/* } */}
                                        {/* {// console.log(shipping)}
                                        <dd className="text-right ml-3">${parseFloat(shipping).toFixed(2)}</dd> */}
                                    </dl>

                                    <hr />
                                    <dl className="dlist-align mb-0">
                                        <dt style={{ fontSize: "20px", color: "black" }}>Total:</dt>
                                        <dd className="text-right ml-3" style={{ fontSize: "20px" }}><strong>${getGrandTotalPrice().toFixed(2)}</strong></dd>
                                    </dl>
                                </div>
                            </div>
                            {/* href={`/payment?address=${selectedAddress}`} */}
                            <div className="make_payment">
                                <a className="btn text-white" onClick={() => BAddressValidation()}>Continue</a>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="rightside_checkout">
                                <div className="address_box">
                                    <div className="add_head">
                                        <h6 className="mb-0" style={{ fontSize: "17px" }}>Delivery Address</h6>
                                        <button className="btn text-white" onClick={handleAddNewAddress}><i className="fa fa-plus mr-2"></i>ADD NEW ADDRESS</button>
                                    </div>
                                    <hr className="my-2" />
                                    {addresses.length == 0 ? <div className="add_address text-center py-5">
                                        <img src={require('../images/no_address.svg').default} alt="" />
                                        <p className="text-center text-secondary mb-2 pb-1 mt-2" style={{ fontSize: "18px" }}>No delivery address added yet.</p>
                                    </div> :
                                        <div className="col-md-12">
                                            <div className="address_list row">
                                                {addresses.map((item, index) => {
                                                return <div className="col-md-5 mb-3 mr-3 address address1">
                                                    <span>
                                                        <div class="form-check pl-0">
                                                            <input class="form-check-input" onChange={(e) => { selectedAddressValue(item) }}
                                                                checked={item._id == selectedAddress} type="radio" />
                                                        </div>
                                                    </span>
                                                    <h5>{item.name}</h5>
                                                    <h6 className="mb-1">{item.phone}</h6>
                                                    <p>{item.street1} {item.city}, {item.state}, {item.country}</p>
                                                    <div className="edit_address">
                                                        <i onClick={(e) => handleShowEdit(item._id)} className="fa fa-edit"></i>
                                                    </div>

                                                    <Modal
                                                        show={editAddress}
                                                        onHide={handleCloseEdit}
                                                        aria-labelledby="example-modal-sizes-title-lg"
                                                        className="address_modal"
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title id="example-modal-sizes-title-lg">
                                                                Edit Details
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="payment_modal px-3">
                                                                <div className="row">


                                                                    <div className="col-md-6 mb-3">

                                                                        <label><i className="fa fa-eraser" style={{ cursor: "pointer" }} onClick={handleClearText}> Clear</i></label>
                                                                        { console.log("newAddress", address)}
                                                                        <PlacesAutocomplete
                                                                            value={address}
                                                                            onChange={(e) => setAddress(e)}
                                                                            onSelect={handleSelect}
                                                                        >


                                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                <div>

                                                                                    <input
                                                                                        {...getInputProps({
                                                                                            placeholder: "Search Places ...",
                                                                                            className: "location-search-input"
                                                                                        })}
                                                                                    />

                                                                                    <div className="autocomplete-dropdown-container">
                                                                                        {loading && <div>Loading...</div>}
                                                                                        {suggestions.map((suggestion) => {
                                                                                            const className = suggestion.active
                                                                                                ? "suggestion-item--active"
                                                                                                : "suggestion-item";
                                                                                            // inline style for demonstration purpose
                                                                                            const style = suggestion.active
                                                                                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                                                                : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                                            return (
                                                                                                <div
                                                                                                    key={suggestion.placeId}
                                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                                        className,
                                                                                                        style
                                                                                                    })}
                                                                                                >
                                                                                                    <span>{suggestion.description}</span>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>

                                                                                </div>
                                                                            )}
                                                                        </PlacesAutocomplete>


                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div>
                                                                            <label>Full Name</label>
                                                                            <input value={name} onChange={(e) => setName(e.target.value)} maxLength={20} type="" placeholder="Enter Full Name" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div>
                                                                            <label>Phone Number</label>
                                                                            <MaskedInput
                                                                                mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                                placeholder="Enter Phone Number"
                                                                                guide={false}
                                                                                onChange={(e) => setNumber(e.target.value)}
                                                                                maxLength={12}
                                                                                value={phone}
                                                                            />


                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div>
                                                                            <label>Street 1</label>
                                                                            <input value={street1} onChange={(e) => setStreet1(e.target.value)} type="" placeholder="Enter Street 1" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div>
                                                                            <label>City</label>
                                                                            <input value={city} onChange={(e) => setCity(e.target.value)} maxLength={20} type="" placeholder="Enter City" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <div>
                                                                            <label>State</label>
                                                                            <input value={state} onChange={(e) => setState(e.target.value)} maxLength={20} type="" placeholder="Enter State" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <label>ZIP Code</label>
                                                                            <input value={zip} onChange={(e) => setZip(e.target.value)} type="" maxLength={7} placeholder="Enter ZIP Code" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <label>Country</label>
                                                                            <Form>
                                                                                <Form.Control as="select" value={country} onChange={(e) => setCountry(e.target.value)}>
                                                                                    <option value="">Select Country</option>
                                                                                    {Country.getAllCountries().map((c) => {
                                                                                        return (<option>{c.name}</option>)
                                                                                    })}
                                                                                </Form.Control>
                                                                            </Form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer className="border-0 pb-4 px-4">
                                                            <button
                                                                className="paybtn w-100 py-3" onClick={(e) => handleEditAddress(item._id)}>
                                                                Edit Address
                                                            </button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    <div className="dlte_address">
                                                        <i onClick={() => deleteAddress(item._id)} className="fa fa-trash"></i>
                                                    </div>
                                                </div>
                                            })}
                                            </div>
                                        </div>}
                                    {addresses.length > 0 && <div className="col-md-12 mt-4">
                                        <div className="payment_modal px-3">
                                            <div className="address_box">
                                                <div className="add_head checboxhead pb-1">
                                                    <h6 className="mb-0" style={{ fontSize: "17px" }}>Billing Address</h6>
                                                    <Form.Group className="mb-1" controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" onChange={(e) => {
                                                            if (!e.target.checked) {
                                                                setBAddress("")
                                                                setBCountry("")
                                                                setBName("")
                                                                setBNumber("")
                                                                setBZip("")
                                                                setStreetName("")
                                                                setCityName("")
                                                                setBState("")
                                                            } else {
                                                                let address = addresses.filter(i => i._id == selectedAddress)[0]
                                                                setBAddress(address.address)
                                                                setBCountry(address.country)
                                                                setBName(address.name)
                                                                setBNumber(address.phone)
                                                                setBZip(address.zip_code)
                                                                setStreetName(address.street1)
                                                                setCityName(address.city)
                                                                setBState(address.state)


                                                            }
                                                        }} label="Same as billing address" />
                                                    </Form.Group>
                                                </div>
                                                <hr className="my-2" />
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div>
                                                        <label>Full Name</label>
                                                        <input value={bname} maxLength={30} onChange={(e) => setBName(e.target.value)} type="" placeholder="Enter Full Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div>
                                                        <label>Phone Number</label>
                                                        <input value={bphone} maxLength={13} onChange={(e) => setBNumber(e.target.value)} type="" placeholder="Enter Phone Number" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <label>ZIP Code</label>
                                                        <input value={bzip} maxLength={7} onChange={(e) => setBZip(e.target.value)} type="" placeholder="Enter ZIP Code" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <label>Country</label>
                                                        <Form>
                                                            <Form.Control as="select" value={bcountry} onChange={(e) => setBCountry(e.target.value)}>
                                                                <option value="">Select Country</option>
                                                                {Country.getAllCountries().map((c) => <option>{c.name}</option>)}
                                                            </Form.Control>
                                                        </Form>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <div>
                                                        <label>City</label>
                                                        <input value={cityName} maxLength={30} onChange={(e) => setCityName(e.target.value)} type="" placeholder="Enter City Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div>
                                                        <label>Street</label>
                                                        <input value={streetName} onChange={(e) => setStreetName(e.target.value)} type="" placeholder="Enter Your Street" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mb-1">
                                                    <div>
                                                        <label>State</label>
                                                        <input value={bstate} maxLength={70} onChange={(e) => setBState(e.target.value)} type="" placeholder="Enter State" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}

            {similarProducts.length > 0 ?
                <section className="product-page-area similar_products py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 className="mb-2">Similar Products</h5>
                            </div>
                            {similarProducts.map((similarProduct) => {
                                return (
                                    <div className="col-lg-3 col-md-4 mb-3">
                                        <div className="product-list-box">
                                            <a href={"./product_details?id=" + similarProduct._id}>
                                                <div className="product-list-image text-center">
                                                    <img src={similarProduct.images.length > 0 ? config.imageUrl + similarProduct.images[0].image : ''} alt="img" />
                                                </div>
                                                <div className="product-list-details">
                                                    <h4>{similarProduct.name}</h4>
                                                    {similarProduct?.rating && <h6>
                                                        {Array.from(Array(parseInt(similarProduct?.rating))).map(t => {
                                                            return <i class="fa fa-star" aria-hidden="true" />
                                                        })}
                                                        <span className="total-review ml-1">({similarProduct?.reviewCount} reviews)</span>
                                                    </h6>}
                                                    {similarProduct?.discount > 0 ? <h5>Price:
                                                        <del className="orginal-amount">${parseFloat(similarProduct?.price).toFixed(2)}</del>
                                                        <span className="discount-amount"> ${getDiscountedPrice(similarProduct)}</span>
                                                    </h5> : <h5>Price: <span className="discount-amount"> ${parseFloat(similarProduct?.price).toFixed(2)}</span></h5>}
                                                </div>
                                            </a>
                                            <div className="product-details">
                                                <div className="buttons d-flex flex-row">
                                                    <a className="cart shadow pb-3" onClick={() => handleFavourite(similarProduct._id, similarProduct.is_favourite)}><i className={similarProduct.is_favourite ? "fa fa-heart" : "fa fa-heart-o"}></i></a>
                                                    <a className="btn btn-success cart-button btn-block shadow pointer" onClick={() => addToCart(similarProduct._id)}><i className="fa fa-shopping-cart mr-2" style={{ fontSize: "19px" }}></i> ADD TO CART </a>
                                                </div>
                                                <div class="weight"> </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </section>
                : null}


            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pay_modal card_modal"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment_modal px-3">
                        <div className="row">
                            <div className="col-md-12 text-center mb-4">
                                <img src={require('../images/mastercard1.png').default} alt="" />
                            </div>

                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>Card Number</label>
                                    <input type="" maxLength={16} value="xxxx xxxx xxxx 1234" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>Card Holder Name</label>
                                    <input type="" maxLength={20} value="Alex Smith" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>Expiry</label>
                                    <input type="" value="01/25" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>CVV</label>
                                    <input type="" maxLength={4} value="112" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 pb-4 px-4">
                    <button className="dltebtn" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        className="paybtn" onClick={handleClose}>
                        PAY
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="address_modal">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Addresssss
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment_modal px-3">
                        <div className="row">
                            {/* -----------------------------------------Places Autofill---------------------------------------------------------- */}
                            <div className="col-md-6 mb-3">

                                <label><i className="fa fa-eraser" style={{ cursor: "pointer" }} onClick={handleClearText}> Clear</i></label>
                                { console.log("newAddress", address)}
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={(e) => setAddress(e)}
                                    onSelect={handleSelect}
                                >


                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>

                                            <input
                                                {...getInputProps({
                                                    placeholder: "Search Places ...",
                                                    className: "location-search-input"
                                                })}
                                            />

                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? "suggestion-item--active"
                                                        : "suggestion-item";
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                    return (
                                                        <div
                                                            key={suggestion.placeId}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                        </div>
                                    )}
                                </PlacesAutocomplete>


                            </div>



                            {/* -------------------------------------------Places Autofill--------------------------------------------------------- */}
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>Full Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} maxLength={20} type="" placeholder="Enter Full Name" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>Phone Number</label>
                                    {/* <input
                                        value={phone}
                                        onChange={(e) => setNumber(e.target.value)}
                                        maxLength={13} 
                                        placeholder="Enter Phone Number" /> */}

                                    <MaskedInput
                                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        placeholder="Enter Phone Number"
                                        guide={false}
                                        onChange={(e) => setNumber(e.target.value)}
                                        maxLength={12}
                                        value={phone}
                                    />


                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>Street 1</label>
                                    <input value={street1} onChange={(e) => setStreet1(e.target.value)} type="" placeholder="Enter Street 1" />
                                </div>
                            </div>
                            {/* <div className="col-md-6 mb-3">
                                <div>
                                    <label>Street 2</label>
                                    <input value={street2} onChange={(e) => setStreet2(e.target.value)} maxLength={20} type="" placeholder="Enter Street 2" />
                                </div>
                            </div> */}
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>City</label>
                                    <input value={city} onChange={(e) => setCity(e.target.value)} maxLength={20} type="" placeholder="Enter City" />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div>
                                    <label>State</label>
                                    <input value={state} onChange={(e) => setState(e.target.value)} maxLength={20} type="" placeholder="Enter State" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <label>ZIP Code</label>
                                    <input value={zip} onChange={(e) => setZip(e.target.value)} type="" maxLength={7} placeholder="Enter ZIP Code" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <label>Country</label>
                                    <Form>
                                        <Form.Control as="select" value={country} onChange={(e) => setCountry(e.target.value)}>
                                            <option value="">Select Country</option>
                                            {Country.getAllCountries().map((c) => {
                                                return (<option >{c.name}</option>)
                                            })}
                                        </Form.Control>
                                    </Form>
                                </div>
                            </div>
                            {/* <div className="col-md-12 mb-1">
                                <div>
                                    <label>Address</label>
                                    <input value={address} onChange={(e) => setAddress(e.target.value)} type="" placeholder="Enter Address" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 pb-4 px-4">
                    <button
                        className="paybtn w-100 py-3" onClick={onAddAddress}>
                        Save Address
                    </button>
                </Modal.Footer>
            </Modal>
            {!isLoading && <Footer />}
            {isLoading && <Loader />}

        </>
    )
}
export default Checkout;