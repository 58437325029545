import React, { useEffect, useState } from 'react'
import { Modal, Form, Alert } from 'react-bootstrap';
import Header from './common/Header'
import Footer from './common/Footer'
import { toast } from 'react-toastify';
import { Country } from 'country-state-city';
import { userService } from '../services';
import Loader from './common/Loader';
import { config } from '../config/config';
import MaskedInput from 'react-text-mask';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Shipping = () => {
    const history = useHistory()
    const [show, setShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [name, setName] = useState("");
    const [phone, setNumber] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [address, setAddress] = useState("");
    const [bname, setBName] = useState("");
    const [bphone, setBNumber] = useState("");
    const [bzip, setBZip] = useState("");
    const [bcountry, setBCountry] = useState("");
    const [baddress, setBAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [prices, setPrices] = useState([]);
    const [cartProducts, setCartProducts] = useState([]);
    const [cartCount, setCartCount] = useState('');
    const [shipping, setShipping] = useState('');
    const [tax, setTax] = useState('');
    const [similarProducts, setSimilarProducts] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [favCount, setFavCount] = useState('');
    const [rates, setRates] = useState([]);
    const [userId, setUserId] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const addressId = urlParams.get('addressID');
    const addressIDDD = urlParams.get('address');
    const cartId = urlParams.get('cartId');
    const [selectedCarrier, setSelectedCarrier] = useState("");
    const [selectedService, setSelectedService] = useState("");
    const [orderId, setOrderId] = useState("");
    const location = useLocation()
  const taxvalue = location?.state?.taxvalue;
  console.log("taxvalue============= in shitting detail", taxvalue)

    useEffect(() => {
        createShipment();
    }, []);


    function createShipment() {
        if (localStorage.getItem('user_id')) {
            setIsLoading(true);
            let params = { addressId: addressId, user: localStorage.getItem('user_id') }
            userService.createShipment(params).then((response) => {
                setIsLoading(false);
                if (response.data.status == 200) {
                    setRates(response.data.shipResult.rates)
                    setOrderId(response.data.shipResult.id)
                } else {
                    toast.error("Some Error Occur");
                }
            }).catch((error) => {
                setIsLoading(false);
                setRates([]);
                setSelectedCarrier('');
            });
        } else {
            window.location.pathname = '/signin'
        }
    }

    function selectShippingDetail(item) {
        setSelectedCarrier(item.carrier)
        setSelectedService(item.service)
    }

    const handleNavigation = () => {
        const url = `/payment?address=${addressIDDD}`;
        const state = { taxvalue: taxvalue };
        
        history.push(url, state);
      };


    function makePayment() {
        if (selectedCarrier == "") {
            toast.error('Please select a shipping service.')
        } else {
            let params = { carrier: selectedCarrier, service: selectedService, orderId: orderId }
            userService.cartShipment(params, cartId).then((response) => {
                setIsLoading(false);
                if (response.data.status == 200) {
                    handleNavigation()
                    // setTimeout(() => {
                        // history.replace('payment?address=' + addressIDDD)
                    // }, 100);
                   
                } else {
                    toast.error("Some Error Occur");
                }
            }).catch((error) => {
                setIsLoading(false);
            });
        }
    }

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} />
            <section className="card_section pt-4 pb-5 checkout shipping-detail-area2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <h4>Shipping Details</h4>
                        </div>
                        {rates.length > 0 && rates.map((item, i) => {
                            if (['ParcelSelect', 'Priority', 'FEDEX_GROUND', 'GROUND_HOME_DELIVERY'].includes(item.service))
                                return (
                                    <div className="col-md-5 mb-3 mr-3 address address1" key={i}>
                                        <div className="notification_list" onClick={() => selectShippingDetail(item)}>
                                            <span>
                                                <div className="form-check pl-0 d-flex align-items-center">
                                                    <input
                                                        className="form-check-input"
                                                        onChange={(e) => { /* handle radio button change if needed */ }}
                                                        checked={item.carrier === selectedCarrier && item.service === selectedService}
                                                        type="radio"
                                                    />
                                                    <h5 className="ml-2 mb-0">{item.carrier} {item.service} {'$'}{item.rate}</h5>
                                                </div>
                                            </span>
                                            {item.delivery_days != null ? (
                                                <h6 className="mb-1 date_1 mt-3">{'Usually it takes ' + item.delivery_days + ' days to deliver.'}</h6>
                                            ) : (
                                                <h6 className="mb-1 date_1 mt-3">{'Usually it takes ' + 5 + ' days to deliver.'}</h6>
                                            )}
                                        </div>
                                    </div>
                                );
                        })}


                        <div className="col-md-12 text-center mt-4">
                            <button
                                className="paybtn py-3 shipping-continue-btn" onClick={makePayment} style={{ width: '240px' }}>Continue
                            </button>
                        </div>


                    </div>
                </div>
            </section>

            <Footer />
            {isLoading && <Loader />}

        </>
    )
}
export default Shipping;