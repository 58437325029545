import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide, Navigation, } from 'swiper/react';
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Autoplay, } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import { config } from '../config/config'
import Header from './common/Header'
import ReactStars from "react-rating-stars-component";

import Footer from './common/Footer'
import moment from 'moment';
import Download from './common/Download';

const Past_order = () => {

    const [lgShow, setLgShow] = useState(false);
    const [returnModal, setReturnModal] = useState(false);
    const [orderDetail, setProductDetail] = useState({});
    const [productId, setProductId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [tax, setTax] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [card, setCard] = useState('');
    const [isFavourite, setIsFavourite] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartCount, setCartCount] = useState('');
    const [favCount, setFavCount] = useState('');
    const [rating, setRating] = useState(0);
    const [temp, setTemp] = useState(0);
    const [review, setReview] = useState('');
    const [returnReason, setReturnReason] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    useEffect(() => {
        getOrderDetails()
    }, []);

    function getOrderDetails() {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);
        setIsLoading(true);
        userService.getOrderDetails(id).then((res) => {
            if (res.status === 200) {
                setIsLoading(false);
                setProductDetail(res.data.data);
                setTax(res.data.data.tax);
                setShipping(res.data.data.shipping_charge);
                let isCard = res.data.data.card ? res.data.data.card : 'Wallet';
                setCard(isCard);
            }
        }).catch((error) => {
            setIsLoading(false);
            setProductDetail('');
        });

    }

    function submitRating() {
        if (!rating) {
            toast.error("Please select rating.")
        } else {
            let data = {
                order: id,
                product: orderDetail.product._id,
                user: localStorage.getItem('user_id'),
                rating: rating,
                review: review
            }
            userService.submitRating(data).then(res => {
                setLgShow(false)
                getOrderDetails()
                if (res.status == 200) {
                }
            })
        }
    }

    const cancelSub = () => {
        window.location.href = '/profile'
    }

    function getTax() {
        return parseFloat((orderDetail?.price / 100) * orderDetail.tax).toFixed(2)
    }

    function getGrandTotalPrice() {
        let gPrice = orderDetail?.price;
        let taxVal = parseFloat(tax);
        let shippingVal = parseFloat(shipping);
        taxVal != 0 ? gPrice += gPrice * (taxVal / 100) : gPrice = gPrice;
        return gPrice;
    }

    function getDiscountedPrice(item) {
        return (item.price - (item.discount / 100) * item.price).toFixed(2)
    }

    function submitReason() {
        if (!returnReason) {
            toast.error("Please enter a return reason.")
        } else {
            let data = {
                returnReason: returnReason
            }
            userService.submitReturnReason(data, orderDetail._id).then(res => {
                setReturnModal(false)
                getOrderDetails()
                if (res.status == 200) {
                }
            })
        }
    }

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} />
            {isLoading && <Loader />}
            <section className="product-detials-page pb-3">
                <div className="details_popup text-center mb-4">
                    <marquee><p>Your order has been delivered to you on "{moment(orderDetail?.completedDate).format("MMM DD, YYYY & hh:mm A")}".</p></marquee>
                </div>
                <div className="container">
                    <div className="row">
                        <div class="col-md-6">

                            <Swiper
                                spaceBetween={25}
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                autoplay={{ delay: 3000 }}

                                navigation
                                scrollbar={{ draggable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() =>  console.log('slide change')}

                            >
                                {orderDetail?.product?.images.map(image => {
                                    return (
                                        <SwiperSlide>
                                            <div className="slider_box_new text-center mt-0">
                                                <img src={image.image ? config.imageUrl + image.image : ''} alt="img" />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}

                            </Swiper>
                        </div>
                        <div className="col-md-6">
                            <div className="product-detail-rightside mt-0">
                                <h5 className="mb-2 text-secondary mt-0">{moment(orderDetail?.createdOn).format("MMM DD, YYYY & hh:mm A")}</h5>
                                <h2>{orderDetail?.product?.name}</h2>
                                <h6><span>{orderDetail.product?.category?.name}</span></h6>

                                {orderDetail?.product?.discount > 0 && <h5 className="mt-4">PRICE:
                                    <del>${parseFloat(orderDetail?.product?.price).toFixed(2)}</del>
                                    <span style={{ fontSize: "23px" }}>${getDiscountedPrice(orderDetail?.product)}
                                        ({orderDetail?.product?.discount}% Off)
                                    </span></h5>}

                                {orderDetail?.product?.discount == 0 && <h5 className="mt-4">PRICE:
                                    <span style={{ fontSize: "23px" }}>${parseFloat(orderDetail?.product?.price).toFixed(2)}
                                    </span></h5>}

                                <h5 className="mt-2 ">QUANTITY:
                                    <span>
                                        {orderDetail.qty}
                                    </span>
                                </h5>

                                {orderDetail?.lineitem?.size && <h5 className="mt-0">SIZE:
                                    <div className="size_row">
                                        <span >{orderDetail?.lineitem.size}</span>
                                    </div>
                                </h5>}

                                {orderDetail?.lineitem?.color && <h5 className="mt-4">Color:
                                    <div className="color_row">
                                        <span style={{ backgroundColor: orderDetail?.lineitem.color }}>
                                            <i className="fa fa-check"></i>
                                        </span>
                                    </div>
                                </h5>}

                                <div className="border-top address_list pt-1">
                                    <h5 className="mt-2 mb-2">ADDRESS:</h5>
                                    <div className="address" style={{ padding: "6px 15px 5px 45px" }}>
                                        <span>
                                            <div class="form-check pl-0">
                                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" checked />
                                            </div>
                                        </span>
                                        <h5 className="my-1">{orderDetail.address?.name}</h5>
                                        <h6 className="mt-2 mb-1">{orderDetail.address?.phone}</h6>
                                        <h6 className="m-0 py-1">{orderDetail.address?.street1}, {orderDetail.address?.city}, {orderDetail.address?.state}, {orderDetail.address?.country} & {orderDetail.address?.zip_code}</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="description-area my-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h5 style={{ fontSize: "17px" }}>ORDER SUMMARY(ID:{orderDetail?._id})</h5>
                            <div className="card mt-2">
                                <div className="card-body" style={{ background: "#e0f8ff" }}>
                                    <dl>
                                        <h6 className="mt-0">{orderDetail?.product?.name}</h6>
                                    </dl>
                                    <dl className="dlist-align">
                                        <dt>Total price:</dt>
                                        <dd className="text-right ml-3">${orderDetail?.price?.toFixed(2)}</dd>
                                    </dl>

                                    <dl className="dlist-align">
                                        <dt>Tax({orderDetail.tax}%):</dt>
                                        <dd className="text-right ml-3">${getTax()}</dd>
                                    </dl>
                                    <dl className="dlist-align">
                                        <dt>Shipping:</dt>
                                        <dd className="text-right ml-3">${shipping}</dd>
                                    </dl>
                                    <hr />
                                    <dl className="dlist-align mb-0">
                                        <dt style={{ fontSize: "20px", color: "black" }}>Total:</dt>
                                        <dd className="text-right ml-3" style={{ fontSize: "20px" }}><strong>${getGrandTotalPrice().toFixed(2)}</strong></dd>
                                    </dl>
                                </div>
                            </div>

                        </div>
                        {orderDetail?.lineitem?.subscription && <div className="col-md-6 mb-3 subscribe_section">
                            <h5 style={{ fontSize: "17px" }}>SUBSCRIPTION</h5>
                            {orderDetail?.lineitem?.subscription_type.type == '30DAYS' && <div className="subs_firstbox subscribe_list P-3 text-center px-0">
                                <h5 style={{ fontSize: "16px" }} className="mb-3 px-5">YOU HAVE SUBSCRIBED FOR THE PRODUCT TO GET IT "{orderDetail?.lineitem?.subscription_type?.name}"</h5>
                                <div className="cancelsubs_btn">
                                    <button onClick={cancelSub} className="btn text-white mb-1">Manage Subscription</button>
                                </div>
                            </div>}
                            {orderDetail?.lineitem?.subscription_type.type == '45DAYS' && <div className="subs_secondbox subscribe_list P-3 text-center px-0">
                                <h5 style={{ fontSize: "16px" }} className="mb-3 px-5">YOU HAVE SUBSCRIBED FOR THE PRODUCT TO GET IT "{orderDetail?.lineitem?.subscription_type?.name}"</h5>
                                <div className="cancelsubs_btn">
                                    <button onClick={cancelSub} className="btn text-white mb-1">Manage Subscription</button>
                                </div>
                            </div>}
                            {orderDetail?.lineitem?.subscription_type.type == '60DAYS' && <div className="subs_thirdbox subscribe_list P-3 text-center px-0">
                                <h5 style={{ fontSize: "16px" }} className="mb-3 px-5">YOU HAVE SUBSCRIBED FOR THE PRODUCT TO GET IT "{orderDetail?.lineitem?.subscription_type?.name}"</h5>
                                <div className="cancelsubs_btn">
                                    <button onClick={cancelSub} className="btn text-white mb-1">Manage Subscription</button>
                                </div>
                            </div>}
                        </div>}
                        <div className="col-md-6 mb-3">
                            <div>
                                <h5 style={{ fontSize: "17px" }}>PAYMENT VIA</h5>
                                <div className="cardbox mb-3 detailscard py-1 mt-2" style={{ height: "unset" }}>
                                    <div className="cardbox_header mb-1 pt-2">
                                        <img src={card !== 'Wallet' ? require('../../src/images/credit-cards.png').default : require('../../src/images/wallet.png').default} />
                                    </div>
                                    <a className="car_btn">
                                        <h5>{card !== 'Wallet' ? 'XXXX XXXX XXXX' : 'Wallet'}<span>{card.last4}</span></h5>
                                        <h6 className="text-dark">{card?.card_holder_name}</h6>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <hr className="my-1" />
            </div>

            <section className="revies_section mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={{ fontSize: "17px" }}>YOUR RATING</h5>
                        </div>

                        <div className="col-md-12">

                            {orderDetail?.rating ? <div>
                                <div className="review_head mt-2">
                                    <h5 className="my-2">
                                        {Array.from(Array(orderDetail?.rating.rating)).map(t => {
                                            return <i class="fa fa-star" aria-hidden="true" />
                                        })}
                                        <span></span></h5>
                                    <h6><i className="fa fa-clock-o"></i> {moment(orderDetail?.rating.createdOn).fromNow()}</h6>
                                </div>
                                <p>{orderDetail?.rating.review}</p>

                            </div> : <div className="review_btn text-center">
                                <button class="btn" onClick={() => setLgShow(true)}>Give Rating & Reviews</button>
                            </div>
                            }

                        </div>

                        {orderDetail?.returnRequested == false ?
                            <div className="col-md-12 text-center mt-10" style={{ marginTop: '20px' }} onClick={() => setReturnModal(true)}>
                                <h5 style={{ fontSize: "17px" }}>want to return?</h5>
                            </div>
                            :
                            <div className="col-md-12 text-center mt-10" style={{ marginTop: '20px' }} >
                                <h5 style={{ fontSize: "17px" }}>{'The Return is processed. Please send the parcel on the mentioned address: ' + orderDetail.address?.street1 + ", " + orderDetail.address?.city + ", " + orderDetail.address?.state + ", " + orderDetail.address?.country + " & " + orderDetail.address?.zip_code + '.'}</h5>
                            </div>
                        }

                    </div>
                </div>
            </section>


            <Download />
            <Footer />

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="cancelorder_modal mb-4">
                                    <h5 style={{ fontSize: "24px", color: "black" }}>Give Rating & Review</h5>
                                    <div class="text-center">
                                        <ReactStars
                                            count={5}
                                            size={49}
                                            value={rating}
                                            onChange={(rating) => setRating(rating)}
                                            activeColor="#ffd700"
                                            classNames="ratingrow"
                                        />
                                    </div>
                                    <label className="mt-3">REVIEW MESSAGE</label>
                                    <textarea onChange={(review) => setReview(review.target.value)} placeholder="Enter Review Message"></textarea>
                                    <a className="btn subbtn text-white" onClick={() => submitRating()}>Submit</a>
                                    <a className="btn nobtn text-white" onClick={() => setLgShow(false)}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={returnModal}
                onHide={() => setReturnModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">

                                <div className="cancelorder_modal mb-4" >
                                    <h5 style={{ fontSize: "20px", color: "black" }}>Return Reason</h5>
                                    <label className="mt-3" style={{ color: "black", fontWeight: 700 }}>Why are you returning this?</label>
                                    <textarea onChange={(review) => setReturnReason(review.target.value)} placeholder="Enter a reason..."></textarea>
                                    <a className="btn subbtn text-white" onClick={() => submitReason()}>Submit</a>
                                    <a className="btn nobtn text-white" onClick={() => setReturnModal(false)}>Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}


export default Past_order;