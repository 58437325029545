import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide, Navigation, } from 'swiper/react';
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Autoplay, } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import { config } from '../config/config'
import Header from './common/Header'
import Footer from './common/Footer'
import moment from 'moment';

import ReactStars from "react-rating-stars-component";
import Download from './common/Download';

const Details = () => {
    const [lgShow, setLgShow] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});
    const [productId, setProductId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [card, setCard] = useState('');
    const [isAccepted, setIsAccepted] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartCount, setCartCount] = useState('');
    const [favCount, setFavCount] = useState('');
    const [cancelReason, setCancelReason] = useState('');

    const [tax, setTax] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [returnReason, setReturnReason] = useState('');
    const [returnModal, setReturnModal] = useState(false);
    const [lgShowPast, setLgShowPast] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);
        getDetails()
    }, []);

    function getDetails() {
        setIsLoading(true);
        userService.getOrderDetails(id).then((res) => {
            if (res.status == 200) {
                setIsLoading(false);
                setOrderDetail(res.data.data)
                let isCard = res.data.data.card ? res.data.data.card : 'Wallet';
                setCard(isCard);

                setTax(res.data.data.tax);
                setShipping(res.data.data.shipping_charge);
            }
        })
    }

    const cancelOrder = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let params = { cancel_reason: cancelReason }
        if (!cancelReason) {
            toast.error('Please enter cancellation reason')
        } else {
            userService.cancelOrder(urlParams.get('id'), params).then(res => {
                setLgShow(false)
                window.location.href = '/my_orders'
            })
        }
    }

    function submitReason() {
        if (!returnReason) {
            toast.error("Please enter a return reason.")
        } else if (!isAccepted) {
            toast.error("Please accept the terms and conditions.")
        } else {
            let data = {
                returnReason: returnReason
            }
            userService.submitReturnReason(data, orderDetail._id).then(res => {
                setReturnModal(false)
                getDetails()
                if (res.status == 200) {
                }
            })
        }
    }

    const cancelSub = () => {
        window.location.href = '/profile'
    }

    function submitRating() {
        if (!rating) {
            toast.error("Please select rating.")
        } else {
            setIsLoading(true)
            let data = {
                order: id,
                product: orderDetail.product._id,
                user: localStorage.getItem('user_id'),
                rating: rating,
                review: review
            }
            userService.submitRating(data).then(res => {
                setLgShowPast(false)
                getDetails()
                if (res.status == 200) {
                }
            })
        }
    }

    function getPrice() {
        return orderDetail?.price + orderDetail?.discountValue;
    }

    function getTax() {
        return parseFloat((orderDetail?.price / 100) * orderDetail.tax).toFixed(2)
    }

    function getSubDiscount() {
        return orderDetail?.discountValue?.toFixed(2);
    }

    function getGrandTotalPrice() {
        let gPrice = getPrice();
        gPrice -= getSubDiscount()
        gPrice = gPrice + parseFloat(getTax())
        gPrice += orderDetail.shipping_charge;
        return parseFloat(gPrice).toFixed(2);
    }

    function getDiscountedPrice(item) {
        return (item.price - (item.discount / 100) * item.price).toFixed(2)
    }

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} />
            {isLoading && <Loader />}
            {!isLoading && <>
                <section className="product-detials-page py-3">

                    <div className="container">
                        <div className="col-md-12">
                            <div className="detail_head">
                                <h5 className="mb-0">Product Details</h5>
                                <div className="cancelorder_btn">
                                    {orderDetail?.status == 'ACT' ? <button className="btn text-white" onClick={() => setLgShow(true)}>Cancel Order</button> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="details_popup text-center my-4">

                        {orderDetail?.status == "ACT" || orderDetail?.status == "ONWAY" ?
                            <marquee><p>{orderDetail.status == 'ONWAY' ? "Your order is on the way." : "Your order is yet to be confirmed."}</p></marquee>
                            :
                            orderDetail?.status == "CAN" ?
                                <marquee><p><img src={require('../images/cancellation1.svg').default} alt="" className="mr-1" /> You had  cancelled your order on "{moment(orderDetail?.cancelled_on).format("MMM DD, YYYY") + " at " + moment(orderDetail?.cancelled_on).format("hh:mm A")}”. Your payment will be back within 5 working days in your bank account. If you have any concern then feel
                                    free to contact us.</p></marquee>
                                :
                                orderDetail?.status == "COM" && orderDetail?.returnRequested == true ?
                                    orderDetail?.refunded == true ? <marquee><p>The order is returned and refunded.</p></marquee>
                                        : <marquee><p>The Return is processed and order has been delivered to you on "{moment(orderDetail?.completedDate).format("MMM DD, YYYY") + " at " + moment(orderDetail?.completedDate).format("hh:mm A")}".</p></marquee>
                                    : <marquee><p>Your order has been delivered to you on "{moment(orderDetail?.completedDate).format("MMM DD, YYYY") + " at " + moment(orderDetail?.completedDate).format("hh:mm A")}".</p></marquee>}
                    </div>

                    <div className="container">
                        <div className="row">

                            <div class="col-md-6">
                                    {orderDetail?.product && orderDetail?.product?.images.map(image => {
                                        return <SwiperSlide>
                                            <div className="slider_box_new text-center mt-0">
                                                <img src={image.image ? config.imageUrl + image.image : ''} alt="img" style={{ objectFit: 'contain' }} />
                                            </div>
                                        </SwiperSlide>
                                    })}
                            </div>

                            <div className="col-md-6">
                                <div className="product-detail-rightside mt-0">
                                    <h5 className="mb-2 text-secondary mt-0">{moment(orderDetail?.createdAt).format("MMM DD, YYYY") + " at " + moment(orderDetail?.createdAt).format("hh:mm A")}</h5>
                                    <h2>{orderDetail?.product?.name}</h2>
                                    <h6><span>{orderDetail?.product?.category?.name}</span></h6>
                                    <h5>
                                    </h5>
                                    {orderDetail?.product?.discount > 0 && <h5 className="mt-4">PRICE:{" "}
                                        <del>${parseFloat(orderDetail?.product?.price).toFixed(2)}</del>{" "}
                                        <span style={{ fontSize: "23px" }}>${getDiscountedPrice(orderDetail?.product)}
                                            ({orderDetail?.product?.discount}% Off)
                                        </span></h5>}
                                    {orderDetail?.product?.discount == 0 && <h5 className="mt-4">PRICE:{" "}
                                        <span style={{ fontSize: "23px" }}>${parseFloat(orderDetail?.product?.price).toFixed(2)}
                                        </span></h5>}
                                    <h5 className="mt-2 ">QUANTITY:
                                        <span> {orderDetail?.qty}
                                        </span></h5>
                                    {orderDetail?.lineitem?.size && <h5 className="mt-0">SIZE:
                                        <div className="size_row">
                                            <span >{orderDetail?.lineitem.size}</span>
                                        </div>
                                    </h5>}
                                    {orderDetail?.lineitem?.color && <h5 className="mt-4">Color:
                                        <div className="color_row">
                                            <span style={{ backgroundColor: orderDetail?.lineitem.color }}>
                                                <i className="fa fa-check"></i>
                                            </span>
                                        </div>
                                    </h5>}
                                    <div className="border-top address_list pt-1">
                                        <h5 className="mt-2 mb-2">ADDRESS:</h5>
                                        <div className="address" style={{ padding: "6px 15px 5px 45px" }}>
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" checked />
                                                </div>
                                            </span>
                                            <h5 className="my-1">{orderDetail?.address?.name}</h5>
                                            <p className="m-0 py-1">{orderDetail?.address?.street1}, {orderDetail?.address?.city}, {orderDetail?.address?.state} & {orderDetail?.address?.zip_code}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>


                <section className="description-area my-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <h5 style={{ fontSize: "17px" }}>ORDER SUMMARY(ID: {orderDetail?._id})</h5>
                                <div className="card mt-2">
                                    <div className="card-body" style={{ background: "#e0f8ff" }}>
                                        <dl>
                                            <h6 className="mt-0">{orderDetail?.product?.name}</h6>
                                        </dl>
                                        <dl className="dlist-align">
                                            <dt>Total price:</dt>
                                            <dd className="text-right ml-3">${getPrice()?.toFixed(2)}</dd>
                                        </dl>
                                        {getSubDiscount() > 0 && <dl className="dlist-align">
                                            <dt>Discount:</dt>
                                            <dd className="text-right text-success ml-3">-${getSubDiscount()}</dd>
                                        </dl>}
                                        <dl className="dlist-align">
                                            <dt>Tax({orderDetail.tax}%):</dt>
                                            <dd className="text-right ml-3">${getTax()}</dd>
                                        </dl>
                                        <dl className="dlist-align">
                                            <dt>Shipping:</dt>
                                            <dd className="text-right ml-3">${parseFloat(orderDetail.shipping_charge).toFixed(2)}</dd>
                                        </dl>
                                        <hr />
                                        <dl className="dlist-align mb-0">
                                            <dt style={{ fontSize: "20px", color: "black" }}>Total:</dt>
                                            <dd className="text-right ml-3" style={{ fontSize: "20px" }}><strong>${getGrandTotalPrice()}</strong></dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5 style={{ fontSize: "17px" }}>PAYMENT VIA</h5>
                                    <div className="cardbox mb-3 detailscard py-1 mt-2" style={{ height: "unset" }}>
                                        <div className="cardbox_header mb-1 pt-2">
                                            <img src={card !== 'Wallet' ? require('../../src/images/credit-cards.png').default : require('../../src/images/wallet.png').default} />
                                        </div>
                                        <a className="car_btn">
                                            <h5>{card !== 'Wallet' ? 'XXXX XXXX XXXX' : 'Wallet'}<span>{card.last4}</span></h5>
                                            <h6 className="text-dark">{card?.card_holder_name}</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {orderDetail?.status == "ONWAY" ?
                                orderDetail?.tracker != null ?
                                    <div className="col-md-5">
                                        <h5 style={{ fontSize: "17px" }}>DELIVERY STATUS</h5>
                                        {orderDetail?.tracker?.tracking_details.length > 0 && orderDetail?.tracker?.tracking_details.map((item, i) => {
                                            return (<div className="mb-3 mr-3 address address1" >
                                                <div className="notification_list">
                                                    <span>
                                                        <div class="form-check pl-0 d-flex align-items-center">
                                                            {orderDetail?.tracker?.tracking_details[orderDetail?.tracker?.tracking_details.length - 1].message == item.message ?
                                                                <input class="form-check-input" checked={true} type="radio" />
                                                                :
                                                                <input class="form-check-input" checked={false} type="radio" />
                                                            }
                                                            <h5 class="ml-2 mb-0"> {item.message}  </h5>
                                                        </div>
                                                    </span>
                                                    <h6 className="mb-1 date_1 mt-3">
                                                        <i className="fa fa-calendar mr-2"></i>
                                                        {moment(item.datetime).format("MMM DD, YYYY") + " at " + moment(item.datetime).format("hh:mm A")}</h6>
                                                    <h6 class="d-block date_1">
                                                        <i className="fa fa-map-marker mr-2 mt-1"></i>
                                                        {item.tracking_location.city?.toLowerCase()}, {item.tracking_location.state} {item.tracking_location.zip} & United States</h6>
                                                </div>
                                            </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    null
                                : null}

                            {orderDetail?.lineitem?.subscription && <div className="col-md-6 mb-3 subscribe_section">
                                <h5 style={{ fontSize: "17px" }}>SUBSCRIPTION</h5>
                                {orderDetail?.lineitem?.subscription_type.type == '30DAYS' && <div className="subs_firstbox subscribe_list P-3 text-center px-0">
                                    <h5 style={{ fontSize: "16px" }} className="mb-3 px-5">YOU HAVE SUBSCRIBED FOR THE PRODUCT TO GET IT "{orderDetail?.lineitem?.subscription_type?.name}"</h5>
                                    <div className="cancelsubs_btn">
                                        <button onClick={cancelSub} className="btn text-white mb-1">Manage Subscription</button>
                                    </div>
                                </div>}
                                {orderDetail?.lineitem?.subscription_type.type == '45DAYS' && <div className="subs_secondbox subscribe_list P-3 text-center px-0">
                                    <h5 style={{ fontSize: "16px" }} className="mb-3 px-5">YOU HAVE SUBSCRIBED FOR THE PRODUCT TO GET IT "{orderDetail?.lineitem?.subscription_type?.name}"</h5>
                                    <div className="cancelsubs_btn">
                                        <button onClick={cancelSub} className="btn text-white mb-1">Manage Subscription</button>
                                    </div>
                                </div>}
                                {orderDetail?.lineitem?.subscription_type.type == '60DAYS' && <div className="subs_thirdbox subscribe_list P-3 text-center px-0">
                                    <h5 style={{ fontSize: "16px" }} className="mb-3 px-5">YOU HAVE SUBSCRIBED FOR THE PRODUCT TO GET IT "{orderDetail?.lineitem?.subscription_type?.name}"</h5>
                                    <div className="cancelsubs_btn">
                                        <button onClick={cancelSub} className="btn text-white mb-1">Manage Subscription</button>
                                    </div>
                                </div>}
                            </div>}


                        </div>
                    </div>
                </section>
            </>}
            {orderDetail?.status == "COM" ?
                <div className="container">
                    <hr className="my-1" />
                </div>
                : null}

            {orderDetail?.status == "COM" ?
                <section className="revies_section mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 style={{ fontSize: "17px" }}>YOUR RATING</h5>
                            </div>

                            <div className="col-md-12">

                                {orderDetail?.rating ? <div>
                                    <div className="review_head mt-2">
                                        <h5 className="my-2">
                                            {Array.from(Array(orderDetail?.rating.rating)).map(t => {
                                                return <i class="fa fa-star" aria-hidden="true" />
                                            })}
                                            <span></span></h5>
                                        <h6><i className="fa fa-clock-o"></i> {moment(orderDetail?.rating.createdAt).fromNow()}</h6>
                                    </div>
                                    <p>{orderDetail?.rating.review}</p>

                                </div> : <div className="review_btn text-center">
                                    <button class="btn" onClick={() => setLgShowPast(true)}>Give Rating & Reviews</button>
                                </div>
                                }

                            </div>

                            <div className="container">
                                {moment().isBefore(moment(orderDetail?.createdAt).add(7, 'days')) && orderDetail?.returnRequested == false ?
                                    <div className="col-md-12 text-center mt-10" style={{ marginTop: '20px' }} onClick={() => setReturnModal(true)}>
                                        <h5 class="btn" style={{ fontSize: "17px" }}>Want to return?</h5>
                                    </div>
                                    :
                                    orderDetail?.returnRequested == true ? <div className="col-md-12 text-center mt-10" style={{ marginTop: '20px' }}>
                                        <h5 style={{ fontSize: "17px" }}>
                                            {orderDetail?.refunded == false ? 'The Return is processed. Please send the parcel on the mentioned address: 416 6th Street SW Willmar Minnesota 56201.' : 'The order is returned and refunded.'}
                                        </h5>
                                    </div> : null
                                }
                            </div>


                        </div>
                    </div>
                </section>
                : null}
            {!isLoading && <>
                <Download />
                <Footer />
            </>}

            <Modal
                show={lgShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header  className="border-0 pb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="cancelorder_modal mb-4">
                                    <h5>Cancel Order</h5>
                                    <p>Are you sure, want to cancel your order <b>"{orderDetail?.product?.name}"</b> ?</p>
                                    <label className="mt-3">GIVE A REASON</label>
                                    <textarea
                                        onChange={e => setCancelReason(e.target.value)}
                                        value={cancelReason}
                                        placeholder="Enter Reason">
                                    </textarea>
                                    <a className="btn subbtn text-white" onClick={() => cancelOrder()}>Submit</a>
                                    <a className="btn nobtn text-white" onClick={() => setLgShow(false)}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={lgShowPast}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header  className="border-0 pb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="cancelorder_modal mb-4">
                                    <h5 style={{ fontSize: "24px", color: "black" }}>Give Rating & Review</h5>
                                    <div class="text-center">
                                        <ReactStars
                                            count={5}
                                            size={49}
                                            value={rating}
                                            onChange={(rating) => setRating(rating)}
                                            activeColor="#ffd700"
                                            classNames="ratingrow"
                                        />
                                    </div>
                                    <label className="mt-3">REVIEW MESSAGE</label>
                                    <textarea onChange={(review) => setReview(review.target.value)} placeholder="Enter Review Message"></textarea>
                                    <a className="btn subbtn text-white" onClick={() => submitRating()}>Submit</a>
                                    <a className="btn nobtn text-white" onClick={() => setLgShowPast(false)}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={returnModal}
                onHide={() => setReturnModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">

                                <div className="cancelorder_modal mb-4" >
                                    <h5 style={{ fontSize: "24px", color: "black" }}>Return Reason</h5>
                                    <label className="mt-3" style={{ color: "black", fontWeight: 700 }}>Why are you returning this?</label>
                                    <textarea onChange={(review) => setReturnReason(review.target.value)} placeholder="Enter a reason..."></textarea>
                                    <div className='text-left'>Disclaimer: No refund will be initiated if product is consumed or seal of product is broken.</div>
                                    <div class="mb-3 form-check" style={{ "position": 'relative', marginTop: 10 }} >
                                        <input type="checkbox" onChange={() => setIsAccepted(!isAccepted)} value={isAccepted} class="form-check-input" id="exampleCheck1" style={{
                                            position: 'absolute',
                                            left: '20px'
                                        }} />
                                        <label class="form-check-label" for="exampleCheck1">I agree to terms and conditions</label>
                                    </div>
                                    <a className="btn subbtn text-white" onClick={() => submitReason()}>Submit</a>
                                    <a className="btn nobtn text-white" onClick={() => setReturnModal(false)}>Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Details;