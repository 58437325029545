import React from "react";

import { EmailIcon, EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

const shareOnWhatsApp = async (url, title) => {
  const os = getOperatingSystem();
  console.log("Operating System:", os);

  if (os === "Windows" || os === "Linux" || os === "MacOS") {
    if (await isWhatsAppInstalled()) {
      console.log("WhatsApp app is available. Sharing on WhatsApp app.");
      try {
        await navigator.share({
          title,
          url,
        });
        console.log("Shared successfully!");
      } catch (error) {
        console.error('Error sharing on WhatsApp:', error);
        fallbackToWhatsAppWeb(url, title);
      }
    } else {
      console.log("WhatsApp app is not available. Redirecting to WhatsApp Web.");
      fallbackToWhatsAppWeb(url, title);
    }
  } else {
    console.log("Unsupported operating system.");
  }
};

const getOperatingSystem = () => {
  const platform = navigator.platform.toLowerCase();
  if (platform.includes("win")) {
    return "Windows";
  } else if (platform.includes("linux")) {
    return "Linux";
  } else if (platform.includes("mac")) {
    return "MacOS";
  } else {
    return "Unknown";
  }
};

const isWhatsAppInstalled = async () => {
  return new Promise((resolve) => {
    const isWhatsApp = /WhatsApp/.test(navigator.userAgent);
    resolve(isWhatsApp);
  });
};

const fallbackToWhatsAppWeb = (url, title) => {
  console.log("WhatsApp app is not available. Opening WhatsApp link.");
  window.open(`https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`, '_blank');
};

function shareOnWhatsapp(url, text) {
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);
  const navUrl = `https://api.whatsapp.com/send?text=${encodedText}%0A${encodedUrl}`;
  console.log("navUrl", navUrl)
  window.open(navUrl, '_blank');
}






const ShareOnSocialMedia = props => (

  <div className={" animated animatedFadeInUp fadeInUp social-btns text-right d-flex " + (props.quizClass ? props.quizClass : '')}>
    <div className="social-btn">
      <FacebookShareButton url={props.url} quote={props.text}>
        <button className="btn btn-sm btn-facebook" data-toggle="tooltip" title="Facebook">
          <i className="fa fa-facebook" />
        </button>
      </FacebookShareButton>
    </div>
    <div className="social-btn">
      <TwitterShareButton url={props.url} title={props.text}>
        <button className="btn btn-sm twt-btn" data-toggle="tooltip" title="Twitter">
          <i class="fa-brands fa-x-twitter"></i>
        </button>
      </TwitterShareButton>
    </div>
    <div className="social-btn share-social-top">
      <EmailShareButton
        url={props.url}
        subject={"Join me on this FREE Health Social Network!"}
        body={props.text}
        separator=" "
        className="btn btn-sm whtsp-btn share-whtsapp-icon"
      >
        <i class="fa fa-envelope-o" aria-hidden="true"></i>
      </EmailShareButton>
    </div>
    <div className="social-btn">
      <button className="btn btn-sm whtsp-btn share-whtsapp-icon" data-toggle="tooltip" title="Whatsapp"
        onClick={(e) => shareOnWhatsapp(props.url, props.text)}
      >
        <i className="fa fa-whatsapp" />
      </button>
    </div>
  </div>
);

export default ShareOnSocialMedia;
