import React, { useState, useEffect } from 'react';
import { userService } from '../../services'
import { toast } from 'react-toastify';
import Loader from './Loader'
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { config } from '../../config/config'
import Header from './Header'
import Footer from './Footer'
import { useParams } from 'react-router-dom';

const ReferralCode = () => {

    const [email, setEmail] = useState('');

    const { id } = useParams()

    useEffect(() => {
        localStorage.setItem('referralId', id)
        window.location.href = "/"
    }, [])


    return (
        <>

        </>
    );
}

export default ReferralCode;