import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { userService } from "../services";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import validator from 'validator';
import { appName, appUrl } from '../config/config';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactLanguageSelect from "react-languages-select";
import 'react-languages-select/css/react-languages-select.css';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import Loader from "./common/Loader";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import ShareOnSocialMedia from "./common/ShareOnSocialMedia";
let title = `Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link.`


const CreateWorld = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const { transcript, resetTranscript } = useSpeechRecognition();
  const bottomRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [copy, setCopy] = useState(true);
  const [share_url, setshare_url] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [categoryList, setcategoryList] = useState()
  const [isDateSelected, setIsDateSelected] = useState(false);
  const handleCloseSuccess = () => {
    if (localStorage.getItem("userType") === "Professional") {
      window.location.href = `/world-listing`
      setShowSuccess(false)
    }
  }



  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);
  const [createWebinar, setCreateWebinar] = useState({ "title": "", "webinarLanguage": "", "description": "", "ZoomUrl": "", "dateandTime": "", "webinar_endTime": "", "category": "" })
  useEffect(() => {
    getCotagery()
  }, [])
  function getCotagery() {
    userService.get_category().then((res) => {
      setCreateWebinar({ ...createWebinar, category: res?.data?.data[0]?._id })
      setcategoryList([...res?.data?.data])

    })
      .catch((Error) => {
      })
  }
  const handleChange = (event) => {
    setCreateWebinar({ ...createWebinar, category: event.target.value });
  };

  function inputChange(e) {
    let { name, value } = e.target;
    setCreateWebinar({ ...createWebinar, [name]: value });
  }

  useEffect(() => {
    if (isListening) {
      setCreateWebinar({ ...createWebinar, description: transcript })
      const textInputEl = inputRef.current;
      textInputEl.scrollTop = textInputEl?.scrollHeight;
    }
  }, [transcript])

  const getSpeechLanguage = () => {

    return localStorage.getItem("userLaguage") || 'en-US'; // Default to English if not found
  };
  const browserLanguage = navigator.language || navigator.userLanguage;

  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: selectedLanguage
    });
    setTimeout(stopHandle, 120000)
  };

  const stopHandle = () => {
    setIsListening(false);
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
    setCreateWebinar({ ...createWebinar, description: (document.getElementById("input_focused").value) });

  };
  const handleReset = () => {
    setCreateWebinar({ ...createWebinar, description: transcript })
    stopHandle();
    resetTranscript();
  };

  function handleSubmit(e) {
    e.preventDefault()
    if (!createWebinar?.title || createWebinar?.title?.trim() === "") {
      Swal.fire("Warning", "Please enter title.", "warning");

      return
    }
    else
      if (!createWebinar?.dateandTime || createWebinar?.dateandTime === "") {
        Swal.fire("Warning", "Please select Webinar Start Time.", "warning");
        return
      } else if (createWebinar?.dateandTime && new Date(createWebinar?.dateandTime).getTime() < Date.now()) {
        Swal.fire("Warning", "Selected date time should not be less than current date time.", "warning");
      }

      else if (!createWebinar?.webinar_endTime || createWebinar?.webinar_endTime === "") {
        Swal.fire("Warning", "Please select Webinar End Time.", "warning");
        return
      }

      else if (new Date(createWebinar?.webinar_endTime).getTime() <= new Date(createWebinar?.dateandTime).getTime()) {
        Swal.fire("Warning", "Webinar End Time should be greater than Webinar Start Time.", "warning");
        return;
      }

      else
        if (!createWebinar?.webinarLanguage || createWebinar?.webinarLanguage === "") {
          Swal.fire("Warning", "Please enter webinar language.", "warning");
          return
        }
        else if (!createWebinar?.ZoomUrl || createWebinar?.ZoomUrl === "") {
          Swal.fire("Warning", "Please enter zoom url.", "warning");
          return
        }
        else if (createWebinar?.ZoomUrl && !validator.isURL(createWebinar?.ZoomUrl)) {
          Swal.fire("Warning", "Please enter valid Url.", "warning");
          return
        }
        else
          if (!createWebinar?.description || createWebinar?.description?.trim() === "") {
            Swal.fire("Warning", "Please enter description.", "warning");
            return
          }
          else {
            createNewWebinar()
          }
  }
  const currentDate = new Date();

  function shareOnWhatsapp(url, text) {
    const encodedUrl = encodeURIComponent(url);
    const encodedText = encodeURIComponent(text);
    const navUrl = `https://api.whatsapp.com/send?text=${encodedText}%0A${encodedUrl}`;
    window.open(navUrl, '_blank');
  }


  function createNewWebinar() {
    const params = {
      title: createWebinar?.title,
      description: createWebinar?.description,
      date: createWebinar?.dateandTime,//Date?.parse(createWebinar?.dateandTime),
      webinar_endTime: createWebinar?.webinar_endTime,
      category: createWebinar?.category,
      webinar_url: createWebinar?.ZoomUrl,
      language: createWebinar?.webinarLanguage,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    setIsLoading(true)
    userService.create_webinar(params).then((res) => {
      if (res?.data?.status === 200) {
        setIsLoading(false)
        setShowSuccess(true)
        setshare_url(appUrl + `world-details/${res?.data?.data?.webinarSlug}`)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }
  const hindiPlaceholder = {
    pm: "पीएम",
  };


  const shareOnWhatsApp = async (url, title) => {
    const os = getOperatingSystem();
    console.log("Operating System:", os);

    if (os === "Windows" || os === "Linux" || os === "MacOS") {
      if (await isWhatsAppInstalled()) {
        console.log("WhatsApp app is available. Sharing on WhatsApp app.");
        try {
          await navigator.share({
            title,
            url,
          });
          console.log("Shared successfully!");
        } catch (error) {
          console.error('Error sharing on WhatsApp:', error);
          fallbackToWhatsAppWeb(url, title);
        }
      } else {
        console.log("WhatsApp app is not available. Redirecting to WhatsApp Web.");
        fallbackToWhatsAppWeb(url, title);
      }
    } else {
      console.log("Unsupported operating system.");
    }
  };

  const getOperatingSystem = () => {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes("win")) {
      return "Windows";
    } else if (platform.includes("linux")) {
      return "Linux";
    } else if (platform.includes("mac")) {
      return "MacOS";
    } else {
      return "Unknown";
    }
  };

  const isWhatsAppInstalled = async () => {
    return new Promise((resolve) => {
      const isWhatsApp = /WhatsApp/.test(navigator.userAgent);
      resolve(isWhatsApp);
    });
  };

  const fallbackToWhatsAppWeb = (url, title) => {
    console.log("WhatsApp app is not available. Opening WhatsApp link.");
    window.open(`https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`, '_blank');
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <section className="bg_section signup_section redesign-profile">
        <div className="container">
          <div className="back-btn-sec mb-3">
            <Button className="btn back-btn" href="/world-listing"><i className="fa fa-angle-left mr-2"></i>Back to Listing</Button>
          </div>
          <div className="row">
            <aside className="col-md-12">
              <div className="Signup_box">
                <h4 className="mb-4">Create webinar</h4>
                <div className="row border-1">
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <div className="">
                        <div className="row frist_step  ">
                          <aside className="col-md-6 mb-4 text-left">
                            <label>
                              Title<span className="blue-asterisk">*</span><span className="mandatory"></span>
                            </label>
                            <div className="input_row">
                              <input
                                type="title"
                                name="title"
                                maxLength={200}
                                value={createWebinar?.title}
                                onChange={(e) => inputChange(e)}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Title"
                                className="input103 w-100"
                              />
                            </div>
                          </aside>

                          <aside className="col-md-6 mb-4 text-left">
                            <div className="input_row">
                              <label>Category<span className="blue-asterisk">*</span> </label>
                              <select
                                className="input103 w-100 select-main"
                                name="Choose Category"
                                value={createWebinar?.value}
                                onChange={handleChange} >
                                {categoryList?.map((category) => (
                                  <option key={category?._id} value={category?._id}>
                                    {category?.name}
                                  </option>
                                ))}
                              </select>

                            </div>
                          </aside>


                          <aside className="col-md-6 mb-4 text-left">
                            <div className="input_row">
                              <label>Webinar Start Time<span className="blue-asterisk"></span></label>
                              <div className="boder-box create-webinar-date">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DateTimePicker
                                    ampm={true}
                                    autocomplete={false}
                                    inputFormat="AM:PM"
                                    disabledKeyboardNavigation={false}
                                    closeOnSelect={false}
                                    id="enddate"
                                    value={createWebinar?.dateandTime}
                                    allowKeyboardControl={false}
                                    InputProps={{ readOnly: true }}
                                    disablePast={true}
                                    minDateTime={currentDate}
                                    minutesStep={1}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                        InputLabelProps={{
                                          ...(params.InputLabelProps || {}),
                                          placeholder: hindiPlaceholder[params.inputProps?.['data-mui-value']] || 'AM',
                                        }}
                                      />
                                    )}
                                    onChange={(newValue) => {
                                      setCreateWebinar({
                                        ...createWebinar,
                                        dateandTime: newValue,
                                      });
                                      setIsDateSelected(true);
                                    }}
                                    onError={(e) => console.log(e, 'error')}
                                    disableToolbar
                                  />
                                </LocalizationProvider>
                              </div>

                            </div>
                          </aside>
                          <aside className="col-md-6 mb-4 text-left">
                            <div className="input_row">
                              <label>Webinar End Time<span className="blue-asterisk"></span></label>
                              <div className="boder-box create-webinar-date">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DateTimePicker
                                    ampm={true}
                                    autocomplete={false}
                                    inputFormat="AM:PM"
                                    disabledKeyboardNavigation={false}
                                    closeOnSelect={false}
                                    id="webinar_endTime"
                                    value={createWebinar?.webinar_endTime}
                                    allowKeyboardControl={false}
                                    InputProps={{ readOnly: true }}
                                    disablePast={true}
                                    minDateTime={currentDate}
                                    minutesStep={1}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                        InputLabelProps={{
                                          ...(params.InputLabelProps || {}),
                                          placeholder: hindiPlaceholder[params.inputProps?.['data-mui-value']] || 'AM',
                                        }}
                                      />
                                    )}
                                    onChange={(newValue) => {
                                      setCreateWebinar({
                                        ...createWebinar,
                                        webinar_endTime: newValue,
                                      });
                                      setIsDateSelected(true);
                                    }}
                                    onError={(e) => console.log(e, 'error')}
                                    disableToolbar
                                  />
                                </LocalizationProvider>
                              </div>

                            </div>
                          </aside>


                          <aside className="col-md-6 mb-4 text-left">
                            <label>
                              Webinar Language<span className="blue-asterisk">*</span><span className="mandatory"></span>
                            </label>
                            <div className="input_row">
                              <input
                                type="text"
                                name="webinarLanguage"
                                maxLength={30}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                value={createWebinar?.webinarLanguage}
                                onChange={(e) => inputChange(e)}
                                placeholder="Enter Webinar Language"
                                className="input103 w-100"
                              />
                            </div>
                          </aside>
                          <aside className="col-md-6 mb-4 text-left">
                            <label>
                              Zoom Meeting URL<span className="blue-asterisk">*</span><span className="mandatory"></span>
                            </label>
                            <div className="input_row">
                              <input
                                type="url"
                                name="ZoomUrl"
                                maxLength={500}
                                value={createWebinar?.ZoomUrl}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => inputChange(e)}
                                placeholder="Enter Zoom Meeting URL"
                                className="input103 w-100"
                              />
                            </div>
                          </aside>
                          <aside className="col-md-6 mb-4 text-left notranslate msg-placement">
                            <label>Description<span className="blue-asterisk">*</span></label>
                            <div className="input_row">
                              <textarea
                                type=""
                                rows="4"
                                if="input_focused"
                                maxLength={1000}
                                value={createWebinar?.description}
                                ref={inputRef}
                                onChange={(e) => inputChange(e)}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                name="description"
                                placeholder="Enter Description"
                                className="textarea103 w-100"
                              />
                              <div className="send-record-btn">


                                <div className="microphone-section-area">

                                  <>
                                    <div
                                      className="microphone-icon-container submit"
                                      ref={microphoneRef}
                                      onClick={(e) => {
                                        if (isListening) {
                                          stopHandle()
                                          return
                                        }
                                        else {
                                          handleListing()
                                        }
                                      }}
                                    >



                                      {!isListening ?
                                        <i class={"fa fa-microphone microphone-icon"} aria-hidden="true"></i>
                                        :
                                        <span className="stop-text webinar-stop"><i class="fa fa-stop-circle-o" aria-hidden="true"></i></span>}
                                    </div>
                                    <div className="microphone-status">

                                      <i class="fa-light fa-waveform-lines"></i>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                            <div className="language-select-bottom">
                              <ReactLanguageSelect
                                names={["both"]}
                                searchable={true}
                                onSelect={(languageCode) => {
                                  console.log("Selected Language Code:", languageCode);
                                  setSelectedLanguage(languageCode);
                                }}
                              />

                            </div>
                          </aside>
                        </div>
                      </div>
                    </div>
                    <div className="next_btn text-center mt-4">
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn w-25"
                        onClick={handleSubmit}
                      >
                        Create Webinar
                      </Button>
                    </div>
                  </aside>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="email-verfication text-center">
            <img src={require("../images/update/congrats.png").default} alt="img" />
            <h3 className="mt-4 mb-3 black-text"> Webinar created successfully.</h3>
            <p className="black-text">
              <span> Share your new post with 10 friends to expand your network!</span>
            </p>
            <div className="copy-profile d-flex mb-4 copy-link-area">

              <input
                type="text"
                value={share_url}
                disabled={true}
                style={{ fontSize: "12px" }}
              />
              <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
                <i
                  className="fa fa-clipboard Demo__some-network__share-button"
                  title="Copy to clipboard"
                  aria-hidden="true"
                  style={{ fontSize: "19px", cursor: "pointer" }}
                ></i>
              </CopyToClipboard>
              {copy ? (
                <>
                  <span className="copied-text" style={{ fontSize: "12px", color: "#ff7750" }}>
                    Link Copied
                  </span>
                  <br></br>
                </>
              ) : (
                <>
                  <span style={{ fontSize: "10px" }}></span>
                  <br></br>
                </>
              )}
            </div>
            <div className="congrats-social-icons mb-2">

              <ShareOnSocialMedia url={share_url} text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Footer />

    </>
  );
};
export default CreateWorld;
