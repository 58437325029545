import React, { useState, useEffect, useRef } from "react";
import { userService } from "../../services";
import { toast } from "react-toastify";
import Loader from "./Loader";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { categories, config } from "../../config/config";
import Header from "./Header";
import Footer from "./Footer";
import facebookImage from "../../images/update/facebook.png";
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import Swal from "sweetalert2";
import facebookIcon from '../../images/update/facebook.png';


const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [show, setShow] = useState(false);
  const [successPro, setsuccessPro] = useState(false)
  const handleClosePro = () => setsuccessPro(false);

  const [showSocialMedia, setShowSocialMedia] = useState(false)
  const handleCloseSocialMedia = () => setShowSocialMedia(false);
  const [userType, setUserType] = useState("Professional")

  const handleClose = () => setShow(false);
  const [showverify, setShowVerify] = useState(false)
  let redirectUri = localStorage.getItem("redirectUri")

  const handleCloseEmailVerify = () => setShowVerify(false);
  const handleShowEmailVerify = () => setShowVerify(true);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [showbutton, setshowbutton] = useState(false)

  const resendVerification = () => {
    setshowbutton(true)
    userService
      .ResendEmailVerificationApi({
        email: email,
      })
      .then((res) => {
        Swal.fire("Success", "Email Resent successfully", "success").then((res) => {
          handleCloseEmailVerify();
        })

      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Invalid request",
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: "Custom image",
        });
        setshowbutton(false)
      });
  };

  const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  function onLogIn() {
    if (email === "") {
      toast.error("Please Enter Email", {
        toastId: 'success_draft_1',
      });;
    }
    else if (!EMAILREGEX.test(email)) {
      toast.error("Please enter valid email address", {
        toastId: 'success_draft_1',
      });
    }
    else if (password === "") {
      toast.error("Please Enter Password", {
        toastId: 'success_draft_1',
      });
    } else {
      setIsLoading(true);
      let params = {
        email: email,
        password: password,
        loginType: "Standard",
        deviceInfo: { token: "", deviceType: "web" },
      };
      userService
        .logIn(params)
        .then((response) => {
          if (response.data.status == 200) {
            localStorage.setItem("userType", response?.data?.data?.userType)
            localStorage.setItem("user_id", response.data.data?._id);
            localStorage.setItem("image", response.data.data?.profilePicture);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("token", response.data?.token)
            localStorage.setItem("remember_me", rememberMe);
            localStorage.setItem("isStarted", false)
            localStorage.setItem("userSlug", response?.data?.data?.userSlug)
            toast.success("Successfully Signed-In", {
              toastId: 'success_draft_1',
            });
            setIsLoading(false);
            if (localStorage.getItem("redirectUri")) {
              window.location.href = redirectUri;
            }
            else {
              if (response?.data?.data?.ProfileCreated == 0) {
                window.location.href = "/create-profile"
              }
              else {
                window.location.href = "/";
              }
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error?.response?.data?.status == 422) {
            setIsLoading(false);
            setShow(true)
          } else {
            if (error?.response?.data.message == "Please verify your email first") {
              handleShowEmailVerify();
            }
            else if (error?.response?.data.message == "Your account verification request has been rejected by admin. Please submit again." && error?.response?.data?.checkUserDetail?.admin_status == "Rejected") {
              localStorage.setItem("token", error?.response?.data?.token)
              localStorage.setItem("userType", error?.response?.data?.checkUserDetail?.userType)
              localStorage.setItem("user_id", error?.response.data?.checkUserDetail?._id);
              localStorage.setItem("user", JSON.stringify(error?.response.data.checkUserDetail));
              localStorage.setItem("isStarted", false)
              Swal.fire("Warning", error?.response?.data?.message, "warning").then((res) => {
                window.location.href = "/create-profile";
              })
            }
            else {
              toast.error(error?.response?.data?.message, {
                toastId: 'success_draft_1',
              });
            }
          }

        });
    }
  }

  //for facebook Login
  const responseFacebook = (response) => {
    if (!response.status) {
      const formData = new FormData();
      formData.append("signUpType", "Facebook");
      formData.append("screenNumber", 3);
      formData.append("email", response.email);
      formData.append("name", response.name);
      formData.append("fbId", response.id);
      formData.append("appleId", "");
      formData.append("metricsDate", getDate());
      let socialLoginData = {
        signUpType: "Facebook",
        screenNumber: 5,
        email: response.email,
        fbId: response.id,
      };
      socialLogin(formData, socialLoginData);
    }
  };

  function getDate() {
    var date = new Date().getDate(); //Current Date
    var year = new Date().getFullYear(); //Current Year
    var month = new Date().getMonth() + 1;
    var day = new Date().getDay() + 1;
    return month + "-" + date + "-" + year;
  }

  function responseGoogle(response) {
    if (response.profileObj) {
      const formData = {
        signUpType: "Google",
        screenNumber: 3,
        email: response.profileObj.email,
        name: response?.profileObj?.name,
        fbId: "",
        deviceInfo: { token: "", deviceType: "Other" },
        appleId: "",
        metricsDate: getDate(),
      };

      let socialLoginData = {
        signUpType: "Google",
        screenNumber: 5,
        email: response.profileObj.email,
        fbId: "",
      };
      socialLogin(formData, socialLoginData);
    }
  }

  let userIDRef = useRef(null);


  const socialLogin = (params, socialLoginData) => {
    userService.socialLogin(params).then(function (response) {

      localStorage.removeItem('socialLogin');
      if (response.data.status == 200) {
        let data = response?.data?.data[0]?._id;
        userIDRef.current = data;
        if (response?.data?.data[0]?.userType == null) {
          setShowSocialMedia(true)
        }
        else {
          console.log("response?.data?.data[0]?.userType", response?.data?.token)
          localStorage.setItem("token", response?.data?.token)
          localStorage.setItem('user_id', response.data.data[0]._id);
          localStorage.setItem("userType", response?.data?.data[0]?.userType)
          localStorage.setItem("user", JSON.stringify(response.data.data[0]));
          localStorage.setItem("isStarted", false)
          toast.success("Successfully Signed-In", {
            toastId: 'success_draft_1',
          });
          if (response?.data?.data[0]?.ProfileCreated == 0) {
            window.location.href = "/create-profile";
          }
          else {
            window.location.href = "/";
          }
        }
      } else if (response.data.status === 202) {
        localStorage.setItem('socialLogin', JSON.stringify(socialLoginData));
      }
      else if (response.data.status === 401) {
        toast.error(response?.data?.message, {
          toastId: 'success_draft_1',
        });
      }
      else {
        setIsLoading(false);
        toast.error("Some Error Occur", {
          toastId: 'success_draft_1',
        });
      }
    }).catch((error) => {
      setIsLoading(false);
      if (error?.response?.data?.status == 422) {
        setIsLoading(false);
        setShow(true)
      } else {
        if (error?.response?.data.message == "Your account verification request has been rejected by admin. Please submit again." && error?.response?.data?.data[0]?.admin_status == "Rejected") {
          localStorage.setItem("token", error?.response?.data?.token)
          localStorage.setItem("userType", error?.response?.data?.data[0]?.userType)
          localStorage.setItem("user_id", error?.response?.data?.data[0]?._id);
          localStorage.setItem("user", JSON.stringify(error?.response?.data?.data[0]));
          localStorage.setItem("isStarted", false)
          Swal.fire("Warning", error?.response?.data?.message, "warning").then((res) => {
            window.location.href = "/create-profile";
          })
        }
        else {
          toast.error(error?.response?.data?.message, {
            toastId: 'success_draft_1',
          });
        }
      }

    });
  }

  const [userDetail, setuserDetails] = useState({
    accountType: ""
  })

  const handleChange = (event) => {
    setuserDetails({ ...userDetail, accountType: event.target.value });
  };

  function saveSocialLoginUserType() {
    if (userType == "Professional" && userDetail?.accountType == "") {
      Swal.fire("Warning", "Please select account type.", "warning");
    }
    else {
      let params = {
        user_id: userIDRef.current,
        user_type: userType,
        professional_type: userDetail?.accountType,
      }
      userService.SocialLogin_user_type(params).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("token", res?.data?.token)
          localStorage.setItem("userType", res?.data?.data?.userType)
          localStorage.setItem("user_id", res?.data?.data?._id)
          localStorage.setItem("user", JSON.stringify(res?.data?.data));
          localStorage.setItem("isStarted", false)
          setShowSocialMedia(false)
          if (res?.data?.data?.ProfileCreated == 0) {
            window.location.href = "/create-profile"
          }
          else {
            window.location.href = "/";
          }
        }
      })
        .catch((error) => {
        })
    }
  }


  const handleKeypress = (e) => {
    if (e.charCode === 13) onLogIn();
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <section className="bg_section signin-bg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <aside className="col-md-6 col-sm-6 pt-4">
              <div className="Signup_box">
                <h4>SIGN IN</h4>
                <p className="text-secondary">
                  Welcome to{" "}
                  <a href="#" className="higlight-text">
                    BIG4 Health
                  </a>{" "}
                  - where healthcare providers and clients meet to prevent and
                  reverse the BIG4: high blood pressure, diabetes, high
                  cholesterol, or obesity.
                </p>
                <form className="py-4">
                  <div className="row">
                    <aside className="col-md-12 mb-3">
                      <div className="input_row">
                        <label>Email Address</label>
                        <input
                          onKeyPress={handleKeypress}
                          type="text"
                          name=""
                          placeholder="Email Address"
                          className="input103 w-100"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </aside>
                    <aside className="col-md-12 mb-3">
                      <div className="input_row password">
                        <label>Password</label>
                        <input
                          onKeyPress={handleKeypress}
                          type={showPassword ? "password" : "name"}
                          name=""
                          placeholder="Password"
                          className="input103 w-100"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <img
                          onClick={() => setShowPassword(!showPassword)}
                          src={showPassword ? require("../../images/update/eye-close.svg").default : require("../../images/update/eye.svg").default}
                          alt="img"
                        />

                      </div>
                    </aside>
                    <aside className="col-md-12 my-2">
                      <h6 className="psw text-right">
                        {" "}
                        <a href="/forgotpassword">Forgot Password?</a>
                      </h6>
                    </aside>
                    <aside className="col-md-12 mt-3 sign_btn">
                      <a className="btn" onClick={() => onLogIn()}>
                        Sign In
                      </a>
                    </aside>
                    <aside className="col-md-12">
                      <div className="login_footer my-4 p-0 text-center ">
                        <div className="or_line mb-2">
                          OR{" "}
                          <img
                            src={
                              require("../../images/update/circle.svg").default
                            }
                            alt="img"
                          />
                        </div>
                      </div>
                    </aside>
                    <aside className="col-sm-12">
                      <div className="row">
                        <aside className="col-sm-12">
                          <span className="google_btn common-height-btn">
                            <GoogleLogin
                              clientId={`${config.googleClientId}`}
                              render={(renderProps) => (
                                <a
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                  className="btn btn-google"
                                >
                                  <img
                                    src={
                                      require("../../images/update/google-logo.png")
                                        .default
                                    }
                                    alt="img"
                                  />
                                  Google
                                </a>
                              )}
                              redirectUri="https://big4health.com/"
                              buttonText="Login"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy={"single_host_origin"}

                            />
                          </span>
                        </aside>
                        <aside className="col-sm-12 mt-4">
                          <span className="btn-fb front-fb-btn common-height-btn">
                            <a

                              className="btn face-btn"
                            >
                              <img
                                src={
                                  require("../../images/update/facebook.svg")
                                    .default
                                }
                                alt="img"
                              />
                              <FacebookLogin
                                appId={config.fbAppId}
                                cssClass="btn"
                                redirectUri="https://big4health.com/"
                                fields="name,email,picture"
                                callback={responseFacebook}
                                textButton="Facebook"
                              />
                            </a>

                          </span>
                        </aside>

                        <aside className="col-sm-12 mt-4">
                          <a href="/register" className="new-btn common-height-btn">
                            New to BIG4 Health?{" "}
                            <a href="/register" className="higlight-text">
                              Join Now
                            </a>
                          </a>
                        </aside>
                      </div>
                    </aside>
                  </div>
                </form>
              </div>
            </aside>
            <aside className="col-md-6 col-sm-6 pt-4">
              <div className="right-side-img">
                <img
                  src={require("../../images/update/right-doctor.png").default}
                  alt="img"
                />
              </div>
            </aside>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="verify-pop-space">
            <div className="email-verfication text-center">
              <img
                src={require("../../images/update/sand-clock.png").default}
                alt="img"
              />
              <h3 className="mt-5">Verification Pending</h3>
              <p className="mdl-desc verfication-bottom-spacing">
                Your account details are currently under admin approval. Please wait until your account is approved by the admin.
              </p>

            </div>
          </Modal.Body>
        </Modal>


        <Modal show={showSocialMedia} onHide={handleCloseSocialMedia} centered className="new-pop account-type-popup-area notranslate">
          <Modal.Header closeButton >
          </Modal.Header>
          <Modal.Body className="social-signin">
            <h4>Select Account type</h4>
            <div className="email-verfication account-type-popup">
              <Tabs
                defaultActiveKey="Professional"
                id="uncontrolled-tab-example"
                className="my-3 mb-4"
                onSelect={(e) => { setUserType(e) }}
              >
                <Tab
                  eventKey="Professional"
                  title="Healthcare Provider"
                >
                </Tab>
                <Tab eventKey="Client" title="Client">
                </Tab>
              </Tabs>
              {userType == "Professional" &&
                <div className="input_row text-left">
                  <label>Select Account Type</label>
                  <div className="input103">
                    <select
                      className=" w-100 select-main"
                      name="userType"
                      onChange={handleChange}
                      value={userDetail?.accountType}
                    >
                      <option value={""}>Select account type</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>}
              <div className="continue-btn">
                <Button type="button" disabled={userDetail.accountType == "" && userIDRef.current == ""} onClick={saveSocialLoginUserType}>Continue</Button>
              </div>

            </div>
          </Modal.Body>
        </Modal>

        <Modal show={successPro} onHide={handleClosePro} centered>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="email-verfication text-center">
              <img
                src={require("../../images/update/check.png").default}
                alt="img"
              />
              <h3 className="mt-5">Successful</h3>
              <p>
                Account created successfully and submitted for admin approval. You
                will be notified by email once your account is verified.
              </p>

            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showverify} onHide={handleCloseEmailVerify} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="verify-pop-space">
            <div className="email-verfication text-center">
              <img
                src={require("../../images/update/email.png").default}
                alt="img"
              />
              <h3 className="mt-5">Email Verification Pending</h3>
              <p className="mb-5 mdl-desc">
                Your email has not been verified. Kindly proceed with the email verfication process to access your account.
              </p>
              <p>
                <b>Didn’t get the mail?</b>{" "}
                <Button className="higlight-text" onClick={() => {
                  if (!showbutton) {
                    resendVerification()
                  }
                }} >
                  Send it again
                </Button>
              </p>
            </div>
          </Modal.Body>

        </Modal>



      </section>
      <Footer />
    </>
  );
};

export default SignIn;
