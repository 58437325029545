import React, { useState, useEffect, useRef } from 'react';
import Footer from '../Components/common/Footer';
import Header from '../Components/common/Header';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import socket, { APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, QB, CONFIG } from '../config/socket';
import { userService } from '../services';
import Loader from '../Components/common/Loader';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import NotificationSound from "../images/update/rington2.mp3"

QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG)

const VideoCall = (props) => {
    const [isLoading, setisLoading] = useState(false)
    const [incomingCall, setIncomingCall] = useState(false)
    const [videocalldata, setvideocallData] = useState({ "calltype": "", item: "", videoModal: false, session: "" })
    const [voiceMute, setVoiceMute] = useState(false)
    const [videoMute, setVideoMute] = useState(false)
    const [sess, setSess] = useState()
    const [answerBtn, setAnswerBtn] = useState(false)
    const [iamCalling, setIamCalling] = useState(false)
    const [session, setSession] = useState("")
    const [isMuted, setIsMuted] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const handleCloseVideo = () => setShowVideo(false);
    const handleShowVideo = () => setShowVideo(true);
    const [userDetail, setUserDetail] = useState("")
    const [callername, setCallerName] = useState("")
    const [callingUser, setCallingUser] = useState(false)
    const callinguserRef = useRef("")
    const isLoadingRef = useRef(false)
    const [Time, setTime] = useState(new Date())
    const audioPlayer = useRef(null);
    const [ringtone, setRingtone] = useState(new Audio('/images/update/rington2'));// Replace with the path to your ringtone file

    useEffect(() => {
        // let incomingCall =true
        console.log('useEffect triggered:', { incomingCall, callingUser });
        if (incomingCall ==true && callingUser ==false) {
            console.log('Playing audio');
            // ringtone.loop = true;
            // ringtone.play();
            audioPlayer.current.play();
        } else {
            console.log('Pausing audio');
            // ringtone.pause();
            // ringtone.currentTime = 0;
            audioPlayer.current.pause();
            audioPlayer.current.currentTime = 0;
        }
    }, [incomingCall, callingUser, ringtone]);


    const answerHandle = () => {
        console.log("sess------------",sess)
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(() => {
                if (sess) {
                    var extension = {};
                    sess.accept(extension);
                    setvideocallData({ ...videocalldata, videoModal: true });
                    setIncomingCall(false);
                    setCallingUser(false);

                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Camera/Microphone Access',
                    text: 'Please enable your camera and microphone to answer the call.',
                });
            });
    };

    useEffect(() => {
        getProfileList()
    }, [])

    function getProfileList() {
        let user_id = localStorage.getItem('user_id');
        let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
        userService.getProfile(params).then(res => {
            if (res.data.status == 200) {
                let userData = res.data.data;
                setUserDetail(userData)
                initateChat(userData?.qb_user_id)
            }
        }).catch((error) => {
        });
    }




    function initateChat(qb_user_id) {
        var userCredentials = {
            userId: qb_user_id,
            password: "big4healthapp"
        };

        QB.chat.connect(userCredentials, function (error, contactList) {
        });
    }

    QB.webrtc.onCallListener = async function (session, extension) {
        console.log("onCallListener",session,extension)
        getCallerInformation(session.initiatorID)
        setSess(session)
        setSession(session);
        setvideocallData({ videoModal: true });
        getCallerInformation(session.initiatorID)
        setIncomingCall(true)

    };

    QB.webrtc.onAcceptCallListener = function (session, userId, extension) {
        console.log("onAcceptCallListener",session,extension,userId)
        setIncomingCall(false)
        setCallingUser(false)
    };

    QB.webrtc.onRemoteStreamListener = function (session, userID, remoteStream) {
        session.attachMediaStream("remoteOpponentVideoElementIdNew", remoteStream);
        setIncomingCall(false)
        setCallingUser(false)
    };

    QB.webrtc.onRejectCallListener = function (session, userId, extension) {
        console.log("onRejectCallListener",session,extension,userId)
        var extension = {};
        session.stop(extension);
        setSess()
        setIamCalling(false)
        setIncomingCall(false)
        setCallingUser(false)
        setVoiceMute(false)
        setIsMuted(false)
        setVideoMute(false)
        // props.isUserCalling = false;
    };


    function getCallerInformation(qb_id) {
        userService.getCalllerInfo(qb_id).then((res) => {
            setCallerName(res?.data)
        })
            .catch((error) => {
            })
    }

    QB.webrtc.onStopCallListener = function (session, userId, extension) {
        setvideocallData({ ...videocalldata, videoModal: false });
        var extension = {};
        session.stop(extension);
        setSess()
        setIamCalling(false)
        setvideocallData({ ...videocalldata, videoModal: false });
        setIncomingCall(false)
        setCallingUser(false)
        toast.success("call ended", {
            toastId: 'success_draft_1',
        })
        setVoiceMute(false)
        setIsMuted(false)
        setVideoMute(false)
        // props.isUserCalling = false;

    };

    QB.webrtc.onUpdateCallListener = function (session, userId, extension) {
        setvideocallData({ ...videocalldata, videoModal: false });
        var extension = {};
        session.stop(extension);
        setSess()
        setIamCalling(false)
        setIncomingCall(false)
        setCallingUser(false)
        toast.success("No answer", {
            toastId: 'success_draft_1',
        })
        // setVoiceMute(false)
        // setIsMuted(false)
        // setVideoMute(false)
    };

    QB.webrtc.onUserNotAnswerListener = function (session, userId) {
        var extension = {};
        session.stop(extension);
        setSess()
        setIamCalling(false)
        setCallingUser(false)
        setIncomingCall(false)
        setvideocallData({ ...videocalldata, videoModal: false });
        setVoiceMute(false)
        setIsMuted(false)
        setVideoMute(false)
        // props.isUserCalling = false;
    }


    function getCallerInformation(qb_id) {
        userService.getCalllerInfo(qb_id).then((res) => {
            setCallerName(res?.data?.data)
        })
            .catch((error) => {
            })
    }

    const cancelCall = () => {
        if (sess) {
            var extension = {};
            sess.stop(extension);
            sess.stop({ disconnect: true }, function (error) {
            });
            setSession()
            setSess()
            setvideocallData({
                "calltype": "cancelled",
                videoModal: false,
                session: null
            });
            setIncomingCall(false)
            setCallingUser(false)
            setVoiceMute(false)
            setIsMuted(false)
            setVideoMute(false)

        }
        toast.success("call ended", {
            toastId: 'success_draft_1',
        })

    };

    // handle mute audio
    const handleMuteAudio = () => {
        if (sess) {
            sess.mute('audio');
            setVoiceMute(true)
            setIsMuted(true)
        }

    };
    // handle unmute audio
    const handleUnmuteAudio = () => {
        if (sess) {
            sess.unmute('audio');
            setVoiceMute(false)
            setIsMuted(false)
        }

    };
    const handleMuteVideo = () => {
        if (sess) {
            sess.mute('video');
            setVideoMute(true)

        }

    };
    const handleUnmuteVideo = () => {
        if (sess) {
            sess.unmute('video');
            setVideoMute(false)
        }

    };


    function isEmptyObj(obj) {
        return Object.keys(obj).length === 0;
    }

    function isEmptyObj(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        if (videocalldata.videoModal == true && sess) {
            var mediaParams = {
                audio: true,
                video: true,
                options: {
                    muted: true,
                    mirror: true,
                },
                elemId: "localVideoElemforAppointmentNew",
            };
            sess && sess.getUserMedia(mediaParams, function (error, stream) {
                if (error) {
                } else {
                    session.attachMediaStream('localVideoElemforAppointmentNew', stream);
                }
            });

            if (iamCalling) {
                var extension = {};
                sess && sess.call(extension, function (error) {
                });
            }
        }
    }, [videocalldata.videoModal, sess, iamCalling]);

    const joinVideoCall = (item) => {
        isLoadingRef.current = true
        setTime(new Date())
        let callerId = item && item?.meduserDetail?.qb_user_id == userDetail?.qb_user_id ? item?.userDetail?.qb_user_id : item?.meduserDetail?.qb_user_id;
        let qbuserID = localStorage.getItem("qb_user_id") == item?.userDetail?.qb_user_id ? localStorage.getItem("qb_user_id") : item?.meduserDetail?.qb_user_id;
        let callinguser = item?.meduserDetail?.qb_user_id == userDetail?.qb_user_id ? item?.userDetail?.name : item?.meduserDetail?.name
        callinguserRef.current = callinguser;
        var userCredentials = {
            userId: qbuserID,
            password: "big4healthapp"
        };
        QB.chat.connect(userCredentials, function (error, contactList) {
            if (!error) {
                var calleesIds = [callerId]; // Users' ids
                var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
                var session = QB.webrtc.createNewSession(calleesIds, QB.webrtc.CallType.VIDEO, null);
                setIamCalling(true)
                setSession(session)
                setSess(session)
                setCallingUser(true)
                setvideocallData({ videoModal: true })
            }
        });
    };

    function handleJoinVideoCall(item) {
        console.log(" navigator.mediaDevices.getUserMedia({ video: true, audio: true })", navigator.mediaDevices.getUserMedia({ video: true, audio: true }))
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(() => {
                joinVideoCall(item);
                console.log("navigator_mediaDevices>>>>>>>")
            })
            .catch((error) => {
                console.log("error-------",error)
                Swal.fire({
                    icon: 'error',
                    title: 'Camera/Microphone Access',
                    text: 'Please enable your camera and microphone to join the video call.',
                });
            });
    }

    console.log("incomingCall-----",incomingCall)
    console.log("callingUser------",callingUser)
    console.log("sess-----------",sess)
    console.log("acctep video call case",sess?.opponentsIDs && sess?.currentUserID == (sess?.opponentsIDs[0]),   "sess?.opponentsIDs", sess?.opponentsIDs, "")


    return (
        <>
            <audio style={{ display: "none" }} ref={audioPlayer} src={NotificationSound} />

            {(sess && isLoadingRef == true) && <Loader />}
            {props.isUserCalling == "true" ?
                <button className="join-button mr-2" onClick={(e) => handleJoinVideoCall(props.item)}>Join Now</button>
                :
                null
            }
            {props.isFromHeader == "true" || (props.isFromHeader == "false" && (sess?.currentUserID == (sess?.initiatorID))) ?
                <Modal
                    className='video-call-modal-box'
                    show={videocalldata.videoModal}
                    centered size='xl'>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div className='video-call-area'>
                                    <div className='video-call-area-inner' >
                                        <div className='contributors-area'>
                                            <div className='contributors-area-left'>
                                            </div>
                                        </div>
                                        <video
                                            className="j-callees__callee__video qb-video_source"
                                            id={"remoteOpponentVideoElementIdNew"}
                                            autoPlay
                                            playsInline
                                        />
                                        {callingUser == true ? <div className='video-calling-content'>
                                            <p> calling...</p>
                                            {callinguserRef.current}
                                        </div> :
                                            ""}
                                        {incomingCall == true ? <div className='video-calling-content'>
                                            {callername}
                                            <p>Incoming call...</p>
                                        </div> : ""}
                                    </div>
                                    <Row className='align-items-center'>
                                        <Col md={5}>
                                            <div className='video-call-user'>
                                                <video
                                                    className="j-callees__callee__video qb-video_source"
                                                    id={"localVideoElemforAppointmentNew"}
                                                    autoPlay
                                                    playsInline
                                                />
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <div className='video-call-area-icon'>
                                                <div className='video-mute'>
                                                    {videoMute ?
                                                        <img onClick={handleUnmuteVideo} src={require("../images/update/video-mute.png").default} alt="img" />
                                                        :
                                                        <i class="fa fa-video-camera" aria-hidden="true" onClick={handleMuteVideo}></i>
                                                    }
                                                </div>
                                                <div className='video-mute'>
                                                    {isMuted ?
                                                        <i className="fa fa-microphone-slash" aria-hidden="true" onClick={handleUnmuteAudio}></i> :
                                                        <i className="fa fa-microphone" aria-hidden="true" onClick={handleMuteAudio}></i>
                                                    }
                                                </div>
                                                {sess?.opponentsIDs && sess?.currentUserID == (sess?.opponentsIDs[0]) &&
                                                    <>
                                                        {JSON.stringify(sess.acceptCallTime) == "0" ?
                                                            <div className='video-mute call-cute answer-call' onClick={answerHandle}>
                                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }

                                                <div className='video-mute call-cute' onClick={(e) => {
                                                    cancelCall();
                                                }}>
                                                    <img
                                                        src={require("../images/update/call-cut.png").default}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                :
                null}

        </>
    );
};

export default VideoCall;
