import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { config } from '../../config/config';

const ImageConfirmationModal = ({ show, onHide, onConfirm, imageSrc }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation popup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={config.imageUrl + imageSrc} alt="Confirmation" className="img-fluid" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageConfirmationModal;
