import { Dropdown, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useRef, useState } from 'react';
import Footer from "./common/Footer";
import Header from "./common/Header";
import { userService } from "../services";
import { config } from "../config/config";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import MedUserData from "../pages/MedUserdata";
import Pagination from "react-js-pagination";
import WorldUserdata from "../pages/WorldUserdata";
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import ScheduleListing from "./ScheduleListing";
import AppointmentListing from "./AppointmentListing";
import Skeleton from "react-loading-skeleton";

export const DATE_OPTIONS = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}
const Other_Profile = () => {
    const [isLoadingImage, setIsLoadingImage] = useState(true)
    const [subscriber, setSubscriber] = useState("")
    const [selectedMenu, setSelectedMenu] = useState("profile")
    const [page, setPage] = useState(1)
    const [pageM, setPageM] = useState(0)
    const [userId, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "");
    const [userDetail, setUserDetail] = useState();
    const [medUserList, setMedUserList] = useState()
    const [userType, setUserType] = useState("");
    const [categoryList, setcategoryList] = useState()
    const [activeTab, setActiveTab] = useState("live");
    const [activeTabMed, setActiveTabMed] = useState("")
    const [key, setKey] = useState('home');
    var activeTabRef = useRef("")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [email, setEmail] = useState("")
    const { slug } = useParams()
    const [showed, setShowed] = useState(false);
    const handleClosed = () => setShowed(false);
    const [updatepassword, setupdatepassword] = useState({ "oldpassword": "", "newPassword": "" })
    const [profile, setProfile] = useState("yes")
    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const activeMenu = queryParams.get('activeMenu');

    useEffect(() => {
        getUserData(slug)
        getCotagery()
        subscribeManagent()
    }, [])

    function handleContextMenu(event) {
        event.preventDefault();
    }

    useEffect(() => {
        if (localStorage?.getItem("userSlug") == slug) {
            window.location.href = "/profilenew";
            return
        }
    }, [slug])

    useEffect(() => {
        const storedTab = localStorage.getItem("profilewebnairType");
        if (storedTab) {
            setActiveTab(storedTab);
        }
    }, [])

    function handlePageChange(pageNumber) {
        if (activeTab == "live") {
            setPage(pageNumber);
            WebinarList(pageNumber, activeTab, userDetail?._id, "yes")
        }
        if (activeTab == "upcoming") {
            setPage(pageNumber);
            WebinarList(pageNumber, activeTab, userDetail?._id, "yes")
        }
        if (activeTab == "past") {
            setPage(pageNumber)
            WebinarList(pageNumber, activeTab, userDetail?._id, "yes")
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }

    function handleSubmitShare(e) {
        const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!email || email.trim() == "") {
            Swal.fire("Warning", "Please enter email.", "warning");
        }
        else if (!EMAILREGEX.test(email)) {
            Swal.fire("Warning", "Please enter valid email address", "warning");
        }
        else {
            ShareingProfile()
        }
    }

    function ShareingProfile() {
        const params = {
            email: email,
            userId: localStorage.getItem("user_id")
        }
        userService.share_profile(params).then((res) => {
            if (res?.data?.status === 200) {
                Swal.fire("Success", "Profile has been shared successfully.", "success").then((res) => {
                    setShowed(false)
                })

            }
        })
            .catch((error) => {
            })
    }

    const history = useHistory()
    function inputChange(e) {
        let { name, value } = e.target;
        setupdatepassword({ ...updatepassword, [name]: value });
    }

    console.log("selectedMenu------", selectedMenu)


    const handleTabClick = (tab) => {
        medicalUserList(pageM, userDetail?.userType, tab._id, userDetail?._id)
        setActiveTabMed(tab._id);
        activeTabRef.current = tab._id;
    };
    function getUserData(slug) {
        const params = {
            slug: slug
        }
        userService.getOtherUserDetails(params).then(res => {
            if (res.data.status == 200) {
                var userData = res?.data?.data
                setSubscriber(res?.data?.subscribeCount)
                // if (userData.userType == "Client" || ) {
                setActiveTab("menu5")
                // }
                setUserDetail(userData);
                setIsLoadingImage(false)
            }
        }).catch((error) => {
        });
    }
    // userlist
    function medicalUserList(pageM, userType, activeTabMed, userId) {
        console.log("pageM, userType, activeTabMed, userId", pageM, userType, activeTabMed, userId)
        userService.med_list(pageM, userType, activeTabMed, userDetail?._id).then((res) => {
            setMedUserList(res?.data?.postsWithViewsAndDaysAgo)
        })
            .catch((Error) => {
            })
    }

    // get category 
    function getCotagery() {
        userService.get_category().then((res) => {
            setcategoryList([{ name: "All", _id: "" }, ...res?.data?.data])
        })
            .catch((Error) => {
            })
    }

    function subscribeManagent() {
        userService.subscribe_management().then((res) => {
            if (res?.data?.status === 200) {
            }
        })
            .catch((error) => {
            })
    }
    const [webnairdata, setWebnairData] = useState()
    const [totalpage, settotalpage] = useState();

    function WebinarList(page, activeTab, userId, profile) {
        userService.webnair_list((page - 1), activeTab, userDetail?._id, "yes").then((res) => {
            setWebnairData(res?.data?.data)
            settotalpage(res?.data?.count)
        })
            .catch((Error) => {
            })
    }

    const handleTabClickforWorld = (tab) => {
        setActiveTab(tab);
        localStorage.setItem("profilewebnairType", tab)
        if (tab === "live") {
            WebinarList(page, tab, userDetail?._id, profile)
        }
        else if (tab === "upcoming") {
            WebinarList(page, tab, userDetail?._id, profile)
        }
        else if (tab === "past") {
            WebinarList(page, tab, userDetail?._id, profile)
        }
    };
    // update password
    function handleChangePassword(e) {
        e.preventDefault()
        if (updatepassword.oldpassword == "") {
            Swal.fire("Warning", "Old password field can't be empty", "warning");
        }
        else if (updatepassword.newPassword == "") {
            Swal.fire("Warning", "New password field can't be empty", "warning");
        }
        else if (updatepassword.newPassword == updatepassword.oldpassword) {
            Swal.fire("Warning", "New password can't be same as old password", "warning");
        }
        else {
            let params = { userId: localStorage.getItem("user_id"), oldPassword: updatepassword?.oldpassword, newPassword: updatepassword?.newPassword }
            userService.updatePassword(params).then((response) => {
                if (response.data.status == 200) {
                    handleClose();
                    Swal.fire("Success", "Password has been changed successfully", "success");
                }
                else if (response.data.status == 400) {
                    Swal.fire("Warning", response?.data?.message, "warning");
                }
                else {
                    Swal.fire("Error", response.data.message, "Error");
                }

            }).catch((error) => {
                Swal.fire("Error", error?.response?.data.message, "error");

            });
        }
    }
    function deletedListData(id) {
        userService.deleted_med_data(id).then((res) => {
            Swal.fire("Success", "Deleted Successfully.", "success");
            medicalUserList(pageM, userDetail?.userType, activeTabMed, userDetail?._id)
        })
            .catch((Error) => {
            })
    }

    function subscribe(id) {
        const params = {
            postid: id
        }

        userService.subscribe_user(params).then((res) => {
            Swal.fire("Success", "Subscribed successfully.", "success")
            medicalUserList(pageM, userDetail?.userType, activeTabMed, userDetail?._id)
        })
            .catch((error) => {
            })
    }

    const onVideoPlay = (item) => {
        setMedUserList(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
            )
        )
    }
    const onVideoPause = (item) => {
        setMedUserList(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
            )
        )
    }
    function unsubscribe(id) {
        userService.un_subscribe_user(id).then((res) => {
            Swal.fire("Success", "Subscribed deleted Successfully.", "success")
            medicalUserList(pageM, userDetail?.userType, activeTabMed, userDetail?._id)
        })
            .catch((error) => {
            })
    }
    let location = window.location.search?.split("=")[1]

    const combinedData = {
        data: location,
        userId: localStorage.getItem("user_id")
    };
    useEffect(() => {
        medicalUserList(pageM, userDetail?.userType, activeTabMed, userDetail?._id)
    }, [userDetail, activeTab])

    return (
        <>
            <Header />
            <section className="updated-profile-section">
                <div className="container">
                    <div className="profile-header">
                        <h3>User Profile</h3>

                    </div>
                    <div className="row">
                        <aside className="col-lg-4">
                            <div className="profile-area-left">
                                <div className="profile-image-area user-profile-area notranslate">
                                    {isLoadingImage ? (
                                        <Skeleton height={245} width={300} className="profile-image" /> // Shimmer effect while loading
                                    ) : (
                                        <img
                                            src={
                                                userDetail && userDetail.profilePicture && userDetail?.profilePicture !== "undefined"
                                                    ? userDetail.profilePicture
                                                    : require('../../src/images/update/dummy-profile-img.jpg').default
                                            }
                                            alt="img"
                                            className="profile-image"
                                        />)}

                                    {userDetail?.userType == "Professional" && <h4>{userDetail?.name} ({userDetail?.professionalType})</h4>}
                                    {userDetail?.userType == "Professional" && <p className="blue-text m-0">{subscriber ? subscriber : "0"} Subscriber |  {userDetail?.userType == "Professional" && <span className="blue-text m-0">Premium Account</span>}</p>}
                                    {userDetail?.userType == "Professional" && <p><img src={require("../images/update/location.svg").default} alt="img" className="mr-2" />{userDetail?.city}</p>}
                                    {userDetail?.userType == "Professional" && <h6>Speciality: <span>{userDetail?.speciality ? userDetail?.speciality : ""}</span></h6>}
                                    <div className="description-profile-ara notranslate">
                                        {userDetail?.userType == "Professional" && <h6>Bio:</h6>}
                                        {userDetail?.userType == "Professional" && <p>{userDetail && userDetail?.bio}</p>}
                                    </div>
                                    {(userDetail?.userType == "Client" && !userDetail?.pseudoName) && <a href="#"><img src={require("../images/update/email.svg").default} alt="img" className="mr-2" />{userDetail?.email}</a>}

                                    {userDetail?.userType == "Professional" &&
                                        <div className="profile-top-cons-area">
                                            {userDetail && userDetail?.linkedInUrl && userDetail?.linkedInUrl !== "null" && <div className="social-btn">
                                                <button className="btn btn-sm btn-facebook" data-toggle="tooltip" title={userDetail?.linkedInUrl}
                                                    onClick={(e) => window.open(userDetail.linkedInUrl, '_blank')}>
                                                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                                                </button>
                                            </div>}
                                            {userDetail && userDetail?.websiteUrl && userDetail?.websiteUrl !== "null" && <div className="social-btn">
                                                <button className="btn btn-sm twt-btn" data-toggle="tooltip" title={userDetail?.websiteUrl}
                                                    onClick={() => window.open(userDetail.websiteUrl, '_blank')}>
                                                    <img src={require("../images/update/web-icon.png").default} />
                                                </button>
                                            </div>}

                                        </div>}

                                </div>
                                <ul class="nav nav-tabs">
                                    {/* {userDetail && userDetail?.userType == "Professional" &&
                                        <li class="nav-item">
                                            <span
                                            class={`nav-link ${selectedMenu == "profile" ? "active" : ""}`}
                                                data-toggle="tab"
                                                onClick={(e) => window.location.href = `/profile/${slug}`}
                                            ><img src={require("../images/update/profile.svg").default} alt="img" className="wallet" /> Profile</span>
                                        </li>
                                        } */}


                                    {userDetail && userDetail?.userType == "Client" &&
                                        <li class="nav-item">
                                            <a
                                                class={`nav-link ${activeTab === 'menu5' ? 'active' : ''}`}
                                                data-toggle="tab" href="#menu5" onClick={(e) =>
                                                    medicalUserList(pageM, userDetail?.userType, activeTabMed, userDetail?._id)
                                                }>
                                                <img src={require("../images/update/me.svg").default} alt="img" className="" /> BIG4 Health Me
                                            </a>
                                        </li>
                                    }

                                    {userDetail && userDetail?.userType == "Professional" &&
                                        <li class="nav-item">
                                            <a
                                                class={`nav-link ${activeTab === 'menu5' ? 'active' : ''}`}
                                                data-toggle="tab" href="#menu5" onClick={(e) => medicalUserList(pageM, userType, activeTabMed, userDetail?._id)}>
                                                <img src={require("../images/update/manage.svg").default} alt="img" className="" /> BIG4 Health Med
                                            </a>
                                        </li>
                                    }



                                    {userDetail?.userType == "Professional" && <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#menu6" onClick={(e) => {
                                            WebinarList(page, activeTab, userDetail?._id, "yes");
                                            history.push(`/profile/${slug}#menu6`);
                                        }}>
                                            <img src={require("../images/update/world.svg").default} alt="img" className="" /> BIG4 Health World
                                        </a>
                                    </li>}

                                    <li class="nav-item">
                                        <a class="nav-link user-chat-profile" data-toggle="tab"
                                            onClick={(e) => {
                                                if (JSON.parse(localStorage.getItem("user"))?.ProfileCreated == 0) {
                                                    Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                                                        window.location.href = "/create-profile";
                                                        let redirectUri = window.location.pathname + window.location.search;
                                                        localStorage.setItem("redirectUri", redirectUri);
                                                    })
                                                }
                                                else if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
                                                    Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                                                        window.location.href = "/signin";
                                                        let redirectUri = window.location.pathname + window.location.search;
                                                        localStorage.setItem("redirectUri", redirectUri);
                                                    })
                                                }
                                                else {
                                                    window.location.href = `/chat/${userDetail?._id}`
                                                }
                                            }}
                                        >
                                            <img src={require("../images/update/message.svg").default} alt="img" className="" /> Chat
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                        <aside className="col-lg-8">
                            <div class="tab-content">
                                {/* {userDetail?.userType == "Professional" &&
                                <div class={`tab-pane ${selectedMenu == "profile" ? "active" : "fade"}`} id="home">
                                    <div className="profile-right-side">
                                        {userDetail?.userType == "Professional" && <div className="profile-right-header">
                                            <h4>information</h4>
                                        </div>}
                                        <div className="profile-right-body">
                                        <a href="#" className="d-flex"><img src={require("../images/update/email.svg").default} alt="img" className="mr-2" />{userDetail?.userType === "Client" && !userDetail?.pseudoName ? (
                                                <h4>{userDetail?.name}</h4>
                                            ) : (
                                                <p className="mb-0">{userDetail?.pseudoName || userDetail?.email}</p>
                                            )}</a>
                                            {(userDetail?.userType == "Client" && !userDetail?.pseudoName) && <a href="#"><img src={require("../images/update/email.svg").default} alt="img" className="mr-2" />{userDetail?.email}</a>}
                                            {userDetail?.userType == "Professional" && <a href="#"><img src={require("../images/update/call.svg").default} alt="img" className="mr-2" />{userDetail?.mobileNumber}</a>}
                                            {userDetail?.userType == "Professional" && <a href="#"><img src={require("../images/update/location.svg").default} alt="img" className="mr-2" />{userDetail?.city}</a>}
                                        </div>
                                        {userDetail?.userType == "Professional" && <div className="profile-right-body">
                                            <h4>Speciality</h4>
                                            <p className="blue-text"><b>{userDetail?.speciality ? userDetail?.speciality : ""}</b></p>
                                        </div>}

                                        {userDetail?.userType == "Professional" && <div className="profile-right-body">
                                            <h4>Bio</h4>
                                            <p>{userDetail && userDetail?.bio}</p>
                                        </div>}

                                    </div>
                                </div>} */}

                                <div class="tab-pane fade" id="menu3">
                                    <div className="profile-right-side no-upper-main-data">
                                        <div className="no-data-box">
                                            <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                            <h3>No data found</h3>
                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-pane  ${activeTab === 'menu5' ? 'active' : 'fade'}`} id="menu5">
                                    <div className="profile-right-side">
                                        <div className="profile-right-header">
                                            {userDetail?.userType == "Professional" && <h4> BIG4 Health Med</h4>}
                                            {userDetail?.userType == "Client" && <h4> BIG4 Health Me</h4>}
                                        </div>
                                        <div className="profile-right-body">
                                            {!location && <div className="tab-container">
                                                {categoryList?.map(tab => (
                                                    <button
                                                        key={tab._id}
                                                        className={`cat-list mb-3 ${activeTabMed === tab._id ? 'active' : ''}`}
                                                        onClick={(e) => handleTabClick(tab)}
                                                    >
                                                        {tab?.name}
                                                    </button>
                                                ))}
                                            </div>}

                                            <div className="row mt-4" >
                                                {medUserList?.length > 0 ?
                                                    medUserList?.map((item, index) => {
                                                        return (
                                                            <>
                                                                {!location && <div className="col-md-6" >
                                                                    <div className="big-med-wrap notranslate"
                                                                    >

                                                                        {item?.media_type == "image" &&
                                                                            <div className="big-med-main-img profile-big-me"
                                                                                onClick={(e) => {
                                                                                    history.push(`/profile/${slug}?id=${item?._id}`)
                                                                                }}>


                                                                                {item?.media?.length > 1 ? (
                                                                                    <Swiper
                                                                                        spaceBetween={50}
                                                                                        slidesPerView={1}
                                                                                        speed={700}
                                                                                        loop={true}
                                                                                        pagination={{ clickable: true }}
                                                                                    >
                                                                                        {item?.media?.map((image, key) => (
                                                                                            <SwiperSlide key={key}>
                                                                                                <a href="#">
                                                                                                    <img className="" src={image} alt="img" />
                                                                                                </a>
                                                                                            </SwiperSlide>
                                                                                        ))}
                                                                                    </Swiper>
                                                                                ) : (
                                                                                    <a href="#">
                                                                                        <img className="" src={item?.media[0]} alt="img" />
                                                                                    </a>
                                                                                )}
                                                                            </div>}

                                                                        <div className="big-med-main-img"
                                                                            onMouseOver={event => onVideoPlay(item)}
                                                                            onMouseOut={event => onVideoPause(item)}>
                                                                            {item?.media_type == "video" &&
                                                                                <div className="big-med-main-img big-med-main-vid" >
                                                                                    <span onClick={(e) => {
                                                                                        history.push(`/profile/${slug}?id=${item?._id}`)
                                                                                    }}>
                                                                                        {item?.videoPlay ? <video
                                                                                            onContextMenu={handleContextMenu}
                                                                                            controlsList="nodownload"
                                                                                            width="100%"
                                                                                            height="100%"
                                                                                            autoPlay={item?.videoPlay}
                                                                                            muted
                                                                                            loop
                                                                                            preload='auto'
                                                                                            playsinline
                                                                                            poster={item?.thumbnail_icon}

                                                                                        >
                                                                                            <source
                                                                                                src={item?.media[0]}
                                                                                                type="video/mp4"
                                                                                            />
                                                                                            <source
                                                                                                src={item?.media[0]}
                                                                                                type="video/ogg"
                                                                                            />
                                                                                            <source
                                                                                                src={item?.media[0]}
                                                                                                type="video/webm"
                                                                                            />
                                                                                        </video>
                                                                                            :
                                                                                            <div className="big-med-main-img">
                                                                                                <a href="#"><img src={item?.thumbnail_icon} alt="img" /></a>
                                                                                            </div>}
                                                                                    </span>
                                                                                    {!item?.videoPlay &&
                                                                                        <img className="video-btn" onClick={(e) => onVideoPlay(item)} src={require("../images/video-play.svg").default} alt="img" />}
                                                                                </div>}

                                                                            {item?.media_type == "pdf" && <div className="big-med-main-img" onClick={(e) => {
                                                                                history.push(`/profile/${slug}?id=${item?._id}`)
                                                                            }}>
                                                                                <span><img src={require("../images/big-med-pdf.jpg").default} alt="img" /></span>
                                                                            </div>}

                                                                            {item?.media_type == "voice" &&
                                                                                <div className="big-med-main-img" onClick={(e) => {
                                                                                    history.push(`/profile/${slug}?id=${item?._id}`)
                                                                                }}>
                                                                                    <span><img src={require("../images/big-med-audio.jpg").default} alt="img" /></span>
                                                                                </div>}
                                                                            <div className="big-med-dot-section">
                                                                                <div className="big-med-icon-text d-flex">
                                                                                    <div className="big-med-thumbnail" >
                                                                                        {item && item?.user?.profilePicture !== "undefined" ? <img src={item && item?.user?.profilePicture ? item?.user?.profilePicture : require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" /> : <img src={require("../../src/images/update/dummy-profile-img.jpg").default} alt="img" />}
                                                                                    </div>
                                                                                    <div className="big-med-text">
                                                                                        <h4>{item?.title}</h4>
                                                                                        <p className="channel-name">{item?.categoryName?.name}</p>
                                                                                        <p className="channel-name">{item?.user?.firstName}</p>
                                                                                        <p><span className="channel-views">{item?.views ? item?.views : 0}</span>  <span>|</span> <span className="channel-time"> {item?.timeAgo}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                                <Dropdown className="drop-down">
                                                                                </Dropdown>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <div className="profile-right-side no-upper-main-data">
                                                        <div className="no-data-box">
                                                            <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                            <h3>No data found</h3>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {location && <MedUserData {...combinedData} />}
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="menu6">
                                    <div className="profile-right-side">
                                        <div className="profile-right-header ">
                                            {userDetail?.userType == "Professional" && <h4> BIG4 Health World</h4>}
                                        </div>
                                        <div className="profile-right-body">
                                            {!location &&
                                                <div className="tab-container">
                                                    <ul class="nav nav-tabs">
                                                        <li class="nav-item">
                                                            <a className={`nav-link ${activeTab === 'live' ? 'active' : ''}`}
                                                                onClick={(e) => { handleTabClickforWorld('live'); localStorage.setItem("profilewebnairType", "live") }}
                                                                data-toggle="tab" href="#world-1">Live  webinars</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                className={`nav-link ${activeTab === 'upcoming' ? 'active' : ''}`}
                                                                onClick={(e) => { handleTabClickforWorld('upcoming'); localStorage.setItem("profilewebnairType", "upcoming") }}
                                                                data-toggle="tab" href="#world-1">Upcoming  webinars</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a className={`nav-link ${activeTab === 'past' ? 'active' : ''}`}
                                                                onClick={(e) => { handleTabClickforWorld('past'); localStorage.setItem("profilewebnairType", "past") }}
                                                                data-toggle="tab" href="#world-1">Past  webinars</a>
                                                        </li>
                                                    </ul>
                                                </div>}
                                            <div className="row mt-6" >
                                                <div class="tab-content">
                                                    <div class="tab-pane container active" id="menu6">
                                                        <div className="webinar-outer-box">
                                                            <div className="row">
                                                                {webnairdata?.length > 0 ? webnairdata?.map((item, key) => {
                                                                    const webinarDate = new Date(item?.date);
                                                                    const currentTime = new Date();
                                                                    const timeDifference = webinarDate - currentTime;
                                                                    const isJoinable = timeDifference <= 3600000;
                                                                    return (<>
                                                                        {!location &&
                                                                            <aside className="col-md-6">
                                                                                <div className="webinar-inner-box" >
                                                                                    <h4
                                                                                        onClick={(e) => {
                                                                                            history.push(`/profile/${slug}?slug=${item?.webinarSlug}`)
                                                                                        }}
                                                                                    >{item?.title}</h4>
                                                                                    <p className="mb-0"><b>{item?.big_categories?.name}</b></p>
                                                                                    <p className="mb-0"><img src={require("../images/update/date.svg").default} alt="img" className="mr-2" />{new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(webinarDate))}</p>
                                                                                    <p className="blue-text mb-0"><img src={require("../images/update/language.svg").default} alt="img" className="mr-2" />{item?.language}</p>
                                                                                    {(activeTab === "live" || activeTab === "upcoming") && !isJoinable ? (
                                                                                        <></>
                                                                                    ) : (activeTab === "live" || activeTab === "upcoming") && (
                                                                                        <a
                                                                                            href="javascript:;"
                                                                                            onClick={(e) => {
                                                                                                if (localStorage?.getItem("user_id")) {
                                                                                                    window.open(item?.webinar_url, "_blank");
                                                                                                } else {
                                                                                                    Swal.fire('warning', "To perform this action login is required", "warning").then((e) => {
                                                                                                        window.location.href = '/signin';
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                            className="join-button"
                                                                                        >
                                                                                            Join Now
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                            </aside>}
                                                                    </>)
                                                                }) :
                                                                    (<>
                                                                        <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                                                            <div className="no-data-box">
                                                                                <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                                                                <h3>No data found</h3>
                                                                            </div>
                                                                        </div></>)}
                                                                {!location && totalpage > 15 && (
                                                                    <div className='col-md-12 text-center'>
                                                                        <div className="pagination1">
                                                                            <Pagination
                                                                                activePage={page}
                                                                                itemsCountPerPage={15}
                                                                                totalItemsCount={totalpage}
                                                                                pageRangeDisplayed={5}
                                                                                onChange={(e) => handlePageChange(e)}
                                                                                hideDisabled={true}
                                                                                prevPageText={"Previous"}
                                                                                nextPageText={"Next"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {location && <WorldUserdata {...combinedData} />}
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>

            </section>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="mt-3">Change Password</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className="email-verfication text-center">
                        <h5>Old Password</h5>
                        <input type="password" placeholder="Enter old password" name="oldpassword" onChange={(e) => inputChange(e)} />
                        <h5>New Password</h5>
                        <input type="password" placeholder="Enter new password" name="newPassword" onChange={(e) => inputChange(e)} />
                        <br />
                        <div className="text-center">
                            <button type="button" onClick={handleChangePassword} className="text-center">Update</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showed} onHide={handleClosed} centered className="shared-profile">
                <Modal.Header closeButton>
                    <Modal.Title className="mt-3 share-profile-txt">Share Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="share-profile">
                        <input type="email" placeholder="Enter email" name="email" onChange={(e) => setEmail(e.target.value)} />
                        <div className="share-btn-box">
                            <button type="button" className="" onClick={handleSubmitShare}>Send</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
};
export default Other_Profile;