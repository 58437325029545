import Header from "./common/Header";
import Footer from "./common/Footer";
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Row, Card, Accordion, Modal } from "react-bootstrap";
import { userService } from "../services";
import { appUrl, config } from "../config/config";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import { Country, State, City, getAllCountries } from "country-state-city";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Magnifier from "react-magnifier";
import { Swiper, SwiperSlide, } from 'swiper/react';
import Loader from './common/Loader'
import productImage from '../images/update/product.png';
import chartImage from '../images/update/chart.png';
import { Autoplay, Navigation, Pagination, Keyboard } from "swiper";
import ShareOnSocialMedia from "./common/ShareOnSocialMedia";


const Ingredient = () => {
  const [categories, setCategories] = useState([]);
  const [showFullContent, setShowFullContent] = useState(false);
  const [favCount, setFavCount] = useState('');
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [userData, setUserData] = useState("")
  const [isFavourite, setIsFavourite] = useState(false);
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewImageStatus, setPreviewImageStatus] = useState(false)
  const [serviceOther, setserviceOther] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false)
  const [address, setAddress] = useState("");
  const [testimonial, setTestiMonial] = useState("")
  const [onplaying, setOnPlaying] = useState(false)
  const play = useRef("")
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: [""], message: "" })
  const targetRef = useRef(null);
  const cotId = "62d7761d5c91b78ea876856c";
  const [categoryId, setCategoryId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const [products, setProducts] = useState([]);
  const [subs, setSubs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [subIndex, setSubIndex] = useState(0)
  const [userId, setUserId] = useState(localStorage.getItem("user_id") || "");
  const [proceedStatus, setProceedStatus] = useState('');
  const [cartCount, setCartCount] = useState('');
  const [productID, setProductID] = useState('')
  const [temp, setTemp] = useState('0')
  const [haveSubscription, setHaveSubscription] = useState('');
  const [btnStatus, setBtnStatus] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [addtoCartStatus, setAddtoCartStatus] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [save, setSave] = useState(false)
  const handleSave = () => setSave(true);
  const handleCloseSave = () => {
    setSave(false);
  }
  const [productstatus, setProductStatus] = useState(false)


  const handleInputChange = (event) => {
    const { value } = event.target;
    setserviceOther(value)
  };

  useEffect(() => {
    getCategories([]);
  }, [])

  function handleSearch(searchTxt) {
    if (/^[A-Za-z]*$/.test(searchTxt)) {
      setSearchText(searchTxt);
      setPageNo(0);
      getProducts(categoryId, searchTxt, 0);
    }
  }

  function getCategories(searchTxt) {
    userService.getCategories(searchTxt).then((response) => {
      setIsLoading(false);
      if (response.data.status == 200) {
        console.log("res--------", response?.data?.data)
        setCategories(response.data.data);
      } else {
        setCategories([]);
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
      setCategories([]);
    });
  }


  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
    let token = localStorage.getItem("token")
    let params = { userId: user_id, token: token }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        setUserData(userData)
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
      }
    }).catch((error) => {
    });
  }

  useEffect(() => {
    localStorage.removeItem("redirectUri");
    localStorage.removeItem("selectedTabAppointment");
    localStorage.removeItem("selectedTab");
    TestiMonialVideo()
    if (cotId) {
      setCategoryId(cotId);
      getProducts(cotId, searchText, 0);
      getCartProducts();
    }
    getProfileList()

  }, [])


  function getProducts(catId, searchTxt, page) {
    userService.getProducts(catId, searchTxt, page).then((response) => {
      setIsLoading(false);
      if (response.data.status == 200) {
        console.log("response.data.data===========", response)
        setProducts(response.data.data);
        setProductStatus(true)
        setSubs(response.data.subscriptions)
        setColor(response?.data?.data[0]?.color?.length > 0 ? response.data?.data[0]?.color[0] : '')
        setSize(response?.data?.data[0]?.size?.length > 0 ? response?.data?.data[0]?.size[0] : '')
        setTotalCount(response?.data?.totalRecords);
        setIsFavourite(response?.data?.data[0]?.is_favourite || "");
        let btnStatus = cartItems.filter(cartItem => cartItem === response?.data?.data[0]?._id).length > 0;
      } else {
        setProducts([]);
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
      setProducts([]);
    });
  }

  useEffect(() => {
    if (subIndex == 0 && proceedStatus == 'SKIP_SUB') {
      setProceedStatus('')
      addToCart(productID)
    }
  }, [subIndex, proceedStatus])

  function addToCart(id) {
    setShow(false)
    if (userId) {
      setIsLoading(true);
      let params = { user: userId, product: id, qty: 1, subscription_type: subIndex, subscription: subIndex != 0, color, size }
      userService.addToCart(params).then((response) => {
        setIsLoading(false);
        if (response.data.status == 200) {
          setCartCount(response.data.cartCount);
          toast.success("Product added to cart successfully.", {
            toastId: 'success_draft_1',
          });
          setAddtoCartStatus(true)
          getCartProducts();
          setTemp(cartCount);
          setSave(false)
          setTimeout(() => {
            goToCart()
          }, 200)
        } else {
          toast.error("Some Error Occur");
        }
      }).catch((error) => {
        setCartCount('');
        setIsLoading(false);
      });
    }
    else {
      setLoginModal(true)
      // Swal.fire("Warning", "Just a few taps away! Log in to buy!", "warning").then((res) => {
      //   window.location.href = "/signin";
      //   let redirectUri = window.location.pathname + window.location.search;
      //   localStorage.setItem("redirectUri", redirectUri);
      // })
    }
  }

  function getCartProducts() {
    userService.getCartProducts().then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        if (res.data.cart.products)
          setCartItems(res.data.cart.products)
      }
    }).catch((er) => { })
  }

  function subShowModal(haveSubscription, id) {
    console.log("addToCart----------", haveSubscription, id)
    setHaveSubscription(haveSubscription)
    setProductID(id)

    if (haveSubscription == true) {
      setTimeout(() => {
        handleSave()
      }, 500)
    }
  }

  function handlePageChange(page) {
    setPageNo(page);
    getProducts(categoryId, searchText, page);
  }

  function selectSub(id) {
    if (id != subIndex)
      setSubIndex(id)
    else { setSubIndex(0) }
  }
  const [showMagnifier, setShowMagnifier] = useState(false);


  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }

  function TestiMonialVideo(type) {
    userService.testimonial("INGREDIENTS").then((res) => {
      if (res?.data?.status === 200) {
        setTestiMonial(res?.data?.getTestimonial)
      }
    })
      .catch((error) => {
      })
  }
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;

    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }

    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));

  };
  const [service, setservice] = useState(false)

  const handleSubmit2 = (e) => {
    let temp = [...userDetail.service];
    temp[0] = serviceOther;
    e.preventDefault();
    setuserDetails((prevState) => ({
      ...prevState,
      service: temp,
    }));
    setservice(true)
    setShow(false);

    // Scroll to the element with the ID "myInput"
    const element = document.getElementById("myInput");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  function handleContextMenu(event) {
    event.preventDefault();
  }

  function showPreview(image) {
    setPreviewImage(image)
  }

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleSumit(e) {
    e.preventDefault();
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!EMAILREGEX.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    // else if (!userDetail?.service) {
    //   Swal.fire("Warning", 'service field can’t be empty.', "warning")
    //   return
    // }
    else if (!userDetail?.service?.filter(item => item)?.length) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }
  let preview;

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service,
      message: userDetail?.message,
      page_type: "INGREDIENTS"?.toLowerCase()
    }
    userService.contactUs(params).then((res) => {
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: [""],
            message: ""
          });
        })
        setuserDetails({
          email: "",
          name: "",
          PhoneNumber: "",
          companyName: "",
          country: "",
          service: [""],
          message: ""
        });
      }
    })
      .catch((error) => {
      })
  }

  function getDiscountedPrice(item) {
    return (item?.price - (item?.discount / 100) * item?.price).toFixed(2)
  }



  function goToCart() {
    window.location.href = window.location.origin + '/shopping-cart';
  }


  function handleFavourite(status) {
    if (userId) {
      let answer = true;
      if (status) {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to remove it from your favourite list.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            makeUpdateRequest(status);
          }
        });
      } else {
        makeUpdateRequest(status);
      }
    } else {
      window.location.pathname = "/signin";
    }
  }

  function makeUpdateRequest(status) {
    setIsLoading(true);
    let params = { user: userId, product: products[0] && products[0]?._id, is_favourite: !status };
    userService.updateFavourite(params)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 200) {
          setFavCount(response.data.favCount);
          window.location.href = "/ingredient"
          toast.success(
            "Product " +
            (!status ? "added to" : "removed from") +
            " your favourite list successfully."
          );
        } else {
          toast.error("Some Error Occurred");
        }
      })
      .catch((error) => {
        setFavCount("");
        setIsLoading(false);
      });
  }

  // const handleClearText = () => {
  //   if(service == false){
  //     setuserDetails({
  //       service: [""],
  //     });
  //   }
  // }
  const handleClearText = () => {
    if (service == false) {
      setuserDetails({
        ...userDetail,
        service: [""],
      });
    }
  }


  return (
    <>
      {isLoading && <Loader />}
      <Header />

      {/* Ingridient top banner start */}
      <section className="ingridient-top-header">
        <div className="container">
          <div className="bottom-content banner-text">
            <h1>A Delicious World Class Quality</h1>
            <h2>Get your whole health back!</h2>
          </div>
        </div>
      </section>
      {/* Ingridient top banner end */}


      {/* product section start */}
      <section className="burden-disease top-bottom-spacing">
        <div className="container">
          <h2>{products[0]?.name}</h2>
          <span

          >
            <div className="ingridient-product" onClick={(e) => {
              showPreview(productImage)
            }}>
              <img
                src={
                  require("../images/update/product.png").default
                }
                alt="img"
              />
            </div>

            <div className="ingredient-buy-btn">
              <p>{products[0]?.discount > 0 && <span>${products[0]?.price}</span>}
                {productstatus == true && getDiscountedPrice(products[0])}
              </p>
              <div className="ingredient-buy-now"
                onClick={(e) => {
                  // if (userData?.ProfileCreated == 0) {
                  //   Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                  //     window.location.href = "/create-profile";
                  //     let redirectUri = window.location.pathname + window.location.search;
                  //     localStorage.setItem("redirectUri", redirectUri);
                  //   })
                  // }
                  // else if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
                  //   Swal.fire("Warning", "Just a few taps away! Log in to buy!", "warning").then((res) => {
                  //     window.location.href = "/signin";
                  //     let redirectUri = window.location.pathname + window.location.search;
                  //     localStorage.setItem("redirectUri", redirectUri);
                  //   })
                  // }
                  // else {
                  showPreview(productImage)
                  // }
                }}
              >
                <button>
                  <span class="circle1"></span>
                  <span class="circle2"></span>
                  <span class="circle3"></span>
                  <span class="circle4"></span>
                  <span class="circle5"></span>
                  <span class="text"><i class="fa fa-shopping-cart" aria-hidden="true"></i> {cartItems?.length > 0 ? "BUY" : "BUY"}</span>
                </button>
              </div>
              <a className="heart_icon1 fav-product pointer my-1 ml-3" onClick={() => handleFavourite(isFavourite)} ><i class={isFavourite ? "fa fa-heart" : "fa fa-heart-o"} aria-hidden="true"></i></a>

            </div>

            {<h5 className="product-review-bottom">
              {products[0]?.rating && Array.from(Array(parseInt(products[0]?.rating))).map(t => {
                return <i class="fa fa-star" aria-hidden="true" />
              })}
              <span><a href={products[0]?.rating >= 0 ? `/reviews?id=${[products[0]?._id]}` : ""}>({products[0]?.reviewCount} reviews)</a></span></h5>
            }
          </span>
        </div>
      </section>
      {/* product section ends */}

      {/* powerful Ingredients section start */}
      <section className="burden-disease powerful-banner top-bottom-spacing">
        <div className="container">
          <h2 className="mb-0 pb-0">Most powerful Ingredients</h2>
          <h3 className="text-center">Your health is in your hands</h3>
          <div className="powerful-ing-section">
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/ing-1.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Chia Seed</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/ing-2.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Cocoa Powder</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/ing-3.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Pumpkin Seed</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-4.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Moringa Leaf</h5>
            </div> <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-5.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Rice Bran</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-6.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Kale Powder</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-7.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Sesame Seed</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-8.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Red Beet</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-9.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Sunflower Seed</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-10.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Flaxseed Powder</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-11.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Oat Fiber</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-12.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Turmeric Extract</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-13.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Cinnamon</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-14.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Brewer's Yeast</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-15.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Stevia</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-16.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Monk Fruit</h5>
            </div>
            <div className="inner-ing-box text-center">
              <div className="ingredients-img">
                <img
                  src={
                    require("../images/update/img-17.png").default
                  }
                  alt="img"
                />
              </div>
              <h5>Pea Protein Isolate</h5>
            </div>
          </div>
        </div>
      </section>
      {/* powerful Ingredients section start */}

      {/* covered Ingredients section start */}
      <section className="covered-section burden-disease top-bottom-spacing">
        <div className="container">
          <h2>You name it, we get you covered</h2>
          <div className="cover-box">
            <div className="row ">
              <aside className="col-md-6">
                <div className="covered-box">
                  <img
                    src={
                      require("../images/update/covered.png").default
                    }
                    alt="img"
                  />
                </div>
              </aside>
              <aside className="col-md-6">

                <div className="covered-content">
                  <section id="moredesc" className="description-area ">
                    <div>
                      <span style={{ whiteSpace: 'pre-line' }}> {products[0]?.description} </span>
                    </div>
                  </section>

                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      {/* covered Ingredients section start */}

      {/* healthier section start */}
      <section className="burden-disease powerful-banner top-bottom-spacing">
        <div className="container">
          <h2 className="mb-0 pb-0">Get healthier & stronger with the
            BIG4 Health Ingredients.</h2>
          <div className="healthier-box">
            <div className="row align-items-center">
              <aside className="col-md-5">
                <div className="product-healthier-img" onClick={(e) => {
                  setPreviewImageStatus(true);
                  showPreview(productImage)
                }}>

                  <img
                    src={
                      require("../images/update/healthier.png").default
                    }
                    alt="img"
                  />

                </div>

              </aside>
              <aside className="col-md-1">
                <img
                  src={
                    require("../images/update/arrow.svg").default
                  }
                  className="arrow"
                  alt="img"
                />
              </aside>
              <aside className="col-md-6" onClick={(e) => {
                setPreviewImageStatus(true);
                showPreview(chartImage)
              }}>
                <div className="chat-img">
                  <img
                    src={
                      require("../images/update/chart.png").default
                    }
                    alt="img"
                  />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <section className="video-testimonials slider-btn bottom-spacing-area">
        <div className="container">
          <h2>Real Clients - Genuine Stories</h2>
          <div className="video-test-wrap">
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              loop={false}
              navigation
              onSlideChange={() => {
                const currentlyPlayingVideo = document.querySelector('video[autoplay]');
                if (currentlyPlayingVideo) {
                  currentlyPlayingVideo.pause();
                }
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                639: {
                  slidesPerView: 1,
                },
                865: {
                  slidesPerView: 1,
                },
                1000: {
                  slidesPerView: 2,
                },
                1500: {
                  slidesPerView: 2,
                },
                1700: {
                  slidesPerView: 2,
                }
              }}
            >
              {testimonial && testimonial?.map((item, key) => {
                const isVideoPlaying = currentVideoIndex === key;
                return (
                  <SwiperSlide key={key}>
                    <div className="big-med-main-img big-med-main-vid" onClick={(e) => {
                      if (isVideoPlaying) {
                        setCurrentVideoIndex(-1); // Stop playing
                      } else {
                        setCurrentVideoIndex(key); // Start playing
                      }
                    }}
                    >
                      {item?.videoPlay ? <video
                        onContextMenu={handleContextMenu}
                        controlsList="nodownload"
                        width="100%"
                        height="100%"
                        muted
                        loop
                        preload='auto'
                        playsinline
                        poster={item?.image}
                        autoPlay={isVideoPlaying}
                      >
                        <source
                          src={item?.image + "#t=0.1"}
                          type="video/mp4"
                        />
                        <source
                          src={item?.image + "#t=0.1"}
                          type="video/ogg"
                        />
                        <source
                          src={item?.image + "#t=0.1"}
                          type="video/webm"
                        />
                      </video> :
                        <div className="big-med-main-img big-med-main-vid">
                          <video
                            onContextMenu={handleContextMenu}
                            controlsList="nodownload"
                            width="100%"
                            height="100%"
                            muted
                            loop
                            controls
                            ref={play}
                            onPlay={(e) => setOnPlaying(true)}
                            onEnded={(e) => setOnPlaying(false)}
                            preload='auto'
                            playsinline
                          >
                            <source
                              src={item?.image + "#t=0.1"}
                              type="video/mp4"
                            />
                            <source
                              src={item?.image + "#t=0.1"}
                              type="video/ogg"
                            />
                            <source
                              src={item?.image + "#t=0.1"}
                              type="video/webm"
                            />
                          </video>
                        </div>}
                      <h5>{item?.title && item?.title}</h5>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>



      <section className="get-started-form bg_section signup_section redesign-profile">
        <div className="container">
          <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
          <div className="get-form-wrap">
            <h2>Get Started</h2>

            <aside className="col-lg-12 pt-4">
              <div className="Signup_box text-center w-100">
                <Form className="">
                  <div className="row frist_step contact-form-area  ">
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Full Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="firstName"
                          name="name"
                          maxLength={50}
                          autoComplete="off"
                          placeholder="Enter Full Name"
                          className="input103 w-100"
                          value={userDetail?.name}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Email<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Email"
                          name="email"
                          autoComplete="off"
                          placeholder="Enter Email"
                          className="input103 w-100"
                          value={userDetail?.email}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Phone Number<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="PhoneNumber"
                          name="PhoneNumber"
                          autoComplete="off"
                          placeholder="Enter Phone Number"
                          className="input103 w-100"
                          value={userDetail?.PhoneNumber}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Company Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Text"
                          name="companyName"
                          maxLength={50}
                          autoComplete="off"
                          placeholder="Enter Company Name"
                          className="input103 w-100"
                          value={userDetail?.companyName}
                          onChange={(e) => inputChange(e)}
                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>Country </label>
                      <div className="input_row input103">


                        <select
                          className="w-100 select-main"
                          name="country"
                          value={userDetail?.country}
                          onChange={(e) => inputChange(e)}
                        >
                          <option value={""}>Select Country</option>
                          {Country.getAllCountries().map((c) => {
                            return (
                              <option value={c.name}>{c.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </aside>

                    <aside className="col-md-4 mb-3 text-left">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShow}
                          className='d-block servies-text' id="myInput" ref={targetRef}
                        >
                          {/* {userDetail?.service?.length > 0 ? "+ Update Services" : "+ Add Services"} */}
                          {userDetail?.service?.filter(item => item)?.length > 0 ? "+ Update Services" : "+ Add Services"}

                        </a>

                        <div className='services-preview'>
                          {userDetail?.service && <span>{showFullContent ? userDetail?.service?.filter(item => item)?.join(" , ") : userDetail?.service?.filter(item => item)?.join(" , ")?.slice(0, 30)}</span>}
                          {/* {userDetail?.service && <span>{showFullContent ? userDetail?.service?.join(" , ") : userDetail?.service?.join(" , ")?.slice(0, 30)}</span>} */}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                    <aside className="col-md-12 mb-3 text-left">
                      <div className="input_row">
                        <label>Message</label>
                        <textarea
                          className=" w-100 select-main"
                          placeholder="Enter Message"
                          autoComplete="off"
                          name="message"
                          maxLength={500}
                          value={userDetail?.message}
                          onChange={(e) => inputChange(e)}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </Form>
              </div>
              <div className="next_btn text-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="btn w-25"
                  onClick={handleSumit}
                >
                  Submit
                </Button>
              </div>
            </aside>

          </div>
        </div>
      </section>
      <Modal show={show}
        onHide={(e) => { handleClose(); handleClearText(); }}
        // onHide={handleClose}
        className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Chronic Disease Prevention/Reversal Programs" checked={userDetail.service.includes('Chronic Disease Prevention/Reversal Programs')} value="Chronic Disease Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    {/* Health Advocacy and Information: */}
                    Healthcare Speaker & Health Information
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                    Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <label className='mt-3'><b>Other</b></label>
              <textarea
                className="form-control"
                placeholder="Please Specify"
                name="service"
                maxLength={500}
                value={serviceOther ? serviceOther : ""}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 && <a
            type="button"
            className="join-button"
            // onClick={handleSubmit2}
            onClick={(e) => handleSubmit2(e)}

          >
            Submit
          </a>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={previewImage}
        onHide={() => { setPreviewImage('') }}
        aria-labelledby="contained-modal-title-vcenter"
        className="preview-popup-box"
        size="xl"
        centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="example-modal-sizes-title-lg">
            {/* Image Preview */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 mb-3 pt-0" >
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-6">
              <div className="preview-image-area-left">
                <Swiper
                  navigation={true} modules={[Navigation]} className="mySwiper"
                >
                  {products[0]?.images?.map((image) => {
                    return (<SwiperSlide>
                      <div className="slider_box_new text-center">
                        <Magnifier src={config.imageUrl + image.image} mgWidth={200} mgHeight={200} mgShape={'square'} />
                        {/* <img src={image.image ? config.imageUrl + image.image : ''} alt="img" /> */}
                      </div>
                    </SwiperSlide>)
                  })}

                </Swiper>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-6">
              <div className="buy-product-detail-right">
                {/* <ShareOnSocialMedia url={window.location.href} text={`BIG4 Health - ${products[0]?.name}`} /> */}
                <ShareOnSocialMedia url={window.location.href} text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
                <div className="buy-product-detail-right-top mt-2">
                  <h2>{products[0]?.name}</h2>
                  <div className="video-like mt-0">
                    <img onClick={() => handleFavourite(isFavourite)} src={require(!isFavourite ? "../images/update/like.svg" : "../images/update/chat-icon-fill.svg").default} alt="img" />

                  </div>
                </div>

                <h6 className="replace-text">{products[0]?.category?.name}</h6>
                {<h5 className="product-review-bottom">
                  {products[0]?.rating && Array.from(Array(parseInt(products[0]?.rating))).map(t => {
                    return <i class="fa fa-star" aria-hidden="true" />
                  })}
                  <span><a href={`/reviews?id=${[products[0]?._id]}`}>({products[0]?.reviewCount} reviews)</a></span></h5>}
                <h5 className="pricing-content-area">{products[0]?.discount > 0 && <span>${products[0]?.price}</span>}
                  {productstatus == true && getDiscountedPrice(products[0])}
                </h5>
                {console.log("productsisFoodCategory", products[0]?.category?.isFoodCategory)}
                <div>
                  {products[0]?.category?.isFoodCategory == false &&
                    <div>
                      {products[0]?.size?.length > 0 && <h5 className="mt-0 mb-0">Size: <span className="text-secondary font-weight-regular">Choose an option</span>
                        <div className="size_row">
                          {products[0]?.size.map(item => {
                            return <span onClick={() => setSize(item)} className={item == size && "active"}>{item}</span>
                          })}
                        </div>
                      </h5>}
                    </div>
                  }

                  {products[0]?.color?.length > 0 && (
                    <div>
                      <h5 className="mt-3 mb-0">Color: <span className="text-secondary font-weight-regular">Choose an option</span></h5>
                      <div className="color_row">
                        {products[0]?.color.map((item, index) => (
                          <span
                            key={index}
                            onClick={() => setColor(item)}
                            className={item === color ? 'active' : ''}
                            style={{ backgroundColor: item }}
                          >
                            {item === color && <i className="fa fa-check"></i>}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                </div>
                <div className="ingredient-buy-now"
                  // onClick={(e) => {
                  //   if (userData?.ProfileCreated == 0) {
                  //     Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                  //       window.location.href = "/create-profile";
                  //       let redirectUri = window.location.pathname + window.location.search;
                  //       localStorage.setItem("redirectUri", redirectUri);
                  //     })
                  //   }
                  //   else if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
                  //     Swal.fire("Warning", "Just a few taps away! Log in to buy!", "warning").then((res) => {
                  //       window.location.href = "/signin";
                  //       let redirectUri = window.location.pathname + window.location.search;
                  //       localStorage.setItem("redirectUri", redirectUri);
                  //     })
                  //   }
                  //   else if(products[0]?.category?.haveSubscription ==true){
                  //     subIndex == 0 ?
                  //         subShowModal(products[0]?.category?.haveSubscription, products[0]?._id) :
                  //         addToCart(products[0]._id)
                  //   }
                  //   else {
                  //       addToCart(products[0]._id)
                  //   }
                  // }}
                  onClick={(e) => {
                    // if (userData?.ProfileCreated == 0) {
                    //   Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                    //     window.location.href = "/create-profile";
                    //     let redirectUri = window.location.pathname + window.location.search;
                    //     localStorage.setItem("redirectUri", redirectUri);
                    //   })
                    // }
                    // else if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
                    //   Swal.fire("Warning", "Just a few taps away! Log in to buy!", "warning").then((res) => {
                    //     window.location.href = "/signin";
                    //     let redirectUri = window.location.pathname + window.location.search;
                    //     localStorage.setItem("redirectUri", redirectUri);
                    //   })
                    // }
                    if (products[0]?.category?.haveSubscription == true) {
                      subIndex == 0 ?
                        subShowModal(products[0]?.category?.haveSubscription, products[0]?._id) :
                        addToCart(products[0]._id)
                    }
                    else {
                      addToCart(products[0]._id)
                    }
                  }}
                >
                  <button>
                    <span class="circle1"></span>
                    <span class="circle2"></span>
                    <span class="circle3"></span>
                    <span class="circle4"></span>
                    <span class="circle5"></span>
                    <span class="text"><i class="fa fa-shopping-cart" aria-hidden="true"></i> {cartItems?.length > 0 ? "PROCEED" : "PROCEED"}</span>
                  </button>
                </div>
                <p><b>Description:</b><br />
                  <section id="moredesc" className="description-area ">
                    <div>
                      <span style={{ whiteSpace: 'pre-line' }}> {products[0]?.description} </span>
                    </div>
                  </section>
                </p>
              </div>

            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={save} onHide={handleCloseSave} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "grey" }}>SUBSCRIBE & SAVE</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <section className="subscribe_section" style={{ display: btnStatus ? 'none' : 'block' }}>
            <div className="container">

              {subs.length > 0 && <div className="row">

                <div className="col-md-12 mb-2">
                  <div className="subs_firstbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[0]._id}
                          onClick={(e) => selectSub(subs[0]._id)} type="radio" name="exampleRadios4" id="exampleRadios4" />
                      </div>
                    </span>
                    <h5>EVERY 30 DAYS</h5>
                    <h4>& SAVE 20%</h4>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="subs_secondbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[1]._id}
                          onClick={(e) => selectSub(subs[1]._id)} type="radio" name="exampleRadios2" id="exampleRadios2" />
                      </div>
                    </span>
                    <h5>EVERY 45 DAYS</h5>
                    <h4>& SAVE 15%</h4>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="subs_thirdbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[2]._id}
                          onClick={(e) => selectSub(subs[2]._id)} type="radio" name="exampleRadios3" id="exampleRadios3" />
                      </div>
                    </span>
                    <h5>EVERY 60 DAYS</h5>
                    <h4>& SAVE 10%</h4>
                  </div>
                  <div className='add-skip-btn mt-3 text-center'>
                    <button className='btn add_subs_btn mr-2' onClick={() => {
                      if (subIndex != 0) {
                        if (addtoCartStatus == false) {
                          addToCart(productID)
                        }
                      }
                      else
                        toast.error('Please select a subscription first', {
                          toastId: 'success_draft_1',
                        })
                    }}>Add</button>
                    <button className='btn skip_subs_btn'
                      onClick={() => {
                        if (addtoCartStatus == false) {
                          setProceedStatus('SKIP_SUB')
                          setSubIndex(0);
                        }
                      }}
                    >Skip</button>
                  </div>
                </div>

              </div>}
            </div>
          </section>

        </Modal.Body>
      </Modal>



      <Modal show={loginModal} aria-labelledby="contained-modal-title-vcenter" centered className="login-to-buy">

        <Modal.Body>

          <p>Just a few taps away!<br></br><span>Log in to buy!</span></p>
          <Button onClick={() => {
            window.location.href = "/signin";
            let redirectUri = window.location.pathname + window.location.search;
            localStorage.setItem("redirectUri", redirectUri);
          }}
            type="button" variant="unset">Ok</Button>

        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};
export default Ingredient;
