import { useEffect, useRef, useState } from "react";
import CommentInputBox from "./CommentInputBox";
import { userService } from "../../services";
import defaultProfileImage from "../../images/update/dummy-profile-img.jpg"
import Swal from "sweetalert2";
import TimeAgo from "./TimeAgo";
const CommentsSection = ({ userProfileDetail, userDetail, postId }) => {
    const [userid, setUserId] = useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") : "");
    const [postComments, setPostComments] = useState([]);
    const [suggestionsUser, setSuggestionsUser] = useState([]);
    const [page, setPage] = useState(0);
    const commentsContainerRef = useRef(null);

    useEffect(() => {
        fetchUsers();
    }, []);
    const fetchPostComments = async (page) => {
        try {
            const response = await userService.getPostComments(postId, page);
            if (page == 0) {
                setPostComments(response.data);
            } else {
                setPostComments((prev) => ({ ...prev, ...response.data, data: [...prev.data, ...response.data.data] }));
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            // Replace this with the actual API URL to get the users
            const response = await userService.getAllSuggestionUsers();
            let userList = response?.data?.data.filter(user => user._id != userid && user.firstName).map((user, index) => ({
                display: user.firstName,
                id: user._id,
                image: user?.profilePicture ? user.profilePicture : defaultProfileImage
            }));
            setSuggestionsUser(userList);

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };



    function transformMentions(input) {
        // Regular expression to match mentions in the format @[name](id)
        const mentionRegex = /@\[(.+?)\]\((.+?)\)/g;

        // Replace mentions with the desired format
        return input.replace(mentionRegex, (_, name) => `<span style="font-weight: 500; color: #00c9ff;">@${name}</span>`);
    }

    function showParentReply(index) {
        if (!localStorage.getItem("token")) {
            window.location.href = "/signin";
            return;
        }
        const postCommentsBox = postComments?.data;
        postCommentsBox[index].showParentCommentBox = !postCommentsBox[index].showParentCommentBox;
        setPostComments({ ...postComments, data: postCommentsBox });
    }

    function showParentReplyList(index) {
        const postCommentsBox = postComments?.data;
        postCommentsBox[index].showParentReplies = !postCommentsBox[index].showParentReplies;
        setPostComments({ ...postComments, data: postCommentsBox });
    }

    function showChildReply(index, childIndex) {
        if (!localStorage.getItem("token")) {
            window.location.href = "/signin";
            return;
        }
        const postCommentsBox = postComments?.data;
        const replies = postCommentsBox[index].replies;
        replies[childIndex].showChildCommentBox = !replies[childIndex].showChildCommentBox;
        postCommentsBox[index].replies = replies;
        setPostComments({ ...postComments, data: postCommentsBox });
    }

    function showChildReplyList(index, childIndex) {
        const postCommentsBox = postComments?.data;
        const replies = postCommentsBox[index].replies;
        replies[childIndex].showChildReplies = !replies[childIndex].showChildReplies;
        postCommentsBox[index].replies = replies;
        setPostComments({ ...postComments, data: postCommentsBox });
    }

    function showSubChildReply(index, childIndex, subChildIndex) {
        if (!localStorage.getItem("token")) {
            window.location.href = "/signin";
            return;
        }
        const postCommentsBox = postComments?.data;
        const childReplies = postCommentsBox[index].replies;
        const subChildReplies = childReplies[childIndex].replies;
        subChildReplies[subChildIndex].showSubChildCommentBox = !subChildReplies[subChildIndex].showSubChildCommentBox;
        childReplies[childIndex].replies = subChildReplies;
        postCommentsBox[index].replies = childReplies;
        setPostComments({ ...postComments, data: postCommentsBox });
    }



    function CommentTextRender(comment) {
        return (
            <div className="ac5">
                <p className="ac4">{`${comment?.comment_by?.firstName} ${comment?.comment_by?.lastName || ""}`}</p>
                <p className="ac3" dangerouslySetInnerHTML={{ __html: transformMentions(comment.comment_text) }}></p>
            </div>
        );
    }

    function CommentedUserImageRender(comment) {
        return (
            <img src={comment?.comment_by?.profilePicture || defaultProfileImage} loading="lazy" height="56" width="56" title="Profile Picture" alt="Profile Picture of Eder Dionízio" />
        );
    }

    async function removeParentComment(index, comment) {

        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this comment.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true  // Optional: Reverse the order of buttons
        });

        if (!isConfirmed) {
            return; // If the user cancels, exit the function
        }

        const originalState = { ...postComments };
        const updatedPostComments = [...postComments.data];
        updatedPostComments.splice(index, 1); // Remove 1 item at the specified index
        const commentCount = postComments?.commentCount - 1
        setPostComments({ ...postComments, data: updatedPostComments, commentCount });
        try {
            const response = await userService.deletePostCommentByCommentId(comment._id);
            Swal.fire('Deleted!', 'Comment has been deleted.', 'success');
        } catch (error) {
            setPostComments(originalState);
        }
    }

    async function removeChildComment(index, childIndex, childComment) {
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this comment.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true  // Optional: Reverse the order of buttons
        });

        if (!isConfirmed) {
            return; // If the user cancels, exit the function
        }

        const originalState = JSON.parse(JSON.stringify(postComments));
        const updatedParentComments = [...postComments.data];
        const updatedChildComments = [...updatedParentComments[index].replies];
        updatedChildComments.splice(childIndex, 1); // Remove 1 item at the specified index
        updatedParentComments[index].childCommentCount = updatedParentComments[index].childCommentCount - 1;
        updatedParentComments[index].replies = updatedChildComments;
        setPostComments({ ...postComments, data: updatedParentComments });
        try {
            const response = await userService.deletePostCommentByCommentId(childComment._id);
            Swal.fire('Deleted!', 'Comment has been deleted.', 'success');
        } catch (error) {
            console.log("error", error, originalState);

            setPostComments(originalState);
        }
    }

    async function removeSubChildComment(index, childIndex, subChildIndex, subChildComment) {
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this comment.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true  // Optional: Reverse the order of buttons
        });

        if (!isConfirmed) {
            return; // If the user cancels, exit the function
        }
        const originalState = JSON.parse(JSON.stringify(postComments));
        const updatedParentComments = [...postComments.data];
        const updatedChildComments = updatedParentComments[index].replies;
        const updatedSubChildComments = updatedChildComments[childIndex].replies;
        updatedSubChildComments.splice(subChildIndex, 1); // Remove 1 item at the specified index
        updatedChildComments[childIndex].childCommentCount = updatedChildComments[childIndex].childCommentCount - 1;
        updatedChildComments[childIndex].replie = updatedSubChildComments;
        updatedParentComments[index].replies = updatedChildComments;
        setPostComments({ ...postComments, data: updatedParentComments });

        try {
            const response = await userService.deletePostCommentByCommentId(subChildComment._id);
            Swal.fire('Deleted!', 'Comment has been deleted.', 'success');
        } catch (error) {
            setPostComments(originalState);
        }
    }


    async function addAndRemoveParentCommentFlag(index, comment, isFlagged) {
        if (isFlagged) {
            Swal.fire('Error!', `This comment has already been flagged by you.`, 'error');
            return
        }
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${isFlagged ? "unflag" : "flag"} this comment.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, ${isFlagged ? "unflag" : "flag"} it!`,
            cancelButtonText: 'Cancel',
            reverseButtons: true  // Optional: Reverse the order of buttons
        });

        if (!isConfirmed) {
            return; // If the user cancels, exit the function
        }
        const originalState = { ...postComments };
        const updatedPostComments = [...postComments.data];
        updatedPostComments[index].isFlagged = !updatedPostComments[index].isFlagged;
        setPostComments({ ...postComments, data: updatedPostComments });
        try {
            const response = await userService.addAndRemoveParentCommentFlag({ comment_id: comment._id });
            Swal.fire('Flagged!', `Comment has been ${isFlagged ? "unflagged" : "flagged"}.`, 'success');
        } catch (error) {
            setPostComments(originalState);
        }
    }


    async function addAndRemoveChildCommentFlag(index, childIndex, childComment, isFlagged) {
        if (isFlagged) {
            Swal.fire('Error!', `This comment has already been flagged by you.`, 'error');
            return
        }
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${isFlagged ? "unflag" : "flag"} this comment.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, ${isFlagged ? "unflag" : "flag"} it!`,
            cancelButtonText: 'Cancel',
            reverseButtons: true  // Optional: Reverse the order of buttons
        });

        if (!isConfirmed) {
            return; // If the user cancels, exit the function
        }
        const originalState = JSON.parse(JSON.stringify(postComments));
        const updatedParentComments = [...postComments.data];
        const updatedChildComments = [...updatedParentComments[index].replies];
        updatedChildComments[childIndex].isFlagged = !updatedChildComments[childIndex].isFlagged;
        updatedParentComments[index].replies = updatedChildComments;
        setPostComments({ ...postComments, data: updatedParentComments });
        try {
            const response = await userService.addAndRemoveParentCommentFlag({ comment_id: childComment._id });
            Swal.fire('Flagged!', `Comment has been ${isFlagged ? "unflagged" : "flagged"}.`, 'success');
        } catch (error) {
            console.log("error", error, originalState);

            setPostComments(originalState);
        }
    }

    async function addAndRemoveSubChildCommentFlag(index, childIndex, subChildIndex, subChildComment, isFlagged) {
        if (isFlagged) {
            Swal.fire('Error!', `This comment has already been flagged by you.`, 'error');
            return
        }
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${isFlagged ? "unflag" : "flag"} this comment.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, ${isFlagged ? "unflag" : "flag"} it!`,
            cancelButtonText: 'Cancel',
            reverseButtons: true  // Optional: Reverse the order of buttons
        });

        if (!isConfirmed) {
            return; // If the user cancels, exit the function
        }
        const originalState = JSON.parse(JSON.stringify(postComments));
        const updatedParentComments = [...postComments.data];
        const updatedChildComments = updatedParentComments[index].replies;
        const updatedSubChildComments = updatedChildComments[childIndex].replies;
        updatedSubChildComments[subChildIndex].isFlagged = !updatedSubChildComments[childIndex].isFlagged;
        updatedChildComments[childIndex].replie = updatedSubChildComments;
        updatedParentComments[index].replies = updatedChildComments;
        setPostComments({ ...postComments, data: updatedParentComments });

        try {
            const response = await userService.addAndRemoveParentCommentFlag({ comment_id: subChildComment._id });
            Swal.fire('Flagged!', `Comment has been ${isFlagged ? "unflagged" : "flagged"}.`, 'success');
        } catch (error) {
            setPostComments(originalState);
        }
    }


    async function viewMoreChildComment(parentComment, parentIndex, startCount, endCount) {
        console.log("parentComment,parentIndex", parentComment, parentIndex);
        const response = await userService.getPostCommentByCommentId(parentComment._id, startCount, endCount);
        console.log("response", response.data.data.childComments);
        const updatedParentComments = [...postComments.data];
        const updatedChildComments = [...updatedParentComments[parentIndex].replies];
        updatedChildComments.push(...response.data.data.childComments);
        updatedParentComments[parentIndex].replies = updatedChildComments;
        setPostComments({ ...postComments, data: updatedParentComments });
    }

    async function viewMoreSubChildComment(parentComment, parentIndex, childIndex, startCount, endCount) {
        const response = await userService.getPostCommentByCommentId(parentComment._id, startCount, endCount);
        console.log("response", response.data.data.childComments);
        const updatedParentComments = [...postComments.data];
        const updatedChildComments = [...updatedParentComments[parentIndex].replies];
        const updatedSubChildComments = [...updatedChildComments[childIndex].replies];
        updatedSubChildComments.push(...response.data.data.childComments);
        updatedChildComments[childIndex].replies = updatedSubChildComments;
        updatedParentComments[parentIndex].replies = updatedChildComments;
        setPostComments({ ...postComments, data: updatedParentComments });
    }

    useEffect(() => {
        fetchPostComments(page);
    }, [page])

    const handleScroll = () => {
        if (commentsContainerRef.current && commentsContainerRef.current.scrollTop + commentsContainerRef.current.clientHeight >= commentsContainerRef.current.scrollHeight - 10) {
            if (page < postComments.totalPages) {
                setPage((prev) => prev + 1);
            }

        }
    };

    const scrollToTop = () => {
        if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTop = 0;
        }
    };

    const scrollToBottom = () => {
        if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
    };

    return (
        <>
            <section className="aa0">
                <div className="ah2">
                    <div className="ah1">
                        <span>Comments <span className="ag4">({postComments?.commentCount || 0})</span> </span>
                    </div>

                    {postComments?.data?.length > 0 ? <div className="ah0"
                        style={{ maxHeight: "500px", overflowY: "auto" }}
                        ref={commentsContainerRef}
                        onScroll={handleScroll}
                    >
                        <div className="af9">
                            {postComments?.data?.map((comment, index) => {
                                comment[index] = { ...comment, showParentCommentBox: (comment?.showParentCommentBox || false) }
                                comment[index] = { ...comment, showParentReplies: (comment?.showParentReplies || false) }
                                return (<>
                                    <div className="af7">
                                        <div className="ag5">
                                            {CommentedUserImageRender(comment)}
                                            <div className="ac2">

                                                <div className="parent-msg">
                                                    {CommentTextRender(comment)}
                                                    {(userid == userDetail?.user?._id || comment?.comment_by._id == userid) ? <i className="fa fa-trash" aria-hidden="true" onClick={() => removeParentComment(index, comment)}></i> : ""}
                                                    {(userid && comment?.comment_by._id != userid) ? <i className={`fa fa-flag ${comment.isFlagged ? "flagged" : ""}`} aria-hidden="true" onClick={() => addAndRemoveParentCommentFlag(index, comment, comment.isFlagged)}></i> : ""}
                                                </div>
                                                <div className="ac1">
                                                    <a href="#" className="replies-text-btn"> <TimeAgo createdAt={comment.createdAt} /></a>
                                                    {comment?.childCommentCount > 0 ?
                                                        <>
                                                            <a href="#" className="replies-text-btn">{comment?.childCommentCount} replies</a>
                                                            <u onClick={() => showParentReplyList(index)}>{`${comment?.showParentReplies ? "Hide" : "Show"}  ${comment?.childCommentCount > 1 ? "Replies" : "Reply"}`}</u>
                                                        </>
                                                        : ""}
                                                    <u onClick={() => showParentReply(index)}>{comment?.showParentCommentBox ? "Close" : "Reply"}</u>
                                                </div>
                                            </div>
                                        </div>
                                        {comment?.showParentCommentBox ? <CommentInputBox userProfileDetail={userProfileDetail} setPostComments={setPostComments} parentIndex={index} parentCommentId={comment._id} suggestionsUser={suggestionsUser} userDetail={userDetail} postId={postId} /> : ""}
                                        {comment?.showParentReplies && comment?.replies?.map((childComment, childIndex) => {
                                            childComment[childIndex] = { ...childComment, showChildCommentBox: (childComment?.showChildCommentBox || false) }
                                            childComment[childIndex] = { ...childComment, showChildReplies: (childComment?.showChildReplies || false) }

                                            return (<>
                                                <div className="af7">
                                                    <div className="ag5">
                                                        {CommentedUserImageRender(childComment)}
                                                        <div className="ac2">
                                                            <div className="parent-msg">
                                                                {CommentTextRender(childComment)}
                                                                {(userid == userDetail?.user?._id || childComment?.comment_by._id == userid) ? <i className="fa fa-trash" aria-hidden="true" onClick={() => removeChildComment(index, childIndex, childComment)}></i> : ""}
                                                                {(userid && childComment?.comment_by._id != userid) ? <i className={`fa fa-flag ${childComment.isFlagged ? "flagged" : ""}`} aria-hidden="true" onClick={() => addAndRemoveChildCommentFlag(index, childIndex, childComment, childComment.isFlagged)}></i> : ""}
                                                            </div>
                                                            <div className="ac1">
                                                                <a href="#" className="replies-text-btn"> <TimeAgo createdAt={childComment.createdAt} /></a>
                                                                {childComment?.childCommentCount > 0 ?
                                                                    <>
                                                                        <a href="#" className="replies-text-btn">{childComment?.childCommentCount} replies</a>
                                                                        <u onClick={() => showChildReplyList(index, childIndex)}>{`${childComment?.showChildReplies ? "Hide" : "Show"}  ${childComment?.childCommentCount > 1 ? "Replies" : "Reply"}`}</u>
                                                                    </>
                                                                    : ""}
                                                                <u onClick={() => showChildReply(index, childIndex)}>{childComment?.showChildCommentBox ? "Close" : "Reply"}</u>
                                                                {(comment?.childCommentCount - 1 != childIndex) && (comment?.replies.length - 1 == childIndex) ? <u onClick={() => viewMoreChildComment(comment, index, childIndex + 1, childIndex + 50)}>View More</u> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {childComment?.showChildCommentBox ? <CommentInputBox userProfileDetail={userProfileDetail} setPostComments={setPostComments} parentIndex={index} childIndex={childIndex} parentCommentId={childComment._id} suggestionsUser={suggestionsUser} userDetail={userDetail} postId={postId} /> : ""}
                                                    {childComment?.showChildReplies && childComment?.replies?.map((subChildComment, subChildIndex) => {

                                                        subChildComment[subChildIndex] = { ...subChildComment, showSubChildCommentBox: (subChildComment?.showSubChildCommentBox || false) }

                                                        return (<>
                                                            <div className="ag5">
                                                                {CommentedUserImageRender(subChildComment)}
                                                                <div className="ac2">
                                                                    <div className="parent-msg">
                                                                        {CommentTextRender(subChildComment)}
                                                                        {(userid == userDetail?.user?._id || subChildComment?.comment_by._id == userid) ? <i className="fa fa-trash" aria-hidden="true" onClick={() => removeSubChildComment(index, childIndex, subChildIndex, subChildComment)}></i> : ""}
                                                                        {(userid && subChildComment?.comment_by._id != userid) ? <i className={`fa fa-flag ${subChildComment.isFlagged ? "flagged" : ""}`} aria-hidden="true" onClick={() => addAndRemoveSubChildCommentFlag(index, childIndex, subChildIndex, subChildComment, subChildComment.isFlagged)}></i> : ""}
                                                                    </div>
                                                                    <div className="ac1">
                                                                        <a href="#" className="replies-text-btn"> <TimeAgo createdAt={subChildComment.createdAt} /></a>

                                                                        <u onClick={() => showSubChildReply(index, childIndex, subChildIndex)}>{subChildComment?.showSubChildCommentBox ? "Close" : "Reply"}</u>
                                                                        {(childComment?.childCommentCount - 1 != subChildIndex) && (childComment?.replies.length - 1 == subChildIndex) ? <u onClick={() => viewMoreSubChildComment(childComment, index, childIndex, subChildIndex + 1, subChildIndex + 50)}>View More</u> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {subChildComment?.showSubChildCommentBox ? <CommentInputBox userProfileDetail={userProfileDetail} setPostComments={setPostComments} parentIndex={index} childIndex={childIndex} subChildIndex={subChildIndex} parentCommentId={childComment._id} suggestionsUser={suggestionsUser} userDetail={userDetail} postId={postId} /> : ""}
                                                        </>)
                                                    })}
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </>)
                            })}
                        </div>
                    </div> : ""}

                    <CommentInputBox scrollToBottom={scrollToBottom} scrollToTop={scrollToTop} userProfileDetail={userProfileDetail} setPostComments={setPostComments} suggestionsUser={suggestionsUser} userDetail={userDetail} postId={postId} />
                    {/* suggestions list start  */}
                    {/* <div className="suggestions-list">
                                <div>
                                    <img
                                        src={
                                            require("../images/user.png").default
                                        }
                                        alt="img"
                                    />
                                    <h5>Adam Smith</h5>
                                </div>
                                <div>
                                    <img
                                        src={
                                            require("../images/user.png").default
                                        }
                                        alt="img"
                                    />
                                    <h5>Adam Smith</h5>
                                </div>
                                <div>
                                    <img
                                        src={
                                            require("../images/user.png").default
                                        }
                                        alt="img"
                                    />
                                    <h5>Adam Smith</h5>
                                </div>
                            </div> */}
                    {/* suggestions list start  */}

                </div>
            </section>

        </>
    );
};

export default CommentsSection;
