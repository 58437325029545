import Header from "./common/Header";
import Footer from "./common/Footer";
import Download from "./common/Download";
import { useEffect, useRef, useState } from "react";
import { useSSR } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { userService } from "../services";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ExerciseCount = () => {
  const [show, setShow] = useState(false);
  const [formattedDate, setFormattedDate] = useState("")
  const handleClose = () => setShow(false);

  const [showShow, setShowSecond] = useState(false);
  const showSecondRef =useRef(false)

  const handleShowSecond = () => {
    showSecondRef.current =false
    setShowSecond(false);
  }


  const KeyDowns = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "<", ">", ".", "\\", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
  const handleShow = () => setShow(true);
  const [myExercise, setmyExercise] = useState({ exerciseName: "", timeDuration: "", reminderFrequencyArray: "", reminderTime: "", reminderNotification: false })
  const [ExerciseDetail, setExerciseDetail] = useState("")
  const [MyExerciseDetail, setMyExerciseDetail] = useState("")
  const id = window?.location?.pathname?.split("/")[2]
  const [Start, setShowStart] = useState(0)
  const [seconds, setSeconds] = useState("");
  const [reminderSelectedTime, setReminderSelectedTime] = useState("")
  useEffect(() => {
    getMyExerciseDeatils(id)
  }, [])

  const handleCloseSecond = () => {
    showSecondRef.current =false
    setShowSecond(false);
    setSeconds("")
    localStorage.setItem("isStarted","false")
  };



  function EditmyExercise() {
    const params = {
      userId: localStorage.getItem("user_id"),
      exerciseName: myExercise?.exerciseName,
      timeDuration: myExercise.timeDuration,
      exerciseId: ExerciseDetail?._id,
      distance: '',
      reminderDays: myExercise?.reminderNotification == true ? myExercise?.reminderFrequencyArray : "",
      reminderTime: myExercise?.reminderNotification == true ? myExercise?.reminderTime : "",
      reminderNotification: myExercise?.reminderNotification,
      reminderSelectedTime: reminderSelectedTime,
      // timeZone:Intl.DateTimeFormat().resolvedOptions().getTimezoneOffset()
      timeZone:new Date()?.getTimezoneOffset()

    }
    userService.EDITmyExerciseData(params).then((res) => {
      if (res?.data?.status === 200) {
        setShow(false)
        getMyExerciseDeatils(id)
        setmyExercise({ exerciseName: "", timeDuration: "", reminderFrequencyArray: "", reminderTime: "", reminderNotification: false })
        Swal.fire("Success", res?.data?.message, "success");
      }
    })
      .catch((error) => {
      })
  }

  function inputChange(e) {
    let { name, value } = e.target;
    setmyExercise({ ...myExercise, [name]: value });
  }

  const handleDurationChange = (event) => {
    const inputValue = event.target.value;
    const parsedValue = parseInt(inputValue);
    if (!isNaN(parsedValue)) {
      setmyExercise({ ...myExercise, timeDuration: parsedValue });
    } else {
      setmyExercise({ ...myExercise, timeDuration: 0 });
    }
  };

  const handleOptionChange = (event) => {
    setmyExercise({ ...myExercise, reminderNotification: !myExercise?.reminderNotification })

  };

  const handleCheckboxChange = (day) => {
    const updatedFrequencyArray = [...myExercise.reminderFrequencyArray];
    if (updatedFrequencyArray.includes(day)) {
      updatedFrequencyArray.splice(updatedFrequencyArray.indexOf(day), 1);
    } else {
      updatedFrequencyArray.push(day);
    }
    setmyExercise({
      ...myExercise,
      reminderFrequencyArray: updatedFrequencyArray,
    });
  };

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    setmyExercise({
      ...myExercise,
      [name]: value,
    });
  };
  const currentDate = new Date();

  function handleAddExercise(e) {
    EditmyExercise()
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);

      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    if (seconds === 0) {
      setShowSecond(true);
      showSecondRef.current =true
    }
  }, [seconds]);

  useEffect(() =>{
    localStorage.setItem("isStarted","false")
  },[])

  console.log("seconds------",seconds)

  const detailRef = useRef()
  function getMyExerciseDeatils(id) {
    userService.getExeriseDetails(id).then((res) => {
      if (res?.data?.status == 200) {
        const exerciseData = res?.data?.data;
        const updatedMyExercise = {
          exerciseName: exerciseData.exerciseName || "",
          timeDuration: exerciseData.timeDuration || "",
          reminderFrequencyArray: exerciseData.reminderFrequencyArray || "",
          reminderTime: exerciseData.reminderTime || "",
          reminderNotification: exerciseData.reminderNotification || false,
        };

        setExerciseDetail(res?.data?.data)
        detailRef.current = res?.data?.data;
        setmyExercise(updatedMyExercise);
      }
    })
      .catch((error) => {
      })
  }

  // start exercise
  function StartExercise() {
    const credentials = {
      userId: localStorage.getItem("user_id"),
      isStarted: ExerciseDetail?.isStarted == 0 ? 1 : 0,
      exerciseId: ExerciseDetail?._id,
      currentDate: new Date()
    }
    userService.Start_execution(credentials).then((res) => {
      if (res?.data?.status === 200) {
        getMyExerciseDeatils(res?.data?.data[0]?._id)
        setShowStart(1)
        setSeconds(res?.data?.data[0]?.timeDuration * 60 || "")

      }
    })
      .catch((Error) => {
      })
  }
  const history = useHistory()
  return (
    <>
      <Header />
      <div className="big-med-details learning-details">
        <div className="container">
          <div className="back-btn-sec mb-3 d-flex justify-content-between">
            <Button className="btn back-btn"
              onClick={(e) => {
                ExerciseDetail?.isStarted == 1 && StartExercise()
                setTimeout(() => {
                  window.location.href = "/my-exercise";
                }, 500)
              }}
            ><i className="fa fa-angle-left mr-2"></i>Back to My Exercise Listing</Button>
            {localStorage.getItem("isStarted") =="false" &&
              <span className="edit-btn" onClick={(e) => setShow(true)}><img className="mr-1" src={require("../images/update/edit.svg").default} alt="img" />Edit</span>}
          </div>
          <div className="big-med-text text-center mt-4">
            <h4>{ExerciseDetail?.exerciseName}</h4>
            <div className="my-exercise-count text-center mt-4">
              <div className="outer-box">
                <div className="inner-box">
                  <div>
                    <img src={require("../images/update/exercise-main.png").default} alt="img" />
                    { localStorage.getItem("isStarted") =="true" ? <p><b>
                      {minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds} Min</b>
                    </p> : <p><b>{ExerciseDetail?.timeDuration} Min</b></p>
                    }
                  </div>
                </div>
              </div>
            </div>
            {localStorage.getItem("isStarted") =="false" ? <div className="next_btn mt-4 text-center">
              <Button
                variant="primary"
                onClick={(e) =>{StartExercise(); localStorage.setItem("isStarted","true")}}
                type="button"
                className="btn w-fit"
              >
                START
              </Button>
            </div>:
            <div className="next_btn mt-4 text-center">
              <Button
                variant="secondary"
                onClick={(e) => {
                  let logout =
                    Swal.fire({
                      icon: "info",
                      html: `<div class="logout-text">
                              Are you sure, you want to stop this exercise?
                              </div>
                              `,
                      showCloseButton: true,
                      showConfirmButton: true,
                      showCancelButton: true,
                    }).then((res) => {
                      if (res.isConfirmed) {
                        setSeconds("")
                        localStorage.setItem("isStarted","false")
                        history.replace(`${window.location.pathname + window.location.search}`)
                      }
                    })
                }}
                type="button"
                className="btn w-fit"
              >
                STOP
              </Button>
            </div>}
          </div>


        </div>
      </div>
      <Download />
      <Footer />

      <Modal show={showSecondRef.current} onHide={handleCloseSecond} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="congratulation-box text-center">
            <img src={require("../images/update/cong.svg").default} alt="img" className="mr-2" />
            <p className="mt-3">You have successfully completed your workout.</p>
          </div>
        </Modal.Body>
      </Modal>


      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title><h2>Edit Exercise</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="billing-box-address signin-bg">
            <div className="delivery-body biling-box Signup_box p-0 contact-box add-exercise p-0">
              <form>
                <div className="row">
                  <aside class="col-md-12 mb-3">
                    <div class="input_row">
                      <label>Exercise Name</label>
                      <input
                        placeholder="Enter Exercise Name"
                        type="exerciseName"
                        name="exerciseName"
                        className="input103 w-100"
                        value={myExercise?.exerciseName}
                        onChange={(e) => inputChange(e)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                        maxLength={50}
                      />
                    </div>
                  </aside>
                  <aside className="col-md-12 mb-3">
                    <div className="input_row duration-box">
                      <label>Time Duration</label>
                      <input
                        type="text"
                        placeholder="Enter Time Duration"
                        value={myExercise?.timeDuration}
                        className="input103 w-100"
                        onChange={handleDurationChange}
                      />
                      <p className="duration">{myExercise?.timeDuration} Min</p>
                    </div>
                  </aside>
                  <aside class="col-md-12 mb-3">
                    <label className="d-flex radio-text-mob">
                      <input
                        type="radio"
                        name="reminderNotification"
                        value={myExercise?.reminderNotification}
                        checked={myExercise?.reminderNotification}
                        onClick={handleOptionChange}
                        className="remem mr-2"
                      /><span className='text-left'>
                        Would you like reminder notifications?
                      </span>
                    </label>
                    {myExercise.reminderNotification == true &&
                      <div className="tags-days mt-3">
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Sunday")}
                            onChange={() => handleCheckboxChange("Sunday")}
                          />
                          Sunday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Monday")}
                            onChange={() => handleCheckboxChange("Monday")}
                          />
                          Monday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Tuesday")}
                            onChange={() => handleCheckboxChange("Tuesday")}
                          />
                          Tuesday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Wednesday")}
                            onChange={() => handleCheckboxChange("Wednesday")}
                          />
                          Wednesday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Thursday")}
                            onChange={() => handleCheckboxChange("Thursday")}
                          />
                          Thursday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Friday")}
                            onChange={() => handleCheckboxChange("Friday")}
                          />
                          Friday
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={myExercise?.reminderFrequencyArray?.includes("Saturday")}
                            onChange={() => handleCheckboxChange("Saturday")}
                          />
                          Saturday
                        </label>
                      </div>}
                  </aside>
                  
                    <aside className="col-md-12 mb-4 text-left">
                    <div className="input_row">
                      <label>Date & Time<span className="blue-asterisk"></span></label>
                      <div className="">
                        <div className="boder-box">
                          <input
                            type="datetime-local"
                            value={myExercise?.reminderTime}
                            id="birthdaytime"
                            name="birthdaytime"
                            onChange={(e) => {
                              const selectedDateTime = e.target.value;
                              const formattedDateTime = new Date(selectedDateTime);

                              const formattedDate = `${formattedDateTime.getFullYear()}-${(formattedDateTime.getMonth() + 1).toString().padStart(2, '0')}-${formattedDateTime.getDate().toString().padStart(2, '0')}`;

                              const formattedTime = `${formattedDateTime.getHours().toString().padStart(2, '0')}:${formattedDateTime.getMinutes().toString().padStart(2, '0')}`;

                              const formattedDateTimeString = `${formattedDate}T${formattedTime}`;

                              setFormattedDate(formattedDateTimeString);

                              setmyExercise({
                                ...myExercise,
                                reminderTime: formattedDateTimeString,
                              });

                              setReminderSelectedTime(formattedTime);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>

                <div className="next_btn mt-4 text-center">
                  <Button
                    type="button"
                    onClick={handleAddExercise}
                    disabled={!myExercise?.exerciseName || !myExercise?.timeDuration || !myExercise?.reminderTime || !myExercise?.reminderNotification}
                    className={`btn w-25 ${myExercise?.exerciseName && myExercise?.timeDuration && myExercise?.reminderTime ? 'enabled' : 'disabled'}`}
                  >
                    Edit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ExerciseCount;
