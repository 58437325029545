import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import Header from './common/Header'
import Footer from './common/Footer'
import { config } from '../config/config';
import moment from 'moment';
import Download from './common/Download';


SwiperCore.use([Pagination, Navigation, Autoplay]);

const Product = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        userService.getRatings(id).then(res => {
            setIsLoading(false)
            if (res.status == 200) {
                setData(res.data.data)
            }
        })
    }, []);

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="review-list-area review-list">
                <div className="container">
                    <h3>Reviews & Rating</h3>
                    {data?.map(item => {
                        return <div className="media">
                            <img src={item && item?.user?.profilePicture && item?.user?.profilePicture !== "undefined" ? item?.user?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" className="profile-image" />
                            <div className="media-body">
                                <div className="d-flex">
                                    <h5 className="mt-0">{item?.user?.firstName} {item?.user?.lastName}</h5>
                                    <span className="review-time">{moment(item?.createdAt)?.fromNow()}</span>
                                </div>
                                <h6>
                                    {Array.from(Array(item?.rating))?.map(t => {
                                        return <i class="fa fa-star" aria-hidden="true" />
                                    })}
                                </h6>
                                <p>{item.review}</p>
                            </div>
                        </div>
                    })}

                </div>
            </section>
            <Download />
            <Footer />
        </>
    )
}


export default Product;