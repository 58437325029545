import React, { useEffect, useState } from "react";
import { userService } from "../services";
import moment from 'moment-timezone';
import Swal from "sweetalert2";
import { Button, Col, Dropdown, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Checkbox } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";

function Availability() {
    const intervals = [
        { label: "15 minutes", value: 15 },
        { label: "30 minutes", value: 30 },
        { label: "45 minutes", value: 45 },
        { label: "1 hour", value: 60 },
    ];

    const initialSlots = [
        {
            day: "Monday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
        {
            day: "Tuesday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
        {
            day: "Wednesday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
        {
            day: "Thursday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
        {
            day: "Friday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
        {
            day: "Saturday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
        {
            day: "Sunday",
            closed: false,
            slots: [
                {
                    start: "00:00",
                    end: "00:00"
                }
            ]
        },
    ];
    const [availabilityStatus, setAvailabilityStatus] = useState(false)
    const [selectedDays, setSelectedDays] = useState([]);
    const [doctorSlot, setDoctorSlot] = useState(initialSlots)
    const [dropdownOpenIndex, setDropdownOpenIndex] = useState();
    const DaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [availabilityDetail, setAvailabilityDetail] = useState({ category: "", Communication_language: "", slot_interval: "", timezone: "", offset: "" })
     const [selectedInterval, setSelectedInterval] = useState("");
    const [categoryList, setCategoryList] = useState()
    const [closed, setClosed] = useState('no');
    const [selectedCopyDay, setSelectedCopyDay] = useState(''); // State to store the selected day for copying
    const [copiedSlots, setCopiedSlots] = useState([]);
    const handleIntervalChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setSelectedInterval(selectedValue);
        setAvailabilityDetail({ ...availabilityDetail, slot_interval: selectedValue })
    };
    const handleChange = (event) => {
        setAvailabilityDetail({ ...availabilityDetail, category: event.target.value });
    };
    const [slots, setSlots] = useState(initialSlots);

    // get timezon
    const timezones = moment.tz.names(); // Get a list of all timezones
    const getOffset = (timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone, date = new Date()) => {
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
        return (tzDate.getTime() - utcDate.getTime()) / 6e4;
    }
    const handleTimezoneChange = (e) => {

        var offset = getOffset(e.target.value);
        setAvailabilityDetail({ ...availabilityDetail, timezone: e.target.value, offset: offset });
    };
    useEffect(() => {
        getCotagery()
        getAvailability()

    }, [])

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add Slot
        </Tooltip>

    );

    function getAvailability() {
        userService.getUserAvailabilityDetail().then((res) => {
            setAvailabilityDetail({
                category: res?.data?.data?.userCategory,
                Communication_language: res?.data?.data?.communicationLanguage,
                slot_interval: res?.data?.data?.slotInterval,
                timezone: res?.data?.data?.userTimezone,
                offset: res?.data?.data?.timeOffset
            });
            setSelectedInterval(res?.data?.data?.slotInterval)
            if (res?.data?.data?.userSlots && res?.data?.data?.userSlots?.length > 1) {
                const userSlots = res?.data?.data?.userSlots.map((item) => ({
                    day: item.day,
                    closed: item.status === "open" ? true : false,
                    slots: item.slots
                }));
                setSlots(userSlots);
                setDoctorSlot(userSlots)
            }
            else {
                setSlots(initialSlots);
                setDoctorSlot(initialSlots)
            }
        })
            .catch((error) => {
            })
    }
    function AddAvailability() {
        const params = {
            interval: availabilityDetail?.slot_interval,
            category: availabilityDetail?.category,
            comminucation_language: availabilityDetail?.Communication_language,
            timezone: availabilityDetail?.timezone,
            slots: slots,
            offset: availabilityDetail?.offset
        }
        userService.add_Availability(params).then((res) => {
            if (res?.data?.status === 200) {
                setAvailabilityStatus(false)
                Swal.fire("Success", res?.data?.message, "success").then((e) => {
                    getAvailability()
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 200);
                })

            }
        })
            .catch((Error) => {
            })
    }

    const handleDayClick = (day) => {
        setSelectedCopyDay(day);
    };

    const renderTooltip_1 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Copy to Add Availability
        </Tooltip>

    );
    const tooltip = (
        <Tooltip id="tooltip">
            <strong>Holy guacamole!</strong> Check this info.
        </Tooltip>
    );


    const hasValidSlots = slots.some(day => {
        return (
            day.closed == true && // Make sure the day is closed
            day.slots.some(slot => {
                return slot.start != "00:00" && slot.end != "00:00"; 
            })
        );
    });

    function handleAddAvailability(e) {
       
        if (!availabilityDetail?.slot_interval || availabilityDetail?.slot_interval == "") {
            Swal.fire("Warning", "Please select interval slot", "warning");
        }
        else if (!availabilityDetail?.category || availabilityDetail?.category == "") {
            Swal.fire("Warning", "Please select category", "warning");
        }
        else if (!availabilityDetail?.timezone || availabilityDetail?.timezone == "") {
            Swal.fire("Warning", "Please select user timezon", "warning");
        }
        else if (!availabilityDetail?.Communication_language || availabilityDetail?.comminucation_language == "") {
            Swal.fire("Warning", "Please enter Communication language", "warning");
        }
       else if(hasValidSlots == false) {
            Swal.fire("Warning", "Please select atleast one slot", "warning");
          }
        else {
            setAvailabilityStatus(true)
            AddAvailability()
        }
    }

    function getCotagery() {
        userService.get_category().then((res) => {
            setCategoryList([...res?.data?.data])

        })
            .catch((Error) => {
            })
    }

    const renderTimeDropdowns = (index) => {
        const timeOptions = [];
        for (let i = 0; i < 24 * 60; i += selectedInterval) {
            const minutes = i % 60;
            const hours = (i - minutes) / 60;
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            timeOptions.push(formattedTime);
        }
    }

    function inputChange(e) {
        let { name, value } = e.target;
        setAvailabilityDetail({ ...availabilityDetail, [name]: value });
    }

    // enable day
    const onCheckboxSelected = (index) => {
        let array = [...doctorSlot]
        array[index].closed = !array[index].closed
        setDoctorSlot(array)
        setSlots(array)
    }

    const handleStartTimeChange = (value, indexSlot, index) => {
        let array = [...doctorSlot];
        let start = value
        let end = moment(value, 'HH:mm').add(intervals, 'minutes').format('HH:mm');
        let currentStartTime = array[index].slots[indexSlot].start
        let currentEndTime = array[index].slots[indexSlot].end
        const timeDifference = calculateTimeDifferenceInDay(start, end);

        if (!timeDifference) {
            array[index].slots[indexSlot].start = moment(currentStartTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
            array[index].slots[indexSlot].end = moment(currentEndTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
            setDoctorSlot(array);
            setSlots(array)
            Swal.fire({
                icon: 'error',
                title: 'Invalid Time Range',
                text: `Time slots must not exceed 24 hours.`,
            })
            return;
        }
        if (indexSlot > 0) {
            const previousEndTime = array[index].slots[indexSlot - 1].end;
            if (moment(value, 'HH:mm').isBefore(moment(previousEndTime, 'HH:mm'))) {
                array[index].slots[indexSlot].start = moment(currentStartTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
                array[index].slots[indexSlot].end = moment(currentEndTime, 'HH:mm').add(1, 'minutes').format('HH:mm');
                setDoctorSlot(array);
                setSlots(array)
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Time Range',
                    text: `Start time must be after the end time of the previous slot`,
                });
                return; 
            } else {
                const newEndTime = moment(value, 'HH:mm').add(availabilityDetail?.slot_interval, 'minutes').format('HH:mm');
                array[index].slots[indexSlot].end = newEndTime;
                array[index].slots[indexSlot].start = value;
                setDoctorSlot(array);
                setSlots(array)
            }
        } else {
            const newEndTime = moment(value, 'HH:mm').add(availabilityDetail?.slot_interval, 'minutes').format('HH:mm');
            array[index].slots[indexSlot].end = newEndTime;
            array[index].slots[indexSlot].start = value;
            setDoctorSlot(array);
            setSlots(array)
        }


    };

    // end time slot
    function isStartTimeAfterEndTime(startTime, endTime) {
        const [startHour, startMinute] = startTime?.split(':').map(Number);
        const [endHour, endMinute] = endTime?.split(':').map(Number);

        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;

        return startMinutes <= endMinutes;
    }

    const handleEndTimeChange = (value, indexSlot, index) => {
        let array = [...doctorSlot];
        const startMoment = moment(array[index].slots[indexSlot].start, 'HH:mm');
        const endMoment = moment(value, 'HH:mm');
        if (array[index]?.slots[indexSlot + 1]?.start) {
            if (isStartTimeAfterEndTime(array[index].slots[indexSlot + 1].start, value)) {
                array[index].slots[indexSlot].end = moment(array[index].slots[indexSlot].end, 'HH:mm').add(1, 'minutes').format('HH:mm')
                setDoctorSlot(array);
                setSlots(array)
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Time Range',
                    text: `Please choose another time since the current time has already been selected.`,
                });
                return
            }
        }
        if (endMoment.isSameOrAfter(startMoment.add(availabilityDetail?.slot_interval, 'minutes'))) {
            array[index].slots[indexSlot].end = value;
            setDoctorSlot(array);
            setSlots(array)
        } else {
            array[index].slots[indexSlot].end = moment(array[index].slots[indexSlot].end, 'HH:mm').add(1, 'minutes').format('HH:mm')
            setDoctorSlot(array);
            setSlots(array)
            Swal.fire({
                icon: 'error',
                title: 'Invalid Time Range',
                text: `The time span between the start & end times should align with the selected duration, and the end time must be later than the start time.`,
            });
        }

    };

    // add more slot
    const onAddSlot = (index) => {
        let array = [...doctorSlot];
        const hasNullTime = array[index].slots?.some(slot => !slot?.start || !slot?.end);
        if (hasNullTime) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill in both start and end times before adding a new slot.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {

            let start = array[index].slots[array[index].slots?.length - 1].end
            let end = moment(array[index].slots[array[index].slots?.length - 1].end, 'HH:mm').add(availabilityDetail?.slot_interval, 'minutes').format('HH:mm');
            const timeDifference = calculateTimeDifferenceInDay(start, end);
            if (!timeDifference) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Time Range',
                    text: `Time slots must not exceed 24 hours.`,
                })
                return;
            }

            let previousEndTime = array[index].slots[array[index].slots?.length - 1].end
            array[index].slots?.push({
                start: moment(previousEndTime, 'HH:mm').add(1, 'minutes').format('HH:mm'),
                end: moment(previousEndTime, 'HH:mm').add(availabilityDetail?.slot_interval, 'minutes').format('HH:mm')
            });
            setDoctorSlot(array);
            setSlots(array)
        }
    };


    function calculateTimeDifferenceInDay(startTime, endTime) {
        const [startHour, startMinute] = startTime && startTime?.split(':')?.map(Number);
        const [endHour, endMinute] = endTime && endTime?.split(':').map(Number);
        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;
        let timeDifference = endMinutes - startMinutes;
        if (timeDifference < 0) {
            return null;
        }
        const hoursDifference = Math.floor(timeDifference / 60);
        const minutesDifference = timeDifference % 60;

        return { hours: hoursDifference, minutes: minutesDifference };
    }

    // remove slot
    const onRemoveSlot = (indexSlot, index) => {
        let array = [...doctorSlot];
        array[index].slots.splice(indexSlot, 1);
        setDoctorSlot(array);
        setSlots(array)
    };

  
    const handleDaySelection = (day, event) => {
        setSelectedDays((prevSelectedDays) => {
            const isSelected = prevSelectedDays.includes(day);
            if (isSelected) {
                return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
            } else {
                return [...prevSelectedDays, day];
            }
        }, () => {
        });
    };

    // copy slot
    const onCopySlot = (index) => {
        let array = [...doctorSlot];
        let selectedSlot = array[index].slots;

        selectedDays.forEach((day) => {
            const dayIndex = DaysOfWeek.indexOf(day);
            if (dayIndex !== -1) {
                array[dayIndex].slots = selectedSlot.map((slot) => ({ ...slot }));
                array[dayIndex].closed = true;
            }
        });
        setDoctorSlot(array);
        setSlots(array)
        setDropdownOpenIndex("");
        setSelectedDays([]);
    };


    // drop down 
    const onDropDownOpen = (index) => {
        if (dropdownOpenIndex === index) {
            setDropdownOpenIndex("")
            setSelectedDays([])
        } else {
            setDropdownOpenIndex(index)
        }
    }


    return (
        <>
            <div className="row mb-3">
                <aside className="col-md-4">
                    <label>Time Duration</label><br />
                    <select value={selectedInterval} placeholder="Select Interval Slot" onChange={handleIntervalChange}>
                        <option value="" disabled>Select Interval Slot</option>
                        {intervals.map((interval) => (
                            <option key={interval.value} value={interval.value}>
                                {interval.label}
                            </option>
                        ))}
                    </select>
                </aside>
                <aside className="col-md-4">
                    <label>Category<span className="blue-asterisk">*</span> </label>
                    <select
                        className=""
                        name="Choose Category"
                        value={availabilityDetail?.category}
                        onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                            }
                        }}
                        onChange={handleChange} >
                        <option value="" disabled>Choose Category</option>
                        {categoryList?.map((category) => (
                            <option key={category?._id} value={category?._id}>
                                {category?.name}
                            </option>
                        ))}
                    </select>
                </aside>
                <aside className="col-md-4">
                    <label>Select Timezone:</label>
                    <select value={availabilityDetail?.timezone} onChange={handleTimezoneChange}>
                        <option value="" disabled>Select a Timezone</option>
                        {timezones.map((timezone) => (
                            <option key={timezone} value={timezone}>
                                {timezone}
                            </option>
                        ))}
                    </select>
                </aside>
                <aside className="col-md-6 mt-3">
                    <label>
                        Communication language<span className="blue-asterisk">*</span><span className="mandatory"></span>
                    </label>
                    <input
                        type="text"
                        name="Communication_language"
                        maxLength={30}
                        onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                                e.preventDefault();
                            }
                        }}
                        value={availabilityDetail?.Communication_language}
                        onChange={(e) => inputChange(e)}
                        placeholder="Enter Communication Language"
                        className="input103 w-100"
                    />
                </aside>
            </div>
            <div>
            </div>
            <div>
            </div>

            <div>
                {doctorSlot?.map((item, index) => (
                    <Row key={index} className="availabilty-box slot-scroll">
                        <Col md={3}>
                            <div className="d-flex align-items-center">
                                <label className="form-check profile-check">
                                    <input
                                        type="checkbox"
                                        checked={item?.closed}
                                        onChange={(e) => onCheckboxSelected(index)}
                                    />
                                </label>
                                <h5>{item.day}:</h5>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className={item?.slots?.length > 1 ? "available-boxes-main-multiple": "available-boxes-main"}>
                                {item?.slots?.length > 0 ? item?.slots?.map((slot, indexSlot) => {
                                    return (
                                        <div key={indexSlot} className="d-flex align-items-center">
                                            <div className="timing-zone me-2">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <TimePicker
                                                            format="HH:mm"
                                                            value={dayjs(`2023-10-18T${slot.start}`)}
                                                            onChange={(e) => {
                                                                let value = moment(e.$d).format('HH:mm')
                                                                handleStartTimeChange(value, indexSlot, index)
                                                            }}
                                                            label="Start time"
                                                            ampm={false}
                                                            slotProps={{
                                                                textField: {
                                                                    readOnly: true,
                                                                },
                                                            }}
                                                            views={['hours', 'minutes']}
                                                            openTo="minutes"
                                                            minutesStep={15}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className="timing-zone">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>

                                                        <TimePicker
                                                            format="HH:mm"
                                                            value={dayjs(`2023-10-18T${slot?.end}`)}
                                                            onChange={(e) => {
                                                                let value = moment(e.$d).format('HH:mm')
                                                                handleEndTimeChange(value, indexSlot, index)
                                                            }}
                                                            label="End Time"
                                                            ampm={false}
                                                            slotProps={{
                                                                textField: {
                                                                    readOnly: true,
                                                                },
                                                            }}
                                                            views={['hours', 'minutes']}
                                                            openTo="minutes"
                                                            minutesStep={15}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            {(item?.slots.length > 1 || doctorSlot?.slots?.length > 1 || slots?.slots?.length > 1) && (
                                                <div className="time-zone-delete-icon">
                                                    <button onClick={() => onRemoveSlot(indexSlot, index)}>
                                                        <img src={require("../images/update/delete-app.svg").default} alt="img" />
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                    )
                                }) :
                                    <>

                                        <div className="timing-zone">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['TimePicker']}>

                                                    <TimePicker
                                                        format="HH:mm"
                                                        label="Start Time"
                                                        ampm={false}
                                                        slotProps={{
                                                            textField: {
                                                                readOnly: !item.closed,
                                                            },
                                                        }}
                                                        views={['hours', 'minutes']}
                                                        openTo="minutes"
                                                        minutesStep={15}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                        <div className="timing-zone">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['TimePicker']}>
                                                    <TimePicker
                                                        format="HH:mm"
                                                        label="End Time"
                                                        ampm={false}
                                                        slotProps={{
                                                            textField: {
                                                                readOnly: !item.closed,
                                                            },
                                                        }}
                                                        views={['hours', 'minutes']}
                                                        openTo="minutes"
                                                        minutesStep={15}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>

                                    </>}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="available-btn d-flex align-items-center">

                                <a className="blue-text " onClick={() => onAddSlot(index)} data-toggle="tooltip" title="Add Availability">

                                    <img src={require("../images/update/add.svg").default} alt="img" className="" />
                                </a>
                                <a className="add-available-box" >
                                    <Dropdown show={dropdownOpenIndex === index}
                                        className="drop-down">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip_1}
                                            >
                                                <img onClick={() => onDropDownOpen(index)} src={require("../images/update/paste.svg").default} alt="img" className="" data-toggle="tooltip" title="Copy Availability slots" />
                                            </OverlayTrigger>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="delete-wrap">
                                            {DaysOfWeek.map((items, index) => {
                                                if (items.toLowerCase() != (item.day).toLowerCase()) {
                                                    return (
                                                        <Dropdown.Item >
                                                            <div className="form-check" id={index}>
                                                                <Checkbox
                                                                    className="form-check-input"
                                                                    checked={selectedDays.includes(items)}
                                                                    onClick={(e) =>
                                                                        handleDaySelection(items)
                                                                    }
                                                                />

                                                                <label className="form-check-label">
                                                                    {items}
                                                                </label>
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                } else { return; }
                                            })}
                                            <Dropdown.Item className="">
                                                <div onClick={() => onCopySlot(index)} className="availabilty-btn">
                                                    <a type="button" className="join-button" >Apply</a>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </a>
                            </div>
                        </Col>
                    </Row>
                ))}

            </div>
            <div className="availabilty-btn">
                <a type="button" className="join-button save-disable-btn" onClick={(e) => {
                    if (availabilityStatus == false) {
                        handleAddAvailability()
                    }
                }}>Save</a>
            </div>


        </>
    )
}
export default Availability;