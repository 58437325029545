import React, { useEffect, useRef, useState } from 'react';
import Header from './common/Header'
import Footer from './common/Footer'
import { Tab, Nav, Modal, Button, Form, Card, Accordion } from 'react-bootstrap';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from './common/Loader';
import { useHistory } from 'react-router-dom';
import { Country, State, City, getAllCountries } from "country-state-city";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CardForm from './common/CardForm';

const Payment = () => {
  const history = useHistory()
  const [addcardModel, setaddcardModel] = useState(false)
  const handleCloseAddCardModel = () => {
    setaddcardModel(false)
    openAddCardModalCancel()
  };
  const [show, setShow] = useState(false);
  const [showAddToWallet, setShowAddToWallet] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddaCardModal, setShowAddaCardModal] = useState(false);
  const [cardNo, setCardNo] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardId, setCardId] = useState('');
  const [cartId, setCartId] = useState('');
  const [walletBalance, setWalletBalance] = useState('');
  const [selectedTab, setSelectedTab] = useState('card');
  const userId = localStorage.getItem('user_id')
  const [cartProducts, setCartProducts] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [shipping, setShipping] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const addressId = urlParams.get('address');
  const [priceStatus, setPriceStatus] = useState(false)
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [onplaying, setOnPlaying] = useState(false)
  const play = useRef("")
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [address, setAddress] = useState("");
  const handleShow = () => setShow(true);
  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: "", message: "" })
  const [serviceOther, setserviceOther] = useState("")
  const [testimonial, setTestiMonial] = useState("")
  const handleInputChange = (event) => {
    const { value } = event.target;
    setserviceOther(value)
  };
  const location = useLocation()
  const taxvalue = location?.state?.taxvalue;
  console.log("taxvalue payment=============", taxvalue)

  useEffect(() => {
    // getCards()
    getCartProducts()
  }, [])
  useEffect(() => {
    getCards();
  }, [addcardModel])

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;

    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }
    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));

  };
  const [service, setservice] = useState(false)

  const handleSubmit2 = () => {
    setuserDetails((prevState) => ({
      ...prevState,
      service: [...prevState.service, serviceOther],
    }));
    setservice(true)
    setShow(false)
  };

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleSumit(e) {
    e.preventDefault();
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!EMAILREGEX.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    // else if (!userDetail?.service) {
    //   Swal.fire("Warning", 'service field can’t be empty.', "warning")
    //   return
    // }
    else if (!userDetail?.service?.filter(item => item)?.length) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }
  let preview;

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service,
      message: userDetail?.message,
      page_type: "business"
    }
    userService.contactUs(params).then((res) => {
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: "",
            message: ""
          });
        })
        setuserDetails({
          email: "",
          name: "",
          PhoneNumber: "",
          companyName: "",
          country: "",
          service: "",
          message: ""
        });
      }
    })
      .catch((error) => {
      })
  }

  function TestiMonialVideo(type) {
    userService.testimonial("BUSINESS").then((res) => {
      if (res?.data?.status === 200) {
        setTestiMonial(res?.data?.getTestimonial)
      }
    })
      .catch((error) => {
      })
  }

  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }
  function handleContextMenu(event) {
    event.preventDefault();
  }

  const handleClose = () => {
    setShow(false);
    setShowAddToWallet(false)
    setShowAddaCardModal(false);
  }

  function getCartProducts() {
    setIsLoading(true);
    userService.getCartProducts().then((response) => {
      setIsLoading(false);
      if (response?.data?.status == 200) {
        let tmpArry = [];
        if (response?.data.cart?.lineitems?.length) {
          tmpArry = response?.data.cart?.lineitems?.map((product) =>
            ({ price: product?.qty * product?.product?.price, qty: product?.qty, subscription: product.subscription, subscription_type: product.subscription_type, discount: product?.product?.discount })
          );
        }
        console.log("response?.data?.tax", response?.data?.tax)
        setPrices(tmpArry);
        setCartId(response?.data?.cart?._id)
        setTax(response?.data?.tax);
        setShipping(response?.data?.shipping);
        setCartProducts(response?.data?.cart?.lineitems);
        setPriceStatus(true)
      } else {
        setCartProducts([]);
        toast.error("Some Error Occur", {
          toastId: 'success_draft_1',
        });
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  function openAddCardModal() {
    setCardNo('');
    setCardHolderName('');
    setExpiryDate('');
    setCvv('');
    setShowAddaCardModal(true);
  }

  function openAddCardModalCancel() {
    setCardNo('');
    setCardHolderName('');
    setExpiryDate('');
    setCvv('');
  }

  function handleAddMoney() {
    let params = {
      amount: price,
      card: cardId,
      user: userId
    }

    if (price <= 0) {
      toast.error("Please enter valid amount", {
        toastId: 'success_draft_1',
      })
    } else if (!cardId) {
      toast.error("Please add a card to proceed", {
        toastId: 'success_draft_1',
      })
    } else {
      userService.addMoney(params).then(res => {
        if (res.status == 200) {
          handleClose()
          getCards()
          toast.success("Money Added to wallet successfully", {
            toastId: 'success_draft_1',
          })
          window.location.reload()
        }
      })
    }
  }

  const handleExpiryDate = (date) => {
    setExpiryDate(date);
  }

  function getTotalPrice() {
    let data = prices?.reduce((sum, item) => {
      const value = item ? (Number(item?.price) - (Number(item?.discount) * Number(item?.price) / 100)) : 0;
      return sum + value;
    }, 0);

    return data ? data : 0
  }

  var taxPrice;
  function getTax() {
    taxPrice = getTotalPrice() - Number(getSubDiscount())
    return parseFloat((((taxPrice) * Number(tax)) / 100).toString()).toFixed(2);
  }
  function getSubDiscount() {
    let data = prices?.reduce((sum, item) => {
      const value = item?.subscription
        ? (Number(item?.price) - (Number(item?.discount) * Number(item?.price) / 100)) / 100 * parseFloat(item?.subscription_type?.save_percent)
        : 0;

      return sum + value;
    }, 0)?.toFixed(2);
    return data ? data : 0
  }

  // function getGrandTotalPrice() {

  //   let gPrice = getTotalPrice();       //setting initial GrandTotal from getTotalPrice()

  //   let taxVal = parseFloat(getTax());
  //   let shippingVal = parseFloat(shipping);
  //   gPrice += taxVal;
  //   gPrice += shippingVal;
  //   gPrice -= Number(getSubDiscount())
  //   return gPrice;
  // }

  function getGrandTotalPrice() {
    let gPrice = getTotalPrice();
    let taxVal = taxvalue && taxvalue?.map((tax, index) => {
      console.log("tax-------", (tax?.amount) / 100)
      gPrice += (tax?.amount) / 100
    })
    let shippingVal = parseFloat(shipping);
    // gPrice += taxVal;
    gPrice += shippingVal;
    gPrice -= getSubDiscount()
    return gPrice;
  }

  const makePayment = () => {
    if (selectedTab == "wallet" && parseFloat(walletBalance) < getGrandTotalPrice()) {
      toast.error("Wallet balance is low. Please add more money to place order.", {
        toastId: 'success_draft_1',
      })
    } else if (selectedTab == "card" && cards.length == 0) {
      toast.error("No card added yet. Please add card to place order.", {
        toastId: 'success_draft_1',
      })
    } else {
      setIsLoading(true)
      let params = {
        cart: cartId,
        card: selectedTab == "card" ? cardId : null,
        address: addressId,
        user: localStorage.getItem('user_id'),
        payfrom: selectedTab,
        // tax: getTax(),
        tax: taxvalue,
        grandtotal: getGrandTotalPrice(),
        shipping_charge: shipping,
        qty: cartProducts[0]?.qty
      }
      console.log("params----------", params)
      userService.createOrder(params).then((res) => {
        setIsLoading(false)
        if (res.data.status == 200) {
          setLgShow(true)
        }
        else {
          toast.error(res.data.message, {
            toastId: 'success_draft_1',
          });
        }
      })
    }
  }

  // const handleClearText = () => {
  //   if(service == false){
  //     setuserDetails({
  //       service: "",
  //     });
  //   }
  // }
  const handleClearText = () => {
    if (service == false) {
      setuserDetails({
        ...userDetail,
        service: [""],
      });
    }
  }

  function validateForm() {
    if (!/^[0-9]{12,16}$/.test(cardNo)) {
      toast.error("Invalid Card No", {
        toastId: 'success_draft_1',
      });
    } else if (!/^[a-zA-Z-_ ]+$/.test(cardHolderName)) {
      toast.error("Invalid Card Holder Name", {
        toastId: 'success_draft_1',
      });
    } else if (!expiryDate) {
      toast.error("Please Select Expiry Date", {
        toastId: 'success_draft_1',
      });
    } else if (!/^[0-9]{3,4}$/.test(cvv)) {
      toast.error("Invalid CVV Number", {
        toastId: 'success_draft_1',
      });
    } else {
      setIsLoading(true);
      let params = { user: userId, card_number: cardNo, exp_month: expiryDate.getMonth() + 1, exp_year: expiryDate.getFullYear(), cvv: cvv, card_holder_name: cardHolderName }
      userService.addCard(params).then((response) => {
        setIsLoading(false);
        if (response.data.status == 200) {
          handleClose();
          openAddCardModalCancel()
          setaddcardModel(false)
          getCards();
          toast.success("Card Added Successfully", {
            toastId: 'success_draft_1',
          });
        } else {
          toast.error(response.data.message);
        }
        setIsLoading(false);

      }).catch((error) => {
        setIsLoading(false);
      });
    }
  }

  function getCards() {
    setIsLoading(true);
    userService.getCards().then((response) => {
      setIsLoading(false);
      if (response.data.status == 200) {
        setCards(response?.data?.data);
        if (response?.data?.data?.length > 0)
          setCardId(response?.data?.data[0]?._id)
        setWalletBalance(response?.data?.wallet)
      } else {
        toast.error("Some Error Occur", {
          toastId: 'success_draft_1',
        });
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  function getDiscountedPrice(item) {
    let price = (Number(item?.price) - Number(item?.discount / 100) * Number(item?.price))?.toFixed(2)
    return price ? price : 0;
  }
  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <section className="card_section pt-4 pb-5 checkout paymentsection1 shopping-cart-sec burden-disease">
        <div className="container">
          <div class="banner-text">
            <h1 class="text-center">Payment Method</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 summry">
              <div className="total-count">
                <div className="">
                  {cartProducts?.length > 0 && cartProducts?.map((item, index) => {
                    return <dl className="dlist-align mb-1">
                      <h6><span>{index + 1}.</span> {item.product.name} <span>({item.qty}x)</span></h6>
                      {priceStatus && <dd className="text-right ml-3 text-dark" style={{ fontSize: "14px" }}>${getDiscountedPrice(item?.product)}</dd>}
                    </dl>
                  })}
                  <div className="count-box">
                    <p><b> Total Price:</b></p>
                    {priceStatus && <p className="blue-text">${getTotalPrice()?.toFixed(2)}</p>}
                  </div>

                  {priceStatus && <div className="count-box">
                    <p><b>Discount:</b></p>
                    <p className="blue-text">-${getSubDiscount()}</p>
                  </div>}
                  {/* <div className="count-box">
                    <p><b>Tax({tax}%):</b></p>
                    {priceStatus && <p className="blue-text">${getTax()}</p>}
                  </div> */}
                  <dl className="dlist-align">
                    <dt>Total Service Tax:</dt>
                  </dl>
                  <dl className="">
                    {taxvalue && taxvalue?.map((tax, index) => {
                      console.log("tax-----------------", tax?.amount / 100);
                      return (
                        <div className="dlist-align" key={index}>
                          <dt>Tax Type({tax?.tax_rate_details?.tax_type}):</dt>
                          <dd className="text-right text-success ml-3">
                            ${(tax?.amount / 100).toFixed(2)}
                          </dd>
                        </div>
                      );
                    })}
                  </dl>
                  <div className="count-box">
                    <p><b>Shipping:</b></p>
                    {shipping && <p className="blue-text">${parseFloat(shipping).toFixed(2)}</p>}
                  </div>
                  <div className="count-box last-box">
                    <p><b>Grand Total:</b></p>
                    {priceStatus && <p className="blue-text" ><strong>${getGrandTotalPrice()?.toFixed(2)}</strong></p>}
                  </div>
                  <div className="next_btn text-center">
                    <button className="btn w-100 btn btn-secondary" onClick={() => makePayment()}>Make Payment</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="rightside_checkout payment_section delivey-box">
                <div className="add_head justify-content-center d-block text-center delivery-head">
                  <h5 className="mb-0"><b>Select Payment Method</b></h5>
                </div>
                <div className="pt-2">
                  <Tab.Container onSelect={(k) => setSelectedTab(k)}
                    id="left-tabs-example" defaultActiveKey="card">
                    <div>
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                          <Nav.Link eventKey="card">
                            <img src={require('../images/update/debit-card.svg').default} alt="" />
                            <h5>Debit/Credit Card</h5>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="wallet">
                            <img src={require('../images/update/wallet-card.svg').default} alt="" />
                            <h5>Wallet {walletBalance && <text><b>${parseFloat(walletBalance).toFixed(2)}</b></text>}</h5>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div>
                      <Tab.Content>
                        <Tab.Pane eventKey="card">
                          <div className="mt-4">
                            <div className="row selectcard_checkout">
                              <div className="col-md-12 mb-3">
                                <div className='select-top-area'>
                                  {<div className="new-card-add-btn"><button type="button" variant="unset" onClick={(e) => setaddcardModel(true)}>+ Add New Card</button></div>}
                                  {/* {cards && cards?.length > 0 && <div className="new-card-add-btn"><button type="button" variant="unset" onClick={(e) => setaddcardModel(true)}>+ Add New Card</button></div>} */}
                                  <div className="delivery-head">
                                    <h5 className="mb-0"><b>Select Card</b></h5>
                                  </div>
                                </div>

                              </div>
                              <div className="col-md-12">
                                <div className="delivery-bodys">
                                  <p>Your saved card.</p>
                                </div>
                                <div className='row'>
                                  {cards?.length > 0 && cards?.map((card, index) => {
                                    return (

                                      <aside className='col-md-6 mb-3' key={index}>
                                        <div className="cardbox">
                                          <div className="cardbox_header mb-3">
                                            <input onChange={() => setCardId(card._id)} type="radio" checked={card._id === cardId} />
                                            {card?.brand === "Visa" && <img src={require(`../../src/images/update/visa.png`).default} alt="Visa Card" />}
                                            {card?.brand === "MasterCard" && <img src={require(`../../src/images/update/master.png`).default} alt="MasterCard" />}
                                            {!card?.brand && <p>No card brand specified</p>}
                                          </div>
                                          <h5>XXXX XXXX XXXX <span>{card.last4}</span></h5>
                                        </div>
                                      </aside>

                                    )
                                  })}
                                </div>
                                {/* {cards && cards?.length == 0 &&
                                  <div className="billing-box-address signin-bg mt-4">
                                    <div className="delivery-head mt-3">
                                      <h5><b>Add Card</b></h5>
                                    </div>
                                    <div className="delivery-body biling-box Signup_box p-0">
                                      <form>
                                        <div className="row">
                                          <aside class="col-md-6 mb-3">
                                            <div class="input_row">
                                              <label>Card Number</label>
                                              <input type="" placeholder="Card Number" class="input103 w-100" value={cardNo} maxLength={16} onChange={(e) => setCardNo(e.target.value)} />
                                            </div>
                                          </aside>
                                          <aside class="col-md-6 mb-3">
                                            <div class="input_row">
                                              <label>Card Holder Name</label>
                                              <input type="" placeholder="Card Holder Name" class="input103 w-100" value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} />
                                            </div>
                                          </aside>
                                          <aside class="col-md-6 mb-3">
                                            <div class="input_row payment-expiry">
                                              <label>Expiry</label>
                                              <DatePicker
                                                selected={expiryDate}
                                                minDate={new Date()}
                                                showMonthYearPicker
                                                dateFormat={"MM/yyyy"}
                                                placeholderText="MM/YYYY"
                                                class="input103 w-100"
                                                onChange={handleExpiryDate}
                                              />
                                            </div>
                                          </aside>
                                          <aside class="col-md-6 mb-3">
                                            <div class="input_row">
                                              <label>CVV</label>
                                              <input type="" placeholder="Enter CVV" class="input103 w-100" value={cvv} maxLength={4} onChange={(e) => setCvv(e.target.value)} />
                                            </div>
                                          </aside>
                                        </div>
                                        <div className="next_btn text-center mt-4">
                                          <Button
                                            variant="secondary"
                                            type="button"
                                            className="btn w-25"
                                            onClick={() => validateForm()}
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>

                                } */}
                              </div>


                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="wallet">
                          <div className="mt-4">
                            <div className="row selectcard_checkout">
                              <div className="col-md-12 mb-3">
                                <div className="delivery-head">
                                  <h5 className="mb-0" ><b>Total Balance</b>
                                    {walletBalance && <span className="blue-text" ><b>${parseFloat(walletBalance).toFixed(2)}</b></span>}</h5>

                                </div>
                                <div className="billing-box-address signin-bg">
                                  <div className="delivery-body biling-box Signup_box p-0">
                                    <form>
                                      <div className="row">
                                        <aside class="col-md-6 mb-3">
                                          <div class="input_row">
                                            <label>Amount</label>
                                            <input value={price} placeholder="0" class="input103 w-100" onChange={e => setPrice(e.target.value)} />
                                          </div>
                                        </aside>

                                      </div>

                                      <div className='row'>
                                        {cards?.length > 0 && cards?.map((card, index) => {
                                          return (
                                            <aside className='col-md-6 mb-3' key={index}>
                                              <div className="cardbox">
                                                <div className="cardbox_header mb-3">
                                                  <input onChange={() => setCardId(card._id)} type="radio" checked={card._id === cardId} />
                                                  {card?.brand === "Visa" && <img src={require(`../../src/images/update/visa.png`).default} alt="Visa Card" />}
                                                  {card?.brand === "MasterCard" && <img src={require(`../../src/images/update/master.png`).default} alt="MasterCard" />}
                                                  {!card?.brand && <p>No card brand specified</p>}
                                                </div>
                                                <h5>XXXX XXXX XXXX <span>{card.last4}</span></h5>
                                              </div>
                                            </aside>
                                          );
                                        })}
                                      </div>

                                      <div className="next_btn text-center mt-4">
                                        <Button
                                          variant="secondary"
                                          type="button"
                                          className="btn w-25"
                                          onClick={handleAddMoney}
                                        >
                                          Add
                                        </Button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* contact us section start */}
      {/* <section className="get-started-form bg_section signup_section redesign-profile">
        <div className="container">
          <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
          <div className="get-form-wrap">
            <h2>Get Started</h2>

            <aside className="col-lg-12 pt-4">
              <div className="Signup_box text-center w-100">
                <Form className="">
                  <div className="row frist_step  contact-form-area ">
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Full Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="firstName"
                          name="name"
                          maxLength={50}
                          placeholder="Enter Full Name"
                          className="input103 w-100"
                          value={userDetail?.name}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Email<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Email"
                          name="email"
                          placeholder="Enter Email"
                          className="input103 w-100"
                          value={userDetail?.email}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Phone Number<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="PhoneNumber"
                          name="PhoneNumber"
                          placeholder="Enter Phone Number"
                          className="input103 w-100"
                          value={userDetail?.PhoneNumber}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Company Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Text"
                          name="companyName"
                          maxLength={50}
                          placeholder="Enter Company Name"
                          className="input103 w-100"
                          value={userDetail?.companyName}
                          onChange={(e) => inputChange(e)}


                        />
                      </div>
                    </aside>

                    <aside className="col-md-4 mb-3 text-left">
                      <label>Country </label>
                      <div className="input_row input103">


                        <select
                          className="w-100 select-main"
                          value={userDetail.country}
                          name="country"
                          onChange={(e) => inputChange(e)}
                        >
                          <option value={""}>Select Country</option>
                          {Country.getAllCountries().map((c) => {
                            return (
                              <option value={c.name}>{c.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </aside>
                   
                    <aside className="col-md-4 mb-3 text-left">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShow} className='d-block servies-text'>
                          {userDetail?.service?.length > 0 ? "+ Update Services" : "+ Add Services"}

                        </a>
                        <div className='services-preview'>
                          {userDetail?.service && <span>{showFullContent ? userDetail?.service?.join(" , ") : userDetail?.service?.join(" , ")?.slice(0, 30)}</span>}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                    <aside className="col-md-12 mb-3 text-left">
                      <div className="input_row">
                        <label>Message</label>
                        <textarea
                          className=" w-100 select-main"
                          placeholder="Enter Message"
                          name="message"
                          maxLength={500}
                          value={userDetail?.message}
                          onChange={(e) => inputChange(e)}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </Form>
              </div>
              <div className="next_btn text-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="btn w-25"
                  onClick={handleSumit}
                >
                  Submit
                </Button>
              </div>
            </aside>

          </div>
        </div>
      </section> */}

      {/* contact us section start */}

      <Footer />

      <Modal show={showAddaCardModal} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pay_modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">

          <div className='success-box text-center'>
            <img src={require(`../../src/images/update/check.png`).default} />
            <h4>Successful</h4>
            <p>Your card added successful.</p>
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0 pb-4">
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pay_modal card_modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment_modal px-3">
            <div className="row">
              <div className="col-md-12 text-center mb-4">
                <img src={require('../images/mastercard1.png').default} alt="" />
              </div>

              <div className="col-md-6 mb-3">
                <div>
                  <label>Card Number</label>
                  <input type="" value="xxxx xxxx xxxx 1234" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>Card Holder Name</label>
                  <input type="" value="Alex Smith" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>Expiry</label>
                  <input type="" value="01/25" />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label>CVV</label>
                  <input type="" value="112" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 px-4">
          <button
            className="paybtn" onClick={handleClose}>
            PAY
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddToWallet} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pay_modal card_modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Add Money To Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="payment_modal">
            <div className="selectcard_checkout">

              <div className="mb-3">
                <div>
                  <label>AMOUNT</label>
                  <input value={price} onChange={e => setPrice(e.target.value)} />
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 px-4 pt-0">
          <button
            className="paybtn" onClick={handleAddMoney} style={{ width: "130px" }}>
            ADD
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className='order-placed-box'
        show={lgShow}
        backdrop='static'
        onHide={() => setLgShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="border-0" >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center p-0">
                <div className="success_payment my-4">
                  <img src={require('../images/check_icon.png').default} alt="" />
                  <h1>Order Placed Succesfully!</h1>
                  <p>Thanks for purchase with us.</p>
                  <p>Now you can track your order in “My Orders” tab. </p>
                  <a className="btn text-white"
                    onClick={() => history.replace(`/profilenew?activeMenu=${"orders"}`)}
                  >Go to My Orders</a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show}
        // onHide={handleClose}
        onHide={(e) => { handleClose(); handleClearText(); }}
        className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Chronic Disease Prevention/Reversal Programs" checked={userDetail.service.includes('Chronic Disease Prevention/Reversal Programs')} value="Chronic Disease Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    {/* Health Advocacy and Information: */}
                    Healthcare Speaker & Health Information
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                    Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <label className='mt-3'><b>Other</b></label>
              <textarea
                className="form-control"
                placeholder="Please Specify"
                name="service"
                maxLength={500}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 && <a
            type="button"
            className="join-button"
            onClick={handleSubmit2}

          >
            Submit
          </a>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      {/* add card model */}

      <Modal show={addcardModel} onHide={handleCloseAddCardModel} centered>
        <Modal.Header closeButton>
          <Modal.Title><b>Add Card</b></Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <div className="billing-box-address signin-bg">

            <div className="delivery-body biling-box Signup_box p-0">
              {/* <form>
                <div className="row">
                  <aside class="col-md-6 mb-3">
                    <div class="input_row">
                      <label>Card Number</label>
                      <input type="" placeholder="Card Number" class="input103 w-100" value={cardNo} maxLength={16} onChange={(e) => setCardNo(e.target.value)} />
                    </div>
                  </aside>
                  <aside class="col-md-6 mb-3">
                    <div class="input_row">
                      <label>Card Holder Name</label>
                      <input type="" placeholder="Card Holder Name" class="input103 w-100" value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} />
                    </div>
                  </aside>
                  <aside class="col-md-6 mb-3">
                    <div class="input_row payment-expiry">
                      <label>Expiry</label>
                      <DatePicker
                        selected={expiryDate}
                        minDate={new Date()}
                        showMonthYearPicker
                        dateFormat={"MM/yyyy"}
                        placeholderText="MM/YYYY"
                        class="input103 w-100"
                        onChange={handleExpiryDate}
                      />
                    </div>
                  </aside>
                  <aside class="col-md-6 mb-3">
                    <div class="input_row">
                      <label>CVV</label>
                      <input type="" placeholder="Enter CVV" class="input103 w-100" value={cvv} maxLength={4} onChange={(e) => setCvv(e.target.value)} />
                    </div>
                  </aside>
                </div>
                <div className="next_btn text-center mt-4">
                  <Button
                    variant="secondary"
                    type="button"
                    className="btn w-25"
                    onClick={() => validateForm()}
                  >
                    Add
                  </Button>
                </div>
              </form> */}
              <CardForm handleCloseAddCardModel={handleCloseAddCardModel} />
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default Payment;