import React from 'react';
import Header from './common/Header'
import Footer from './common/Footer'

const Redeem = () => {
    return (
        <>
        <Header/>
            <section className="py-4">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 reuslt_title">
                        <h1 className="mb-0 ">BIG4 Quiz Results</h1>
                        <h2 className="mb-0 mt-3">Score 5 of 5</h2>
                        <p>You have won 5% discount on your next purchase.</p>
                    </div>
                    <div className="col-sm-6 text-right">
                        <div className="Redeemed">
                           <h6><img src={require("../images/check1.png").default} alt="img"/> Already Redeemed</h6>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-12">
                        <label className="qstn_label">Question 1</label>
                    </div>
                    <div className="col-sm-12 text-left">
                    <div className="qstns_box">
                        <h4>What are some things you can do to help support your brain health?</h4>
                        <div className="answer_box1 mt-1 py-2">
                            <ul className="m-0 p-0">
                                <li className="correct_ans">
                                    <span>
                                        <img src={require("../images/check.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                    <span>
                                        <label class="qstn_label">CORRECT ANSWER</label>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>

                                </li>
                                <li>
                                     <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        All of the Above
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        None
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-sm-12">
                        <label className="qstn_label">Question 2</label>
                    </div>
                    <div className="col-sm-12 text-left">
                    <div className="qstns_box">
                        <h4>What are some things you can do to help support your brain health?</h4>
                        <div className="answer_box1 mt-1 py-2">
                            <ul className="m-0 p-0">
                                <li className="correct_ans">
                                    <span>
                                        <img src={require("../images/check.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                    <span>
                                        <label class="qstn_label">CORRECT ANSWER</label>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>

                                </li>
                                <li>
                                     <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        All of the Above
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        None
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-sm-12">
                        <label className="qstn_label">Question 3</label>
                    </div>
                    <div className="col-sm-12 text-left">
                    <div className="qstns_box">
                        <h4>What are some things you can do to help support your brain health?</h4>
                        <div className="answer_box1 mt-1 py-2">
                            <ul className="m-0 p-0">
                                <li className="correct_ans">
                                    <span>
                                        <img src={require("../images/check.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                    <span>
                                        <label class="qstn_label">CORRECT ANSWER</label>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>

                                </li>
                                <li>
                                     <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        All of the Above
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        None
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-sm-12">
                        <label className="qstn_label">Question 4</label>
                    </div>
                    <div className="col-sm-12 text-left">
                    <div className="qstns_box">
                        <h4>What are some things you can do to help support your brain health?</h4>
                        <div className="answer_box1 mt-1 py-2">
                            <ul className="m-0 p-0">
                                <li className="correct_ans">
                                    <span>
                                        <img src={require("../images/check.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                    <span>
                                        <label class="qstn_label">CORRECT ANSWER</label>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                   
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>

                                </li>
                                <li>
                                     <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        All of the Above
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        None
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-sm-12">
                        <label className="qstn_label">Question 5</label>
                    </div>
                    <div className="col-sm-12 text-left">
                    <div className="qstns_box">
                        <h4>What are some things you can do to help support your brain health?</h4>
                        <div className="answer_box1 mt-1 py-2">
                            <ul className="m-0 p-0">
                                <li className="correct_ans">
                                    <span>
                                        <img src={require("../images/check.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>
                                    <span>
                                    <label class="qstn_label">CORRECT ANSWER</label>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>

                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        Dreaming of a mobile companion.
                                    </span>

                                </li>
                                <li>
                                     <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        All of the Above
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <img src={require("../images/uncheck.png").default} className="mr-2" alt="img"/>
                                        None
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section> 
        <Footer/>
        </>
    )
}

export default Redeem;