import React, { useState, useEffect } from 'react'
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import ReactDOM from 'react-dom';
import { appUrl, config } from '../config/config'
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download';
import Swal from 'sweetalert2';
import Magnifier from "react-magnifier";
import { Swiper, SwiperSlide, } from 'swiper/react';
import ShareOnSocialMedia from "./common/ShareOnSocialMedia";
import productImage from '../images/update/product.png';
import { Button, Col, Form, Row, Card, Accordion, Modal } from "react-bootstrap";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


SwiperCore.use([Pagination, Navigation, Autoplay]);

const Favorites = () => {
    const [isFavourite, setIsFavourite] = useState(false);
    const [size, setSize] = useState('');
    const [color, setColor] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [userId, setUserId] = useState('');
    const [favCount, setFavCount] = useState('');
    const [cartCount, setCartCount] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [productstatus, setProductStatus] = useState(false)
    const [subIndex, setSubIndex] = useState(0)
    const [productModel, setproductModel] = useState(false)

    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) {
            setUserId(user_id);
            setIsLoading(true);
            getFavourites();
            getCartProducts();
        }
    }, []);

    function selectSub(id) {
        if (id != subIndex)
            setSubIndex(id)
        else { setSubIndex(0) }
    }

    function getFavourites() {
        userService.getFavourites().then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                setFavCount(response.data.data.length);
                setColor(response.data?.data[0]?.product?.color?.length > 0 ? response.data?.data[0]?.product?.color[0] : "")
                setSize(response.data?.data[0]?.product?.size?.length > 0 ? response.data?.data[0]?.product?.size[0] : "")
                setIsFavourite(response.data.data[0]?.is_favourite)
                setProducts(response.data.data);
                setProductStatus(true)
            } else {
                setFavCount('')
                setProducts([]);
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setProducts([]);
        });
    }

    function getCartProducts() {
        setIsLoading(true)
        userService.getCartProducts().then((res) => {
            if (res.status === 200) {
                setIsLoading(false)


                setCartItems(res.data.cart.products)
            }
        }).catch((er) => { })
    }

    function handleFavourite(id, status) {
        if (userId) {
            let answer = true;
            if (status) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You want to remove it from your favourite list.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        makeUpdateRequest(id, status);
                    }
                });
            } else {
                makeUpdateRequest(id, status);
            }
        } else {
            window.location.pathname = "/signin";
        }
    }

    function makeUpdateRequest(id, status) {
        setIsLoading(true);
        let params = { user: userId, product: id, is_favourite: !status }
        userService.updateFavourite(params)
            .then((response) => {
                setIsLoading(false);
                if (response.data.status === 200) {
                    setFavCount(response.data.favCount);
                    setproductModel(false)
                    getFavourites();
                    toast.success(
                        "Product " +
                        (!status ? "added to" : "removed from") +
                        " your favourite list successfully."
                    );
                    setTimeout(() => {
                        window.location.href = "/my_favorites";
                    }, 300)
                } else {
                    toast.error("Some Error Occurred");
                }
            })
            .catch((error) => {
                setFavCount("");
                setIsLoading(false);
            });
    }

    function getDiscountedPrice(item) {
        console.log("item", item)
        return (item?.price - (item?.discount / 100) * item?.price).toFixed(2)
    }

    function addToCart(id) {
        if (userId) {
            setIsLoading(true);
            let params = { user: userId, product: id, qty: 1, subscription_type: subIndex, subscription: subIndex != 0, color, size }
            userService.addToCart(params).then((response) => {
                setIsLoading(false);
                if (response.data.status == 200) {
                    setCartCount(response.data.cartCount);
                    toast.success("Product added to cart successfully.")
                    getCartProducts();
                    setTimeout(() => {
                        goToCart()
                    }, 200)
                } else {
                    setCartCount('');
                    toast.error("Some Error Occur");
                }
            }).catch((error) => {
                setIsLoading(false);
            });
        } else {
            window.location.pathname = '/signin'
        }
    }

    function goToCart() {
        window.location.href = window.location.origin + '/shopping-cart';
    }

    let subtitle;
    const [modalIsOpenremove, setIsOpenremove] = React.useState(false);

    function openModalremove() {
        setIsOpenremove(true);
    }

    function afterOpenModalremove() {
        subtitle.style.color = '#f00';
    }

    function closeModalremove() {
        setIsOpenremove(false);
    }

    function showPreview(image) {
        setPreviewImage(image)
        setproductModel(true)
    }



    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} />
            {isLoading && <Loader />}
            <section className="heading-search ">
                <div className="container justify-content-center">
                    <h2>Favorites</h2>
                </div>
            </section>
            <section className="product-page-area">
                <div className="container">
                    <div className="row justify-content-center">
                        {products.length > 0 && products.map((favourite, i) => {
                            var product = favourite.product;
                            let btnStatus = cartItems?.filter(cartItem => cartItem === product?._id).length > 0;
                            var productImage = product && config.imageUrl + product?.images[0]?.image

                            return (<div className="col-lg-3 col-md-4">
                                <div className="product-list-box">
                                    <div className="product-list-image text-center" onClick={(e) => { showPreview(productImage); setproductModel(true) }}>
                                        <img src={product?.images.length > 0 ? config.imageUrl + product.images[0].image : ''} alt="img" />
                                    </div>
                                    <div className="product-list-details">
                                        <h4>{product.name}</h4>
                                        <h5 className="pricing-content-area">
                                            ${product?.discount > 0 && <span>{product.price.toFixed(2)}</span>}
                                            {productstatus == true && getDiscountedPrice(product)}
                                        </h5>
                                    </div>
                                    <div className="product-details">
                                        <div className="buttons d-flex flex-row">
                                            <a className="cart shadow pb-3 pointer" onClick={(e) => handleFavourite(product._id, favourite.is_favourite)}><i className={favourite.is_favourite ? "fa fa-heart" : "fa fa-heart-o"}></i></a>
                                            <a className="btn btn-success cart-button btn-block shadow pointer"
                                                onClick={(e) => { showPreview(productImage); setproductModel(true) }}
                                            ><i className="fa fa-shopping-cart mr-2" style={{ fontSize: "19px" }}></i>{btnStatus ? 'BUY ' : 'BUY '} </a>
                                        </div>
                                        <div class="weight"></div>
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>


                    {products.length === 0 && !isLoading &&
                        <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                            <div className="no-data-box">
                                <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                <h3>No products added yet.</h3>
                            </div>
                        </div>
                    }

                </div>
            </section>
            <Download />

            <Modal
                isOpen={modalIsOpenremove}
                onAfterOpen={afterOpenModalremove}
                onRequestClose={closeModalremove}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="remove-modal">
                    <div className="modal-heading">
                        <h2 className="modal-heading text-center" ref={_subtitle => (subtitle = _subtitle)}>Remove Favorites</h2>
                        <button className="cross-btn" onClick={closeModalremove}> <img src={require("../images/cross.png").default} alt="img" /></button>
                    </div>
                    <div className="modal-body text-center">
                        <h6>Are you sure, you want to remove</h6>
                        <h4>“WOW Life Science Omega-3</h4>
                        <h4>Fish Oil”</h4>
                        <h6>product from your favorites list?</h6>
                        <p className="mt-5"><span className="modal-no mr-2"><a href="">No</a></span> <span className="modal-yes"><a href="">Yes</a></span></p>
                    </div>
                </div>
            </Modal>

            <Modal
                show={productModel}
                onHide={(e) => { setproductModel(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                className="preview-popup-box"
                size="xl"
                centered>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 mb-3 pt-0" >
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-6">
                            <div className="preview-image-area-left">
                                <Swiper
                                    navigation={true} modules={[Navigation]} className="mySwiper"
                                >
                                    {products[0]?.product?.images?.map((image) => {
                                        return (<SwiperSlide>
                                            <div className="slider_box_new text-center">
                                                <Magnifier src={config.imageUrl + image.image} mgWidth={200} mgHeight={200} mgShape={'square'} />
                                            </div>
                                        </SwiperSlide>)
                                    })}

                                </Swiper>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-6">
                            <div className="buy-product-detail-right">
                                {/* <ShareOnSocialMedia url={appUrl} text={`Big4Health - ${products[0]?.product?.name}`} /> */}
                                <ShareOnSocialMedia url={window.location.href} text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
                                <div className="buy-product-detail-right-top mt-2">
                                    <h2>{products[0]?.product?.name}</h2>
                                    <div className="video-like mt-0">
                                        <img onClick={() => handleFavourite(products[0]?.product?._id, products[0]?.is_favourite)} src={require(!isFavourite ? "../images/update/like.svg" : "../images/update/chat-icon-fill.svg").default} alt="img" />

                                    </div>
                                </div>

                                {console.log("products===========", products[0])}
                                <h6 className="replace-text">{products[0]?.product?.category?.name}</h6>

                                {<h5 className="product-review-bottom">

                                    {products[0]?.product?.rating && Array.from(Array(parseInt(products[0]?.product?.rating))).map(t => {
                                        return <i class="fa fa-star" aria-hidden="true" />
                                    })}
                                    <span><a href={`/reviews?id=${[products[0]?.product?._id]}`}>({products[0]?.product?.reviewCount} reviews)</a></span></h5>}
                                <h5 className="pricing-content-area">${products[0]?.product?.discount > 0 && <span>{products[0]?.product?.price}</span>}
                                    {productstatus == true && getDiscountedPrice(products[0]?.product)}
                                </h5>
                                <div>
                                    {products[0]?.category?.isFoodCategory == false && <div>
                                        {products[0]?.product?.size?.length > 0 && <h5 className="mt-0 mb-0">Size: <span className="text-secondary font-weight-regular">Choose an option</span>
                                            <div className="size_row">
                                                {products[0]?.product?.size.map(item => {
                                                    return <span onClick={() => setSize(item)} className={item == size && "active"}>{item}</span>
                                                })}
                                            </div>
                                        </h5>}
                                    </div>}

                                    {products[0]?.product?.color?.length > 0 && (
                                        <div>
                                            <h5 className="mt-3 mb-0">Color: <span className="text-secondary font-weight-regular">Choose an option</span></h5>
                                            <div className="color_row">
                                                {products[0]?.product?.color.map((item, index) => (
                                                    <span
                                                        key={index}
                                                        onClick={() => setColor(item)}
                                                        className={item === color ? 'active' : ''}
                                                        style={{ backgroundColor: item }}
                                                    >
                                                        {item === color && <i className="fa fa-check"></i>}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                </div>
                                <div className="ingredient-buy-now"
                                    onClick={(e) => {
                                        if (!localStorage.getItem("token") || !localStorage.getItem("user_id")) {
                                            Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                                                window.location.href = "/signin";
                                                let redirectUri = window.location.pathname + window.location.search;
                                                localStorage.setItem("redirectUri", redirectUri);
                                            })
                                        }
                                        else {
                                            addToCart(products[0]?.product?._id)
                                        }
                                    }}

                                >
                                    <button>
                                        <span class="circle1"></span>
                                        <span class="circle2"></span>
                                        <span class="circle3"></span>
                                        <span class="circle4"></span>
                                        <span class="circle5"></span>
                                        <span class="text"><i class="fa fa-shopping-cart" aria-hidden="true"></i> {cartItems?.length > 0 ? "PROCEED" : "PROCEED"}</span>
                                    </button>
                                </div>
                                <p><b>Description:</b><br />
                                    <section id="moredesc" className="description-area ">
                                        <div>
                                            <span style={{ whiteSpace: 'pre-line' }}> {products[0]?.product?.description} </span>
                                        </div>
                                    </section>
                                </p>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Footer />
        </>
    )
}


export default Favorites;