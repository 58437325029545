import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Header from '../../Components/common/Header.js'
import LoadingSpinerComponent from "./Loader";
import { userService } from "../../services";
import Footer from "./Footer";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

function Reset() {
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [errors, setErrors] = useState({
    newpassword: "", confirmpassword: "",
  });

  const handleNewPassword = (e) => {
    e.preventDefault();
    let error = { ...errors }

    if (!newpassword) {
      Swal.fire("Warning","Please enter new password.","warning");
    } else if (newpassword && newpassword.length < 8) {
      Swal.fire("Warning","Password must be at least 8 characters.","warning");
    } 
    else if (!confirmpassword) {
      Swal.fire("Warning","Please enter confirm password.","warning");
    } else if (confirmpassword && confirmpassword.length < 8) {
      Swal.fire("Warning","Password must be at least 8 characters.","warning");
    } else if (newpassword != confirmpassword) {
      Swal.fire("Warning","new password and confirm password must match","warning");
    }
    else{
      ResetpasswordApi();
    }
  };

  function ResetpasswordApi() {
    const params = {
      newPassword: newpassword,
      token: token,
    };
    setisLoading(true);

     userService.ResetpasswordApi(params)
      .then((res) => {
        if (res.status === 200) {
          setisLoading(false);
          Swal.fire("Success", "Password reset successfully", "success").then((res) =>{
            window.location.href = "/signin";
          })
          
        }
        setisLoading(false)
      })
      .catch((error) => {
        setisLoading(false);
        Swal.fire("Error", "Reset Password token has expired", "error");
      });
  }
  return (
    <>
      <section className="about-page about-reset-page">
        <Container>
          <Row className="justify-content-center signin-form">
            <Col md={6}>
              <div className="reset-form">
              <h3 className="text-center mb-3">Reset Password</h3>
              <form className="login-form-box" onSubmit={handleNewPassword}>
                <div className="col-12 mb-3">
                  <label className="form-label">Enter New password</label>
                  <input
                    type="password"
                    placeholder="Enter new password"
                    required=""
                    name="changepassword"
                    autoComplete="off"
                    className="form-control-lg w-100"
                    onKeyDown={(e) =>{
                      if(!e.target.value && e.code === "Space"){
                      e.preventDefault();
                    }
                  }}
                    onChange={(e) => setNewpassword(e.target.value)}
                  />
                  {(((!newpassword) || (newpassword && newpassword.length < 8)) && errors?.newpassword) ? <span className="errors">{errors.newpassword}</span> : <></>}
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label">Enter confirm password</label>
                  <input
                    type="Password"
                    placeholder="Enter confirm password"
                    required=""
                    name="confirmpassword"
                    autoComplete="off"
                    className="form-control-lg w-100"
                    onKeyDown={(e) =>{
                      if(!e.target.value && e.code === "Space"){
                      e.preventDefault();
                    }
                  }}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                  {(((!confirmpassword) || (confirmpassword && confirmpassword.length < 8) || (newpassword != confirmpassword)) && errors?.confirmpassword) ? <span className="errors">{errors.confirmpassword}</span> : <></>}
                </div>
                <button type="submit" className="submit-bt">
                  Submit
                </button>
              </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default Reset;