import Header from "./common/Header";
import Footer from "./common/Footer";
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userService } from "../services";
import Swal from "sweetalert2";
import { Button, Col, Form, Row, Card, Accordion, Modal } from "react-bootstrap";
import { Country, State, City, getAllCountries } from "country-state-city";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Loader from "./common/Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ShoppingCart = () => {
  const [productID, setProductID] = useState('')
  const [haveSubscription, setHaveSubscription] = useState('');
  const [subs, setSubs] = useState([]);
  const [save, setSave] = useState(false)
  const handleSave = () => setSave(true);
  const handleCloseSave = () => setSave(false);
  const [subIndex, setSubIndex] = useState(0)
  const [btnStatus, setBtnStatus] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);
  const [priceStatus, setPriceStatus] = useState(false)
  const [addtoCartStatus, setAddtoCartStatus] = useState(false)

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [serviceOther, setserviceOther] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false)
  const [address, setAddress] = useState("");
  const targetRef = useRef(null);
  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: "", message: "" })
  const history = useHistory()
  const [cartProducts, setCartProducts] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const priceRef = useRef(null)
  const [quantities, setQuantities] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [loadingStates, setLoadingStates] = useState(0);
  const [cartCount, setCartCount] = useState('');
  const [shipping, setShipping] = useState('');
  const [tax, setTax] = useState('');
  const [taxVal, setTaxVal] = useState('');
  const [favCount, setFavCount] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [temp, setTemp] = useState('0')
  const [proceedStatus, setProceedStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const cotId = "62d7761d5c91b78ea876856c";
  const location = useLocation();
  const [taxvalue, setTaxValue]=useState()
 

  useEffect(() => {
    let user_id = localStorage.getItem('user_id');
    if (user_id) {
      setUserId(user_id);
      setIsLoading(true);
      getCartProducts();
      if (cotId) {
        getProducts(cotId, searchText, 0);
      }
    }
  }, []);

  useEffect(() => {
    if (subIndex == 0 && proceedStatus == 'SKIP_SUB') {
      setProceedStatus('')
      addToCart(productID)
    }
  }, [subIndex, proceedStatus])

  function getProducts(catId, searchText, page) {
    userService.getProducts(catId, searchText, page).then((response) => {
      setIsLoading(false);
      if (response.data.status == 200) {
        setProducts(response.data.data);
        setSubs(response.data.subscriptions)
        let btnStatus = cartItems.filter(cartItem => cartItem === response?.data?.data[0]?._id).length > 0;
      } else {
        setProducts([]);
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
      setProducts([]);
    });
  }


  function selectSub(id) {
    if (id != subIndex)
      setSubIndex(id)
    else { setSubIndex(0) }
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setserviceOther(value)
  };

  const handleSelect = async (valueOfLocation, placeId) => {
    const results = await geocodeByAddress(valueOfLocation);
    const latLng = await getLatLng(results[0]);
    setAddress(valueOfLocation)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    Country.getAllCountries().forEach(item => {
      if (countryselect == item.name) {
      }
    })
  }

  function subShowModal(haveSubscription, id) {
    if (cartItems.length > 0) {
      window.location.pathname = '/shopping-cart'
      return
    }
    setHaveSubscription(haveSubscription)
    setProductID(id)

    if (haveSubscription == true) {
      setTimeout(() => {
        handleSave()
      }, 500)
    }
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;

    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }
    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    setuserDetails((prevState) => ({
      ...prevState,
      service: [...prevState.service, serviceOther],
    }));

    setShow(false);
    // Scroll to the element with the ID "myInput"
    const element = document.getElementById("myInput");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // Perform any additional actions you need with the 'service' value.
  };

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleSumit(e) {
    e.preventDefault();
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!EMAILREGEX.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    // else if (!userDetail?.service) {
    //   Swal.fire("Warning", 'service field can’t be empty.', "warning")
    //   return
    // }
    else if (!userDetail?.service?.filter(item => item)?.length) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }
  let preview;

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service,
      message: userDetail?.message,
      page_type: "INGREDIENTS"?.toLowerCase()
    }
    setIsLoading(true)
    userService.contactUs(params).then((res) => {
      setIsLoading(false)
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: [""],
            message: ""
          });
        })
        setuserDetails({
          email: "",
          name: "",
          PhoneNumber: "",
          companyName: "",
          country: "",
          service: [""],
          message: ""
        });
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function getCartProducts() {
    setIsLoading(true);
    userService.getCartProducts().then((response) => {
      setIsLoading(false);
      if (response?.data?.status == 200) {
        let tmpArry = [];
        setCartCount(response?.data?.cart?.lineitems?.length);
        if (response?.data?.cart?.lineitems) {
          response?.data?.cart?.lineitems?.map((product) => {
            tmpArry.push({ price: product?.qty * product?.product?.price, qty: product?.qty, subscription: product.subscription, subscription_type: product.subscription_type, discount: product?.product?.discount });
          });
        }
        let sum=0;
        priceRef.current = tmpArry
        setPrices(tmpArry);
        tmpArry?.map((item) => {
          let value = (item?.price - (item?.discount * item?.price / 100))
          sum = sum + value
        });
        console.log("sum---------",sum)
        getTaxByStripe(sum)
        setCartProducts(response?.data?.cart?.lineitems);
        setSimilarProducts(response?.data?.similarProducts);
        setTax(response?.data?.tax);
        setShipping(response?.data?.shipping);
        setCartItems(response?.data?.cart?.products);
        setPriceStatus(true)
      } else {
        setCartCount('');
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  console.log("setProducts-------",products)

  function handleQuantity(i, type, qty, price, id) {
    var tmpArry = [...prices];
    let tmpQty
    if (type === 'INC' && qty < products[0]?.quantity ) {
      tmpQty = qty + 1;
      console.log("tmpQty=========",tmpQty)
    }
     else if (type === 'DEC' && qty > 1) {
      tmpQty = qty - 1;
    } else {
      return
    }
    let sum=0;
    tmpArry[i] = { ...tmpArry[i], price: tmpQty * price };
    tmpArry[i].qty = tmpQty;
    setPrices(tmpArry);
    console.log("tmpArry===========",tmpArry)
    priceRef.current = tmpArry
    setLoadingStates(loadingStates + 1);
    tmpArry?.map((item) => {
      let value = (item?.price - (item?.discount * item?.price / 100))
      sum = sum + value
    });
    console.log("sum---------",sum)
    getTaxByStripe(sum)
    
    updateQuantity(type, id)
  }

  function updateQuantity(type, id) {
    console.log("type, id",type, id)
    setIsLoading(true);
    let params = { type: type, lineitem: id }
    userService.updateQuantity(params).then((response) => {
      setIsLoading(false);
      if (response?.data?.status == 200) {
        console.log("orderDetail?.tax-------",response)
        
        if (type == 'DEL') {
          window.location.reload()
        }
      } else {
        toast.error("Some Error Occur");
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  function getTotalPrice() {
    let sum = 0;
    prices.map((item) => {
      let value = (item.price - (item.discount * item.price / 100))
      sum = sum + value
    });
    return sum;
  }

  function getTax() {
    return parseFloat(((getTotalPrice() - getSubDiscount()) * tax) / 100).toFixed(2);
  }

  function getSubDiscount() {
    let sum = 0;
    prices.forEach((item) => {
      let value = item.subscription ? (item.price - (item.discount * item.price / 100)) / 100 * parseFloat(item.subscription_type.save_percent) : 0
      sum = sum + value
    });
    return sum.toFixed(2);
  }

  function getGrandTotalPrice() {
    let gPrice = getTotalPrice();
    let taxVal = taxvalue && taxvalue?.map((tax, index) =>{
      console.log("tax-------",(tax?.amount)/100);
      gPrice += (tax?.amount)/100;
    })
    let shippingVal = 0;
    gPrice += shippingVal;
    gPrice -= getSubDiscount()
    return gPrice;
  }


  function handleFavourite(id, status) {
    if (userId) {
      var answer = true;
      if (status) answer = window.confirm("Are you sure want to remove it from your favourite list.");
      if (answer) {
        setIsLoading(true);
        let params = { user: userId, product: id, is_favourite: !status }
        userService.updateFavourite(params).then((response) => {
          setIsLoading(false);
          if (response.data.status == 200) {
            setFavCount(response.data.favCount);
            getCartProducts()
            setTemp(favCount)
          } else {
            toast.error("Some Error Occur");
          }
        }).catch((error) => {
          setIsLoading(false);
        });
      }
    } else {
      window.location.pathname = '/signin'
    }
  }
  function getDiscountedPrice(item) {
    return (item.price - (item.discount / 100) * item.price).toFixed(2)
  }
  function getDiscountedPriceWithSub(item) {
    let discountedPrice = (item.product.price - (item.product.discount / 100) * item.product.price).toFixed(2)
    let finalPrice = item.subscription ? discountedPrice * item.subscription_type.save_percent / 100 : 0
    return discountedPrice - finalPrice
  }

  function addToCart(id) {
    if (window.confirm("Are you sure you want to add this product?")) {
      if (userId) {
        setIsLoading(true);
        let params = { user: userId, product: id, qty: 1 }
        userService.addToCart(params).then((response) => {
          setIsLoading(false);
          if (response.data.status == 200) {
            setCartCount(response.data.cartCount);
            getCartProducts();
            setTemp(cartCount)
            toast.success("Product added to cart successfully.")
          } else {
            toast.error("Some Error Occur");
          }
        }).catch((error) => {
          setCartCount('');
          setIsLoading(false);
        })

      } else {
        window.location.pathname = './signin';
      }
    }
  }

  function goToCart() {
    window.location.href = window.location.origin + '/cart';
  }

  function handleSubscribeProduct() {
    if (cartProducts[0]?.subscription == false) {
      setSave(true)
    }
  }

  const getTotalQty = (index) => {
    return <span>{prices[index]?.qty}</span>;
  }

  

  function getTaxByStripe(amount) {
    const params = {
      amount: amount,
    }
    userService.getStripeTax(params).then((res) => {
      if (res?.data?.status === 200) {
        setTaxValue(res?.data?.data?.tax_breakdown)
      }
    })
      .catch((error) => {
      })
  }

  console.log("getTotalPrice ",getTotalPrice())
  console.log("getGrandTotalPrice ",getGrandTotalPrice())

  return (
    <>
      {isLoading && <Loader />}
      <Header />
      <section className="shopping-cart-sec burden-disease">
        <div className="container">
          <div className="banner-text">
            <h1 className="text-center">Your Shopping Cart</h1>
          </div>
          <div className="row">
            {cartProducts && cartProducts?.length > 0 ? cartProducts.map((cartProduct, i) => {
              return (
                <aside className="col-lg-8">
                  <div className="shopping-cart-wrap shopping-cart-left">
                    <div className="row align-items-center">
                      <aside className="col-md-4">
                        <div className="product-box ">

                          <img
                            src={
                              require("../images/update/Ing-product.png").default
                            }
                            alt="img"
                          />
                        </div>
                      </aside>
                      <aside className="col-md-8">
                        <div className="product-box shopping-added-product">
                          <h6 className="replace-text">{products[0]?.category?.name}</h6>
                          <h5>{cartProduct?.product?.name}</h5>
                          <p><b>Price:  {cartProduct.product?.discount > 0 && <del>${cartProduct.product.price}</del>} $ {priceStatus == true && getDiscountedPrice(cartProduct.product)}</b></p>
                          <p ><b>{cartProduct?.subscription == true && `Subscribed(-${cartProduct?.subscription_type.save_percent}%)`}</b></p>
                          {products[0]?.category?.isFoodCategory == false && <p><b>Size: {cartProduct?.size ? cartProduct?.size : ""}</b></p>}
                          {cartProduct?.color &&
                            <div className="color_row shopping-clr">
                              <div className="color_row">
                                <span
                                  style={{ backgroundColor: cartProduct?.color }}
                                >
                                </span>
                              </div>
                            </div>}
                          <div class="product-count">
                            <button
                              type="button"
                              className="button-count no-active"
                              onClick={() => {
                                if (prices[i]?.qty > 1) {
                                  handleQuantity(i, 'DEC', prices[i]?.qty, cartProduct.product?.price, cartProduct._id);
                                } else {
                                  Swal.fire({
                                    title: 'Are you sure you want to delete this product from your cart?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'No',
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      updateQuantity('DEL', cartProduct._id);
                                    } else {
                                    }
                                  });
                                }
                              }}
                            >
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            </button>
                            {getTotalQty(i)}
                            <button type="button" class="button-count" onClick={() => {
                              handleQuantity(i, 'INC', prices[i]?.qty, cartProduct.product?.price, cartProduct._id)
                            }}>
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                          {priceStatus && <h4 className="blue-text mt-3"><b>${getTotalPrice().toFixed(2)}</b></h4>}
                        </div>
                      </aside>
                      <div className="shopping-btns">


                      </div>
                    </div>
                  </div>
                </aside>)
            }) :
              <>
                <div className="no-item-area">
                  <img src={require("../../src/images/update/no-item-cart.png").default} />
                  <p>No Items on cart.</p>
                </div>
              </>}

            {cartProducts && cartProducts?.length > 0 &&
              <aside className="col-lg-3 summry">
                <div className="card shopping-details">
                  <div className="card-body">
                    <dl className="dlist-align">
                      <dt>Total price:</dt>
                      {priceStatus && <dd className="text-right ml-3">{"$ "+getTotalPrice().toFixed(2)}</dd>}
                    </dl>
                    {priceStatus && <dl className="dlist-align">
                      <dt>Discount:</dt>
                      <dd className="text-right text-success ml-3">-${getSubDiscount()}</dd>
                    </dl>}

                    <dl className="dlist-align">
                      <dt>Total Service Tax:</dt>
                    </dl>
                    <dl className="">
                      {taxvalue && taxvalue?.map((tax, index) => {
                        console.log("tax loop", tax?.amount / 100);
                        return (
                          <div className="dlist-align" key={index}>
                            <dt>Tax Type({tax?.tax_rate_details?.tax_type}):</dt>
                            <dd className="text-right text-success ml-3">
                              ${(tax?.amount / 100).toFixed(2)}
                            </dd>
                          </div>
                        );
                      })}
                    </dl>

                    <dl className="dlist-align">
                      <dt>Shipping:</dt>
                      <dd className="text-right ml-3 pending-text" style={{ color: 'red' }}>{'pending'}</dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Grand Total:</dt>
                      {priceStatus && <dd className="text-right b ml-3"> <strong><p>{"$ "+getGrandTotalPrice().toFixed(2)}</p></strong></dd>}
                    </dl>
                    <hr /> <span onClick={() =>
                     history.push({
                      pathname:'checout-updated',
                      state:taxvalue
                    })
                    } className="btn chkoutbtn w-100 shadow">Checkout</span>
                  </div>
                </div>

              </aside>
            }
          </div>
        </div>
      </section>

      <Modal show={save} onHide={handleCloseSave} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "grey" }}>SUBSCRIBE & SAVE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="subscribe_section" style={{ display: btnStatus ? 'none' : 'block' }}>
            <div className="container">

              {subs.length > 0 && <div className="row">

                <div className="col-md-12 mb-2">
                  <div className="subs_firstbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[0]?._id}
                          onClick={(e) => selectSub(subs[0]._id)} type="radio" name="exampleRadios4" id="exampleRadios4" />
                      </div>
                    </span>
                    <h5>EVERY 30 DAYS</h5>
                    <h4>& SAVE 20%</h4>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="subs_secondbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[1]._id}
                          onClick={(e) => selectSub(subs[1]._id)} type="radio" name="exampleRadios2" id="exampleRadios2" />
                      </div>
                    </span>
                    <h5>EVERY 45 DAYS</h5>
                    <h4>& SAVE 15%</h4>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="subs_thirdbox subscribe_list">
                    <span>
                      <div class="form-check pl-0">
                        <input class="form-check-input" checked={subIndex == subs[2]._id}
                          onClick={(e) => selectSub(subs[2]._id)} type="radio" name="exampleRadios3" id="exampleRadios3" />
                      </div>
                    </span>
                    <h5>EVERY 60 DAYS</h5>
                    <h4>& SAVE 10%</h4>
                  </div>
                  <div className='add-skip-btn mt-3 text-center'>
                    <button className='btn add_subs_btn mr-2' onClick={() => {
                      if (subIndex != 0)
                        // addToCart(productID)
                        addToCart(products[0]._id)
                      else
                        toast.error('Please select a subscription first', {
                          toastId: 'success_draft_1',
                        })
                    }}>Add</button>
                    <button className='btn skip_subs_btn' onClick={() => {
                      setProceedStatus('SKIP_SUB')
                      setSubIndex(0);
                    }}>Skip</button>
                  </div>
                </div>

              </div>}
            </div>
          </section>

        </Modal.Body>
      </Modal>


      {/* contact us section start */}

      <Footer />
    </>
  );
};
export default ShoppingCart;
