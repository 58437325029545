import React, { useRef } from 'react'
import { useEffect, useState } from "react";
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { userService } from '../services';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import Swal from 'sweetalert2';
import { config } from '../config/config';
import { getMinutes, getSeconds, parseISO } from 'date-fns';
import moment from 'moment';
import mtz from 'moment-timezone';
import Loader from './common/Loader';

const HealthMDDetails = () => {
  const calendarRef = useRef();
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [choosenDate, setChoosenDate] = useState(new Date());
  const choosenDateRef = useRef()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [professionalData, setprofessionalData] = useState("")
  const [showtime, setShowtime] = useState(false);
  const [userInformation, setUserInformation] = useState("")
  const [appointmentDate, setAppointmentDate] = useState("")
  const id = window?.location?.pathname?.split("/")[2]
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [user, setUserId] = useState(localStorage.getItem("user_id") || "")
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [localTime, setLocalTime] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  useEffect(() => {
    getMDDetail(id);
    localStorage.removeItem("selectedTabAppointment");
    localStorage.removeItem("selectedTab");

    // console.log("default time--",defaultDate,choosenDate)

    getAppointmentDetail(defaultDate, id, user);
  }, []);


  const [selectedSlot, setSelectedSlot] = useState(null);
  let selectedSlotRef = useRef()
  let selectedSlotRef2 = useRef()
  let selectedSlotRefEnd = useRef()
  let actualTimeZone = useRef()
  let userBrowserTimeZoneRef = useRef()


  const handleSlotClick = (slot) => {
    const currentDate = moment(choosenDate).format('YYYY-MM-DD');
    selectedSlotRef.current = currentDate + " " + slot?.start;
    const outputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    const convertedTime = moment(selectedSlotRef.current, 'YYYY-MM-DD HH:mm').format(outputFormat);
    setLocalTime(convertedTime);
    let proUserTimeZone = moment.utc(currentDate + " " + slot?.start + `:00.000`);
    console.log("proUserTimeZone------", proUserTimeZone)
    let userBrowserTimeZone = moment.tz(currentDate + " " + slot?.start + `:00.000`, professionalData?.userTimezone);
    userBrowserTimeZone = userBrowserTimeZone.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const formattedDateUTCProUser = proUserTimeZone.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
    console.log('formattedDateUTCProUser', formattedDateUTCProUser, proUserTimeZone.toISOString())
    actualTimeZone.current = formattedDateUTCProUser;
    const formattedDate = userBrowserTimeZone.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
    console.log("formattedDate=======", formattedDate)
    userBrowserTimeZoneRef.current = formattedDate;

    console.log("userBrowserTimeZone======", userBrowserTimeZone)
    // show time
    // var appointmentTimeUTC = moment.utc(currentDate + " " + slot?.start + `:00.000`, professionalData?.userTimezone);
    // var appointmentTimeUTCEnd = moment.utc(currentDate + " " + slot?.end + `:00.000`, professionalData?.userTimezone);
    // var appointmentTimeUTC = moment.tz(currentDate + " " + slot?.start + `:00.0000${professionalData?.timezoneOffset}`, "UTC");
    var appointmentTimeUTC = moment.tz(`${currentDate} ${slot?.start}`, professionalData?.userTimezone).utc().format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
    var appointmentTimeUTCEnd = moment.tz(`${currentDate} ${slot?.end}`, professionalData?.userTimezone).utc().format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
    // var appointmentTimeUTCEnd = moment.tz(currentDate + " " + slot?.end + `:00.0000${professionalData?.timezoneOffset}`, "UTC");
    console.log("appointmentTimeUTC----------", appointmentTimeUTC, "appointmentTimeUTCEnd-------", appointmentTimeUTCEnd)
    const formattedDateUTC = appointmentTimeUTC
    selectedSlotRef2.current = formattedDateUTC;
    // const formattedDateUTCEnd = appointmentTimeUTCEnd.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
    const formattedDateUTCEnd = appointmentTimeUTCEnd
    selectedSlotRefEnd.current = formattedDateUTCEnd;



    setSelectedSlot(slot);

    const localDateTime = moment();
    const targetDateTime = localDateTime.clone().tz(professionalData?.userTimezone).format();
    const localTimeMs = localDateTime.valueOf();
    const targetTimeMs = moment(targetDateTime).valueOf();

    if (localTimeMs <= targetTimeMs) {
      const timeDifference = targetTimeMs - localTimeMs;
      const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
      const relativeTime = relativeTimeFormatter.format(
        timeDifference / 1000, // Convert to seconds
        'second'
      );
    } else {
    }
  };


  // old time handling
  // const handleSlotClick = (slot) => {
  //   const currentDate = moment(choosenDate).format('YYYY-MM-DD');
  //   selectedSlotRef.current = currentDate + " " + slot?.start;

  //   const outputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
  //   const convertedTime = moment(selectedSlotRef.current, 'YYYY-MM-DD HH:mm').format(outputFormat);
  //   setLocalTime(convertedTime);

  //   let proUserTimeZone = moment.tz(currentDate + " " + slot?.start + `:00.0000${professionalData?.timezoneOffset}`, professionalData?.userTimezone);
  //   let userBrowserTimeZone = moment.tz(currentDate + " " + slot?.start + `:00.0000${professionalData?.timezoneOffset}`, Intl.DateTimeFormat().resolvedOptions().timeZone);
  //   var appointmentTimeUTC = moment.tz(currentDate + " " + slot?.start + `:00.0000${professionalData?.timezoneOffset}`, "UTC");
  //   var appointmentTimeUTCEnd = moment.tz(currentDate + " " + slot?.end + `:00.0000${professionalData?.timezoneOffset}`, "UTC");

  //   const formattedDateUTC = appointmentTimeUTC.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
  //   selectedSlotRef2.current = formattedDateUTC;
  //   const formattedDateUTCEnd = appointmentTimeUTCEnd.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
  //   selectedSlotRefEnd.current = formattedDateUTCEnd;

  //   const formattedDateUTCProUser = proUserTimeZone.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
  //   actualTimeZone.current = formattedDateUTCProUser;

  //   const formattedDate = userBrowserTimeZone.format('dddd, MMMM DD, YYYY [at] h:mm:ss A');
  //   userBrowserTimeZoneRef.current = formattedDate;

  //   setSelectedSlot(slot);

  //   const localDateTime = moment();
  //   const targetDateTime = localDateTime.clone().tz(professionalData?.userTimezone).format();
  //   const localTimeMs = localDateTime.valueOf();
  //   const targetTimeMs = moment(targetDateTime).valueOf();

  //   if (localTimeMs <= targetTimeMs) {
  //     const timeDifference = targetTimeMs - localTimeMs;
  //     const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  //     const relativeTime = relativeTimeFormatter.format(
  //       timeDifference / 1000, // Convert to seconds
  //       'second'
  //     );
  //   } else {
  //   }
  // };

  function getMDDetail(id) {
    setIsLoading(true)
    userService.getMDDetail(id).then((res) => {
      if (res?.data?.status === 200) {
        let ProviderTimeZone = convertTZ(defaultDate, res?.data?.data?.userTimezone)
        console.log("ProviderTimeZone", ProviderTimeZone)
        // setChoosenDate(ProviderTimeZone)
        const staringDate = convertTZ(defaultDate, "UTC").toISOString().split("T")[0];
        setprofessionalData(res?.data?.data)
        setIsLoading(false)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }
  const handleDateClick = (date) => {
    const dateIndex = selectedDates.findIndex((d) => d.getTime() === date.getTime());
    if (dateIndex === -1) {
      setSelectedDates([...selectedDates, date]);
    } else {
      const updatedDates = [...selectedDates];
      updatedDates.splice(dateIndex, 1);
      setSelectedDates(updatedDates);
    }
  };



  const isDateDisabled = (date) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const matchingSlot = professionalData?.userSlots?.find(
      (slot) => days[date.getDay()] === slot.day && slot.status === 'closed'
    );
    if (date.getFullYear() === new Date().getFullYear() &&
      date.getMonth() === new Date().getMonth() &&
      date.getDate() === new Date().getDate()) {
      return false;
    }
    return !!matchingSlot;
  };

  const tileContent = ({ date }) => {
    const stringDate = selectedDate?.toISOString().split('T')[0];
    if (date < new Date()) {
      return null;
    }
    if (isDateDisabled(date)) {
      return null;
    }
    // Display a dot for the default selected date only if it has a slot.
    const isDefaultSelectedDate = date.getDate() === choosenDate.getDate() && date.getMonth() === choosenDate.getMonth() && date.getFullYear() === choosenDate.getFullYear();
    const isDefaultSelectedDateDot = isDefaultSelectedDate && professionalData?.userSlots?.some((slot) => slot.day === date.getDay() && slot.status === 'open');

    // Display a dot for the current date, even if it does not have a slot.
    const currentDay = new Date();
    const isCurrentDate = date.getDate() === currentDay.getDate() && date.getMonth() === currentDay.getMonth() && date.getFullYear() === currentDay.getFullYear();
    const isCurrentDateDot = isCurrentDate && !professionalData?.userSlots?.some((slot) => slot.day === date.getDay() && slot.status === 'open');
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const matchingSlot = professionalData?.userSlots?.find(
      (slot) => (days[date.getDay()]).toUpperCase() === slot.day && slot.status === 'open'
    );

    return matchingSlot || isCurrentDateDot || isDefaultSelectedDateDot ? <span className='dot-calender'></span> : null;
  };

  let todayCurrentDate = new Date()
  const formattedDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");



  function getAppointmentDetail(splitTime, id, user) {
    console.log("splitTime========", splitTime)
    const formattedDate = moment(splitTime).format('YYYY-MM-DD');
    setIsLoading(true)
    userService.getAppointment_Detail(formattedDate, id, user).then((res) => {
      if (res?.data?.status === 200) {
        setAppointmentDate(res?.data?.data)
        setIsLoading(false)

      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const formatTime = (time) => {
    const hours = parseInt(time?.split(':')[0], 10);
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${time?.split(':')[1]} ${ampm}`;
  };


  function BookAppointment() {

    if (!localStorage.getItem("user_id")) {
      Swal.fire("Warning", "To perform this action, login is required.", "warning").then((res) => {
        window.location.href = "/signin";
        let redirectUri = window.location.pathname + window.location.search;
        localStorage.setItem("redirectUri", redirectUri);
      })
      return
    }




    const params = {
      bookingDate: moment(selectedSlotRef2.current, "dddd, MMMM DD, YYYY [at] h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      bookingEndDate: moment(selectedSlotRefEnd.current, "dddd, MMMM DD, YYYY [at] h:mm:ss A").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      notes: userInformation,
      medUser: professionalData?._id,
      date: localTime,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    setIsLoading(true)
    userService.book_appointment(params).then((res) => {
      if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          window.location.href = `/profilenew?activeMenu=${"appointment"}&appointmentStatus=${true}`
        })
        setIsLoading(false)
      }
    })
      .catch((Error) => {
        if (Error?.response?.status == 401) {
          Swal.fire("Warning", Error?.response?.data, "warning").then((e) => {
            window.location.href = "/signin";
            let redirectUri = window.location.pathname + window.location.search;
            localStorage.setItem("redirectUri", redirectUri);
          })
        }
        else {
          Swal.fire("Warning", Error?.response?.data?.message, "warning")
        }
        setIsLoading(false)
      })
  }
  const handleContinueClick = (e) => {
    BookAppointment()
  };

  const handleChangeDate = (date) => {
    setChoosenDate(date);
    setSelectedSlot(null); // Clear selectedSlot when switching to another date
    setUserInformation("")
    choosenDateRef.current = date;
    getAppointmentDetail(date, id, user);
  };
  let currentOffset = (new Date()).getTimezoneOffset();

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med py-4">
        <div className="container booking-detail-area">
          <div class="big-med-heading d-flex align-items-start justify-content-between w-100">
            <h1>Booking Details</h1>
          </div>
          <div className='booking-box notranslate'>
            <div className='booking-inner-box d-flex'>
              <img
                onClick={(e) => window.location.href = `/profile/${professionalData?.userSlug}`}
                className="booking-inner-profile mr-2" src={professionalData?.profilePicture && professionalData?.profilePicture !== "undefined" && professionalData?.profilePicture !== undefined ? professionalData?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" />
              <div className='booking-text'>
                <h4>{professionalData?.name}</h4>
                <p>{professionalData?.professionalType}</p>
                <p>{professionalData?.category?.name}</p>
                <p className="mb-0"><img src={require("../images/update/brief-one.svg").default} alt="img" className="mr-2" /> {professionalData?.experience ? "+" + professionalData?.experience + `Years` : "N/A"} </p>
                <p className="mb-0"><img src={require("../images/update/globe.svg").default} alt="img" className="mr-2" />{professionalData?.communicationLanguage ? professionalData?.communicationLanguage : "N/A"}</p>
                <p className="mb-0"><img src={require("../images/update/clock.svg").default} alt="img" className="mr-2" />{professionalData?.userTimezone ? professionalData?.userTimezone : "N/A"}</p>
                <div className="profile-top-cons-area">
                  {professionalData && professionalData?.linkedInUrl && professionalData?.linkedInUrl !== "null" &&
                    <div className="social-btn">
                      <button className="btn btn-sm btn-facebook" data-toggle="tooltip" title={professionalData?.linkedInUrl}
                        onClick={(e) => window.open(professionalData?.linkedInUrl, '_blank')}>
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </button>
                    </div>
                  }
                  {professionalData && professionalData?.websiteUrl && professionalData?.websiteUrl !== "null" && (
                    <div className="social-btn">
                      <a
                        className="btn btn-sm twt-btn"
                        href={professionalData.websiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={professionalData?.websiteUrl}
                      >
                        <img src={require("../images/update/web-icon.png").default} alt="Website Icon" />
                      </a>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>
          <Row className='select-date-area'>
            <Col md={12} lg={7}>
              <h2 className='mt-3'>Select Date & Time</h2>
              <div className="calendar-container notranslate">
                <Calendar
                  ref={calendarRef}
                  selected={choosenDate}
                  onChange={handleChangeDate}
                  minDate={new Date()}
                  tileDisabled={({ date }) => isDateDisabled(date)}
                  tileContent={tileContent}
                />
              </div>
            </Col>
            <Col md={12} lg={5}>
              <div className='timestamp-box notranslate'>

                <h5 className='mt-3'>{appointmentDate[0]?.day}</h5>
                {
                  appointmentDate[0]?.slotBroken?.length > 0 ?
                    appointmentDate[0]?.slotBroken?.map((item, key) => {
                      let currentDate = choosenDate;
                      // let currentTime = new Date();
                      let currentTime = moment.tz(new Date(), professionalData?.userTimezone);
                      let currentTimeUnix = currentTime.valueOf();
                      let currentTimeUTC = moment(currentTime).utc();
                      // console.log("currentTimeUTC",currentTimeUTC.format("DD-MM-YYYY-HH:MM"))
                      let timeGap = 0;
                      let startTime = new Date(choosenDate.toDateString() + " " + item.start);
                      var utcDate = startTime;
                      // if (currentOffset > 0) {
                      //   timeGap = professionalData?.timeOffset + Math.abs(currentOffset);
                      //   utcDate = new Date(startTime.getTime() - timeGap * 60 * 1000)
                      // } else if (currentOffset < 0) {
                      //   timeGap = Math.abs(currentOffset) - professionalData?.timeOffset;
                      //   utcDate = new Date(startTime.getTime() + timeGap * 60 * 1000)
                      // }
                      let convertProviderTime = startTime;

                      console.log("Current time in :" + professionalData?.userTimezone, moment(currentTime.format("YYYY-MM-DD HH:mm:ss")));
                      console.log("Current time for each slot --- ", moment(utcDate));
                      console.log("Current time match result", moment(currentTime.format("YYYY-MM-DD HH:mm:ss")).isBefore(moment(utcDate)) ? 'yes' : 'no')
                      // console.log("my date for disable--->",utcDate.getTime() > currentTime.getTime(),"currentTime.getTime()",currentTime.getTime(), "utcDate.getTime()",utcDate.getTime() );
                      // console.log("current time, get time-->",utcDate.getTime() < currentTimeUnix , "utcDate========",utcDate.getTime(), "currentTimeUnix",currentTimeUnix, currentOffset, professionalData?.timeOffset)
                      return (
                        <>
                          <span
                            key={key}
                            // className={`time-inner-box mr-2 ${item?.available == "YES" && utcDate.getTime() < currentTimeUnix ? "" : "disabled"}`}
                            className={`time-inner-box mr-2 ${item?.available == "YES" && moment(currentTime.format("YYYY-MM-DD HH:mm:ss")).isBefore(moment(utcDate)) ? "" : "disabled"}`}
                            onClick={() => (item?.available == "YES" && moment(currentTime.format("YYYY-MM-DD HH:mm:ss")).isBefore(moment(utcDate))) ? handleSlotClick(item) : ''}
                            disabled={item?.available == "No"}
                          >
                            <span className={` ${item?.available == "YES" && moment(currentTime.format("YYYY-MM-DD HH:mm:ss")).isBefore(moment(utcDate)) ? '' : "disable-slot"} ${selectedSlot === item && item?.available == "YES" ? 'active' : ""}`}>{formatTime(item?.start)} - {formatTime(item?.end)}</span>
                          </span>
                        </>
                      )
                    }) :
                    <>
                      <div className='no-available-slots'>
                        <img src={require("../../src/images/update/no-data.png").default} />
                        <p>No slots available</p>
                      </div>
                    </>
                }
              </div>
            </Col>
          </Row>
          <Row>
            {selectedSlot ? (
              <div className='select-time-slot'>
                <h6 className='mb-0'>Healthcare Provider Time</h6>
                <Row>
                  <Col md={4}>
                    <div className='time-inner-box '>
                      <div>
                        <span className={`active ${selectedSlot === selectedSlotRef.current ? 'selected' : ''}`}>
                          {(actualTimeZone.current) + ' ' + (professionalData?.userTimezone)}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <h6 className='mb-0'>Converted Slot in Local Time</h6>
                    <div className='time-inner-box '>
                      <div>
                        <span className={`active ${selectedSlot === selectedSlotRef.current ? 'selected' : ''}`}>
                          {(userBrowserTimeZoneRef.current) + ' ' + userTimezone}
                        </span>
                        {appointmentDate?.timezone}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </Row>

          <h2 className='mt-3 additional-info'>Health concerns</h2>
          <div className='information-box'>
            <textarea className='form-control' onChange={(e) => setUserInformation(e.target.value)}
              onKeyDown={(e) => {
                if (!e.target.value && e.code === "Space") {
                  e.preventDefault();
                }
              }}
              maxLength={500}
              placeholder='Describe your health concerns here...'>
            </textarea>
          </div>
          <div className='action-btns mt-3 additional-action-btn'>
            <button
              className={`cancel-bnt join-button mr-2`}
              onClick={(e) => {
                if (!userInformation || !selectedSlot) {
                  window.location.href = "/health-md";
                }
                else {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'Your saved appointment will not be booked if you go back',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = "/health-md";
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                    }
                  });
                }

              }}
            >Back</button>
            <button
              className={`join-button ${(!userInformation || !selectedSlot) ? 'disabled' : ''}`}
              onClick={handleContinueClick}
              disabled={!userInformation || !selectedSlot}
            >
              Book Medical Chat
            </button>

          </div>


        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='appointment-box text-center'>
            <img src={require("../images/update/request-sent.svg").default} alt="img" className="mr-2" />
            <h4 className='mt-3'>Appointment request sent successfully. </h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default HealthMDDetails;