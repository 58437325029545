import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import Paginate from './common/Pagination';
import { config } from '../config/config'
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download';
import ShareOnSocialMedia from './common/ShareOnSocialMedia';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const Product = () => {

    const [products, setProducts] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [pageNo, setPageNo] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [userId, setUserId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [cartCount, setCartCount] = useState('');
    const [favCount, setFavCount] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [temp, setTemp] = useState('0')

    const [proceedStatus, setProceedStatus] = useState('');
    const [subIndex, setSubIndex] = useState(0)
    const [show, setShow] = useState(false);
    const [subs, setSubs] = useState([]);
    const [btnStatus, setBtnStatus] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [haveSubscription, setHaveSubscription] = useState('');
    const [productID, setProductID] = useState('')

    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);
        const urlParams = new URLSearchParams(window.location.search);
        const catId = urlParams.get('id');
        setCategoryName(localStorage.getItem('cat_name'));
        if (catId) {
            setIsLoading(true);
            setCategoryId(catId);
            getProducts(catId, searchText, 0);
            getCartProducts();
        }
    }, []);

    useEffect(() => {
        if (subIndex == 0 && proceedStatus == 'SKIP_SUB') {
            setProceedStatus('')
            addToCart(productID)
        }
    }, [subIndex, proceedStatus])

    function getProducts(catId, searchTxt, page) {
        userService.getProducts(catId, searchTxt, page).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                // console.log("getProducts", response.data.data)
                setProducts(response.data.data);
                setSubs(response.data.subscriptions)
                setTotalCount(response.data.totalRecords);
            } else {
                setProducts([]);
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setProducts([]);
            // console.log("error ", error);
        });
    }

    function getCartProducts() {
        setIsLoading(true)
        userService.getCartProducts().then((res) => {
            if (res.status === 200) {
                setIsLoading(false)
                if (res.data.cart.products)
                    setCartItems(res.data.cart.products)
            }
        }).catch((er) => { })
    }

    function handleFavourite(id, status) {
        if (userId) {
            var answer = true;
            if (status) answer = window.confirm("Are you sure want to remove it from your favourite list.");
            if (answer) {
                setIsLoading(true);
                let params = { user: userId, product: id, is_favourite: !status }
                userService.updateFavourite(params).then((response) => {
                    setIsLoading(false);
                    if (response.data.status == 200) {
                        setFavCount(response.data.favCount);
                        getProducts(categoryId, searchText, pageNo);
                        setTemp(favCount);
                        // setProducts(response.data.delquizesData);
                        // setTotalCount(response.data.totalRecords);
                    } else {
                        // setProducts([]);
                        toast.error("Some Error Occur");
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    // setProducts([]);
                    // console.log("error ", error);
                });
            }
        } else {
            window.location.pathname = '/signin'
        }
    }

    function selectSub(id) {
        // console.log(subIndex)
        if (id != subIndex)
            setSubIndex(id)
        else { setSubIndex(0) }
    }

    function addToCart(id) {
        setShow(false)
        if (userId) {
            setIsLoading(true);
            // user: userId, product: productId, qty: quantity, subscription_type: subIndex, subscription: subIndex != 0, color, size
            let params = { user: userId, product: id, qty: 1, subscription_type: subIndex, subscription: subIndex != 0 }
            userService.addToCart(params).then((response) => {
                setIsLoading(false);
                if (response.data.status == 200) {
                    setCartCount(response.data.cartCount);
                    toast.success("Product added to cart successfully.");
                    getCartProducts();
                    setTemp(cartCount);
                } else {
                    toast.error("Some Error Occur");
                }
            }).catch((error) => {
                setCartCount('');
                setIsLoading(false);
                // console.log("error ", error);
            });
        } else {
            window.location.pathname = '/signin'
        }
    }

    function goToCart() {
        window.location.href = window.location.origin + '/cart';
    }

    function handleSearch(searchTxt) {
        if (/^[A-Za-z]*$/.test(searchTxt)) {
            setSearchText(searchTxt);
            setPageNo(0);
            getProducts(categoryId, searchTxt, 0);
        }
    }

    function handlePageChange(page) {
        setPageNo(page);
        getProducts(categoryId, searchText, page);
    }

    function subShowModal(haveSubscription, id) {
        setHaveSubscription(haveSubscription)
        setProductID(id)

        if (haveSubscription == true) {
            setTimeout(() => {
                handleShow()
            }, 500)
        }
    }

    return (
        <>
            <Header cartCount={cartCount} favCount={favCount} refresh={temp} />
            {isLoading && <Loader />}
            <section className="heading-search">
                <div className="container">
                    <h2 className='mt-2'>{categoryName ? categoryName : ''}</h2>
                    <div className="input-group search-box">
                        <input type="text" class="form-control" placeholder="Search by product name" aria-label="" aria-describedby="basic-addon1" value={searchText} onChange={(e) => handleSearch(e.target.value)} />
                        <div className="input-group-append">
                            <button className="btn" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-page-area">
                <div className="container">
                    <div className="row">
                        {/* <p className="like-favorite-box"><img src={require("../images/like.png").default} alt="img" /></p> */}
                        {/* {products.length > 0  && products.map((product) => {
                        return(<div className="col-md-4">
                                <p className="like-favorite-box"><img src={require("../images/like.png").default} alt="img" /></p>
                                <a href={"/product_details?id=" + product._id}>
                                <div className="product-list-box">
                                    <div className="product-list-image text-center">
                                        <img src={require("../images/fish_oil1.png").default} alt="img" />
                                    </div>
                                    <div className="product-list-details">
                                        <h4>WOW Life Science Omega-3 Fish Oil</h4>
                                        <h6><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i> <span className="total-review ml-1">(1.2k reviews)</span></h6>
                                        <h5>Price: <del className="orginal-amount">$15.50</del> <span className="discount-amount">$13.95</span></h5>
                                        
                                    </div>
                                </div>
                                </a>
                            </div>)
                        })} */}
                        {/* <div className="col-md-4"> */}
                        {/* <p className="like-favorite-box"><img src={require("../images/like.png").default} alt="img" /></p> */}


                        {products.length > 0 && products.map((product, i) => {
                            let btnStatus = cartItems.filter(cartItem => cartItem === product?._id).length > 0;

                            return (<div className="col-lg-3 col-md-4 col-sm-6">

                                <div className="product-list-box product-list-box-hover">
                                    <a href={"/product_details?id=" + product._id}>
                                        <div className="product-list-image text-center">
                                            <img src={product?.images.length > 0 ? config.imageUrl + product.images[0].image : ''} alt="img" />
                                        </div>
                                        <div className="product-list-details">
                                            <h4>{product.name}</h4>
                                            {product?.discount > 0 ? <h5>Price:
                                                <del className="orginal-amount">${parseFloat(product?.price).toFixed(2)}</del>
                                                <span className="discount-amount"> ${parseFloat(product?.price - (product?.price *
                                                    product?.discount / 100)).toFixed(2)}</span>
                                            </h5> : <h5>Price: <span className="discount-amount"> ${parseFloat(product?.price).toFixed(2)}</span></h5>}

                                        </div>
                                    </a>
                                    <div className="product-details">

                                        {product.quantity != 0 ?
                                            <div className="buttons d-flex flex-row">
                                                <a className="cart shadow pb-3 pointer" onClick={() => handleFavourite(product._id, product.is_favourite)}><i className={product.is_favourite ? "fa fa-heart" : "fa fa-heart-o"}></i></a>
                                                <a className="btn btn-success cart-button btn-block shadow pointer"
                                                    onClick={() => btnStatus ? goToCart() :
                                                        subIndex == 0 ?
                                                            subShowModal(product.category.haveSubscription, product._id) :
                                                            addToCart(product._id)}
                                                >
                                                    <i className="fa fa-shopping-cart mr-2" style={{ fontSize: "19px" }}></i> {btnStatus ? 'GO' : 'ADD'} TO CARTttttttt </a>

                                            </div>
                                            :
                                            <div className="buttons d-flex flex-row">

                                                <a style={{
                                                    fontSize: "16px", backgroundColor: 'white', display: 'block', width: '100%', padding: '9px',
                                                    height: '44px', borderRadius: '5px', color: 'red', justifyContent: 'center', textAlign: 'center', borderWidth: '0.2px', borderColor: 'red', border: 'solid'
                                                }}>SOLD OUT</a>

                                            </div>
                                        }
                                        <div class="weight"> </div>
                                    </div>
                                </div>

                            </div>)
                        })}

                    </div>
                    {products.length === 0 && !isLoading && <section className="product-area-box"><p className="no-categgory text-center">No Products Available. </p></section>}

                    <div style={{ width: '100%' }}>
                        <Paginate count={totalCount} activePage={pageNo} handlePageChange={(page) => handlePageChange(page)} perPageEntries={perPage} />
                    </div>

                </div>
            </section>

            <Download />
            <Footer />


            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "grey" }}>SUBSCRIBEEEEEEE & SAVE</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {haveSubscription == true ?
                        <section className="subscribe_section" style={{ display: btnStatus ? 'none' : 'block' }}>
                            <div className="container">

                                {subs.length > 0 && <div className="row">

                                    <div className="col-md-12 mb-2">
                                        <div className="subs_firstbox subscribe_list">
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" checked={subIndex == subs[0]._id}
                                                        onClick={(e) => selectSub(subs[0]._id)} type="radio" name="exampleRadios4" id="exampleRadios4" />
                                                </div>
                                            </span>
                                            <h5>EVERY 30 DAYS</h5>
                                            <h4>& SAVE 10%</h4>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <div className="subs_secondbox subscribe_list">
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" checked={subIndex == subs[1]._id}
                                                        onClick={(e) => selectSub(subs[1]._id)} type="radio" name="exampleRadios2" id="exampleRadios2" />
                                                </div>
                                            </span>
                                            <h5>EVERY 45 DAYS</h5>
                                            <h4>& SAVE 5%</h4>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <div className="subs_thirdbox subscribe_list">
                                            <span>
                                                <div class="form-check pl-0">
                                                    <input class="form-check-input" checked={subIndex == subs[2]._id}
                                                        onClick={(e) => selectSub(subs[2]._id)} type="radio" name="exampleRadios3" id="exampleRadios3" />
                                                </div>
                                            </span>
                                            <h5>EVERY 60 DAYS</h5>
                                            <h4>& SAVE 2%</h4>
                                        </div>
                                        <div className='add-skip-btn mt-3 text-center'>
                                            <button className='btn add_subs_btn mr-2' onClick={() => {
                                                if (subIndex != 0)
                                                    addToCart(productID)
                                                else
                                                    toast.error('Please select a subscription first')
                                            }}>Add</button>
                                            <button className='btn skip_subs_btn' onClick={() => {
                                                setProceedStatus('SKIP_SUB')
                                                setSubIndex(0);
                                            }}>Skip</button>
                                        </div>
                                    </div>

                                </div>}
                            </div>
                        </section> : null}

                </Modal.Body>
            </Modal>

        </>
    )
}


export default Product;