import React from 'react'
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download'
import { Col, Row } from 'react-bootstrap'

const Foodoption = () => {
    return (
        <>
            <Header />
            <section className="food_section">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-lg-9 text-right">
                            <div className='food-option-text'>
                                <h1>We bring you the right formula</h1>
                                <p>Vegetables, seeds, grains, and fruits to prevent and reverse chronic diseases.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='food-options-area'>
                <div className='container'>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="corporate-govt-box">
                                <p>Full Load Plate Breakfast</p>
                                <ul>
                                    <li>Banana (ripe), Blackberries, Cantaloupe, Fresh Coconut</li>
                                    <li>Cranberry, Dates, Figs (dried), Grapefruit</li>
                                    <li>Nectarine, Orange, Papaya</li>
                                    <li>Apples, Blackberries, Strawberries</li>
                                    <li>Apricot, Blueberry, Tangerine, Rye bread</li>
                                    <li>Cherry, Watermelon, Plum, Wheat Bread</li>
                                    <li>Gooseberry, Banana, Prickly (Cactus) Pear</li>
                                    <li>Clementines, Guava, Kiwifruit</li>
                                    <li>Cranberry, Cherimoya, Durian</li>
                                    <li>Figs, Boysenberries, Kumquat</li>
                                    <li>Grapefruit, Honeydew, Passion Fruit</li>
                                    <li>Grapes, Persimmon, Pomelo</li>
                                    <li>Mandarin orange, Rambutan, Raspberry</li>
                                    <li>Peach, Carambola (Star Fruit)</li>
                                    <li>Pear, Guanabana</li>
                                    <li>Pomegranate, Peanuts, Soursop</li>
                                    <li>Almond, Quinoa, Almond butter</li>
                                    <li>Oats, Nuts (moderate amount), Honey.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="corporate-govt-box corporate-govt-box-two">
                                <p>Moderate Load Plate Lunch</p>
                                <ul>
                                    <li>Brown rice, Lentils, Avocado</li>
                                    <li>Buckwheat, Cashews (moderate amount)</li>
                                    <li>Bulgar wheat, Macadamia Nuts (moderate amount)</li>
                                    <li>Couscous, Lima beans</li>
                                    <li>Tofu, Almond (moderate amount), Carrot</li>
                                    <li>Soybeans (Fresh), Fresh Red Beet</li>
                                    <li>Hummus, Kohlrabi, Aubergine/Egg Plant</li>
                                    <li>Pistachios (moderate amount), Potatoes</li>
                                    <li>Hazelnut (moderate amount), Red Radish</li>
                                    <li>Hazelnut (moderate amount), Rutabaga, Peppers</li>
                                    <li>Walnuts (moderate amount), Turnip</li>
                                    <li>Sweet Potatoes, Kale</li>
                                    <li>Yams, Mushrooms, Onion</li>
                                    <li>Barley, Peas, Tortillas</li>
                                    <li>Barley Grass</li>
                                    <li>Kamut Grass</li>
                                    <li>Spinach, Swiss Chard, Chia Seed</li>
                                    <li>Kidney Beans, Corn, Squash.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="corporate-govt-box">
                                <p>Limited Load Plate Dinner</p>
                                <ul>
                                    <li>Mango, Currant, Pineapple</li>
                                    <li>Breadfruit, Alfalfa, Alfalfa grass</li>
                                    <li>Flax Seeds, Pumpkin Seeds, Cucumber</li>
                                    <li>Sunflower Seeds, Artichokes, Dandelion</li>
                                    <li>Asparagus, Brussels Sprouts</li>
                                    <li>Blue-Green Algae, Cauliflower</li>
                                    <li>Bok Choy, Jicama, Seaweed</li>
                                    <li>Cabbage Lettuce, Tomatoes</li>
                                    <li>Celery, Green Beans, Parsnip</li>
                                    <li>Lettuce, Sorrel, Watercress</li>
                                    <li>Garbanzo (Chickpeas), Radicchio</li>
                                    <li>Whole Wheat Pasta, Edamame</li>
                                    <li>Plant-based milks (rice, soy, oat, almond, cashew, hemp milks)</li>
                                    <li>Lima Beans, Broccoli</li>
                                    <li>Black-eyed Peas, Zucchini</li>
                                    <li>Collards, Okra, Shallots</li>
                                    <li>Plant-based milks (rice, soy, oat, almond, cashew, hemp milks)</li>
                                    <li>Millet, Pecan (moderate amount), Sesame Seeds.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="corporate-govt-box corporate-govt-three">
                                <p>REDUCE or AVOID</p>
                                <ul>
                                    <li>White Rice.</li>
                                    <li>Bleached Flours</li>
                                    <li>White Bread</li>
                                    <li>White Pasta</li>
                                    <li>Refined Sweeteners</li>
                                    <li>Dairy Products (yogurt, cheese and butter)</li>
                                    <li>Eggs</li>
                                    <li>Meat</li>
                                    <li>Poultry</li>
                                    <li>Seafood.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <p className='text-center food-text'>Please note that foods suggested above are completely plant-based. Please share with your health care provider to see if they recommend a different one. This list contains great plant-based protein.</p>
                        </Col>
                    </Row>
                </div>
            </section>
          
            <Download />
            <Footer />
        </>
    )
}

export default Foodoption;