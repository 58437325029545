import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Country, State, City, getAllCountries } from "country-state-city";
import { userService } from "../services";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId,
} from "react-places-autocomplete";
import { config } from "../config/config";


const Quiz_new = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [quizdata, setQuizData] = useState([])
    const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: "", message: "" });
    const [quizAns, setQuizAns] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showtime, setShowtime] = useState(false);
    const handleClosetime = () => setShowtime(false);
    const handleShowtime = () => setShowtime(true);

    const currentQuestion = quizdata[currentQuestionIndex];

    useEffect(() => {
        QuizList()
    }, [])

    const handleNextQuestion = () => {
        if (currentQuestionIndex < quizdata.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };


    const handleShowtimer = (questionNumber) => {
        const timer = document.querySelector("h2");
        const remainingTime = 2 * 60; // 2 minutes
        const interval = setInterval(() => {
            remainingTime -= 1;
            timer.textContent = remainingTime;
            if (remainingTime === 0) {
                clearInterval(interval);
            }
        }, 1000);
    };

    function QuizList() {
        userService.quizList().then((res) => {
            if (res?.data?.status === 200) {
                setQuizData(res?.data?.data)
            }
        })
            .catch((error) => {
            })
    }

    function inputChange(e) {
        let { name, value } = e.target;
        setuserDetails({ ...userDetail, [name]: value });
    }

    const [questionData, setQuestiondata]=useState()
    const [answerData, setAnswerData]=useState()

    function setAnswers(ansData) {
        let temp =[...quizAns];
        temp.push(ansData);
        setQuizAns(temp);
        
    }
    const [data, setData]=useState("")
    const [questioAndAnswer, setQuestioAndAnswer] = useState([]);
    function selectAnswerOption(dataIndex,quizIndex,ansIndex) {
        let questioAndAnswerTemp =[...questioAndAnswer];
        let temp  = quizdata[dataIndex];
        setData(temp)
        let question = temp.questions[quizIndex];
        let answer = question.answers[ansIndex];
        let existingQuestionIndex = questioAndAnswerTemp.findIndex(item => item.quesId === question._id);

        if (existingQuestionIndex !== -1) {
            questioAndAnswerTemp[existingQuestionIndex].answer = answer._id;
        } else {
            questioAndAnswerTemp.push({
                quesId: question._id,
                answer: answer._id
            });
        }
        setQuestioAndAnswer(questioAndAnswerTemp);
    }

    function saveQuiz(e){
        e.preventDefault()
        const params ={
            score:quizdata.length,
            userId:localStorage.getItem("user_id"),
            quizId:data?._id,
            timeToComplete:"",
            name:data?.name,
            questions : questioAndAnswer,
        }
        userService.saveQuizData(params).then((res) =>{
            if(res?.data?.status ==200){
            }
        })
        .catch((error) =>{
        })
    }
    return (
        <>
            <Header />

            <section className="quiz-questions">
                <div className="container">
                    <h1>Question 1 of 3</h1>
                    <div className="row">

                        {quizdata && quizdata[quizAns.length] && <aside className="col-md-9">
                                <div className="quiz-question-wrap">
                                    {quizdata[quizAns.length]?.questions && quizdata[quizAns.length]?.questions?.map((que, quizIndex) => {
                                        return (
                                            <div key={quizIndex}>
                                                <h2>{que?.title}</h2>
                                                <Form className="mt-3">
                                                    {que?.answers && que?.answers?.map((choice, ansIndex) => {
                                                        return (
                                                            <div key={`${quizAns.length}-${quizIndex}-${ansIndex}` } className="mb-3">
                                                                <Form.Check
                                                                    label={choice.value}
                                                                    name={`question-${quizIndex}`}
                                                                    type="radio"
                                                                    id={`choice-${quizAns.length}-${quizIndex}-${ansIndex}`}
                                                                    onChange={()=>selectAnswerOption(quizAns.length,quizIndex,ansIndex)}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                </Form>
                                            </div>
                                        )
                                    })}
                                </div>

                            </aside>
                        }
                        <aside className="col-md-3">
                            <div className="quiz-time">
                                <img src={require("../images/update/quiz-timer.png").default} alt="img" />
                                <p>Time Left</p>
                                <h2 onClick={handleShowtime}>01:38</h2>
                                <a href="#" className="btn btn-closequiz" onClick={handleShow}>Close Quiz</a>
                            </div>
                        </aside>
                    </div>
                    <div className="quiz-submit mt-4">
                        {quizdata && quizAns && quizdata.length == quizAns.length +1 ? <span className="btn btn-submit-quiz" onClick={(e) => saveQuiz(e)}>SUBMIT</span> : quizAns && quizdata && 
                        <span className="btn btn-submit-quiz" onClick={()=> setAnswers(quizdata[quizAns.length])}>NEXT</span>}
                    </div>
                </div>
            </section>

            <section className="download-app">
                <div className="container">
                    <Row className="align-items-center">
                        <Col md={5}>
                            <div className="business-sec-img">
                                <img src={require("../images/update/download-app.png").default} alt="img" />
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="health-app-text">
                                <h1>Download the BIG4 Health App now</h1>
                                <p>Join the millions of users already benefiting from this comprehensive and personalized approach to managing chronic conditions. Take
                                    control of your health today with the BIG4 Health App.</p>
                                <div className="play-apple-store">
                                    <a href="#"><img src={require("../images/update/apple-store.png").default} alt="img" /></a>
                                    <a href="#"><img src={require("../images/update/play-store.png").default} alt="img" /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <Footer />


            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="quiz-pop quiz-close"
            >
                <Modal.Header className="m-auto q-mark">
                    <Modal.Title>?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='start-the-quiz text-center p-0'>

                    <p>Are you sure you want to exit this quiz? Once you leave, you will not have the opportunity to participate again.</p>
                </Modal.Body>
                <Modal.Footer className="border-none m-auto">
                    <Button className='btn notnowbtn' onClick={handleClose}>NO</Button>
                    <Button className='btn quiznow-btn'>YES</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showtime} onHide={handleClosetime}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="quiz-pop quiz-close timeout"
            >
                <Modal.Header closeButton className="m-auto q-mark">
                    <Modal.Title><img src={require("../images/update/time-out.png").default} alt="img" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='start-the-quiz text-center p-0'>
                    <h3>Time Out</h3>
                    <p>Quiz has expired.</p>
                </Modal.Body>
            </Modal>

        </>
    );
};
export default Quiz_new