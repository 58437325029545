import React, { useEffect, useState } from 'react'
import Header from './common/Header'
import { Swiper, SwiperSlide, Navigation, } from 'swiper/react';
import { Modal } from 'react-bootstrap';
import Footer from './common/Footer'
import { Nav, Tab, Row, Col } from 'react-bootstrap'
import { userService } from '../services';
import moment from 'moment'
import { config } from '../config/config';
import { toast } from 'react-toastify';

const My_orders = () => {
    const [lgShow, setLgShow] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [activeOrders, setActiveOrders] = useState([]);
    const [cancelledOrders, setCancelledOrders] = useState([]);
    const [pastOrders, setPastOrders] = useState([]);
    const [selectedTab, setTab] = useState('active');
    const [cancelReason, setCancelReason] = useState('');

    useEffect(() => {
        if (localStorage.getItem('user_id')) {
            if (selectedTab == 'active')
                userService.getActiveOrders().then(res => {
                    if (res.status == 200) {
                        setActiveOrders(res.data.data)
                    }
                })
            else if (selectedTab == 'past')
                userService.getPastOrders().then(res => {
                    if (res.status == 200) {
                        setPastOrders(res.data.data)
                    }
                })
            else if (selectedTab == 'cancelled')
                userService.getCancelledOrders().then(res => {
                    if (res.status == 200) {
                        setCancelledOrders(res.data.data)
                    }
                })
        } else {
            window.location.pathname = '/signin'
        }
    }, [selectedTab, modalContent])

    const cancelOrder = () => {
        if (!cancelReason) {
            toast.error('Please enter cancellation reason')
        } else {
            let params = { cancel_reason: cancelReason }
            userService.cancelOrder(modalContent._id, params).then(res => {
                setModalContent(null)
                setCancelReason("")
            })
        }
    }

    function getDiscountedPrice(item) {
        return parseFloat(item.price + item.shipping_charge + ((item.tax / 100) * item.price)).toFixed(2)
    }

    return (
        <>
            <Header />
            <section className="py-4 orders_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add_head justify-content-center d-block mb-3 text-left">
                                <h6 className="mb-0" style={{ fontSize: "20px" }}>My Orders</h6>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Tab.Container onSelect={e => setTab(e)} id="left-tabs-example" defaultActiveKey="active">
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="flex-row">
                                            <Nav.Item>
                                                <Nav.Link eventKey="active">Active</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="past">Past</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="cancelled">Cancelled</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="active">
                                                <div>
                                                    {activeOrders?.length == 0 ? <div className="no_data text-center">
                                                        <img src={require('../images/nodata.png').default} alt="" />
                                                        <h4>No data found.</h4>
                                                    </div> :
                                                        <div className="row">
                                                            {activeOrders?.map(item => {
                                                                return <div className="col-lg-3 col-md-4 mb-3">
                                                                    <div className="product-list-box mt-0">
                                                                        <a href={"./details?id=" + item._id}>
                                                                            <div className="product-list-image text-center">
                                                                                <img src={item.product?.images.length > 0 ? config.imageUrl + item.product.images[0].image : ''} alt="img" />
                                                                                <span className="confirmed">
                                                                                    <i className="fa fa-check"></i>
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                        <div className="product-list-details" >
                                                                            <h4>{item.product.name}</h4>
                                                                            <div className="d-flex confirm-box">
                                                                                <h6 className="">{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</h6>
                                                                                {item.status == 'ACT' ?
                                                                                    <span className="discount-amount" style={{ color: '#ff9933' }}>{'Pending'}</span> :
                                                                                    <span className="discount-amount">{'On The Way'}</span>
                                                                                }

                                                                            </div>
                                                                            {item.product?.discount > 0 ? <h5>
                                                                                <span className="discount-amount"> ${getDiscountedPrice(item)}</span> | <span className="ml-1">Qty: {item.qty}</span>
                                                                            </h5> : <h5><span className="discount-amount"> ${getDiscountedPrice(item)}</span> | <span className="ml-1">Qty: {item.qty}</span></h5>}
                                                                            <h5><span className="ml-1">ID: {item._id}</span></h5>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>}
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="past">
                                                <div>
                                                    {pastOrders?.length == 0 ? <div className="no_data text-center">
                                                        <img src={require('../images/nodata.png').default} alt="" />
                                                        <h4>No data found.</h4>
                                                    </div> :
                                                        <div className="row">
                                                            {pastOrders?.map(item => {
                                                                return <div className="col-lg-3 col-md-4 mb-3">
                                                                    <div className="product-list-box mt-0">
                                                                        <a href={"/details?id=" + item._id}>
                                                                            <div className="product-list-image text-center">
                                                                                <img src={item.product?.images.length > 0 ? config.imageUrl + item.product.images[0].image : ''} alt="img" />
                                                                                <span className="confirmed">
                                                                                    <img src={require('../images/past.svg').default} alt="" />
                                                                                </span>
                                                                            </div>

                                                                            <div className="product-list-details">
                                                                                <h4>{item.product.name}</h4>
                                                                                <div className="d-flex confirm-box">
                                                                                    <h6 className="">{moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</h6>
                                                                                    <span className="discount-amount">{item.refunded ? 'Returned' : 'Delivered'}</span>
                                                                                </div>
                                                                                {item.product?.discount > 0 ? <h5>
                                                                                    <span className="discount-amount"> ${getDiscountedPrice(item)}</span> | <span className="ml-1">Qty: {item.qty}</span>
                                                                                </h5> : <h5><span className="discount-amount"> ${getDiscountedPrice(item)}</span> | <span className="ml-1">Qty: {item.qty}</span></h5>}
                                                                                <h5><span className="ml-1">ID: {item._id}</span></h5>
                                                                                <h5 className="my-2">
                                                                                    {item.rating ? Array.from(Array(item.rating.rating)).map(t => {
                                                                                        return <i class="fa fa-star" aria-hidden="true" />
                                                                                    }) : 'No rating given'}
                                                                                </h5>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>}
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="cancelled">
                                                <div>
                                                    {cancelledOrders?.length == 0 ? <div className="no_data text-center">
                                                        <img src={require('../images/nodata.png').default} alt="" />
                                                        <h4>No data found.</h4>
                                                    </div> :
                                                        <div className="row">
                                                            {cancelledOrders?.map(item => {
                                                                return <div className="col-lg-3 col-md-4 mb-3">
                                                                    <div className="product-list-box mt-0">
                                                                        <a href={"/details?id=" + item._id}>
                                                                            <div className="product-list-image text-center">
                                                                                <img src={item.product?.images.length > 0 ? config.imageUrl + item.product.images[0].image : ''} alt="img" />
                                                                                <span className="confirmed">
                                                                                    <img src={require('../images/cancellation.svg').default} alt="" />
                                                                                </span>
                                                                            </div>

                                                                            <div className="product-list-details">
                                                                                <h4>{item.product.name}</h4>
                                                                                <div className="d-flex confirm-box">
                                                                                    <h6 className="">{moment(item.updatedAt).format("MMM DD, YYYY") + " at " + moment(item.updatedAt).format("hh:mm A")}</h6>
                                                                                    <span className="discount-amount">{'Cancelled'}</span>
                                                                                </div>
                                                                                {item.product?.discount > 0 ? <h5>
                                                                                    <span className="discount-amount"> ${getDiscountedPrice(item)}</span> | <span className="ml-1">Qty: {item.qty}</span>
                                                                                </h5> : <h5><span className="discount-amount"> ${getDiscountedPrice(item)}</span> | <span className="ml-1">Qty: {item.qty}</span></h5>}
                                                                                <h5><span className="ml-1">ID: {item._id}</span></h5>
                                                                            </div>

                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>}
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />


            <Modal
                show={modalContent}
                onHide={() => setModalContent(null)}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="border-0 pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="cancelorder_modal mb-4">
                                    <h5>Cancel Order</h5>
                                    <p>Are you sure, want to cancel your order <b>"{modalContent?.product?.name}"</b> ?</p>
                                    <label className="mt-3">GIVE A REASON</label>
                                    <textarea onChange={e => setCancelReason(e.target.value)} placeholder="Enter Reason"></textarea>
                                    <a className="btn subbtn text-white" onClick={() => cancelOrder()}> Submit</a>
                                    <a className="btn nobtn text-white" onClick={() => setModalContent(null)}>No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default My_orders;