import Header from "./common/Header";
import Footer from "./common/Footer";
import Download from "./common/Download";
import { useEffect } from "react";
const Exercise = () => {

    useEffect(() => {
        localStorage.removeItem("selectedTab")
    }, [])

    return (
        <>
            <Header />
            <section className="exercise-banner">
                <div className="container">
                    <div className="exercise-text">
                        <h1>Exercise is <span>medicine,</span> prescribed by you,<br /> for you, to create <span>a healthier<br /> and happier</span> you</h1>
                    </div>
                </div>
            </section>

            <section className="exercise-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <aside className="col-lg-4 col-md-6 col-sm-6">
                            <a href="/my-exercise">
                                <div className="exercise-content-wrap text-center exercise-content-light">
                                    <img src={require("../images/update/exercise-girl.jpg").default} alt="img" />
                                    <h2>My Exercise</h2>
                                </div>
                            </a>
                        </aside>
                        <aside className="col-lg-4 col-md-6 col-sm-6">
                            <a href="/exercise-listing">
                                <div className="exercise-content-wrap text-center exercise-content-light">
                                    <img src={require("../images/update/exercise-girl.jpg").default} alt="img" />
                                    <h2>Exercise videos</h2>
                                </div>
                            </a>
                        </aside>
                    </div>
                </div>
            </section>
            <Download />
            <Footer />

        </>
    );
};
export default Exercise;
