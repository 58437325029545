import AWS from 'aws-sdk';
// import { accessAwsDetail } from './config';
function configureAWS() {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
}

configureAWS();

const S3 = new AWS.S3();
async function uploadFileToS3(file, key, type = "application/*") {
  const fileExtension = file.name.split('.').pop();
  console.log("fileExtension", fileExtension)
  console.log("file.name", file.name)
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: `${key ? key : "profile"}/${Date.now()}.${fileExtension}`,
    Body: file,
    ContentDisposition: "inline",
    ContentType: type,
    ACL: 'public-read'
  };

  try {
    console.log("params",params);
    
    const data = await S3.upload(params).promise();
    
    return data;
  } catch (err) {
    console.log("err---------->",err);
    
  }
}

async function removeFileToS3(key) {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: `posts/${key}`,
    // Body: file,
    ACL: 'public-read'
  };

  try {
    const data = await S3.deleteObject(params);
    return data;
  } catch (err) {
  }
}

async function uploadFilesToS3(files) {
  const uploadedFileLocations = [];
  try {
    // Use Promise.all to upload all files concurrently
    const uploadPromises = files.map(async (file) => {
      const fileExtension = file.name.split('.').pop();
      console.log("fileExtension", fileExtension)
      console.log("file.name", file.name)
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `posts/${Date.now()}.${fileExtension}`,
        Body: file,
        ContentDisposition: "inline",
        ContentType: "application/pdf",
        ACL: 'public-read'
      };

      const data = await S3.upload(params).promise();
      uploadedFileLocations.push(data.Location); // Store the uploaded file location
    });

    await Promise.all(uploadPromises); // Wait for all uploads to complete

    return uploadedFileLocations;
  } catch (err) {
  }
}

const generatePresignedURL = async (objectKey, expirationInSeconds = 3600) => {

  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: objectKey,
    Expires: expirationInSeconds,
  };

  return new Promise((resolve, reject) => {
    S3.getSignedUrl('getObject', params, (error, url) => {
      if (error) {
        reject(error);
      } else {
        resolve(url);
      }
    });
  });
};

export { S3, uploadFileToS3, uploadFilesToS3, generatePresignedURL, removeFileToS3 };
