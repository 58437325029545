import React, { useEffect, useRef, useState } from 'react';
import { userService } from '../../services';
import defaultProfileImage from "../../images/update/dummy-profile-img.jpg"
import EmojiPicker from 'emoji-picker-react';
import { MentionsInput, Mention } from 'react-mentions'
import Swal from 'sweetalert2';

const CommentInputBox = ({ scrollToBottom, scrollToTop, userProfileDetail, setPostComments, parentIndex, childIndex, subChildIndex, userDetail, suggestionsUser, postId, parentCommentId }) => {
    const [mainComment, setMainComment] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    // Function to handle comment submission via API
    const handleSendComment = async () => {
        if (mainComment.trim()) {
            try {
                // const response = await axios.post('/api/comments', { comment: mainComment });
                const regex = /\(([^)]+)\)/g;

                // Extract matches
                const ids = [];
                let match;
                while ((match = regex.exec(mainComment)) !== null) {
                    ids.push(match[1]);
                }
                setMainComment('');
                const response = await userService.postComment({
                    comment_text: mainComment,
                    parent_comment: parentCommentId ? parentCommentId : null,
                    post_id: postId,
                    tagUsers: ids
                });

                if (response.status == 200) {
                    if (parentCommentId) {
                        setPostComments((prev) => {
                            // Create a copy of the previous state to avoid mutations
                            const updatedData = [...prev.data];

                            if (updatedData[parentIndex]?.replies?.[childIndex]?.replies?.[subChildIndex]) {
                                updatedData[parentIndex].replies[childIndex].replies = updatedData[parentIndex].replies[childIndex].replies || [];
                                updatedData[parentIndex].replies[childIndex].childCommentCount += 1;
                                updatedData[parentIndex].replies[childIndex].showChildReplies = true;
                                updatedData[parentIndex].replies[childIndex].replies.unshift(response.data.createComment);
                            } else if (updatedData?.[parentIndex]?.replies?.[childIndex]) {
                                updatedData[parentIndex].replies[childIndex].replies = updatedData[parentIndex].replies[childIndex].replies || [];
                                updatedData[parentIndex].replies[childIndex].childCommentCount += 1;
                                updatedData[parentIndex].replies[childIndex].showChildReplies = true;
                                updatedData[parentIndex].replies[childIndex].replies.unshift(response.data.createComment);
                            } else if (updatedData[parentIndex]) {
                                updatedData[parentIndex].replies = updatedData[parentIndex].replies || [];
                                updatedData[parentIndex].showParentReplies = true;
                                updatedData[parentIndex].childCommentCount += 1;

                                updatedData[parentIndex].replies.unshift(response.data.createComment);
                            }

                            // Return the updated state
                            return { ...prev, data: updatedData };
                        });
                    } else {
                        setPostComments((prev) => ({ ...prev, commentCount: prev.commentCount + 1, data: [...prev.data, response.data.createComment] }))
                        scrollToBottom();
                    }
                }
            } catch (error) {
                console.log("error", error?.response?.status, error.response);

                if (error?.response?.status == 401) {
                    localStorage.clear();
                    await Swal.fire('Error!', error.response.data, 'error');
                    window.location.href = "/signin";
                }
            }
        }
    };

    const handleOnKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevent new line from being added
            handleSendComment();
        }
        if (!localStorage.getItem("token")) {
            window.location.href = "/signin";
        }
    }
    return (
        <>
            <div className={`type-comments position-relative ${userProfileDetail ? "" : "after-login"}`}
                onClick={() => {
                    if (!userProfileDetail) {
                        window.location.href = "/signin";
                    }
                }}
            >
                <img src={userProfileDetail?.profilePicture || defaultProfileImage} alt="img" />
                <div className="main-comment-box">
                    <MentionsInput
                        value={mainComment}
                        onChange={(e) => setMainComment(e.target.value)}
                        placeholder={"Type Comment here..."}
                        onKeyDown={handleOnKeyDown}

                        style={{
                            control: {

                                fontSize: 14,
                                fontWeight: 'normal',
                            },

                            '&multiLine': {
                                control: {
                                    minHeight: 23,
                                },
                                highlighter: {
                                    padding: 9,
                                    border: '1px solid transparent',
                                },
                                input: {
                                    padding: 9,
                                },
                            },

                            '&singleLine': {
                                display: 'inline-block',
                                width: 180,

                                highlighter: {
                                    padding: 1,
                                    border: '2px inset transparent',
                                },
                                input: {
                                    padding: 1,
                                    border: '2px inset',
                                },
                            },

                            suggestions: {
                                list: {
                                    backgroundColor: 'white',
                                    border: '1px solid rgba(0,0,0,0.15)',
                                    fontSize: 14,
                                },
                                item: {
                                    padding: '5px 15px',
                                    borderBottom: '1px solid rgba(0,0,0,0.15)',
                                    '&focused': {
                                        backgroundColor: '#cee4e5',
                                    },
                                },
                                zIndex: "9999",
                                height: "344px",
                                overflowY: "auto",
                                top: "19px",
                                left: "0",
                            },

                        }}

                    >

                        <Mention

                            trigger="@"
                            data={suggestionsUser}
                            // markup='@[__display__](__id__)'
                            displayTransform={(id, display) => {
                                return (`@${display}`)
                            }}
                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                <div style={{
                                    display: "flex", alignItems: "center", padding: "5px",
                                }} >
                                    <img
                                        src={suggestion.image}
                                        alt={suggestion.display}
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                            marginRight: "10px",
                                        }}
                                    />
                                    <span>{highlightedDisplay}</span>
                                </div>
                            )}
                            className='mention'
                            style={{
                                backgroundColor: "#d1e7ff",
                                // zIndex: "9999",
                            }}
                            appendSpaceOnAdd={true}

                        />

                    </MentionsInput>


                    <div className="text-footer">
                        <i className="fa fa-smile-o" aria-hidden="true" onClick={() => setShowEmojiPicker(!showEmojiPicker)}></i>

                        <i
                            className={`fa fa-paper-plane send-btn ${mainComment ? "" : "disabled"}`}
                            aria-hidden="true"
                            onClick={handleSendComment} // Trigger comment submission on click
                        ></i>
                    </div>
                </div>
            </div>
            <div
                onMouseLeave={() => setShowEmojiPicker(false)}
            >
                <EmojiPicker open={showEmojiPicker} onEmojiClick={(event) => setMainComment((prev) => prev + event.emoji)} emojiStyle='facebook' suggestedEmojisMode={"recent"} reactionsDefaultOpen={true} skinTonesDisabled={true} searchDisabled={true} />
            </div>
        </>
    );
};

export default CommentInputBox;
