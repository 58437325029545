import Header from './common/Header';
import Footer from './common/Footer';
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { userService } from '../services';
import Loader from './common/Loader';
import Pagination from "react-js-pagination";


const Notification = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [notificationList, setNotificationList] = useState([]);
    const [page, setpage] = useState(1)
    const [totalRecord, settotalRecord] = useState()
    let notification_type = "WEBSITE"


    useEffect(() => {
        getNotificationList(page)
        localStorage.removeItem("selectedTabAppointment")
        localStorage.removeItem("selectedTab")

    }, []);

    function getNotificationList(page) {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);
        setIsLoading(true);
        let params = { userId: user_id, page: page - 1, notification_type: notification_type }
        userService.getAllNotifications(params).then(res => {
            if (res.data.status == 200) {
                setIsLoading(false);
                settotalRecord(res?.data?.count)
                setNotificationList(res.data.data);
            }
        }).catch((error) => {
            setIsLoading(false);
            setNotificationList('');
        });
    }

    function handlePageChange(pageNumber) {
        setpage(pageNumber);
        getNotificationList(pageNumber)
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="py-4" style={{ minHeight: "500px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <h1 className='notification-heading'>Notifications</h1>
                        </div>

                        <div style={{ width: '100%' }}>
                            {notificationList.length == 0 && !isLoading && <div className="no_data text-center">
                                <img src={require('../images/nodata.png').default} alt="" />
                                <h4>No data found.</h4>
                            </div>}

                            {notificationList.length > 0 && <div className="row">
                                {notificationList.map(item => {
                                    const currentDate = moment();
                                    const bookingDate = moment(item?.appointmentData?.bookingDate);
                                    const bookingEndDate = moment(item?.appointmentData?.bookingEndDate);
                                    const status = item?.appointmentData?.status;
                                    return <div className="col-md-12">

                                        <a href={item.notificationType == 'medication_reminder' ? '' :
                                            item.notificationType == 'appointment_reminder' ? '' :
                                                item.notificationType == 'order_completed' ? "/order-details?id=" + item.id :
                                                    item.notificationType == 'order_started' ? "/order-details?id=" + item.id :
                                                        item.notificationType == 'order' ?
                                                            "/order-details?id=" + item.id :
                                                            item.notificationType == 'product_subscription' ? "/shopping-cart" : null}>
                                            <div className="notification_list" onClick={(e) => {
                                                if (item.notificationType == "APPOINTMENT_STATUS_USER" || item?.notificationType == "BOOK_APPOINTMENT_USER" || item?.notificationType == "APPOINTMENT_REMINDER_USER" ) {
                                                    if (
                                                        (status === "ACCEPTED" || status === "PENDING") &&
                                                        bookingEndDate.isAfter(currentDate)
                                                    ) {
                                                        // Redirect to upcoming menu
                                                        window.location.href = `/profilenew?activeMenu=${"appointment"}&activeTab=${"world-1"}&appointmentStatus=${true}`;
                                                        localStorage.setItem("selectedTabAppointment", "world-1")
                                                    } else if (
                                                        bookingEndDate.isBefore(currentDate) &&
                                                        (status === "ACCEPTED")
                                                    ) {
                                                        // Redirect to complete menu
                                                        window.location.href = `/profilenew?activeMenu=${"appointment"}&activeTab=${"world-2"}&appointmentStatus=${true}`;
                                                        localStorage.setItem("selectedTabAppointment", "world-2")
                                                    } else if (status === "REJECTED" || status === "CANCELLED") {
                                                        // Redirect to cancelled menu
                                                        window.location.href = `/profilenew?activeMenu=${"appointment"}&activeTab=${"world-4"}&appointmentStatus=${true}`;
                                                        localStorage.setItem("selectedTabAppointment", "world-4")
                                                    }
                                                }
                                                else if (item?.notificationType == "BOOK_APPOINTMENT_MEDUSER" || item?.notificationType == "APPOINTMENT_REMINDER_MEDUSER" || item?.notificationType == "APPOINTMENT_STATUS_MEDUSER") {
                                                    if (
                                                        (status === "PENDING") &&
                                                        bookingEndDate.isAfter(currentDate)
                                                    ) {
                                                        // Redirect to upcoming menu
                                                        window.location.href = `/profilenew?activeMenu=${"schedule"}&activeTab=${"world-1"}&scheduleStatus=${true}`;
                                                        localStorage.setItem("selectedTab", "world-1")
                                                    } else if (
                                                        bookingEndDate.isAfter(currentDate) &&
                                                        (status === "ACCEPTED")
                                                    ) {
                                                        // Redirect to complete menu
                                                        window.location.href = `/profilenew?activeMenu=${"schedule"}&activeTab=${"world-2"}&scheduleStatus=${true}`;
                                                        localStorage.setItem("selectedTab", "world-2")
                                                    }
                                                    else if ((status === "ACCEPTED") && currentDate.isBefore(bookingEndDate)) {
                                                        window.location.href = `/profilenew?activeMenu=${"schedule"}&activeTab=${"world-3"}&scheduleStatus=${true}`;
                                                        localStorage.setItem("selectedTab", "world-3")
                                                    }
                                                    else if (status === "REJECTED" || status === "CANCELLED") {
                                                        // Redirect to cancelled menu
                                                        window.location.href = `/profilenew?activeMenu=${"schedule"}&activeTab=${"world-4"}&scheduleStatus=${true}`;
                                                        localStorage.setItem("selectedTab", "world-4")
                                                    }


                                                }
                                                else if (
                                                    item.notificationType == "Exercise Video") {
                                                    window.location.href = "/exercise-listing";
                                                }
                                                else if(item?.notificationType =="Exercise_reminder" || item?.notificationType == "AdminExercise"){
                                                    window.location.href = "/my-exercise";

                                                }
                                                else if (item?.notificationType == "Post Liked" && item?.postData?.liked_by?.userType == "Professional") {
                                                    window.location.href = `/big-med-details?id=${item?.postData?.post_id}`
                                                }
                                                else if (item?.notificationType == "Post Liked" && item?.postData?.liked_by?.userType == "Client") {
                                                    window.location.href = `/big-me-details?id=${item?.postData?.post_id}`
                                                }
                                                else if (item?.notificationType == "Webinar_reminder") {
                                                    window.location.href = `world-details/${item?.webinarData?.webinarSlug}`
                                                }
                                                else if (item?.notificationType == "Webinar_Subscriber_reminder") {
                                                    window.location.href = `world-details/${item?.webinarData?.webinarSlug}`
                                                }
                                            }}>
                                                <h5 className="mb-2">
                                                    {item.title}
                                                </h5>

                                                <h6>{item.msg}</h6>
                                                <p>{item.category}</p>
                                                <div className="notification-date">
                                                    <h6><i className="fa fa-clock-o mr-1"></i>{moment(item.createdAt).fromNow()}</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                })}

                            </div>}
                            {totalRecord > 20 && (
                                <div className='col-md-12 text-center'>
                                    <div className="pagination1">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={20}
                                            totalItemsCount={totalRecord}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            hideDisabled={true}
                                            prevPageText={"Previous"}
                                            nextPageText={"Next"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Notification;