import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download';


SwiperCore.use([Pagination, Navigation, Autoplay]);

const Product = () => {

    const [showHide, setShowHide] = useState(false);
    const [allQuizes, setAllQuizes] = useState([]);
    const [selectedQuizId, setSelectedQuizId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [isPlayedFirstTime, setIsPlayedFirstTime] = useState(true);


    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) {
            setIsUserLogin(true);
            getAllQuizes();
            // setCurrentLocation();
        }
    }, []);




    function error_location(err) {
        setIsLoading(false);
        // console.warn(`ERROR(${err.code}): ${err.message}`);
        if (err.message == "User denied Geolocation") {
            // alert("Please enable location settings");
        }
        if (err.code == 2 || err.code == "2") {
            alert("We can't locate your position, please try again!");
        }
    }

    function getAllQuizes(city) {
        setIsLoading(true);
        userService.getQuizes(city).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                let quizesData = response.data.data;
                setAllQuizes(quizesData);
                for (let i = 0; i < quizesData; i++) {
                    if (quizesData[i] === 'COM') {
                        setIsPlayedFirstTime(true);
                        break;
                    }
                }
            } else {
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setAllQuizes([]);
            // console.log("error ", error);
        });
    }


    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="heading-search">
                <div className="container">
                    <h2 className="recent-search">Recent searches</h2>
                    <div className="input-group search-box">
                        <input type="text" class="form-control" placeholder="Search any category or product here" aria-label="" aria-describedby="basic-addon1" />
                        <div className="input-group-append">
                            <button className="btn" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <p className="like-favorite-box"><img src={require("../images/like.png").default} alt="img" /></p>
                            <a href="">
                                <div className="product-list-box">
                                    <div className="product-list-image text-center">
                                        <img src={require("../images/fish_oil1.png").default} alt="img" />
                                    </div>
                                    <div className="product-list-details">
                                        <h4>WOW Life Science Omega-3 Fish Oil</h4>
                                        <h6><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i> <span className="total-review ml-1">(1.2k reviews)</span></h6>
                                        <h5>Price: <del className="orginal-amount">$15.50</del> <span className="discount-amount">$13.95</span></h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <Download />
            <Footer />
        </>
    )
}


export default Product;