import React, { useState, useEffect } from "react";

const TimeAgo = ({ createdAt }) => {
    const [timeAgo, setTimeAgo] = useState("");

    // Helper function to calculate elapsed time
    const getTimeAgo = (timestamp) => {
        const now = new Date();
        const created = new Date(timestamp);
        const elapsed = Math.floor((now - created) / 1000); // Elapsed time in seconds

        if (elapsed < 60) return `Just now`; // Seconds
        if (elapsed < 3600) return `${Math.floor(elapsed / 60)}m`; // Minutes
        if (elapsed < 86400) return `${Math.floor(elapsed / 3600)}h`; // Hours
        return `${Math.floor(elapsed / 86400)}d`; // Days
    };

    // Update the timeAgo state every second
    useEffect(() => {
        const update = () => {
            setTimeAgo(getTimeAgo(createdAt));
        };

        update(); // Initial calculation
        const interval = setInterval(update, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [createdAt]);

    return <span>{timeAgo}</span>;
};

export default TimeAgo;
