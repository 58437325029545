import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button } from "react-bootstrap";
import Download from "./common/Download";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from "react";
import { userService } from "../services";
import { useHistory } from 'react-router-dom';
import { config } from "../config/config"
import Loader from './common/Loader'

const ExerciseDetails = () => {
  const [exerciseDetail, setExerciseDetail] = useState("")
  const [onplaying, setOnPlaying] = useState(false)
  const id = window?.location?.pathname?.split("/")[2]
  const history = useHistory();
  const play = useRef("")

  const [userType, setUserType] = useState(localStorage.getItem("selectedTab") || "Beginner")
  const [user_id, setUserId] = useState(localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "")
  const [exerciseData, setExerciseData] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getExerciseVideo(user_id, userType)
  }, [])

  function getExerciseVideo(user_id, userType) {
    const params = {
      userId: user_id,
      videoType: userType
    }
    setIsLoading(true)
    userService.exerciseVideo(params).then((res) => {
      if (res?.data?.status === 200) {
        setExerciseData(res?.data?.data)
        let data = res?.data?.data.find((exercise, index) => id == exercise?._id);
        setExerciseDetail(data);
        setIsLoading(false)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }



  const onVideoPlay = (item) => {
    setExerciseData(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
      )
    )
  }
  const onVideoPause = (item) => {
    setExerciseData(prev =>
      prev.map((value) =>
        value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
      )
    )
  }
  function changeIdInUrl(id) {
    window.location.href=`/exercise-details/${id}`
    let data = exerciseData.find((exercise, index) => id == exercise?._id);
    setExerciseDetail(data);
  }
  function handleContextMenu(event) {
    event.preventDefault();
}

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med-details learning-details">
        <div className="container">
          <div className="back-btn-sec mb-3">
            <Button className="btn back-btn" href="/exercise-listing"><i className="fa fa-angle-left mr-2"></i>Back to Exercise</Button>
          </div>
          {exerciseDetail &&<div className="big-med-details-wrap">
            <div className="big-med-main-img big-med-main-vid details-wrap-files">
              <video
              onContextMenu={handleContextMenu}
              controlsList="nodownload"
                width="100%"
                height="100%"
                ref={play}
                muted
                loop
                preload='auto'
                playsinline
                onPlay={(e) => setOnPlaying(true)}
                onEnded={(e) => setOnPlaying(false)}
                controls 
              >
                <source
                  src={exerciseDetail?.videoLink }
                  type="video/mp4"
                />
                <source
                  src={exerciseDetail?.videoLink }
                  type="video/ogg"
                />
                <source
                  src={exerciseDetail?.videoLink }
                  type="video/webm"
                />
              </video>
              {
                !onplaying &&

                <img className="video-btn" onClick={(e) => play.current.play()} src={require("../images/video-play.svg").default} alt="img" />}
            </div>


          </div>}
          <div className="big-med-text text-center">
            <h4 className="my-3">{exerciseDetail?.categoryName}</h4>
          </div>
          <div className="videos-swiper-section">
            <h5 className="text-center mb-3">(Recent Exercise Videos)</h5>
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              navigation
              breakpoints={{
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1366: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            }}
            >
              {exerciseData && exerciseData?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <span>
                      <div className="big-med-wrap">
                        <div className="big-med-icon-text" onClick={() => changeIdInUrl(item._id)}>
                          <div className="big-med-main-img big-med-main-vid"
                            onMouseOver={event => onVideoPlay(item)}
                            onMouseOut={event => onVideoPause(item)}
                          >
                            <span
                            >
                              {item?.videoPlay ?
                                <video
                                  autoPlay={item?.videoPlay}
                                  width="100%"
                                  height="100%"
                                  muted
                                  loop
                                  preload='auto'
                                  playsinline
                                  controls={false}
                                  poster={item?.videoLink}

                                >
                                  <source
                                    src={item?.videoLink + "#t=0.1"}
                                    type="video/mp4"
                                  />
                                  <source
                                    src={item?.videoLink + "#t=0.1"}
                                    type="video/ogg"
                                  />
                                  <source
                                    src={item?.videoLink + "#t=0.1"}
                                    type="video/webm"
                                  />
                                </video>

                                :
                                <div className="big-med-main-img">
                                  <video
                                  autoPlay={item?.videoPlay}
                                  width="100%"
                                  height="100%"
                                  muted
                                  loop
                                  preload='auto'
                                  playsinline
                                  controls={false}
                                  poster={item?.videoLink}

                                >
                                  <source
                                    src={item?.videoLink + "#t=0.1"}
                                    type="video/mp4"
                                  />
                                  <source
                                    src={item?.videoLink + "#t=0.1"}
                                    type="video/ogg"
                                  />
                                  <source
                                    src={item?.videoLink + "#t=0.1"}
                                    type="video/webm"
                                  />
                                </video>
                            </div>
                              }
                            </span>

                            {!item.videoPlay &&
                              <img className="video-btn" onClick={(e) => onVideoPlay(item)} src={require("../images/video-play.svg").default} alt="img" />
                            }


                          </div>
                        </div>
                      </div>
                    </span>
                  </SwiperSlide>

                )
              })}
            </Swiper>
          </div>
        </div>

      </div>
      <Download />
      <Footer />
    </>
  );
};
export default ExerciseDetails;
