import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button } from "react-bootstrap";
import Download from "./common/Download";
import { useEffect, useRef, useState } from "react";
import { userService } from "../services";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { config } from '../config/config';
import { faTruckFieldUn } from "@fortawesome/free-solid-svg-icons";
import Loader from "./common/Loader";
import ReactPlayer from "react-player";
const ELearningDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [e_learningDetail, setE_learningDetail] = useState()
  const [onplaying, setOnPlaying] = useState(false)
  const id = window?.location?.pathname?.split("/")[2]
  const play = useRef("")

  useEffect(() => {
    E_learningDetails(id)
  }, [])

  function E_learningDetails(id) {
    setIsLoading(true)
    userService.e_learningDetails(id).then((res) => {
      if (res?.data?.status === 200) {
        setIsLoading(false)
        setE_learningDetail(res?.data?.data)
      }
    })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  function handleContextMenu(event) {
    event.preventDefault();
  }


  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className="big-med-details learning-details">
        <div className="container">
          <div className="back-btn-sec mb-3">
            <Button className="btn back-btn" href="/e-learning"><i className="fa fa-angle-left mr-2"></i>Back to Listing</Button>
          </div>
          <div className="big-med-details-wrap">

            <ReactPlayer
              onContextMenu={e => e.preventDefault()}
              url={e_learningDetail?.url}
              controls={true}
              width={"100%"}
              height={"auto"}
              muted={true}
              className="react-workout-player"
              config={{
                file: {
                  attributes: {
                    // crossOrigin: "true",
                    controlsList: 'nodownload'
                  },
                  tracks: [
                    {
                      kind: "subtitles",
                      src: config.imageUrl + e_learningDetail?.captions,
                      srcLang: "en",
                      default: true,
                    },
                  ],
                },
              }}
            />
          </div>
          <div className="big-med-text">
            {e_learningDetail && <h4>{e_learningDetail?.title}</h4>}
          </div>

        </div>

      </div>
      <Download />
      <Footer />
    </>
  );
};
export default ELearningDetail;
