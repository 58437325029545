import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Navbar, Nav, Dropdown, NavDropdown, Button, Modal, Form, InputGroup, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { userService } from "../../services";
import { config } from "../../config/config";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import VideoCall from "../../pages/VideoCall";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const Header = (props) => {
  const [isLoadingImage, setIsLoadingImage]=useState(true)
  const [editModal, setEditModal] = useState(false)
  const [temp, setTemp] = useState('aa')
  const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user')))
  const regExPositiveInt = /^\d+$/;
  const [values, setValues] = useState({ weightType: "kg", height: "0", glucose: "0", cholesterol: "0", bloodPressureMax: "0", bloodPressureMin: "0", weight: "0" })
  const heightOptions = ["4", "4'1", "4'2", "4'3", "4'4", "4'5", "4'6", "4'7", "4'8", "4'9", "4'10", "4'11", "5", "5'1", "5'2", "5'3", "5'4", "5'5", "5'6", "5'7", "5'8", "5'9", "5'10", "5'11", "6", "6'1", "6'2", "6'3", "6'4", "6'5", "6'6", "6'7", "6'8", "6'9", "6'10", "6'11", "7"]
  const [submitting, setSubmitting] = useState(false)
  const [isModified, setIsModified] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [favCount, setFavCount] = useState("");
  const [notiCount, setNotiCount] = useState();
  const [cartCount, setCartCount] = useState();
  const [userDetail, setUserDetail] = useState()
  const [chatCount, setChatCount]=useState()

  const [showSolution, setShowSolution] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showShop, setShowShop] = useState(false);
  const [showResource, setShowResource] = useState(false);

  const handleSolutionHover = () => {
    setShowSolution((prevShow) => (prevShow = !prevShow));
    setShowProducts(false)
    setShowResource(false)
    setShowShop(false)
  }
  const handleProductHover = () => {
    setShowProducts((prevShow) => (prevShow = !prevShow));
    setShowResource(false)
    setShowSolution(false)
    setShowShop(false)
  }
  const handleShopHover = () => {
    setShowShop((prevShow) => (prevShow = !prevShow));
    setShowResource(false)
    setShowSolution(false)
    setShowProducts(false)
  }
  const handleResourceHover = () => {
    setShowResource((prevShow) => (prevShow = !prevShow));
    setShowShop(false)
    setShowSolution(false)
    setShowProducts(false)
  }


  const userLanguage = navigator.language.split('-')[0];

  i18n.use(initReactI18next).init({
    lng: userLanguage, 
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false,
    },
  });

  useEffect(() => {
    setProfileImage(localStorage.getItem("image"));
    getCounts();
  }, [props.refresh]);

  useEffect(() => {
    getProfileList()
  }, [])

  const [isUserPresent, setIsUserPresent] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    setProfileImage(localStorage.getItem("image"));
    let isUser = localStorage.getItem("user_id");
    if (isUser) setIsUserPresent(true);
    getCounts(isUser);
  }, []);

  function getCounts() {
    let isUser = localStorage.getItem("user_id");
    userService
      .getProductCount(isUser)
      .then((response) => {
        if (response.data.status == 200) {
          setFavCount(response.data.favCount);
          setCartCount(response.data.cartCount);
          setNotiCount(response.data.notiCount);
          setChatCount(response?.data?.chatCount)
        } else {
        }
      })
      .catch((error) => {
      });
  }

  function onLogOut() {
    setIsLoading(true);
    localStorage.clear();
    toast.success("Successfully Logout");
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  function checkIfValid(val) {
    if (
      !isUserPresent ||
      val == undefined ||
      val == null ||
      val == ""
    )
      return false;
    else return true;
  }

  function Logout(e) {
    let logout =
      Swal.fire({
        icon: "info",
        html: `<div class="logout-text">
        Are you sure you want to logout?
      </div>
      `,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          localStorage.clear();
          window.location.href = "/signin";
        }
      });

  }

  function getProfileList() {
    let user_id = localStorage.getItem('user_id');
     let token =localStorage.getItem("token")
    let params = { userId: user_id, token:token }
    userService.getProfile(params).then(res => {
      if (res.data.status == 200) {
        let userData = res.data.data;
        setUserDetail(userData)
        setIsLoadingImage(false)
        localStorage.setItem("qb_user_id", userData?.qb_user_id)
      }
    }).catch((error) => {
    });
  }



  let location = window?.location?.pathname;
  const [elearningstatus, setElearningStatus] = useState(false)

  function handleChangeExercise(e) {
    setElearningStatus(true)
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("userType") === "Client") {
        if (userDetail && !userDetail.weight) {
          window.location.href = "/exercise";
          return;
        }
        if (userDetail && userDetail.weight) {
          window.location.href = "/exercise";
        }
      } else if (localStorage.getItem("userType") === "Professional") {
        window.location.href = "/exercise";
      }
    }
    else {
      window.location.href = "/exercise";
    }

  }


  function handleChangeE_Learning(e) {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("userType") === "Client") {
        if (userDetail && !userDetail.weight) {
          window.location.href = "/e-learning";
          return;
        }
        if (userDetail && userDetail.weight) {
          window.location.href = "/e-learning";
        }
      } else if (localStorage.getItem("userType") === "Professional") {
        window.location.href = "/e-learning";
      }
    }
    else {
      window.location.href = "/e-learning";
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <VideoCall isFromHeader="true" />
      <div className="main_header redesign-header">
        <header className="">
          <div className="container-fluid">
            <section className="header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="nav1">

                      <div className="">
                        <Navbar expand="lg" className="pl-0">
                          {location !== "/create-profile" && <Navbar.Brand href="#home">
                            <a href="/#">
                              {" "}
                              <img
                                src={
                                  require("../../images/update/logo.png").default
                                }
                                alt="img"
                              />
                            </a>
                          </Navbar.Brand>}
                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                              {location !== "/create-profile" && <Nav.Link href="/" className={location == "/" ? "active" : ""}>HOME</Nav.Link>}
                              {/* {location !== "/create-profile" && <Nav.Link href="/about" className={location == "/about" ? "active" : ""}>ABOUT US</Nav.Link>} */}
                              {location !== "/create-profile" &&
                                <NavDropdown
                                  title="SOLUTION"
                                  id="collasible-nav-dropdown"
                                  show={showSolution}
                                  onMouseEnter={handleSolutionHover}
                                  onMouseLeave={handleSolutionHover}
                                  className={location == "/big-med" || location == "/big-me" ? "active" : ""}
                                >
                                  <NavDropdown.Item href="/big-med">
                                    BIG4 Health Med
                                  </NavDropdown.Item>
                                  <NavDropdown.Item href="/big-me">
                                    BIG4 Health Me
                                  </NavDropdown.Item>
                                  <NavDropdown.Item href="/world-listing">
                                    BIG4 Health World
                                  </NavDropdown.Item>
                                  {/* <NavDropdown.Item href="/health-md">
                                  Find Healthcare Provider
                                  </NavDropdown.Item> */}
                                </NavDropdown>}
                                 {location !== "/create-profile" && <Nav.Link  href="/health-md" className={location == "/health-md" ? "active" : ""}>Find Healthcare Provider</Nav.Link>}
                              {location !== "/create-profile" && <NavDropdown
                                title="PRODUCTS"
                                id="collasible-nav-dropdown"
                                show={showProducts}
                                onMouseEnter={handleProductHover}
                                onMouseLeave={handleProductHover}
                                className={location == "/business" || location == "/health-app" ? "active" : ""}
                              >
                                <NavDropdown.Item href="/business">
                                  BIG4 Health Business
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/health-app">
                                  BIG4 Health App
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/wellness">
                                  Health and Wellness Speaker
                                </NavDropdown.Item>

                              </NavDropdown>}
                              {location !== "/create-profile" && <NavDropdown title="SHOP" id="collasible-nav-dropdown"
                                show={showShop}
                                onMouseEnter={handleShopHover}
                                onMouseLeave={handleShopHover}
                              >
                                <NavDropdown.Item href="/ingredient">
                                  BIG4 Health Ingredients
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/offer">
                                  Offer
                                </NavDropdown.Item>
                              </NavDropdown>}
                              {location !== "/create-profile" && <NavDropdown
                                title="RESOURCES"
                                id="collasible-nav-dropdown"
                                show={showResource}
                                onMouseEnter={handleResourceHover}
                                onMouseLeave={handleResourceHover}
                                className={location == "/foodoption" || location == "/blog" || location == "/faq" ? "active" : ""}
                              >
                                <NavDropdown.Item href="/foodoption">
                                  Food Options
                                </NavDropdown.Item >
                                <NavDropdown.Item onClick={(e) => handleChangeExercise(e)}>
                                  Exercise
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e) => handleChangeE_Learning(e)}>
                                  Educational Videos
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/blog">
                                  Blogs
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/faq">
                                  FAQ
                                </NavDropdown.Item>
                              </NavDropdown>}
                              {location !== "/create-profile" && localStorage.getItem("token") &&  localStorage.getItem("user_id") && <Nav.Link data-toggle="tooltip" title="Messages" 
                               onClick={(e) =>{
                                            if(userDetail?.ProfileCreated ==0){
                                                Swal.fire("Warning", "Your profile is incomplete. Please take a moment to fill in the required details.", "warning").then((e) => {
                                                    window.location.href = "/create-profile";
                                                    let redirectUri = window.location.pathname + window.location.search;
                                                    localStorage.setItem("redirectUri", redirectUri);
                                                })
                                            }
                                            else{
                                                window.location.href = `/chat`;
                                            }
                                        }} className={location == "/chat" ? "active" : ""}>
                                <img
                                  src={
                                    require("../../images/update/chat-top.svg").default
                                  }
                                  alt="img" className="img-hide-mobile"
                                />
                                <span className="txt-for-mobile d-none">Messages</span>
                                {chatCount ? <span className="count-text">{chatCount}</span> : " "}
                              </Nav.Link>}
                              {location !== "/create-profile" && localStorage.getItem("token") &&   localStorage.getItem("user_id") && <Nav.Link data-toggle="tooltip"
                              //  onClick={(e) => window.location.href ="/notifications"} 
                              href="/notifications"
                               title="Notifications" className={location == "/contact" ? "active" : ""}>

                                <img
                                  src={
                                    require("../../images/update/notification.svg").default
                                  }
                                  alt="img" className="img-hide-mobile"
                                />
                                <span className="txt-for-mobile d-none">NOTIFICATION</span>
                                {/* {checkIfValid(notiCount) && {<span className="count-text">{notiCount}</span>} */}
                                {notiCount ? <span className="count-text">{notiCount}</span> : " "}
                              </Nav.Link>}
                              {location !== "/create-profile" && localStorage.getItem("token") &&  localStorage.getItem("user_id") && <Nav.Link data-toggle="tooltip" title="Favorites" href="/my_favorites" className={location == "/contact" ? "active" : ""}>
                                <img 
                                  src={
                                    require("../../images/update/like.svg").default
                                  }
                                  alt="img" className="img-hide-mobile"
                                />
                                <span className="txt-for-mobile d-none">FAVORITES</span>
                                {checkIfValid(favCount) && <span className="count-text">{favCount}</span>}
                              </Nav.Link>}
                              {location !== "/create-profile" && localStorage.getItem("token") &&  localStorage.getItem("user_id") && <Nav.Link data-toggle="tooltip" title="Cart" href="/shopping-cart" className={location == "/contact" ? "active" : ""}>
                                <img
                                  src={
                                    require("../../images/update/cart-top-icon.png").default
                                  }
                                  alt="img" className="img-hide-mobile top-cart-icon"
                                />
                                <span className="txt-for-mobile d-none">SHOPPING CART</span>
                                {checkIfValid(cartCount) && <span className="count-text">{cartCount}</span>}
                              </Nav.Link>}
                              {location !== "/create-profile" && <div id="google_translate_element"></div>}
                              {location !== "/create-profile" ?
                                localStorage.getItem("token") &&  localStorage.getItem("user_id") &&
                                <NavDropdown
                                className="pull-left-profile"
                                title={
                                  <div className="pull-left" data-toggle="tooltip" title="Profile">
                                    {isLoadingImage ? (
                                      <Skeleton circle height={40} width={40} className="profile-image" /> // Shimmer effect while loading
                                    ) : (
                                      <img
                                        src={
                                          userDetail && userDetail.profilePicture && userDetail?.profilePicture !== "undefined"
                                            ? userDetail.profilePicture
                                            : require('../../images/update/dummy-profile-img.jpg').default
                                        }
                                        alt="img"
                                        className="profile-image"
                                      />
                                    )}
                                  </div>
                                }
                                id="basic-nav-dropdown"
                              >
                                  <NavDropdown.Item
                                    onClick={(e) => {

                                      if (userDetail.userType == "Professional" && userDetail?.admin_status != "Approved") {
                                        window.location.href = "/create-profile";
                                      }
                                      else if (userDetail?.userType == "Client" && userDetail?.ProfileCreated != 1) {
                                        window.location.href = "/create-profile";
                                      }
                                      else {
                                        window.location.href = "/profilenew";
                                      }
                                    }}>
                                    Profile
                                  </NavDropdown.Item>
                                  <NavDropdown.Item onClick={Logout} >
                                    Logout
                                  </NavDropdown.Item>
                                </NavDropdown> :
                                <ul>
                                  <li className="signup_btn pl-2 pr-0">
                                    <a className="btn logout" onClick={Logout}>
                                      LOGOUT
                                    </a>
                                  </li>
                                </ul>
                              }

                            </Nav>
                            <ul className="login-top-area-right">
                              {location !== "/signin" && !isUserPresent && (
                                <li className="signin_btn pr-0">
                                  <a className="btn" href="/signin">
                                    SIGN IN
                                  </a>
                                </li>
                              )}
                              {location == "/signin" && !isUserPresent && (
                                <li className="signup_btn pl-2 pr-0">
                                  <a
                                    className="btn"
                                    onClick={() => {
                                      localStorage.clear();
                                      window.location.href = "/register";
                                    }}
                                  >
                                    SIGN UP
                                  </a>
                                </li>
                              )}
                            </ul>
                          </Navbar.Collapse>
                        </Navbar>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </header>



      </div>
      <Modal show={editModal} onHide={() => {
        setEditModal(false);
        elearningstatus == true ? window.location.href = "/exercise" : window.location.href = "/e-learning";
      }} backdrop="static" className="edit-profile-pop">
        <Modal.Header closeButton><h5>Please provide your health information.</h5></Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group>
              <label>Height</label>
              <Form.Control as="select" value={values?.height} onChange={(e) => { setValues((val) => ({ ...val, height: e.target.value })); setIsModified(true) }}>
                {heightOptions.map((op) => { return <option value={op} selected={op == values?.height ? true : false}>{op}</option> })}
              </Form.Control>
            </Form.Group>

            <div>
              <label>Weight</label>
              <InputGroup>
                <Form.Control type='number' placeholder="Weight" className="input103 " value={values?.weight} onChange={(e) => {
                  if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                    setValues((val) => ({ ...val, weight: e.target.value ? e.target.value : 0 }))
                    setIsModified(true)

                  }
                }}
                />
                <DropdownButton title={values.weightType} align="end" variant="outline-primary" id="weight-unit">
                  <Dropdown.Item href="#" onClick={() => { setValues((val) => ({ ...val, weightType: "kg" })); setIsModified(true) }}
                  >kg</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => setValues((val) => ({ ...val, weightType: "lbs" }))}>lbs</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
            <Form.Group className='mt-3'>
              <div className='row'>
                <div className='col-md-6'>
                  <label>Blood Pressure Min</label>
                  <Form.Control placeholder="0" value={values.bloodPressureMin} onChange={(e) => {
                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                      setValues((val) => ({ ...val, bloodPressureMin: e.target.value }))
                      setIsModified(true)
                    }
                  }} />
                </div>
                <div className='col-md-6'>
                  <label>Blood Pressure Max</label>
                  <Form.Control placeholder="0" value={values.bloodPressureMax} onChange={(e) => {
                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                      setValues((val) => ({ ...val, bloodPressureMax: e.target.value }))
                      setIsModified(true)
                    }
                  }} />
                </div>
              </div>
            </Form.Group>
            <Form.Group>
              <label>Glucose</label>
              <InputGroup>
                <Form.Control type='number' placeholder="0" className="input103 " value={values?.glucose} onChange={(e) => {
                  if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                    setValues((val) => ({ ...val, glucose: e.target.value }))
                    setIsModified(true)
                  }
                }} />
                <Button variant="outline-primary" id="button-addon1">
                  mg/dl
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <label>Cholesterol</label>
              <InputGroup>
                <Form.Control type='number' placeholder="0" className="input103 " value={values?.cholesterol} onChange={(e) => {
                  if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                    setValues((val) => ({ ...val, cholesterol: e.target.value }))
                    setIsModified(true)
                  }
                }} />
                <Button variant="outline-primary" id="button-addon1">
                  mg/dl
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <div className='text-center'>
                <button
                  disabled={submitting || !isModified}
                  className='btn btn-primary px-4'
                  onClick={(e) => {
                    if (!values?.height) {
                      Swal.fire("Warning", "Please select height", "warning");
                    } else if (!values?.weight) {
                      Swal.fire("Warning", "Please enter Weight", "warning");
                    } else {
                      setSubmitting(true);
                      userService
                        .editProfile({
                          ...values,
                          userId: localStorage.getItem('user_id'),
                          'foot': values?.height?.split("'")[0],
                          'inch': values?.height?.split("'")[1] ? values?.height?.split("'")[1] : 0,
                          'weightType': values.weightType,
                          'weight': values?.weight,
                        })
                        .then((res) => {
                          setSubmitting(false);
                          setEditModal(false);
                          setUser(res.data.data[0]);
                          toast.dismiss();
                          getProfileList();
                          toast.success("Data updated successfully", {
                            toastId: 'success_draft_1',
                          });
                          localStorage.setItem('user', JSON.stringify(res.data.data[0]));
                          let data = res.data.data[0].profilePicture;
                          setTemp(data);
                        })
                        .catch((err) => {
                          setSubmitting(false);
                          toast.dismiss();
                          toast.error("An error occurred", {
                            toastId: 'error_draft_1',
                          });
                        });
                    }
                  }}
                >
                  Update
                </button>
              </div>
            </Form.Group>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
