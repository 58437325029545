import React, { useState, useEffect } from 'react'

import { Swiper, SwiperSlide, } from 'swiper/react';
import { Modal } from 'react-bootstrap';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { userService } from '../services';
import { toast } from 'react-toastify';
import Loader from './common/Loader'
import { config } from '../config/config'
import Header from './common/Header'
import Footer from './common/Footer'
import Download from './common/Download';


SwiperCore.use([Pagination, Navigation, Autoplay]);

const Shop = () => {

    const [categories, setCategories] = useState([]);
    const [selectedQuizId, setSelectedQuizId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [userId, setUserId] = useState('');
    const [offers, setOffers] = useState([]);


    useEffect(() => {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);
        setIsLoading(true);
        getCategories([]);
        getOffers();
    }, []);

    function getOffers() {
        setIsLoading(true);
        userService.getOffers().then((res) => {
            if (res.status === 200) {
                setIsLoading(false);
                setOffers(res.data.offers);
            }
        }).catch((err) => {

        });
    }

    function goToOffer(id) {
        id ? window.location.href = window.location.origin + '/product_details?id=' + id : window.location.href = window.location.origin;
    }

    function getCategories(searchTxt) {
        userService.getCategories(searchTxt).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                setCategories(response.data.data);
            } else {
                setCategories([]);
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setCategories([]);
        });
    }

    function handleSearch(txt) {
        if (/^[A-Za-z]*$/.test(txt)) {
            setSearchText(txt);
            getCategories(txt);
        }
    }

    function redirectToProducts(cat) {
        localStorage.setItem('cat_name', cat.name);
        setTimeout(() => {
            window.location.href = `/product?id=${cat._id}`;
        }, 100);
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <section className="heading-search mb-2">
                <div className="container">
                    <h2>SHOP</h2>
                    <div className="input-group search-box">
                        <input type="text" class="form-control" placeholder="Search by category name" aria-label="" aria-describedby="basic-addon1" value={searchText} onChange={(e) => handleSearch(e.target.value)} />
                        <div className="input-group-append">
                            <button className="btn" type="button"><i class="fa fa-search" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="banner pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Swiper
                                pagination={{ clickable: true }}
                                slidesPerView={1}
                                autoplay={{ delay: 3000 }}
                            >
                                {offers.map((offer) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="slider_box mb-4" onClick={() => goToOffer(offer?.product_id?._id)}>
                                                <div className="row align-items-center">
                                                    <div className="col-md-12 text-center">
                                                        <img src={offer?.image ? config.imageUrl + offer?.image : ''} alt="img" height="400px" />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-area-box">
                <div className="container">
                    <div className="row">
                        {categories.length > 0 && categories.map((cat) => {
                            return (<div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                                <a className="pointer shop_img" onClick={() => redirectToProducts(cat)}>
                                    <div className="first-product text-center pt-0">
                                        <div className="product-image mb-3">
                                            <img src={cat.image ? config.imageUrl + cat.image : require("../images/no-image.png").default} alt="img" />
                                        </div>
                                        <p>{cat.name}</p>
                                    </div>
                                </a>
                            </div>)
                        })}
                       
                    </div>
                    {categories.length === 0 && !isLoading && <p className="no-categgory text-center">No Categories Available. </p>}
                </div>
            </section>

            <Download />
            <Footer />
        </>
    )
}


export default Shop;