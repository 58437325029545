import React, { useState, useEffect } from 'react'
import Header from './common/Header'
import { Modal, Form, InputGroup, DropdownButton, Dropdown, Button } from 'react-bootstrap'
import Footer from './common/Footer'
import moment from 'moment'
import { toast } from 'react-toastify'
import { userService } from '../services'
import { config } from '../config/config'
import Subscriptions from './Subscriptions'

const Profile = () => {
    const [show, setShow] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const regExPositiveInt = /^\d+$/;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowShareModal = () => setShowShareModal(true)
    const handleCloseShareModal = () => setShowShareModal(false)
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user')))
    const [userId, setUserId] = useState('');
    const [temp, setTemp] = useState('aa')
    const [oldPwd, setOldPwd] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const [email, setEmail] = useState("")
    const [shareEmail, setShareEmail] = useState("")
    const [editModal, setEditModal] = useState(false)
    const [values, setValues] = useState({ weightType: "kg" })
    const [submitting, setSubmitting] = useState(false)
    var emailRegrex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    let user_id = localStorage.getItem('user_id');
    const heightOptions = ["4", "4'1", "4'2", "4'3", "4'4", "4'5", "4'6", "4'7", "4'8", "4'9", "4'10", "4'11", "5", "5'1", "5'2", "5'3", "5'4", "5'5", "5'6", "5'7", "5'8", "5'9", "5'10", "5'11", "6", "6'1", "6'2", "6'3", "6'4", "6'5", "6'6", "6'7", "6'8", "6'9", "6'10", "6'11", "7"]

    useEffect(() => {
        getProfileList()
    }, []);



    function handleSendDetails() {
        if (shareEmail === '') {
            toast.error('Please enter email address');
        } else if (!emailRegrex.test(shareEmail)) {
            toast.error("Please enter valid email address");
        } else {
            let params = { userId: user_id, email: shareEmail }
            userService.sendProfileDetails(params).then(res => {
                if (res.data.status === 200) {
                    toast.success("Mail sent Successfully")
                    handleCloseShareModal()
                }
            }).catch((error) => {
            })
        }
    }


    function getProfileList() {
        let user_id = localStorage.getItem('user_id');
        if (user_id) setUserId(user_id);
        // let token =localStorage.getItem("token")
    let params = { userId: user_id}
        userService.getProfile(params).then(res => {
            if (res.data.status == 200) {
                let userData = res.data.data
                setEmail(userData.email)
                setUser(userData)
                setValues({ height: userData.height, weight: userData.weight, weightType: userData.weightType, bloodPressureMin: userData.bloodPressureMin, bloodPressureMax: userData.bloodPressureMax, glucose: userData.glucose, cholesterol: userData.cholesterol, bmi: userData.bmi })
            }
        }).catch((error) => {
            setUser('');
        });
    }

    function calculateBMI(h, w) {
        let arry = h.split("'");
        let params = new URLSearchParams({
            'foot': arry[0],
            'inch': arry[1] ? arry[1] : 0,
            'weight': w,
            'weightType': values.weightType
        });
        userService.calculateBMI(params).then((response) => {
            setUser((val) => ({ ...val, bmi: response.data.data }))
        }).catch((error) => {
        });
    }

    function checkImagefile(file_img) {
        var get_ext = file_img.name.split('.');
        var exts = ['png', 'jpg', 'jpeg'];
        get_ext = get_ext.reverse();
        var a = exts.indexOf(get_ext[0].toLowerCase());
        if (a > -1) {
            return true;
        } else {
            return false;
        }
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(
            `NAME:${user?.firstName} ${user?.lastName}\nBMI:${user?.bmi}\nHEIGHT:${user?.height}\nWEIGHT:${user?.weight}${user?.weightType}\nBLOOD PRESSURE:${user?.bloodPressureMax}/${user?.bloodPressureMin}\nGLUCOSE:${user?.glucose}\nCHOLESTEROL:${user?.cholesterol}`
        )
        toast.success("Copied to clipboard")
    }

    function updateImage(e) {
        if (checkImagefile(e.target.files[0])) {
            let data = new FormData()
            data.append("profilePicture", e.target.files[0])
            data.append("userId", localStorage.getItem('user_id'))
            data.append("email", user?.email)

            userService.uploadImage(data).then(res => {
                if (res.status == 200) {
                    user.profilePicture = res.data.data
                    onImageUpdate(localStorage.getItem('user_id'), res.data.data, user?.email)
                }
            })
        } else {
            toast.error("Please upload a valid image.")
        }
    }

    function onImageUpdate(id, image, email) {
        const credentials = {
            userId: id,
            email: email,
            profilePicture: image
        }

        userService.editProfile(credentials).then(res => {
            localStorage.setItem('user', JSON.stringify(res.data.data[0]));
            localStorage.setItem('image', res.data.data[0].profilePicture);
            let data = res.data.data[0].profilePicture
            setTemp(data);
        })
    }

    function resetPassword() {
        if (oldPwd == '') {
            toast.error("Old password field can't be empty")
        } else if (newPwd == '') {
            toast.error("New password field can't be empty")
        } else if (newPwd == oldPwd) {
            toast.error("New password can't be same as old password")
        } else {
            let params = { userId: userId, oldPassword: oldPwd, newPassword: newPwd }
            userService.updatePassword(params).then((response) => {
                if (response.data.status == 200) {
                    setOldPwd('')
                    setNewPwd('')
                    handleClose();
                    toast.success("Password Has Been Reset Successfully");
                }
                else if (response.data.status == 201) {
                    toast.error('Enter Correct Old Password')
                }
                else {
                    toast.error(response.data.message);
                }

            }).catch((error) => {
            });
        }
    }



    return (
        <>
            <Header refresh={temp} />
            <section className="py-5 profile_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">

                            <div className="text-center profile_img2">
                                <img src={user?.profilePicture ? user?.profilePicture : "http://ssl.gstatic.com/accounts/ui/avatar_2x.png"} style={{ objectFit: 'contain' }} className="avatar img-circle img-thumbnail p-0" alt="avatar" />
                                <i className='fa fa-edit file-upload'></i>
                                <input accept='.jpg, .jpeg, .png' type="file" onChange={updateImage} className="text-center center-block file-upload custom-input-add" />
                                <h6>{user?.firstName} {user?.lastName}</h6>
                                {user?.isPremium ? <p>Premium Account </p> : ""}
                                {user?.isPremium ? <p style={{ color: "gray" }}> To upgrade and buy another package <br />please go to the apps for futher informaton.</p> : ""}
                                <p>Joined {moment(user?.signUpDate).format('YYYY-MM-DD')}</p>
                                <div className="inforbox py-2 container-fluid mt-2">
                                    <div className="list-group">
                                        <li className="list-group-item text-right">
                                            <span className="pull-left"><strong> <span className="bmi">WALLET</span></strong></span>
                                            <p className="ch mb-0">${parseFloat(user?.wallet).toFixed(2)}</p>
                                        </li>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <ul className="list-group mt-4">
                                <h6>Share with your doctor:</h6>
                                <li className="list-group-item text-left">
                                    <span className="bp"><strong>BLOOD PRESSURE</strong></span>
                                    <p className="mb-0">{user?.bloodPressureMax}/{user?.bloodPressureMin}</p>
                                </li>
                                <li className="list-group-item text-left">
                                    <span className="gl"><strong>GLUCOSE</strong></span>
                                    <p className="mb-0">{user?.glucose} mg/dl</p>
                                </li>
                                <li className="list-group-item text-left">
                                    <span className="ch"><strong>CHOLESTEROL</strong></span>
                                    <p className="mb-0">{user?.cholesterol} mg/dl</p>
                                </li>
                                <li className="list-group-item text-left">
                                    <span className="bmi"><strong>BMI</strong></span>
                                    <p className="mb-0">{parseFloat(user?.bmi).toFixed(2)}</p>
                                </li>
                                <li className="list-group-item text-left sharebtn">
                                    <button onClick={handleShowShareModal} className="btn">Share</button>
                                </li>
                            </ul>

                            <ul className="list-group mt-4">
                                <a className="" href="/cards">
                                    <li className="list-group-item text-right">
                                        <span className="pull-left"><strong>My cards</strong></span> <i className="fa fa-credit-card"></i>
                                    </li>
                                </a>
                                <a className="" style={{ cursor: 'pointer' }} onClick={handleShow}>
                                    <li className="list-group-item text-right">
                                        <span className="pull-left" ><strong>Reset Password</strong></span> <i className="fa fa-lock"></i>
                                    </li>
                                </a>
                            </ul>

                        </div>

                        <div className="col-md-9">
                            <div className="info_head">
                                <h5 className="mb-0">Information</h5>
                                <button className="edit_btn btn" onClick={() => setEditModal(true)}>Edit</button>
                            </div>
                            <div className="inforbox py-2 container-fluid mt-2">
                                <div className="row">
                                    <aside className="col-md-6 text-left my-2">
                                        <div className="input_row">
                                            <label>Email Address</label>
                                            <input type="text" name="" placeholder="Email Address" className="input103 w-100" value={user?.email} disabled />
                                        </div>
                                    </aside>

                                    <aside className="col-md-6 text-left my-2">
                                        <div className="input_row">
                                            <label>Height</label>
                                            <input type="text" name="" placeholder="Height" className="input103 w-100" value={user?.height} disabled />
                                        </div>
                                    </aside>

                                    <aside className="col-md-6 text-left my-2">
                                        <div className="row">
                                            <aside className="col-md-6">
                                                <div className="input_row">
                                                    <label>Weight</label>
                                                    <input type="text" name="" placeholder="Height" className="input103 w-100" value={user?.weightType} disabled />
                                                </div>
                                            </aside>
                                            <aside className="col-md-6">
                                                <div className="input_row">
                                                    <label style={{ opacity: 0 }}>Weight</label>
                                                    <input type="text" name="" placeholder="Weight" className="input103 w-100" value={user?.weight} disabled />
                                                </div>
                                            </aside>
                                        </div>
                                    </aside>
                                </div>


                            </div>
                            <div className="Subscription-division">
                                <h2 >Subscriptions</h2>
                                <Subscriptions />
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <Footer />
            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className='reset_password'>
                    <form>
                        <div className="">
                            <input type="password" name="" placeholder="Old Password" className="input103 w-100" value={oldPwd} onChange={(e) => setOldPwd(e.target.value)} />
                        </div>
                        <div className="">
                            <input type="password" name="" placeholder="New Password" className="input103 w-100" value={newPwd} onChange={(e) => setNewPwd(e.target.value)} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn resetbtn' onClick={resetPassword}>Reset</button>
                    <button className='btn cancelbtn' onClick={handleClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showShareModal} onHide={handleCloseShareModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body className='reset_password'>

                    <input type="text" name="" placeholder="Email" className="input103 w-100" onChange={(e) => setShareEmail(e.target.value)} value={shareEmail} />

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn resetbtn' onClick={handleSendDetails}><i class="fa fa-send-o" ></i>&nbsp;Send</button>
                    <button className='btn cancelbtn' onClick={handleCloseShareModal}>Cancel</button>
                </Modal.Footer>
            </Modal>

            <Modal show={editModal} onHide={() => setEditModal(false)} backdrop="static">
                <Modal.Header closeButton><h5>Edit Profile</h5></Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Group>
                            <label>Height</label>
                            <Form.Control as="select" onChange={(e) => setValues((val) => ({ ...val, height: e.target.value }))}>
                                {heightOptions.map((op) => { return <option value={op} selected={op == values?.height ? true : false}>{op}</option> })}
                            </Form.Control>
                        </Form.Group>
                        <div>
                            <label>Weight</label>
                            <InputGroup>
                                <Form.Control type='number' placeholder="Weight" className="input103 " value={values?.weight} onChange={(e) => {
                                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                        setValues((val) => ({ ...val, weight: e.target.value }))
                                    }
                                }}
                                />
                                <DropdownButton title={values.weightType} align="end" variant="outline-primary" id="weight-unit">
                                    <Dropdown.Item href="#" onClick={() => setValues((val) => ({ ...val, weightType: "kg" }))}>kg</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => setValues((val) => ({ ...val, weightType: "lbs" }))}>lbs</Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                        </div>
                        <Form.Group className='mt-3'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label>Blood Pressure Min</label>
                                    <Form.Control value={values.bloodPressureMin} onChange={(e) => {
                                        if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                            setValues((val) => ({ ...val, bloodPressureMin: e.target.value }))
                                        }
                                    }} />
                                </div>
                                <div className='col-md-6'>
                                    <label>Blood Pressure Max</label>
                                    <Form.Control value={values.bloodPressureMax} onChange={(e) => {
                                        if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                            setValues((val) => ({ ...val, bloodPressureMax: e.target.value }))
                                        }
                                    }} />
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <label>Glucose</label>
                            <InputGroup>
                                <Form.Control type='number' placeholder="Glucose" className="input103 " value={values?.glucose} onChange={(e) => {
                                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                        setValues((val) => ({ ...val, glucose: e.target.value }))
                                    }
                                }} />
                                <Button variant="outline-primary" id="button-addon1">
                                    mg/dl
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <label>Cholesterol</label>
                            <InputGroup>
                                <Form.Control type='number' placeholder="Cholesterol" className="input103 " value={values?.cholesterol} onChange={(e) => {
                                    if (regExPositiveInt.test(e.target.value) || e.target.value == '') {
                                        setValues((val) => ({ ...val, cholesterol: e.target.value }))
                                    }
                                }} />
                                <Button variant="outline-primary" id="button-addon1">
                                    mg/dl
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <div className='text-center'>
                                <button disabled={submitting} className='btn btn-primary px-4' onClick={() => {
                                    setSubmitting(true)
                                    userService.editProfile({ ...values, userId: localStorage.getItem('user_id'), email: email, }).then(res => {
                                        setSubmitting(false)
                                        setEditModal(false)
                                        setUser(res.data.data[0])
                                        toast.dismiss()
                                        toast.success("Data updated successfully.")
                                        localStorage.setItem('user', JSON.stringify(res.data.data[0]));
                                        calculateBMI(values.height, values.weight)
                                        let data = res.data.data[0].profilePicture
                                        setTemp(data);
                                    }).catch((err) => {
                                        setSubmitting(false)
                                        toast.dismiss()
                                        toast.error("An error occured")
                                    })
                                }}>Update</button>
                            </div>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>

       
        </>
    )
}
export default Profile;