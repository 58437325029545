import { useEffect, useState } from "react";
import { userService } from "../services";
import { toast } from 'react-toastify';
import moment from 'moment'
import { Button, Form, Modal } from "react-bootstrap";


function MyOrder() {
    const [lgShow, setLgShow] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [activeOrders, setActiveOrders] = useState([]);
    const [cancelledOrders, setCancelledOrders] = useState([]);
    const [pastOrders, setPastOrders] = useState([]);
    const [selectedTab, setTab] = useState('Active');
    const [cancelReason, setCancelReason] = useState('');

    useEffect(() => {
        if (localStorage.getItem('user_id')) {
            if (selectedTab == 'Active')
                userService.getActiveOrders().then(res => {
                    if (res.status == 200) {
                        setActiveOrders(res.data.data)
                    }
                })
        }
    }, [])

    const cancelOrder = () => {
        if (!cancelReason) {
            toast.error('Please enter cancellation reason',{
                toastId: 'success_draft_1',
            })
        } else {
            let params = { cancel_reason: cancelReason }
            userService.cancelOrder(modalContent._id, params).then(res => {
                setModalContent(null)
                setCancelReason("")
                setLgShow(false)
                if (selectedTab == 'Active')
                userService.getActiveOrders().then(res => {
                    if (res.status == 200) {
                        setActiveOrders(res.data.data)
                    }
                })
            })
            .catch((error) => {
                        })
        }
    }

    // const cancelOrder = () => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     let params = { cancel_reason: cancelReason }
    //     if (!cancelReason) {
    //       toast.error('Please enter cancellation reason', {
    //         toastId: 'success_draft_1',
    //       })
    //     } else {
    //       userService.cancelOrder(urlParams.get('id'), params).then(res => {
    //         setLgShow(false)
    //         setCancelReason("")
    //         window.location.reload()
    //         if (res?.data?.status === 400) {
    //           toast.error(res?.data?.message)
    //         }
    //       })
    //         .catch((error) => {
    //         })
    //     }
    //   }

    function getDiscountedPrice(item) {
        return parseFloat((item.price)*(item?.qty) + item.shipping_charge + ((item.tax / 100) * item.price)).toFixed(2)
    }

    const handleTabClick = (tab) => {
        setTab(tab);
        localStorage.setItem("webnairType", tab);
        if (tab === "active") {
            userService.getActiveOrders().then(res => {
                if (res.status == 200) {
                    setActiveOrders(res.data.data)
                }
            })
        }
        else if (tab === "past") {
            userService.getPastOrders().then(res => {
                if (res.status == 200) {
                    setPastOrders(res.data.data)
                }
            })
        }
        else if (tab === "cancelled") {
            userService.getCancelledOrders().then(res => {
                if (res.status == 200) {
                    setCancelledOrders(res.data.data)
                }
            })
        }
    };

   


    return (
        <>
            <div className="my-order profile-right-body row">
                <ul class="nav nav-tabs mb-3">
                    <li class="nav-item">
                        <a
                            className={`nav-link ${selectedTab === 'Active' ? 'active' : ''}`}
                            onClick={(e) => { handleTabClick('active'); localStorage.setItem("Ordercategory", "Active"); }} data-toggle="tab" href="#Active">Active</a>
                    </li>
                    <li class="nav-item">
                        <a
                            className={`nav-link ${selectedTab === 'post' ? 'active' : ''}`}
                            onClick={(e) => { handleTabClick('past'); localStorage.setItem("Ordercategory", "past") }} data-toggle="tab" href="#post">Past</a>
                    </li>
                    <li class="nav-item">
                        <a
                            className={`nav-link ${selectedTab === 'cancel' ? 'active' : ''}`}
                            onClick={(e) => { handleTabClick('cancelled'); localStorage.setItem("Ordercategory", "cancelled") }} data-toggle="tab" href="#cancel">Cancelled</a>
                    </li>
                </ul>
                <div className="order-list-area-content">
                <div class="tab-content">
                    <div class="tab-pane container active p-0" id="Active">
                        {activeOrders?.length > 0 ? activeOrders?.map((item, key) => {
                            
                            return (
                                <div className="order-box mt-3 d-flex align-items-center w-100" >
                                    <img  onClick={(e) => window.location.href=`/order-details?id=`+ item._id} src={require("../images/update/Ing-product.png").default} alt="img" className="order-product" />
                                    {item?.status == "ONWAY" && <img src={require("../images/update/check-mark-green.svg").default} alt="img" className="check-mark-green" />}
                                    <div className="w-100  order-detail-right">
                                        <div className="detail-order-top">
                                        <h4 onClick={(e) => window.location.href=`/order-details?id=`+ item._id}>{item?.product?.name}</h4>
                                        {item.status == 'ACT' ?
                                                <span className="discount-amount" style={{ color: '#ff9933' }}>{'Pending'}</span> : <a href="#" className="hightlight-green-btn">On the Way</a>}
                                        </div>
                                       
                                        <p className="mb-1" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}><b>{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</b></p>
                                        <p className="mb-3" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}><b className="blue-text">${item?.grandtotal >0 ? item?.grandtotal.toFixed(2) :getDiscountedPrice(item)}</b> Qty: {item?.qty}</p>
                                        

                                        <div className="order-status-bottom-area">
                                        <div className="d-flex align-items-center justify-content-between order-id-area">
                                            <span className="unique-id">ID: {item?.product?._id}</span>
                                           
                                            
                                        </div>
                                        <div className="order-status">
                                    
                                                      <a href='#' className='shopping-btn delete-btn' onClick={() =>{ setLgShow(true); setModalContent(item) }}>Cancel Order</a> 
                                    </div>
                                        </div>
                               
                                     
                                    </div>
                                 
                                </div>)
                        }) :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>}

                    </div>

                    <div class="tab-pane container p-0" id="post">
                        {pastOrders?.length > 0 ? pastOrders?.map((item) => {
                            return (

                                <div className="order-box mt-3 d-flex align-items-center w-100" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}>
                                    <img src={require("../images/update/Ing-product.png").default} alt="img" className="order-product" />
                                    <img src={require("../images/update/delivered.svg").default} alt="img" className="check-mark-green" />
                                    <div className="w-100  order-detail-right">
                                        <h4>{item?.product?.name}</h4>
                                        <p className="mb-1"><b>{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</b></p>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <p className="mb-1"><b className="blue-text">${item?.grandtotal >0 ? item?.grandtotal.toFixed(2) :getDiscountedPrice(item)}</b> Qty: {item?.qty}</p>
                                            <h5 className="ratings">
                                                {item.rating ? Array.from(Array(item.rating.rating)).map(t => {
                                                    return <i class="fa fa-star" aria-hidden="true"></i>
                                                }) : 'No rating given'}
                                            </h5>
                                        </div>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <span className="unique-id">ID:  {item?.product?._id}</span>
                                            {item.refunded ==false ? <a href="#" className="hightlight-blue-btn">Returned</a> : (item.status == 'COM' && item.refunded ==true) && <a href="#" className="hightlight-blue-btn">Delivered</a>}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="tab-pane container p-0" id="cancel" >
                        {cancelledOrders?.length > 0 ? cancelledOrders?.map((item, index) => {
                            return (
                                <div className="order-box mt-3 d-flex align-items-center w-100" onClick={(e) => window.location.href=`/order-details?id=`+ item._id}>
                                    <img src={require("../images/update/Ing-product.png").default} alt="img" className="order-product" />
                                    <img src={require("../images/update/shipping.svg").default} alt="img" className="check-mark-green" />
                                    <div className="w-100  order-detail-right">
                                        <h4>{item?.product?.name}</h4>
                                        <p className="mb-1"><b>{"Ordered On " + moment(item.createdAt).format("MMM DD, YYYY") + " at " + moment(item.createdAt).format("hh:mm A")}</b></p>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <p className="mb-1"><b className="blue-text">${item?.grandtotal >0 ? item?.grandtotal.toFixed(2) :getDiscountedPrice(item)}</b> Qty: {item?.qty}</p>
                                        </div>
                                        <div className="mb-1 d-flex align-items-center justify-content-between">
                                            <span className="unique-id">ID: {item?.product?._id}</span>
                                            <a href="#" className="hightlight-red-btn">Cancelled</a>
                                        </div>
                                    </div>
                                </div>
                            )

                        }) :
                            <div className="profile-right-side no-data-profile no-upper-main-data mb-5">
                                <div className="no-data-box">
                                    <img src={require("../images/update/no-data.png").default} alt="img" className="mr-2" />
                                    <h3>No data found</h3>
                                </div>
                            </div>
                        }

                    </div>

                </div>
                </div>
            </div>
              {/* cancel order section start */}

      <Modal show={lgShow}
        centered className='quiz-pop appointment-box cancel-order-popup'>
        <Modal.Header
        >
          <Modal.Title><h4><b>Cancel Order</b></h4></Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='appointment-box text-center'>
            <h5 className='mb-4'>Are you sure you want to Cancel this <b>"{activeOrders[0]?.product?.name}"</b>?</h5>
            <div className="Signup_box text-center contact-main-box">
              <Form className="text-left mt-3 cancel-order-area">
                <label className='text-left'>
                  GIVE A REASON<span className="mandatory"></span>
                </label>
                <textarea
                  onChange={e => setCancelReason(e.target.value)}
                  value={cancelReason}
                  placeholder="Enter Reason">
                </textarea>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none m-auto">
          <Button className='btn notnowbtn' onClick={() => setLgShow(false)}>NO</Button>
          <Button className='btn quiznow-btn' onClick={() => cancelOrder()}>SUBMIT</Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}
export default MyOrder;